// Copyright © Veeam Software Group GmbH

import first from 'lodash/first';
import moment from 'moment';
import { useCallback } from 'react';
import { map } from 'rxjs/operators';
import { z } from 'zod';

import type { DurationInputArg1 } from 'moment';
import type { Observable } from 'rxjs';
import type { TypeOf } from 'zod';
import type {
    Sorting } from 'infrastructure/client-side-operations';
import type { LocalizableString } from 'infrastructure/resources';

import { restoreSessionApi } from 'api/rxjs';
import {
    createFilterEngine,
    filters,
    createSortEngine,
    comparators,
} from 'infrastructure/client-side-operations';
import { never } from 'infrastructure/never';
import { loadPages } from 'infrastructure/rxjs';
import { useResources } from 'infrastructure/resources';
import { RestoreSessionStatus, RestoreSessionType } from '../models';
import { convertSessionFromRest } from './converters';

import type { RestoreSession, RestoreSessionId } from '../models';

export enum RestoreSessionsSortKeys {
    Title = 'Title',
    Type = 'Type',
    Status = 'Status',
    StartTime = 'StartTime',
    EndTime = 'EndTime',
    Details = 'Details',
    Reason = 'Reason',
    ScopeName = 'ScopeName',
}

export enum GetRestoreSessionsPeriod {
    LastDay = 'last-day',
    LastWeek = 'last-week',
    LastMonth = 'last-month',
}

export const RestoreSessionsFilterSchema = z.object({
    period: z
        .array(z.nativeEnum(GetRestoreSessionsPeriod))
        .nullish()
        .transform(val => first(val) || GetRestoreSessionsPeriod.LastWeek),
    search: z
        .string()
        .nullish()
        .transform(val => val || ''),
    type: z
        .array(z.nativeEnum(RestoreSessionType))
        .nullish()
        .transform(val => val || []),
    status: z
        .array(z.nativeEnum(RestoreSessionStatus))
        .nullish()
        .transform(val => val || []),
});

export type RestoreSessionsFilter = TypeOf<typeof RestoreSessionsFilterSchema>;

export const filterSessions = createFilterEngine<RestoreSession, RestoreSessionsFilter>({
    period: filters.skip,
    search: filters.search(session => session.reason),
    status: filters.enum(session => session.status),
    type: filters.enum(session => session.type),
});

export const sortSessions = createSortEngine<RestoreSession, typeof RestoreSessionsSortKeys>({
    Title: (one, two) => comparators.string(one.type, two.type),
    Type: (one, two) => comparators.string(one.type, two.type),
    Status: (one, two) => comparators.string(one.status, two.status),
    StartTime: (one, two) => comparators.date(one.startTime, two.startTime),
    EndTime: (one, two) => comparators.date(one.endTime, two.endTime),
    Details: (one, two) => comparators.string(one.details, two.details),
    Reason: (one, two) => comparators.string(one.reason, two.reason),
    ScopeName: (one, two) => comparators.string(one.scopeName, two.scopeName),
});

function getDuration(period: GetRestoreSessionsPeriod): DurationInputArg1 {
    switch (period) {
        case GetRestoreSessionsPeriod.LastDay:
            return { day: 1 };
        case GetRestoreSessionsPeriod.LastWeek:
            return { week: 1 };
        case GetRestoreSessionsPeriod.LastMonth:
            return { month: 1 };
        default:
            return never(period);
    }
}

export function useRestoreSessionTitle(): (session: RestoreSession) => LocalizableString {
    const resources = useResources().services.restoreService.sessions.restoreSessionTitle;
    return useCallback(
        (session: RestoreSession) => {
            switch (session.type) {
                case RestoreSessionType.Vex:
                    return resources.vex;
                case RestoreSessionType.Vesp:
                    return resources.vesp;
                case RestoreSessionType.Veod:
                    return resources.veod;
                case RestoreSessionType.Vet:
                    return resources.vet;
                default:
                    return never(session.type);
            }
        },
        [resources]
    );
}

interface GetSessionsRequest {
    filter: RestoreSessionsFilter;
    sorting: Sorting<typeof RestoreSessionsSortKeys> | undefined;
}

export function getSessions(request: GetSessionsRequest): Observable<RestoreSession[]> {
    const duration = getDuration(request.filter.period);
    const to = moment().utc()
        .startOf('day')
        .add({ day: 1 });
    const from = moment(to).subtract(duration);

    return loadPages(restoreSessionApi.restoreSessionGetPage)({
        startTimeFrom: from.toISOString(),
        startTimeTo: to.toISOString(),
    }).pipe(
        map(sessions => sessions.data.map(convertSessionFromRest)),
        map(sessions => filterSessions(request.filter, sessions)),
        map(sessions => sortSessions(request.sorting, sessions))
    );
}

export function getSession(restoreSessionId: RestoreSessionId): Observable<RestoreSession | undefined> {
    return restoreSessionApi.restoreSessionGetByRestoreSessionId({ restoreSessionId }).pipe(
        map(response => response.map(undefined)),
        map(session => (session ? convertSessionFromRest(session) : undefined))
    );
}
