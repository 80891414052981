// Copyright © Veeam Software Group GmbH

import React from 'react';
import { useModel } from '@veeam/core';
import {
    Button,
    BUTTON_VIEW,
    CONTROL_SIZE,
    FormLayout,
    GROUP_DIRECTION,
    INDENT,
    LabelGroup,
    RadioField,
    StackView,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_GAP,
    TextField,
} from '@veeam/components';
import { observeOn } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';

import type { UsePanelActions, WizardStepConfig } from 'components/wizard';
import type { Form } from '@veeam/core';
import type { FC } from 'react';
import type { FormWithRestoreOptions } from 'features/Restore/Wizards/ExchangeRestoreWizard/RestoreOptionsPanel';
import type { Fieldset } from 'infrastructure/types';
import type { Resources, LocalizableString } from 'infrastructure/resources';

import { RestoreWizardPanelsEnum } from 'features/Restore/Wizards/types';
import { never } from 'infrastructure/never';
import { PageSection } from 'components/wizard';

export enum RestoreMode {
    Original = 'Original',
    AnotherPlace = 'AnotherPlace',
}

export interface FormWithRestoreMode {
    restoreMode: RestoreMode;
    folderName: string;
}

type StepResources = Resources['features']['Restore']['Wizards']['ExchangeRestoreWizard']['RestoreModeStep'];

function mapLabel(type: RestoreMode, resources: StepResources): LocalizableString {
    switch (type) {
        case RestoreMode.Original:
            return resources.originalLabel;
        case RestoreMode.AnotherPlace:
            return resources.anotherPlaceLabel;
        default:
            return never(type);
    }
}

const RestoreModeForm: FC<{
    form: Form<FormWithRestoreMode>;
    resources: StepResources;
    panelActions?: UsePanelActions<RestoreWizardPanelsEnum>;
}> = ({ form, resources, panelActions }) => {
    const {
        value: { restoreMode },
    } = useModel(form.model);
    return (
        <PageSection title={resources.title} description={resources.description}>
            <FormLayout direction={GROUP_DIRECTION.column}>
                <RadioField
                    label={resources.originalLabel}
                    helpText={resources.originalDescription}
                    field={form.fields.restoreMode}
                    value={RestoreMode.Original}
                />
                <LabelGroup
                    label={
                        <RadioField
                            label={resources.anotherPlaceLabel}
                            helpText={resources.anotherPlaceDescription}
                            field={form.fields.restoreMode}
                            value={RestoreMode.AnotherPlace}
                        />
                    }
                >
                    <StackView
                        spaceLeft={INDENT.l}
                        direction={STACK_DIRECTION.column}
                        gap={STACK_GAP.s}
                        align={STACK_ALIGN.start}
                    >
                        <TextField
                            field={form.fields.folderName}
                            disabled={restoreMode !== RestoreMode.AnotherPlace}
                            size={CONTROL_SIZE.l}
                        />
                        <Button
                            ui={BUTTON_VIEW.text}
                            onClick={() => {
                                panelActions?.showPanel(RestoreWizardPanelsEnum.RestoreOptions, {
                                    panelMinWidth: '400px',
                                });
                            }}
                        >
                            {resources.showAdvancedOptionsLabel}
                        </Button>
                    </StackView>
                </LabelGroup>
            </FormLayout>
        </PageSection>
    );
};

export const useRestoreModeStep = (
    form: Form<FormWithRestoreMode>,
    resources: StepResources,
    panelActions?: UsePanelActions<RestoreWizardPanelsEnum>
): WizardStepConfig => ({
    tabTitle: resources.title,
    tag: 'items',
    form: {
        asObserver: () => form.model.asObservable().pipe(observeOn(asyncScheduler)),
        isValid: () => form.getMetaValue().isValid,
        submit: () => {
            form.validateAndDisplayErrors();
        },
    },
    body: <RestoreModeForm form={form} resources={resources} panelActions={panelActions} />,
});

export const getRestoreModeSummary = (
    value: FormWithRestoreMode & FormWithRestoreOptions,
    resources: StepResources
): Fieldset => ({
    title: resources.restoreSummary,
    fields: [
        {
            label: resources.title,
            value: mapLabel(value.restoreMode, resources),
        },
        {
            label: resources.folderNameLabel,
            value: value.folderName,
            hidden: value.restoreMode === RestoreMode.Original,
        },
        {
            label: resources.restoreChangedItemsLabel,
            value: value.restoreChangedItems ? resources.yes : resources.no,
        },
        {
            label: resources.restoreMissingItemsLabel,
            value: value.restoreMissingItems ? resources.yes : resources.no,
        },
        {
            label: resources.markRestoredItemsLabel,
            value: value.markRestoredItems ? resources.yes : resources.no,
        },
    ],
});
