// Copyright © Veeam Software Group GmbH

import { teamsChannelApi, teamsFileApi, teamsPostApi, teamsTabApi, teamsTeamApi } from 'api/rxjs';
import { exploreSessionService } from 'services/exploreSessions';
import { renewSession } from '../../renewSession';

import type { Fetch } from '../../renewSession';

export const renewTeamsSession = <TRequest, TResponse>(fetch: Fetch<TRequest, TResponse>): Fetch<TRequest, TResponse> =>
    renewSession(fetch, exploreSessionService.renewSession.team);

export const teamsApi = {
    getTeams: renewTeamsSession(teamsTeamApi.teamsTeamGet),
    getTeamChildren: renewTeamsSession(teamsChannelApi.teamsChannelGet),
    getTeamItems: renewTeamsSession(teamsChannelApi.teamsChannelGet),
    getChannelChildren: renewTeamsSession(teamsChannelApi.teamsChannelGetChildrenPage),
    getChannelItems: renewTeamsSession(teamsChannelApi.teamsChannelGetChildrenPage),
    getTabItems: renewTeamsSession(teamsTabApi.teamsTabGet),
    getPostItems: renewTeamsSession(teamsPostApi.teamsPostGetPage),
    getFileItems: renewTeamsSession(teamsFileApi.teamsFileGetPage),

    searchTabItems: renewTeamsSession(teamsTabApi.teamsTabSearch),
    searchPostItems: renewTeamsSession(teamsPostApi.teamsPostSearch),
    searchFileItems: renewTeamsSession(teamsFileApi.teamsFileSearch),

    getFileVersions: renewTeamsSession(teamsFileApi.teamsFileGetVersions),
};
