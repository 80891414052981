// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTGroupMember
 */
export interface RESTGroupMember {
    /**
     * ID of the group to which the account belongs.
     * @type {string}
     * @memberof RESTGroupMember
     */
    groupId?: string;
    /**
     * Name of the account.
     * @type {string}
     * @memberof RESTGroupMember
     */
    name?: string;
    /**
     * Account login.
     * @type {string}
     * @memberof RESTGroupMember
     */
    login?: string;
    /**
     * Type of the account.
     * @type {string}
     * @memberof RESTGroupMember
     */
    type?: RESTGroupMemberTypeEnum;
    /**
     * Defines whether the account belongs to the Microsoft Online group, not on-premises.
     * @type {boolean}
     * @memberof RESTGroupMember
     */
    isCloud?: boolean;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTGroupMember
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTGroupMemberTypeEnum {
    Unknown = 'Unknown',
    User = 'User',
    Group = 'Group'
}

