// Copyright © Veeam Software Group GmbH

import type { StorageKeys } from './StorageKeys';

export interface RawStorage<T> {
    get(): T | undefined;
    save(value: T): void;
    clear(): void;
}

export function getLocalStorage<T>(key: StorageKeys): RawStorage<T> {
    const save = (value: T): void => localStorage.setItem(key, JSON.stringify(value));

    const clear = (): void => localStorage.removeItem(key);

    function get(): T | undefined {
        const content = localStorage.getItem(key);

        if (!content) return undefined;

        try {
            return JSON.parse(content);
        } catch (err) {
            console.error(err);
        }

        clear();

        return undefined;
    }

    return { get, save, clear };
}
