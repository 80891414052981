// Copyright © Veeam Software Group GmbH

import type { Product } from '../Product';
import type { NodeBase } from '../NodeBase';
import type { OneDriveId } from './OneDriveId';

export enum OneDriveNodeType {
    OneDrive = 'OneDrive',
    Folder = 'Folder',
}

export type OneDriveFolderId = string & { __onedrive_folder_id: never; };

interface OneDriveFolderBase extends NodeBase {
    product: Product.OneDrive;
    nodeType: OneDriveNodeType;
    oneDriveId: OneDriveId;
}

export interface OneDrive extends OneDriveFolderBase {
    nodeType: OneDriveNodeType.OneDrive;
}

export interface OneDriveFolder extends OneDriveFolderBase {
    nodeType: OneDriveNodeType.Folder;
    folderId: OneDriveFolderId;
}

export type OneDriveNode = OneDrive | OneDriveFolder;
