// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTExchangeItemStringId,
} from './';

/**
 * @export
 * @interface RESTRestoreToDifferentLocationSelectedItems
 */
export interface RESTRestoreToDifferentLocationSelectedItems {
    /**
     * Defines whether all versions of mailbox items will be restored.
     * @type {boolean}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    changedItems?: boolean;
    /**
     * Defines whether the deleted mailbox items will be restored.
     * @type {boolean}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    deletedItems?: boolean;
    /**
     * Defines whether the restored mailbox items will be marked as unread.
     * @type {boolean}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    markRestoredAsUnread?: boolean;
    /**
     * Defines whether the *Drafts* mailbox folder will not be restored.
     * @type {boolean}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    excludeDrafts?: boolean;
    /**
     * Defines whether the *Deleted Items* mailbox folder will not be restored.
     * @type {boolean}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    excludeDeletedItems?: boolean;
    /**
     * Defines whether the preserved items of mailboxes placed on In-Place Hold will not be restored.
     * @type {boolean}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    excludeInPlaceHoldItems?: boolean;
    /**
     * Defines whether the preserved items of mailboxes placed on Litigation Hold will not be restored.
     * @type {boolean}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    excludeLitigationHoldItems?: boolean;
    /**
     * Specifies the email address of the mailbox to which you want to restore mailbox items.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    mailbox?: string;
    /**
     * Specifies the Microsoft Exchange server with Client Access Server (CAS) role. The mailbox items will be restored to a specified mailbox server.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    casServer?: string;
    /**
     * Specifies the folder to which you want to restore mailbox items.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    folder?: string;
    /**
     * Specifies the region of the target Exchange organization.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    officeRegion?: RESTRestoreToDifferentLocationSelectedItemsOfficeRegionEnum;
    /**
     * Specifies the name of the target Exchange organization.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    officeOrganizationName?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the Exchange organization.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    userPassword?: string;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    userCode?: string;
    /**
     * Specifies the ID of the Azure AD application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    applicationId?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Azure AD application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    applicationCertificate?: string;
    /**
     * Specifies a user name of the account that will be used as a Microsoft Exchange account to restore backed-up mailbox items.  **Note**: This property is required if you want to use an application certificate for data restore. Use this property only with the `applicationCertificate` property. 
     * @type {string}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    impersonationAccountName?: string;
    /**
     * Specifies IDs of mailbox items that you want to restore. For more information on how to get such IDs, see [Get Mailbox Items](#tag/ExchangeItem/operation/ExchangeItem_Get).
     * @type {Array<RESTExchangeItemStringId>}
     * @memberof RESTRestoreToDifferentLocationSelectedItems
     */
    items?: Array<RESTExchangeItemStringId>;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreToDifferentLocationSelectedItemsOfficeRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

