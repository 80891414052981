// Copyright © Veeam Software Group GmbH

import { useEffect, useState } from 'react';
import { useRouterActions } from '@veeam/core';
import { APP_BAR_ITEM_TYPE } from '@veeam/components';

import type { RouterApi } from '@veeam/core';
import type { LinkTypeItemProps } from '@veeam/components';
import type { Resources, LocalizableString } from 'infrastructure/resources';

import { Routes } from 'features/Routing';
import { restoreService } from 'services/restoreService';

type NavResources = Resources['features']['Main']['bar'];

const createLink = (title: LocalizableString, route: Routes, { isActive, goTo }: RouterApi): LinkTypeItemProps => ({
    type: APP_BAR_ITEM_TYPE.link,
    text: title,
    active: isActive(route),
    onClick: () => goTo(route),
});

const createRecoveryListLink = (
    resources: NavResources,
    itemsCount: number,
    route: Routes,
    { isActive, goTo }: RouterApi
): LinkTypeItemProps | undefined =>
    isActive(route) || itemsCount > 0
        ? {
            type: APP_BAR_ITEM_TYPE.link,
            text:
                  itemsCount === 0 ? resources.recoveryListTitle : resources.recoveryListTitleXCount.format(itemsCount),
            active: isActive(route),
            onClick: () => goTo(route),
        }
        : undefined;

function useRecoveryListItemsCount(): number {
    const [itemsCount, setItemsCount] = useState(0);

    useEffect(() => {
        const subscription = restoreService.bucket.onItemsCountChangedSubject.subscribe(setItemsCount);

        restoreService.bucket.getItemsCount()
            .then(setItemsCount);

        return () => subscription.unsubscribe();
    }, []);

    return itemsCount;
}

export function useNavigation(resources: NavResources): LinkTypeItemProps[] {
    const router = useRouterActions();
    const itemsCount = useRecoveryListItemsCount();
    const tabs = [
        createLink(resources.exploreTitle, Routes.explore, router),
        createLink(resources.restoreSessionsTitle, Routes.restoreSessions, router),
        createRecoveryListLink(resources, itemsCount, Routes.recoveryList, router),
    ].filter(Boolean);

    return tabs;
}
