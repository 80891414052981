// Copyright © Veeam Software Group GmbH

import React from 'react';
import { useForm } from '@veeam/core';
import { FormLayout, CheckboxField, LabelGroup, SidePanel, SidePanelBody, ACTION_VIEW } from '@veeam/components';

import type { Form, Model } from '@veeam/core';
import type { FC } from 'react';
import type { Resources } from 'infrastructure/resources';
import type { PanelProps } from 'components/wizard';

import { useResources } from 'infrastructure/resources';
import { useMessageBoxManagement } from 'infrastructure/error-management';


export interface FormWithRestoreOptions {
    restoreChangedItems: boolean;
    restoreMissingItems: boolean;
    markRestoredItems: boolean;
}

type PanelResources = Resources['features']['Restore']['Wizards']['ExchangeRestoreWizard']['RestoreOptionsPanel'];

const validateRestoreOptionsStep = (form: Form<FormWithRestoreOptions>) => {
    const { restoreChangedItems, restoreMissingItems } = form.getValue();
    return restoreChangedItems || restoreMissingItems;
};

const RestoreOptionsForm: FC<{ form: Form<FormWithRestoreOptions>; resources: PanelResources; }> = ({
    form,
    resources,
}) => (
    <FormLayout>
        <CheckboxField label={resources.restoreChangedItemsLabel} field={form.fields.restoreChangedItems} />
        <CheckboxField label={resources.restoreMissingItemsLabel} field={form.fields.restoreMissingItems} />
        <LabelGroup label={resources.markRestoredItemsTitle}>
            <CheckboxField label={resources.markRestoredItemsLabel} field={form.fields.markRestoredItems} />
        </LabelGroup>
    </FormLayout>
);

export const RestoreOptionsPanel: FC<PanelProps<Model<FormWithRestoreOptions>>> = ({
    formConfig: formModel,
    isActive,
    closePanel,
}) => {
    const resources = useResources().features.Restore.Wizards.ExchangeRestoreWizard.RestoreOptionsPanel;
    const form = useForm(formModel.getValue());
    const { show } = useMessageBoxManagement();
    if (!isActive) {
        return null;
    }

    return (
        <SidePanel title={resources.title} description={resources.description} onRequestClose={closePanel}>
            <SidePanelBody
                actions={[
                    {
                        id: 'apply',
                        view: ACTION_VIEW.primary,
                        text: resources.applyLabel,
                        onClick: () => {
                            if (!validateRestoreOptionsStep(form)) {
                                show({ message: resources.oneOfRestoreOptionsShouldBeSpecified });
                                return;
                            }
                            formModel.update((values) => {
                                const { restoreChangedItems, restoreMissingItems, markRestoredItems } = form.getValue();
                                values.restoreChangedItems = restoreChangedItems;
                                values.restoreMissingItems = restoreMissingItems;
                                values.markRestoredItems = markRestoredItems;
                            });
                            closePanel();
                        },
                    },
                    {
                        id: 'cancel',
                        view: ACTION_VIEW.secondary,
                        text: resources.cancelLabel,
                        onClick: () => {
                            closePanel();
                        },
                    },
                ]}
                content={<RestoreOptionsForm form={form} resources={resources} />}
            ></SidePanelBody>
        </SidePanel>
    );
};
