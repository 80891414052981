// Copyright © Veeam Software Group GmbH

import { restoreExchange } from './restoreExchange';
import { restoreOneDrive, OneDriveRestoreType } from './restoreOneDrive';
import { restoreSharePoint, SharePointVersion, SharePointLastVersionAction } from './restoreSharePoint';

export type { ExchangeRestoreOptions } from './restoreExchange';
export type { OneDriveRestoreOptions } from './restoreOneDrive';
export type { SharePointRestoreOptions } from './restoreSharePoint';


export const restoreService = {
    exchange: restoreExchange,
    oneDrive: restoreOneDrive,
    sharePoint: restoreSharePoint,
};

export { OneDriveRestoreType, SharePointVersion, SharePointLastVersionAction };
