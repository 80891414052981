// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTJobStatistics,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTJobSession
 */
export interface RESTJobSession {
    /**
     * Job session ID.
     * @type {string}
     * @memberof RESTJobSession
     */
    id?: string;
    /**
     * Backup repository ID.
     * @type {string}
     * @memberof RESTJobSession
     */
    repositoryId?: string;
    /**
     * Job session ID.
     * @type {string}
     * @memberof RESTJobSession
     */
    mainSessionId?: string;
    /**
     * Job session details.
     * @type {string}
     * @memberof RESTJobSession
     */
    details?: string;
    /**
     * Date and time when the job session was created.
     * @type {string}
     * @memberof RESTJobSession
     */
    creationTime?: string;
    /**
     * Date and time when the latest job session ended.
     * @type {string}
     * @memberof RESTJobSession
     */
    endTime?: string;
    /**
     * Order number of the job session retry.
     * @type {number}
     * @memberof RESTJobSession
     */
    retryCount?: number;
    /**
     * Defines whether the job session will be restarted.
     * @type {boolean}
     * @memberof RESTJobSession
     */
    jobWillBeRetried?: boolean;
    /**
     * Number of processed objects during the job session.
     * @type {number}
     * @memberof RESTJobSession
     */
    progress?: number;
    /**
     * Type of the job session.
     * @type {string}
     * @memberof RESTJobSession
     */
    jobType?: RESTJobSessionJobTypeEnum;
    /**
     * Status of the backup or backup copy job.
     * @type {string}
     * @memberof RESTJobSession
     */
    status?: RESTJobSessionStatusEnum;
    /**
     * @type {RESTJobStatistics}
     * @memberof RESTJobSession
     */
    statistics?: RESTJobStatistics;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTJobSession
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTJobSessionJobTypeEnum {
    Backup = 'Backup',
    Copy = 'Copy'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTJobSessionStatusEnum {
    Stopped = 'Stopped',
    Running = 'Running',
    Success = 'Success',
    Failed = 'Failed',
    Warning = 'Warning',
    NotConfigured = 'NotConfigured',
    Disconnected = 'Disconnected',
    Queued = 'Queued'
}

