// Copyright © Veeam Software Group GmbH

import { toolbarAction } from '@veeam/components';

import type { Item } from 'services';
import { Product } from 'services';

import { addSrc } from '../../../assets';
import { isRestoreListAvailable } from './isRestoreListAvailable';
import { restoreService } from '../../../services/restoreService';
import { TeamsItemType, TeamsPostItem } from '../../../services/models/Teams';

export const addToRestoreListAction = (getItems: () => Item[], unselectAll: () => void) => toolbarAction({
    title: 'Add to Restore List',
    icon: addSrc,
    decorators: [
        (payload) => {
            let disabled = false;
            const selectedItems = payload.selectedItems as unknown as Item[];

            if (payload.selectedItems.length === 0) {
                disabled = true;
            }

            if (payload.selectedItems.length > 0) {
                disabled = selectedItems.some(item => !isRestoreListAvailable(item));
            }

            if (
                !disabled
                && selectedItems.every(item => item.product === Product.Teams && item.itemType === TeamsItemType.Post)
            ) {
                disabled = selectedItems.every((item: TeamsPostItem) => item.isDeleted)
            }

            return {
                disabled,
            };
        },
    ],
    handler: () => {
        const items = getItems().filter(item => {
            if (item => item.product === Product.Teams && item.itemType === TeamsItemType.Post) {
                return !(item as TeamsPostItem).isDeleted;
            }

            return item.itemType !== TeamsItemType.Folder;
        });

        restoreService.bucket.addItems(items)
            .then(unselectAll);
    },
});
