// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';
import { toolbarAction, toolbarItemDecorators } from '@veeam/components';

import type { CollectionToolbarTypedItem } from '@veeam/components';

import { deleteSrc } from 'assets';
import { restoreService } from 'services/restoreService';

import type { BucketResources } from './resources';
import type { RestoreBucketModel } from './useModel';


export function useRemoveAction(resources: BucketResources, model: RestoreBucketModel): CollectionToolbarTypedItem {
    return useMemo(() => {
        async function handler(): Promise<void> {
            const itemsToDelete = model.getValue().selection;
            if (itemsToDelete.length === 0) return;
            await restoreService.bucket.removeItem(itemsToDelete);
            model.load();
        }

        return toolbarAction({
            title: resources.removeItemActionTitle,
            icon: deleteSrc,
            decorators: [
                toolbarItemDecorators.disallowWithoutSelection(),
            ],
            handler,
        });
    }, [resources, model]);
}
