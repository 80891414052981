// Copyright © Veeam Software Group GmbH

import React from 'react';
import { DataGrid, GRID_COLUMN_JUSTIFY } from '@veeam/components';

import type { FC } from 'react';
import type { Form } from '@veeam/core';
import type { WizardStepConfig } from 'components/wizard';
import type { Resources } from 'infrastructure/resources';
import type { ExchangeItem, OneDriveDocument, OneDriveItem, SharePointItem } from 'services';
import type { TeamsItem } from 'services/models/Teams/TeamsItem';

import { PageSection } from 'components/wizard';
import { sizeCell, textCell } from 'components/dataGridCells';
import { useToolbar } from './useToolbar';
import { RestoreItemsSortKeys, useRestoreItemsModel } from './useModel';
import { explorePath as path } from 'services';
import { mapItemType } from 'services/restoreService/bucket';

export interface FormWithBucketItems {
    items: ExchangeItem[] | OneDriveItem[] | SharePointItem[] | TeamsItem[];
}

type StepResources = Resources['features']['Restore']['Wizards']['Shared']['ItemsStep'];

const ItemsStepForm: FC<{ form: Form<FormWithBucketItems>; resources: StepResources; }> = ({ form, resources }) => {
    const model = useRestoreItemsModel(form);
    const toolbar = useToolbar({ form, model, resources });

    return (
        <PageSection title={resources.title} description={resources.description} style={{ height: '100%' }}>
            <DataGrid
                model={model}
                toolbar={toolbar}
                columns={[
                    {
                        id: 'name',
                        title: resources.name,
                        cell: textCell(row => row.title),
                        sortKey: RestoreItemsSortKeys.Name,
                    },
                    {
                        id: 'size',
                        title: resources.size,
                        cell: sizeCell(row => (row as OneDriveDocument).sizeBytes),
                        justify: GRID_COLUMN_JUSTIFY.right,
                        sortKey: RestoreItemsSortKeys.Size,
                    },
                    {
                        id: 'type',
                        title: resources.type,
                        cell: textCell(row => mapItemType(row)),
                        sortKey: RestoreItemsSortKeys.Type,
                    },
                    {
                        id: 'location',
                        title: resources.location,
                        cell: textCell(row => path.stringify(row.explorePath)),
                        sortKey: RestoreItemsSortKeys.Location,
                    },
                ]}
                config={{
                    canResizeColumns: true,
                }}
            />
        </PageSection>
    );
};

export const useItemsStep = (form: Form<FormWithBucketItems>, resources: StepResources): WizardStepConfig => ({
    tabTitle: resources.title,
    tag: 'items',
    body: <ItemsStepForm form={form} resources={resources} />,
    contentHeight: '100%',
});
