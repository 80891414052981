// Copyright © Veeam Software Group GmbH

import { StorageKeys } from '../StorageKeys';
import { getLocalStorage } from '../rawStorage';

interface MsalInfo {
    appId: string;
    loginHint: string;
    msalAuthorityUri?: string;
}

export const msalStorage = getLocalStorage<MsalInfo>(StorageKeys.MSAL);
