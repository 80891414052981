/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTExceptionInfo,
    RESTOrganizationLicensingInformation,
} from '../models';

export interface OrganizationLicensingInformationApiOrganizationLicensingInformationGetLicenseCountRequest {
    organizationId: string;
}

/**
 * no description
 */
export const organizationLicensingInformationApi = ({

    /**
     * Returns licensing information for an organization with the specified ID.
     * Get License Information
     */
    organizationLicensingInformationGetLicenseCount: ({ organizationId }: OrganizationLicensingInformationApiOrganizationLicensingInformationGetLicenseCountRequest, option?: RequestOption): Observable<Optional<RESTOrganizationLicensingInformation>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationLicensingInformationGetLicenseCount');

        return requestAuthenticated<RESTOrganizationLicensingInformation>({
            url: '/v7/Organizations/{organizationId}/LicensingInformation'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
        }, option);
    },

})
