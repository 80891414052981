// Copyright © Veeam Software Group GmbH

import { map } from 'rxjs/operators';
import { z } from 'zod';

import type { TypeOf } from 'zod';
import type { Observable } from 'rxjs';
import type {
    RESTRbacEffectiveScope,
    RESTRbacSiteItem,
    RESTRbacUserItem,
    RESTRbacTeamItem,
} from 'api/rxjs';
import type {
    Sorting } from 'infrastructure/client-side-operations';

import {
    organizationRbacApi,
    RESTRbacUserTypeEnum,
} from 'api/rxjs';
import {
    comparators,
    createFilterEngine,
    createSortEngine,
    filters,
} from 'infrastructure/client-side-operations';
import { never } from 'infrastructure/never';
import { RbacScopeType } from './models';
import { ServiceMemoryCache } from 'infrastructure/serviceCache';

import type { RbacScope, RbacScopeId } from './models';

const hasUserScopeSharePoint = (userType: RESTRbacUserTypeEnum, isOperator: boolean): boolean => {
    switch (userType) {
        case RESTRbacUserTypeEnum.User:
            return isOperator;
        case RESTRbacUserTypeEnum.SharedMailbox:
            return isOperator;
        case RESTRbacUserTypeEnum.PublicMailbox:
            return false;
        default:
            return never(userType);
    }
};
const hasUserScopeOneDrive = (userType: RESTRbacUserTypeEnum): boolean => {
    switch (userType) {
        case RESTRbacUserTypeEnum.User:
            return true;
        case RESTRbacUserTypeEnum.SharedMailbox:
            return true;
        case RESTRbacUserTypeEnum.PublicMailbox:
            return false;
        default:
            return never(userType);
    }
};

const convertUserScopeFromRest = (item: RESTRbacUserItem, isOperator: boolean): RbacScope => ({
    item,
    id: item.user.id as RbacScopeId,
    type: RbacScopeType.User,
    title: item.user.displayName,
    details: item.user.name ?? '',
    has: {
        sharePoint: hasUserScopeSharePoint(item.user.type, isOperator),
        exchange: true,
        oneDrive: hasUserScopeOneDrive(item.user.type),
        teams: false,
    },
});

const convertUsersScopeFromRest = (users: RESTRbacUserItem[] | undefined, isOperator: boolean): RbacScope[] =>
    users ? users.map(user => convertUserScopeFromRest(user, isOperator)) : [];

const convertSiteScopeFromRest = (item: RESTRbacSiteItem): RbacScope => ({
    item,
    id: item.site.id as RbacScopeId,
    type: RbacScopeType.Site,
    title: item.site.title,
    details: item.site.url ?? '',
    has: {
        sharePoint: true,
        exchange: false,
        oneDrive: false,
        teams: false,
    },
});

const convertSitesScopeFromRest = (sites: RESTRbacSiteItem[] | undefined): RbacScope[] =>
    sites ? sites.map(convertSiteScopeFromRest) : [];

const convertTeamScopeFromRest = (item: RESTRbacTeamItem): RbacScope => ({
    item,
    id: item.team.id as RbacScopeId,
    type: RbacScopeType.Team,
    title: item.team.displayName,
    details: item.team.mail ?? '',
    has: {
        sharePoint: false,
        exchange: false,
        oneDrive: false,
        teams: true,
    },
});

const convertTeamsScopeFromRest = (teams: RESTRbacTeamItem[] | undefined): RbacScope[] =>
    teams ? teams.map(convertTeamScopeFromRest) : [];

const convertScopeFromRest = (scope: RESTRbacEffectiveScope, isOperator: boolean): RbacScope[] => [
    ...convertUsersScopeFromRest(scope.users, isOperator),
    ...convertSitesScopeFromRest(scope.sites),
    ...convertTeamsScopeFromRest(scope.teams),
];

export enum RbacScopeSortKeys {
    Title = 'Title',
    Type = 'Type',
    Details = 'Details',
}

export const sortScopes = createSortEngine<RbacScope, typeof RbacScopeSortKeys>({
    Title: (one, two) => comparators.string(one.title, two.title),
    Type: (one, two) => comparators.string(one.type, two.type),
    Details: (one, two) => comparators.string(one.title, two.title),
});

export const RbacScopeFilterSchema = z.object({
    search: z
        .string()
        .nullish()
        .transform(val => val || ''),
    type: z
        .array(z.nativeEnum(RbacScopeType))
        .nullish()
        .transform(val => val || []),
});

export type RbacScopeFilter = TypeOf<typeof RbacScopeFilterSchema>;

export const filterScopes = createFilterEngine<RbacScope, RbacScopeFilter>({
    search: filters.search(session => session.title),
    type: filters.enum(session => session.type),
});

export interface GetRbacScopeRequest {
    filter: RbacScopeFilter;
    sorting: Sorting<typeof RbacScopeSortKeys> | undefined;
    forceReload: boolean;
}

const rbacScopesCache = new ServiceMemoryCache(organizationRbacApi.organizationRbacGetLoggedInUserEffectiveScope);
export const getRbacScopes = (
    request: GetRbacScopeRequest,
    currentScope: RbacScopeId,
    isOperator: boolean
): Observable<RbacScope[]> =>
    rbacScopesCache.getValue(undefined, request.forceReload).pipe(
        map(response => response.map({})),
        map(scope => convertScopeFromRest(scope, isOperator)),
        map(scopes => filterScopes(request.filter, scopes)),
        map(scopes => sortScopes(request.sorting, scopes))
    );
