// Copyright © Veeam Software Group GmbH

import {
    GRID_HEIGHT_MODE,
    INDENT,
    List,
    SPACE_FILL,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    StackView,
    Text,
} from '@veeam/components';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { ReactElement } from 'react';
import type { DatetimeInputRightSectionRendererProps } from '@veeam/components';

import { useExplorePageStore } from '../../../stores/explore-page-store';
import { findDateInRestorePoints } from '../helpers/find-date-in-restore-points';
import { findLatestRestorePoint } from '../helpers/find-latest-restore-point';
import { filterRestorePointsByDate } from '../helpers/filter-restore-points-by-date';
import { useResources } from 'infrastructure/resources';
import { formatStr } from '../../../../../infrastructure/helpers';
import { datetimeFormatter } from '../../../helpers/datetimeFormatter';

import type { RESTRestorePoint } from '../../../../../api/rxjs';

const WrapperList = styled.div`
    border: 1px solid ${({ theme }) => theme.colorBorderMain};
    width: 226px;
`;

interface RestorePointWithLabel extends RESTRestorePoint {
    /**
     * @type {string}
     * @memberof RestorePointWithLabel
     */
    label?: string;
}

export function createRightSection(
    restorePoints: RESTRestorePoint[],
    setApplyActionDisabled: React.Dispatch<React.SetStateAction<boolean>>,
) {
    return function RightSectionRenderer({
        value,
        select,
    }: DatetimeInputRightSectionRendererProps): ReactElement {
        const latestRestorePoint = useMemo(() => findLatestRestorePoint(restorePoints), [restorePoints]);
        const resources = useResources().features.Explore.RestorePointSelector;

        const filteredRestorePoints = filterRestorePointsByDate(value, restorePoints)
            .sort((one, two) => (new Date(two.backupTime)).getTime() - (new Date(one.backupTime)).getTime())
            .map((restorePoint, i, list) => {
                let label = restorePoint && datetimeFormatter({
                    value: new Date(restorePoint.backupTime),
                    withTime: true,
                    is12Hours: false,
                    withSeconds: true,
                });

                if (restorePoint === latestRestorePoint) {
                    label += resources.latestSuffix;
                }

                return {
                    ...restorePoint,
                    label,
                } as RestorePointWithLabel;
            });
        const explorePageStore = useExplorePageStore();
        const isDateChanged = value && value.getHours() === 0 &&  value.getMinutes() === 0 && value.getMilliseconds() === 0;
        const [selected, setSelected] = useState(() => [findDateInRestorePoints(value, filteredRestorePoints)]);

        useEffect(() => {
            const selectedDate = explorePageStore.restorePoint && new Date(explorePageStore.restorePoint.backupTime);

            if (selectedDate && findDateInRestorePoints(selectedDate, restorePoints) === selected[0]) {
                setApplyActionDisabled(true);
            } else {
                setApplyActionDisabled(false);
            }
        }, [value?.toJSON()]);

        useEffect(() => {
            if (isDateChanged) {
                select(new Date(filteredRestorePoints[0].backupTime));
                setSelected([filteredRestorePoints[0].backupTime]);
            }
        }, [isDateChanged]);

        useEffect(() => {
            if (!value) {
                const latestRestorePointBackupTime = latestRestorePoint.backupTime;
                const latestRestorePointDate = new Date(latestRestorePointBackupTime);

                select(latestRestorePointDate);

                setSelected([latestRestorePointBackupTime]);
            }
        }, []);

        return (
            <StackView
                indentTop={INDENT.s}
                gap={STACK_GAP.xs}
                direction={STACK_DIRECTION.column}
                distribution={STACK_DISTRIBUTION.lastFill}
                spaceFill={SPACE_FILL.all}
            >
                <Text>{formatStr(resources.restorePoint, filteredRestorePoints && filteredRestorePoints.length)}</Text>
                <WrapperList>
                    <List
                        columnWidth='206px'
                        heightMode={GRID_HEIGHT_MODE.auto}
                        data={filteredRestorePoints as any}
                        selected={selected}
                        valueGetter={(point: RestorePointWithLabel) => point?.backupTime}
                        textGetter={(point: RestorePointWithLabel) => point && point.label}
                        onChange={(selectedPoints) => {
                            select(new Date(selectedPoints[0]));
                            setSelected(selectedPoints);
                        }}
                    />
                </WrapperList>
            </StackView>
        );
    };
}
