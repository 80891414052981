// Copyright © Veeam Software Group GmbH

import { GRID_COLUMN_JUSTIFY } from '@veeam/components';
import React from 'react';
import moment from 'moment';

import type { DataGridColumnConfig } from '@veeam/components';
import type { Action1 } from 'infrastructure/types';
import type {
    ExchangeAppointment,
    ExchangeContact,
    ExchangeMail,
    ExchangeStickyNote,
    ExchangeTask,
    Item,
    Node,
    OneDriveDocument,
    SharePointDocument,
    SharePointItem,
    VersionableItem,
} from 'services';
import type {
    TeamsChannelItem,
    TeamsFileItem,
    TeamsFilesItem,
    TeamsPostItem,
    TeamsPostsItem,
    TeamsTabItem,
    TeamsTabsItem,
    TeamsTeamItem,
} from 'services/models/Teams/TeamsItem';

import { ExchangeFolderType, ExchangeNodeType, Product, SharePointItemType, SharePointNodeType } from 'services';
import { attachmentSrc, highImportanceSrc, importanceSrc, itemInRestoreList } from 'assets';
import { mapAttachments, mapMailImportance } from 'pages/ExplorePage/components/ExploreGrid/helpers/mappers';
import { RestoreListCell } from 'pages/ExplorePage/components/ExploreGrid/components/RestoreListCell';
import { never } from 'infrastructure/never';
import { actionCell, iconCell, iconHeaderCell, sizeCell, textCell } from 'components/dataGridCells';
import { utcDateCell } from 'components/dataGridCells/utcDateCell';
import { ExploreSortKeys } from 'services/explore/enums/explore-sort-keys';
import { TeamsNodeType } from 'services/models/Teams/TeamsNode';
import { iconHeaderCellContent } from '../../../../../components/dataGridCells/iconHeaderCell';

import type { GridResources } from '../interfaces/grid-resources';

const getRestoreListColumn = (resources: GridResources): DataGridColumnConfig<Item> => ({
    id: 'inRestoreList',
    title: resources.columns.inRestoreList,
    cell: (rowData, payload) => <RestoreListCell rowData={rowData} payload={payload} />,
    headerCell: iconHeaderCell(() => itemInRestoreList),
    justify: GRID_COLUMN_JUSTIFY.center,
    width: 32,
    minWidth: 32,
});

export const getExchangeStickyNoteColumns = (resources: GridResources): DataGridColumnConfig<ExchangeStickyNote>[] => [
    getRestoreListColumn(resources),
    {
        id: 'subject',
        title: resources.columns.exchange.subject,
        cell: textCell(row => row.subject),
        sortKey: ExploreSortKeys.Subject,
    },
    {
        id: 'date',
        title: resources.columns.exchange.date,
        cell: utcDateCell(row => row.date),
        sortKey: ExploreSortKeys.Date,
    },
];

export const getExchangeAppointmentColumns = (
    resources: GridResources
): DataGridColumnConfig<ExchangeAppointment>[] => [
    getRestoreListColumn(resources),
    {
        id: 'subject',
        title: resources.columns.exchange.subject,
        cell: textCell(row => row.subject),
        sortKey: ExploreSortKeys.Subject,
    },
    {
        id: 'startTime',
        title: resources.columns.exchange.startTime,
        cell: utcDateCell(row => row.startTime),
        sortKey: ExploreSortKeys.StartTime,
    },
    {
        id: 'endTime',
        title: resources.columns.exchange.endTime,
        cell: utcDateCell(row => row.endTime),
        sortKey: ExploreSortKeys.EndTime,
    },
    {
        id: 'organizer',
        title: resources.columns.exchange.organizer,
        cell: textCell(row => row.organizer),
        sortKey: ExploreSortKeys.Organizer,
    },
    {
        id: 'location',
        title: resources.columns.exchange.location,
        cell: textCell(row => row.location),
        sortKey: ExploreSortKeys.Location,
    },
    {
        id: 'attendees',
        title: resources.columns.exchange.attendees,
        cell: textCell(row => row.attendees),
        sortKey: ExploreSortKeys.Attendees,
    },
];

export const getExchangeTaskColumns = (resources: GridResources): DataGridColumnConfig<ExchangeTask>[] => [
    getRestoreListColumn(resources),
    {
        id: 'subject',
        title: resources.columns.exchange.subject,
        cell: textCell(row => row.subject),
        sortKey: ExploreSortKeys.Subject,
    },
    {
        id: 'status',
        title: resources.columns.exchange.status,
        cell: textCell(row => row.status),
        sortKey: ExploreSortKeys.Status,
    },
    {
        id: 'percentComplete',
        title: resources.columns.exchange.percentComplete,
        cell: textCell(row => row.percentComplete?.toString()),
        sortKey: ExploreSortKeys.PercentComplete,
    },
    {
        id: 'owner',
        title: resources.columns.exchange.owner,
        cell: textCell(row => row.owner),
        sortKey: ExploreSortKeys.Owner,
    },
];

export const getExchangeContactColumns = (resources: GridResources): DataGridColumnConfig<ExchangeContact>[] => [
    getRestoreListColumn(resources),
    {
        id: 'fullName',
        title: resources.columns.exchange.fullName,
        cell: textCell(row => row.fullName),
        sortKey: ExploreSortKeys.FullName,
    },
    {
        id: 'company',
        title: resources.columns.exchange.company,
        cell: textCell(row => row.company),
        sortKey: ExploreSortKeys.Company,
    },
    {
        id: 'jobTitle',
        title: resources.columns.exchange.jobTitle,
        cell: textCell(row => row.jobTitle),
        sortKey: ExploreSortKeys.JobTitle,
    },
    {
        id: 'fileAs',
        title: resources.columns.exchange.fileAs,
        cell: textCell(row => row.fileAs),
        sortKey: ExploreSortKeys.FileAs,
    },
    {
        id: 'email',
        title: resources.columns.exchange.email,
        cell: textCell(row => row.email),
        sortKey: ExploreSortKeys.Email,
    },
    {
        id: 'displayAs',
        title: resources.columns.exchange.displayAs,
        cell: textCell(row => row.displayAs),
        sortKey: ExploreSortKeys.DisplayAs,
    },
    {
        id: 'webPage',
        title: resources.columns.exchange.webPage,
        cell: textCell(row => row.webPage),
        sortKey: ExploreSortKeys.WebPage,
    },
    {
        id: 'imAddress',
        title: resources.columns.exchange.imAddress,
        cell: textCell(row => row.imAddress),
        sortKey: ExploreSortKeys.ImAddress,
    },
    {
        id: 'businessPhone',
        title: resources.columns.exchange.businessPhone,
        cell: textCell(row => row.businessPhone),
        sortKey: ExploreSortKeys.BusinessPhone,
    },
];

export const getExchangeMailColumns = (resources: GridResources): DataGridColumnConfig<ExchangeMail>[] => [
    getRestoreListColumn(resources),
    {
        id: 'importance',
        title: resources.columns.exchange.importance,
        cell: iconCell(row => ({ iconSrc: mapMailImportance(row.importance), text: null }), true),
        headerCellContent: iconHeaderCellContent(importanceSrc),
        sortKey: ExploreSortKeys.Importance,
        width: 62,
        minWidth: 62,
    },
    {
        id: 'attachments',
        title: resources.columns.exchange.attachments,
        cell: iconCell(row => ({ iconSrc: mapAttachments(row.attachments), text: null }), true),
        headerCellContent: iconHeaderCellContent(attachmentSrc),
        sortKey: ExploreSortKeys.Attachments,
        width: 62,
        minWidth: 62,
    },
    {
        id: 'from',
        title: resources.columns.exchange.from,
        cell: textCell(row => row.from),
        sortKey: ExploreSortKeys.From,
    },
    {
        id: 'to',
        title: resources.columns.exchange.to,
        cell: textCell(row => row.to),
        sortKey: ExploreSortKeys.To,
    },
    {
        id: 'cc',
        title: resources.columns.exchange.cc,
        cell: textCell(row => row.cc),
        sortKey: ExploreSortKeys.Cc,
    },
    {
        id: 'bcc',
        title: resources.columns.exchange.bcc,
        cell: textCell(row => row.bcc),
        sortKey: ExploreSortKeys.Bcc,
    },
    {
        id: 'subject',
        title: resources.columns.exchange.subject,
        cell: textCell(row => row.subject),
        sortKey: ExploreSortKeys.Subject,
    },
    {
        id: 'received',
        title: resources.columns.exchange.received,
        cell: utcDateCell(row => row.received),
        sortKey: ExploreSortKeys.Received,
    },
];

export const getOneDriveDocumentsColumns = (
    resources: GridResources,
    showVersionsDialog: Action1<VersionableItem>
): DataGridColumnConfig<OneDriveDocument>[] => [
    getRestoreListColumn(resources),
    {
        id: 'name',
        title: resources.columns.name,
        cell: textCell(row => row.title),
        sortKey: ExploreSortKeys.Title,
    },
    {
        id: 'type',
        title: resources.columns.type,
        cell: textCell(row => row._raw_rest?.isFolder ? 'Folder' : 'File'),
        sortKey: ExploreSortKeys.IsFolder,
    },
    {
        id: 'sizeBytes',
        title: resources.columns.sizeBytes,
        cell: sizeCell(row => row.sizeBytes),
        justify: GRID_COLUMN_JUSTIFY.right,
        sortKey: ExploreSortKeys.SizeBytes,
    },
    {
        id: 'modificationTime',
        title: resources.columns.modificationTime,
        cell: utcDateCell(row => row.modificationTime),
        sortKey: ExploreSortKeys.ModificationTime,
    },
    {
        id: 'version',
        title: resources.columns.version,
        cell: actionCell(row => ({ text: row.version, action: () => showVersionsDialog(row) })),
        sortKey: ExploreSortKeys.Version,
    },
];

export const getSharePointDocumentsColumns = (
    resources: GridResources,
    showVersionsDialog: Action1<VersionableItem>
): DataGridColumnConfig<SharePointItem>[] => [
    getRestoreListColumn(resources),
    {
        id: 'name',
        title: resources.columns.name,
        cell: textCell(row => row.title),
        sortKey: ExploreSortKeys.Title,
    },
    {
        id: 'type',
        title: resources.columns.type,
        cell: textCell((row) => {
            if (row.itemType === SharePointItemType.ListItem) {
                return 'List Item';
            }

            if (row.itemType === SharePointItemType.ListFolderItem) {
                return 'List Folder';
            }

            if (row.itemType === SharePointItemType.Document) {
                return 'Library Document';
            }

            if (row.itemType === SharePointItemType.Folder) {
                return 'Library Folder';
            }
        }),
        sortKey: ExploreSortKeys.ItemType,
    },
    {
        id: 'size',
        title: resources.columns.sizeBytes,
        cell: sizeCell(row => (row as SharePointDocument).sizeBytes),
        justify: GRID_COLUMN_JUSTIFY.right,
        sortKey: ExploreSortKeys.SizeBytes,
    },
    {
        id: 'modificationTime',
        title: resources.columns.modificationTime,
        cell: utcDateCell(row => row.modificationTime),
        sortKey: ExploreSortKeys.ModificationTime,
    },
    {
        id: 'version',
        title: resources.columns.version,
        cell: actionCell(row => ({ text: row.version, action: () => showVersionsDialog(row) })),
        sortKey: ExploreSortKeys.Version,
    },
];

export const getSharePointListItemColumns = (
    resources: GridResources,
    showVersionsDialog: Action1<VersionableItem>
): DataGridColumnConfig<SharePointItem>[] => [
    getRestoreListColumn(resources),
    {
        id: 'name',
        title: resources.columns.name,
        cell: textCell(row => row.title),
        sortKey: ExploreSortKeys.Title,
    },
    {
        id: 'type',
        title: resources.columns.type,
        cell: textCell((row) => {
            if (row.itemType === SharePointItemType.ListItem) {
                return 'List Item';
            }

            if (row.itemType === SharePointItemType.ListFolderItem) {
                return 'List Folder';
            }

            if (row.itemType === SharePointItemType.Document) {
                return 'Library Document';
            }

            if (row.itemType === SharePointItemType.Folder) {
                return 'Library Folder';
            }
        }),
        sortKey: ExploreSortKeys.ItemType,
    },
    {
        id: 'modificationTime',
        title: resources.columns.modificationTime,
        cell: utcDateCell(row => row.modificationTime),
        sortKey: ExploreSortKeys.ModificationTime,
    },
    {
        id: 'version',
        title: resources.columns.version,
        cell: actionCell(row => ({ text: row.version, action: () => showVersionsDialog(row) })),
        sortKey: ExploreSortKeys.Version,
    },
];

export const getTeamsColumns = (
    resources: GridResources,
): DataGridColumnConfig<TeamsTeamItem>[] => [
    {
        id: 'name',
        title: resources.columns.teams.team,
        cell: textCell(row => row.title),
        sortKey: ExploreSortKeys.Title,
    }, {
        id: 'description',
        title: resources.columns.teams.description,
        cell: textCell(row => row.description),
        sortKey: ExploreSortKeys.Description,
    },
];

export const getTeamsChannelsColumns = (
    resources: GridResources,
): DataGridColumnConfig<TeamsChannelItem>[] => [
    {
        id: 'name',
        title: resources.columns.teams.channel,
        cell: textCell(row => row.title),
        sortKey: ExploreSortKeys.Title,
    },
];

export const getTeamsChannelsFoldersColumns = (
    resources: GridResources,
): DataGridColumnConfig<TeamsPostsItem | TeamsFilesItem | TeamsTabsItem>[] => [
    {
        id: 'name',
        title: resources.columns.name,
        cell: textCell(row => row.title),
        sortKey: ExploreSortKeys.Title,
    },
];

export const getTeamsTabsColumns = (
    resources: GridResources,
): DataGridColumnConfig<TeamsTabItem>[] => [
    getRestoreListColumn(resources),
    {
        id: 'name',
        title: resources.columns.name,
        cell: textCell(row => row.title),
        sortKey: ExploreSortKeys.Title,
    },
    {
        id: 'type',
        title: resources.columns.type,
        cell: textCell(row => row.type),
        sortKey: ExploreSortKeys.Type,
    },
    {
        id: 'contentUrl',
        title: resources.columns.teams.contentUrl,
        cell: textCell(row => row.contentUrl),
        sortKey: ExploreSortKeys.ContentUrl,
    },
];

export const getTeamsPostsColumns = (
    resources: GridResources,
    showRepliesDialog?: Action1<TeamsPostItem>
): DataGridColumnConfig<TeamsPostItem>[] => [
    getRestoreListColumn(resources),
    {
        id: 'importance',
        title: resources.columns.exchange.importance,
        cell: iconCell(row => ({ iconSrc: row.isImportant ? highImportanceSrc : undefined, text: null }), true),
        headerCellContent: iconHeaderCellContent(importanceSrc),
        sortKey: ExploreSortKeys.IsImportant,
        width: 62,
        minWidth: 62,
    },
    {
        id: 'attachments',
        title: resources.columns.exchange.attachments,
        cell: iconCell(row => ({ iconSrc: mapAttachments(row.attachments), text: null }), true),
        headerCellContent: iconHeaderCellContent(attachmentSrc),
        sortKey: ExploreSortKeys.Attachments,
        width: 62,
        minWidth: 62,
    },
    {
        id: 'author',
        title: resources.columns.teams.author,
        cell: textCell(row => row.author),
        sortKey: ExploreSortKeys.Author,
    },
    {
        id: 'subject',
        title: resources.columns.teams.subject,
        cell: textCell(row => row.subject),
        sortKey: ExploreSortKeys.Subject,
    },
    {
        id: 'replies',
        title: resources.columns.teams.replies,
        cell: actionCell(row => ({ text: resources.columns.teams.show, action: () => showRepliesDialog(row) })),
        hidden: !showRepliesDialog,
    },
    {
        id: 'createdTime',
        title: resources.columns.teams.created,
        cell: utcDateCell(row => moment(row.createdTime)),
        sortKey: ExploreSortKeys.CreatedTime,
    },
    {
        id: 'lastModifiedTime',
        title: resources.columns.teams.lastModified,
        cell: utcDateCell(row => moment(row.lastModifiedTime)),
        sortKey: ExploreSortKeys.LastModifiedTime,
    },
];

export const getTeamsFilesColumns = (
    resources: GridResources,
    showVersionsDialog: Action1<VersionableItem>,
): DataGridColumnConfig<TeamsFileItem>[] => [
    getRestoreListColumn(resources),
    {
        id: 'name',
        title: resources.columns.name,
        cell: textCell(row => row.name),
        sortKey: ExploreSortKeys.Title,
    },
    {
        id: 'fileType',
        title: resources.columns.type,
        cell: textCell(row => row.fileType),
        sortKey: ExploreSortKeys.FileType,
    },
    {
        id: 'sizeBytes',
        title: resources.columns.sizeBytes,
        cell: sizeCell(row => row.sizeBytes),
        sortKey: ExploreSortKeys.SizeBytes,
    },
    {
        id: 'version',
        title: resources.columns.version,
        cell: actionCell(row => ({ text: row.version || '1.0', action: () => showVersionsDialog(row) })),
        sortKey: ExploreSortKeys.Version,
    },
    {
        id: 'modified',
        title: resources.columns.teams.modified,
        cell: utcDateCell(row => moment(row.modified)),
        sortKey: ExploreSortKeys.Modified,
    },
    {
        id: 'modifiedBy',
        title: resources.columns.teams.modifiedBy,
        cell: textCell(row => row.modifiedBy),
        sortKey: ExploreSortKeys.ModifiedBy,
    },
];

type Cols = DataGridColumnConfig<Item>[];

export const getColumns = (
    resources: GridResources,
    node: Node | undefined,
    showVersionsDialog: Action1<VersionableItem>,
    showRepliesDialog: Action1<TeamsPostItem>
): Cols => {
    if (node === undefined) return getExchangeMailColumns(resources) as Cols;
    switch (node.product) {
        case Product.Exchange: {
            switch (node.nodeType) {
                case ExchangeNodeType.Mailbox:
                    return getExchangeMailColumns(resources) as Cols;
                case ExchangeNodeType.Folder: {
                    switch (node.folderType) {
                        case ExchangeFolderType.Appointment:
                            return getExchangeAppointmentColumns(resources) as Cols;
                        case ExchangeFolderType.Contact:
                            return getExchangeContactColumns(resources) as Cols;
                        case ExchangeFolderType.StickyNote:
                            return getExchangeStickyNoteColumns(resources) as Cols;
                        case ExchangeFolderType.Task:
                            return getExchangeTaskColumns(resources) as Cols;
                        default:
                            return getExchangeMailColumns(resources) as Cols;
                    }
                }
            }
        }
        case Product.OneDrive:
            return getOneDriveDocumentsColumns(resources, showVersionsDialog) as Cols;
        case Product.SharePoint: {
            switch (node.nodeType) {
                case SharePointNodeType.Site:
                case SharePointNodeType.SubSites:
                case SharePointNodeType.Content:
                case SharePointNodeType.Library:
                case SharePointNodeType.LibraryFolder:
                    return getSharePointDocumentsColumns(resources, showVersionsDialog) as Cols;
                case SharePointNodeType.List:
                case SharePointNodeType.ListFolder:
                    return getSharePointListItemColumns(resources, showVersionsDialog) as Cols;
            }
        }
        case Product.Teams:
            switch (node.nodeType) {
                case TeamsNodeType.Teams:
                    return getTeamsColumns(resources);
                case TeamsNodeType.Team:
                    return getTeamsChannelsColumns(resources);
                case TeamsNodeType.Channel:
                    return getTeamsChannelsFoldersColumns(resources);
                case TeamsNodeType.Tabs:
                    return getTeamsTabsColumns(resources);
                case TeamsNodeType.Posts:
                    return getTeamsPostsColumns(resources, showRepliesDialog);
                case TeamsNodeType.Files:
                    return getTeamsFilesColumns(resources, showVersionsDialog);
                case TeamsNodeType.Folder:
                    return getTeamsFilesColumns(resources, showVersionsDialog);
            }
        default:
            return never(node);
    }
};
