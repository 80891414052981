export enum PeriodicallyTime {
    Minutes5 = 0,
    Minutes10 = 1,
    Minutes15 = 2,
    Minutes30 = 3,
    Hours1 = 4,
    Hours2 = 5,
    Hours4 = 6,
    Hours8 = 7
}
