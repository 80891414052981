// Copyright © Veeam Software Group GmbH

import React from 'react';
import { Button, BUTTON_VIEW, CommandMenu, Icon, INDENT, View } from '@veeam/components';

import type { FC } from 'react';
import type { CommandMenuItemConfig } from '@veeam/components';
import type { Item } from 'services';

import { useResources } from 'infrastructure/resources';
import { restore16 } from 'assets';
import { useRestoreWizards } from './RestoreContext';
import { restoreService } from 'services/restoreService';

export interface RestoreButtonProps {
    items: Item[];
}

export const RestoreButton: FC<RestoreButtonProps> = ({ items }) => {
    const resources = useResources().features.Restore.restoreButton;
    const restoreWizards = useRestoreWizards();
    const { canRestore, isMultiple, exchange, sharePoint, oneDrive, teams } = restoreService.groupByRestoreType(items);

    if (!canRestore) return (
        <View spaceHorizontal={INDENT.s} >
            <Button ui={BUTTON_VIEW.text} disabled>
                <>
                    <Icon src={restore16} />
                    {resources.Restore}
                </>
            </Button>
        </View>
    );

    if (isMultiple) {
        const commandItems: CommandMenuItemConfig[] = [];

        if (exchange.items.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vex',
                text: resources.RestoreExchange,
                disabled: !canRestore,
                onClick: () => restoreWizards.openExchangeWizard(exchange.items),
            });
        }

        if (sharePoint.listItems.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vesp.listItems',
                text: resources.RestoreSharePointItems,
                disabled: !canRestore,
                onClick: () => restoreWizards.openSharePointWizard(sharePoint.listItems),
            });
        }

        if (sharePoint.listFolderItems.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vesp.listFolderItems',
                text: resources.RestoreSharePointListFolders,
                disabled: !canRestore,
                onClick: () => restoreWizards.openSharePointWizard(sharePoint.listFolderItems),
            });
        }

        if (sharePoint.docs.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vesp.docs',
                text: resources.RestoreSharePointDocuments,
                disabled: !canRestore,
                onClick: () => restoreWizards.openSharePointWizard(sharePoint.docs),
            });
        }

        if (sharePoint.sharePointFolders.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vesp.folders',
                text: resources.RestoreSharePointFolders,
                disabled: !canRestore,
                onClick: () => restoreWizards.openSharePointWizard(sharePoint.sharePointFolders),
            });
        }

        if (oneDrive.oneDriveDocuments.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'veod.documents',
                text: resources.RestoreOneDriveDocuments,
                disabled: !canRestore,
                onClick: () => restoreWizards.openOneDriveWizard(oneDrive.oneDriveDocuments),
            });
        }

        if (oneDrive.oneDriveFolders.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'veod.folders',
                text: resources.RestoreOneDriveFolders,
                disabled: !canRestore,
                onClick: () => restoreWizards.openOneDriveWizard(oneDrive.oneDriveFolders),
            });
        }

        if (teams.files.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.files',
                text: resources.RestoreTeamsFiles,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.files),
            });
        }

        if (teams.folders.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.folders',
                text: resources.RestoreTeamsFolders,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.folders),
            });
        }

        if (teams.tabs.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.tabs',
                text: resources.RestoreTeamsTabs,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.tabs),
            });
        }

        if (teams.posts.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.posts',
                text: resources.RestoreTeamsPosts,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.posts),
            });
        }

        if (teams.channels.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.channels',
                text: resources.RestoreTeamsChannels,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.channels),
            });
        }

        if (teams.postsFolder.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.postsFolder',
                text: resources.RestoreTeamsPosts,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.postsFolder),
            });
        }

        if (teams.tabsFolder.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.tabsFolder',
                text: resources.RestoreTeamsTabs,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.tabsFolder),
            });
        }

        if (teams.filesFolder.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.filesFolder',
                text: resources.RestoreTeamsFiles,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.filesFolder),
            });
        }

        if (teams.teams.length > 0) {
            commandItems.push({
                icon: restore16,
                id: 'vet.filesFolder',
                text: resources.RestoreTeams,
                disabled: !canRestore,
                onClick: () => restoreWizards.openTeamsWizard(teams.teams),
            });
        }

        return (
            <CommandMenu
                controlText='Restore'
                controlIcon={restore16}
                items={commandItems}
            />
        );
    }

    const restore = () => {
        if (exchange.items.length > 0) restoreWizards.openExchangeWizard(exchange.items);
        else if (oneDrive.oneDriveDocuments.length > 0) restoreWizards.openOneDriveWizard(oneDrive.oneDriveDocuments);
        else if (oneDrive.oneDriveFolders.length > 0) restoreWizards.openOneDriveWizard(oneDrive.oneDriveFolders);
        else if (sharePoint.docs.length > 0) restoreWizards.openSharePointWizard(sharePoint.docs);
        else if (sharePoint.sharePointFolders.length > 0) restoreWizards.openSharePointWizard(sharePoint.sharePointFolders);
        else if (sharePoint.listItems.length > 0) restoreWizards.openSharePointWizard(sharePoint.listItems);
        else if (sharePoint.listFolderItems.length > 0) restoreWizards.openSharePointWizard(sharePoint.listFolderItems);
        else if (teams.files.length > 0) restoreWizards.openTeamsWizard(teams.files);
        else if (teams.folders.length > 0) restoreWizards.openTeamsWizard(teams.folders);
        else if (teams.tabs.length > 0) restoreWizards.openTeamsWizard(teams.tabs);
        else if (teams.posts.length > 0) restoreWizards.openTeamsWizard(teams.posts);
        else if (teams.channels.length > 0) restoreWizards.openTeamsWizard(teams.channels);
        else if (teams.postsFolder.length > 0) restoreWizards.openTeamsWizard(teams.postsFolder);
        else if (teams.filesFolder.length > 0) restoreWizards.openTeamsWizard(teams.filesFolder);
        else if (teams.tabsFolder.length > 0) restoreWizards.openTeamsWizard(teams.tabsFolder);
        else if (teams.teams.length > 0) restoreWizards.openTeamsWizard(teams.teams);
    };

    return (
        <View spaceHorizontal={INDENT.s}>
            <Button ui={BUTTON_VIEW.text} onClick={restore}>
                <>
                    <Icon src={restore16} />
                    {resources.Restore}
                </>
            </Button>
        </View>
    );
};
