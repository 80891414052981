// Copyright © Veeam Software Group GmbH

import type { Moment } from 'moment';
import type { DataGridCellRenderer } from '@veeam/components';
import type { Func1 } from 'infrastructure/types';

import { textCellRender } from './textCell';

import type { CellRender, CellRendererProps } from './CellRender';

export const dateCellRender = (value: Moment | undefined, props: CellRendererProps): CellRender => textCellRender(
    value?.format('LLL'),
    props,
);

export const dateCell = <TRowData>(
    value: Func1<TRowData, Moment | undefined>,
): DataGridCellRenderer<TRowData> => (row, payload) => dateCellRender(value(row), payload);
