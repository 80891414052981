// Copyright © Veeam Software Group GmbH

import { StorageKeys } from '../StorageKeys';
import { getLocalStorage } from '../rawStorage';

export interface NotificationsInfo {
    autoDisplayMessages: boolean;
}

export const notificationsStorage = getLocalStorage<NotificationsInfo>(StorageKeys.Notifications);
