// Copyright © Veeam Software Group GmbH

import type { RESTRestorePoint } from '../../../../../api/rxjs';

export function findLatestRestorePoint(restorePoints: RESTRestorePoint[]): RESTRestorePoint {
    return restorePoints
        .reduce((previousValue, currentValue) =>
            new Date(previousValue.backupTime) > new Date(currentValue.backupTime) ? previousValue : currentValue
        );
}
