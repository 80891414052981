// Copyright © Veeam Software Group GmbH

import type { EnumMap } from 'infrastructure/types';
import type {
    Node,
    OneDriveNodeType,
    SharePointNodeType,
    ExchangeNode,
    ExchangeFolderCategory } from 'services';

import {
    mailboxSrc,
    oneDriveSrc,
    folderSrc,
    spSiteSrc,
    appointmentSrc,
    contactsSrc,
    journalSrc,
    tasksSrc,
    notesSrc,
    spSubsitesSrc,
    listsSrc,
    listSrc,
    librarySrc,
    channelSrc,
    postSrc,
    fileSrc,
    otherTabSrc,
    deletedItemsSrc,
    draftsSrc,
    inboxSrc,
    outboxSrc,
    permanentlyDeletedItemsSrc,
    sentItemsSrc,
    junkEmailSrc,
    teamFolderSrc,
    teamRootSrc,
} from 'assets';
import { never } from 'infrastructure/never';
import {
    ExchangeNodeType,
    Product,
    ExchangeFolderType,
} from 'services';

import type { TeamsNodeType } from '../../../services/models/Teams';

const exchangeFolderIconsByType: EnumMap<ExchangeFolderType, string> = {
    Appointment: appointmentSrc,
    Contact: contactsSrc,
    Journal: journalSrc,
    Task: tasksSrc,
    StickyNote: notesSrc,
    None: folderSrc,
};

const exchangeFolderIconsByCategory: EnumMap<ExchangeFolderCategory, string> = {
    Inbox: inboxSrc,
    Outbox: outboxSrc,
    Sent: sentItemsSrc,
    Deleted: deletedItemsSrc,
    Drafts: draftsSrc,
    PermanentlyDeletedItems: permanentlyDeletedItemsSrc,
    Purges: folderSrc,
    DiscoveryHolds: folderSrc,
    Conflicts: folderSrc,
    SyncIssues: folderSrc,
    LocalFailures: folderSrc,
    ServerFailures: folderSrc,
    Junk: junkEmailSrc,
    Versions: folderSrc,
    TeamsMessagesData: folderSrc,
    None: folderSrc,
};

const getExchangeNodeIcons = (node: ExchangeNode): string => {
    switch (node.nodeType) {
        case ExchangeNodeType.Mailbox:
            return mailboxSrc;
        case ExchangeNodeType.Folder:
            if (node.folderType !== ExchangeFolderType.None) {
                return exchangeFolderIconsByType[node.folderType];
            }
            return exchangeFolderIconsByCategory[node.folderCategory];
        default:
            return never(node);
    }
};

const oneDriveNodeIcons: EnumMap<OneDriveNodeType, string> = {
    OneDrive: oneDriveSrc,
    Folder: folderSrc,
};

const sharePointNodeIcons: EnumMap<SharePointNodeType, string> = {
    Site: spSiteSrc,
    SubSites: spSubsitesSrc,
    Content: listsSrc,
    List: listSrc,
    Library: librarySrc,
    ListFolder: folderSrc,
    LibraryFolder: folderSrc,
};

const teamsNodeIcons: EnumMap<TeamsNodeType, string> = {
    Teams: teamRootSrc,
    Team: teamFolderSrc,
    Channel: channelSrc,
    Files: fileSrc,
    Posts: postSrc,
    Tabs: otherTabSrc,
    Folder: folderSrc,
};

export const getIcon = (node: Node): string => {
    switch (node.product) {
        case Product.Exchange:
            return getExchangeNodeIcons(node);
        case Product.OneDrive:
            return oneDriveNodeIcons[node.nodeType];
        case Product.SharePoint:
            return sharePointNodeIcons[node.nodeType];
        case Product.Teams:
            return teamsNodeIcons[node.nodeType];
        default:
            return never(node);
    }
};
