// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTOrganizationSyncState
 */
export interface RESTOrganizationSyncState {
    /**
     * Type of the synchronization.
     * @type {string}
     * @memberof RESTOrganizationSyncState
     */
    type?: RESTOrganizationSyncStateTypeEnum;
    /**
     * Status of the synchronization.
     * @type {string}
     * @memberof RESTOrganizationSyncState
     */
    status?: RESTOrganizationSyncStateStatusEnum;
    /**
     * Date and time when the synchronization operation was performed for the last time.
     * @type {string}
     * @memberof RESTOrganizationSyncState
     */
    lastSyncTime?: string;
    /**
     * Error occured during the synchronization operation.
     * @type {string}
     * @memberof RESTOrganizationSyncState
     */
    error?: string;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTOrganizationSyncState
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationSyncStateTypeEnum {
    Incremental = 'Incremental',
    Full = 'Full'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTOrganizationSyncStateStatusEnum {
    None = 'None',
    Success = 'Success',
    Error = 'Error'
}

