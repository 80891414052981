// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTTeamsFile,
} from './';

/**
 * @export
 * @interface RESTOperatorRestoreFilesOptions
 */
export interface RESTOperatorRestoreFilesOptions {
    /**
     * Specifies the ID of the channel whose files you want to restore using Restore Portal. For more information on how to get this parameter, see [Get Team Channels](#tag/TeamsChannel/operation/TeamsChannel_Get).  **Note**: You do not need to use this property if you use the `files` property to specify what files to restore. 
     * @type {string}
     * @memberof RESTOperatorRestoreFilesOptions
     */
    channelId?: string;
    /**
     * Specifies IDs of files that you want to restore. The files must reside in the same channel. For more information on how to get such IDs, see [Get Files](#tag/TeamsFile/operation/TeamsFile_GetPage).  **Note**: You do not need to use this property if you use the `channelId` property to specify a channel whose files to restore. 
     * @type {Array<RESTTeamsFile>}
     * @memberof RESTOperatorRestoreFilesOptions
     */
    files?: Array<RESTTeamsFile>;
    /**
     * Defines whether to restore files that have been modified in the original location since the time when the backup was created.
     * @type {boolean}
     * @memberof RESTOperatorRestoreFilesOptions
     */
    restoreChangedItems: boolean;
    /**
     * Defines whether to restore files that are missed in the original location.
     * @type {boolean}
     * @memberof RESTOperatorRestoreFilesOptions
     */
    restoreMissingItems: boolean;
    /**
     * Specifies what version of files will be restored.
     * @type {string}
     * @memberof RESTOperatorRestoreFilesOptions
     */
    fileVersion: RESTOperatorRestoreFilesOptionsFileVersionEnum;
    /**
     * Specifies a reason for the restore operation.
     * @type {string}
     * @memberof RESTOperatorRestoreFilesOptions
     */
    reason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOperatorRestoreFilesOptionsFileVersionEnum {
    All = 'All',
    Last = 'Last'
}

