// Copyright © Veeam Software Group GmbH

import type { EnumMap } from 'infrastructure/types';
import type { RestoreSessionEventsStatus } from 'services/restoreService';

import { errorSrc, runningSrc, cancelledSrc, successSrc, warningSrc } from 'assets';

export const statusIconMap: EnumMap<`${RestoreSessionEventsStatus}`, string> = {
    Running: runningSrc,
    Success: successSrc,
    Skipped: successSrc,
    Warning: warningSrc,
    Failed: errorSrc,
    Cancelled: cancelledSrc,
};
