// Copyright © Veeam Software Group GmbH

export enum StorageKeys {
    Auth = 'auth',
    Rbac = 'rbac',
    MSAL = 'msal',
    ExploreSessions = 'explore-sessions',
    RestorePoints = 'restore-points',
    RecoveryListTable = 'recovery-list-table',
    Notifications = 'notifications',
}
