// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import isArray from 'lodash/isArray';
import { map } from 'rxjs/operators';

import type { CollectionLoader, CollectionModelValue } from '@veeam/components';
import type { Observable } from 'rxjs';
import type { Func2 } from 'infrastructure/types';
import type { Batch } from 'infrastructure/rxjs';

type LoadData<TRq, TData> = (request: TRq) => Observable<TData[] | Batch<TData>>;
type GetRequest<TRq, TData, TId, TSort extends string, TExtra> = (
    value: CollectionModelValue<TData, TId, TSort> & TExtra
) => TRq;

export const commonLoaderWithMap = <TRequest, TDataIn, TDataOut, TId, TSort extends string, TExtra = {}>(
    getRequest: GetRequest<TRequest, TDataOut, TId, TSort, TExtra>,
    loadData: LoadData<TRequest, TDataIn>,
    itemsMapper: Func2<TDataIn[], TRequest, TDataOut[]>,
    extraDataMapper?: Func2<CollectionModelValue<TDataOut, TId, TSort> & TExtra, TRequest, TExtra>,
    useCurrentItemsTotalCount?: boolean,
): CollectionLoader<TDataOut, TId, TSort, TExtra> => (value) => {
        const request = getRequest(value);
        return loadData(request).pipe(
            map((batch) => {
                const data = isArray(batch) ? batch : batch.data;
                const totalCount = isArray(batch) ? batch.length : batch.state.totalCount;
                const loadState = isArray(batch) ? undefined : batch.state;
                const items = itemsMapper(data, request);
                const extraData = extraDataMapper?.({ ...value, items, totalCount, loadState }, request) ?? {};
                return { data: { ...value, ...extraData, items, totalCount: useCurrentItemsTotalCount ? items.length : totalCount, loadState } };
            })
        );
    };

export const commonLoader = <TRequest, TData, TId, TSort extends string, TExtra = {}>(
    getRequest: GetRequest<TRequest, TData, TId, TSort, TExtra>,
    loadData: LoadData<TRequest, TData>,
    extraDataMapper?: Func2<CollectionModelValue<TData, TId, TSort> & TExtra, TRequest, TExtra>
): CollectionLoader<TData, TId, TSort, TExtra> =>
        commonLoaderWithMap(getRequest, loadData, data => data, extraDataMapper);

type GetRequestOrUndefined<TRequest, TData, TId, TSort extends string, TExtra> = (
    value: CollectionModelValue<TData, TId, TSort> & TExtra
) => TRequest | undefined;

export const commonLoaderOrDefault = <TRequest, TData, TId, TSort extends string, TExtra = {}>(
    getRequest: GetRequestOrUndefined<TRequest, TData, TId, TSort, TExtra>,
    loadData: LoadData<TRequest, TData>,
    defaultValue: TData[]
): CollectionLoader<TData, TId, TSort, TExtra> => (value) => {
        const request = getRequest(value);
        const data = request ? loadData(request) : of(defaultValue);
        return data.pipe(
            map((batch) => {
                const items = isArray(batch) ? batch : batch.data;
                const totalCount = isArray(batch) ? batch.length : batch.state.totalCount;
                const loadState = isArray(batch) ? undefined : batch.state;
                return { data: { ...value, items, totalCount, loadState } };
            })
        );
    };
