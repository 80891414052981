/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTSharePointItem,
    RESTExceptionInfo,
    RESTItemRestoreStatistics,
    RESTOperatorRestoreSessionResponse,
    RESTOperatorRestoreSharePointItemsConfig,
    RESTRestoreItemConfig,
    RESTRestoreItemsConfig,
    RESTRestoreToItemConfig,
    RESTRestoreToItemsConfig,
    RESTSharePointItem,
} from '../models';

export interface SharePointItemApiSharePointItemGetRequest {
    restoreSessionId: string;
    siteId: string;
    offset: number;
    limit: number;
    parentId?: string;
    includeFolders?: boolean;
}

export interface SharePointItemApiSharePointItemGetByIdRequest {
    restoreSessionId: string;
    siteId: string;
    itemId: string;
}

export interface SharePointItemApiSharePointItemGetByIdByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    itemId: string;
    versionId: number;
}

export interface SharePointItemApiSharePointItemGetVersionsRequest {
    restoreSessionId: string;
    siteId: string;
    itemId: string;
    offset: number;
    limit: number;
}

export interface SharePointItemApiSharePointItemOperatorRestoreRequest {
    restoreSessionId: string;
    siteId: string;
    body: RESTOperatorRestoreSharePointItemsConfig;
}

export interface SharePointItemApiSharePointItemRestoreItemActionRequest {
    restoreSessionId: string;
    siteId: string;
    itemId: string;
    body: RESTRestoreItemConfig;
}

export interface SharePointItemApiSharePointItemRestoreItemActionByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    itemId: string;
    versionId: number;
    body: RESTRestoreItemConfig;
}

export interface SharePointItemApiSharePointItemRestoreItemActionByVersionIdToRequest {
    restoreSessionId: string;
    siteId: string;
    itemId: string;
    versionId: number;
    body: RESTRestoreToItemConfig;
}

export interface SharePointItemApiSharePointItemRestoreItemActionToRequest {
    restoreSessionId: string;
    siteId: string;
    itemId: string;
    body: RESTRestoreToItemConfig;
}

export interface SharePointItemApiSharePointItemRestoreItemsActionRequest {
    restoreSessionId: string;
    siteId: string;
    body: RESTRestoreItemsConfig;
}

export interface SharePointItemApiSharePointItemRestoreItemsActionToRequest {
    restoreSessionId: string;
    siteId: string;
    body: RESTRestoreToItemsConfig;
}

/**
 * no description
 */
export const sharePointItemApi = ({

    /**
     * Returns a collection of backed-up of SharePoint items to explore and restore.
     * Get SharePoint Items
     */
    sharePointItemGet: ({ restoreSessionId, siteId, offset, limit, parentId, includeFolders }: SharePointItemApiSharePointItemGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointItem>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemGet');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemGet');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointItemGet');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointItemGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }
        if (includeFolders != null) { query['includeFolders'] = includeFolders; }

        return requestAuthenticated<PageOfRESTSharePointItem>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up SharePoint item with the specified ID.
     * Get SharePoint Item
     */
    sharePointItemGetById: ({ restoreSessionId, siteId, itemId }: SharePointItemApiSharePointItemGetByIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointItem>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemGetById');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemGetById');
        throwIfNullOrUndefined(itemId, 'itemId', 'sharePointItemGetById');

        return requestAuthenticated<RESTSharePointItem>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/{itemId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{itemId}', encodeURI(itemId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a resource representation of a specific version of a backed-up SharePoint item with the specified ID.
     * Get Specific Version of SharePoint Item
     */
    sharePointItemGetByIdByVersionId: ({ restoreSessionId, siteId, itemId, versionId }: SharePointItemApiSharePointItemGetByIdByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointItem>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemGetByIdByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemGetByIdByVersionId');
        throwIfNullOrUndefined(itemId, 'itemId', 'sharePointItemGetByIdByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointItemGetByIdByVersionId');

        return requestAuthenticated<RESTSharePointItem>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/{itemId}/Versions/{versionId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{itemId}', encodeURI(itemId)).replace('{versionId}', encodeURI(versionId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a collection of versions of a backed-up SharePoint item with the specified ID.  When you get SharePoint item versions, the server returns information about previous versions of the item. To get the latest version, use either [Get SharePoint Item](#tag/SharePointItem/operation/SharePointItem_GetById) or [Get Specific Version of SharePoint Item](#tag/SharePointItem/operation/SharePointItem_GetByIdByVersionId). 
     * Get All Versions of SharePoint Item
     */
    sharePointItemGetVersions: ({ restoreSessionId, siteId, itemId, offset, limit }: SharePointItemApiSharePointItemGetVersionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointItem>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemGetVersions');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemGetVersions');
        throwIfNullOrUndefined(itemId, 'itemId', 'sharePointItemGetVersions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointItemGetVersions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointItemGetVersions');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTSharePointItem>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/{itemId}/Versions'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{itemId}', encodeURI(itemId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Restores backed-up SharePoint items using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=70) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong>  </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div>   
     * Restore SharePoint Items by Restore Operator
     */
    sharePointItemOperatorRestore: ({ restoreSessionId, siteId, body }: SharePointItemApiSharePointItemOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemOperatorRestore');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemOperatorRestore');
        throwIfNullOrUndefined(body, 'body', 'sharePointItemOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTOperatorRestoreSessionResponse>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint item with the specified ID to the original location.
     * Restore SharePoint Item to Original Location
     */
    sharePointItemRestoreItemAction: ({ restoreSessionId, siteId, itemId, body }: SharePointItemApiSharePointItemRestoreItemActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemRestoreItemAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemRestoreItemAction');
        throwIfNullOrUndefined(itemId, 'itemId', 'sharePointItemRestoreItemAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointItemRestoreItemAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/{itemId}/Restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up SharePoint item to the original location.
     * Restore Version of SharePoint Item to Original Location
     */
    sharePointItemRestoreItemActionByVersionId: ({ restoreSessionId, siteId, itemId, versionId, body }: SharePointItemApiSharePointItemRestoreItemActionByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemRestoreItemActionByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemRestoreItemActionByVersionId');
        throwIfNullOrUndefined(itemId, 'itemId', 'sharePointItemRestoreItemActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointItemRestoreItemActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'sharePointItemRestoreItemActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/{itemId}/Versions/{versionId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{itemId}', encodeURI(itemId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up SharePoint item to another location.
     * Restore Version of SharePoint Item to Another Location
     */
    sharePointItemRestoreItemActionByVersionIdTo: ({ restoreSessionId, siteId, itemId, versionId, body }: SharePointItemApiSharePointItemRestoreItemActionByVersionIdToRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemRestoreItemActionByVersionIdTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemRestoreItemActionByVersionIdTo');
        throwIfNullOrUndefined(itemId, 'itemId', 'sharePointItemRestoreItemActionByVersionIdTo');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointItemRestoreItemActionByVersionIdTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointItemRestoreItemActionByVersionIdTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/{itemId}/Versions/{versionId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{itemId}', encodeURI(itemId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint item with the specified ID to another location.
     * Restore SharePoint Item to Another Location
     */
    sharePointItemRestoreItemActionTo: ({ restoreSessionId, siteId, itemId, body }: SharePointItemApiSharePointItemRestoreItemActionToRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemRestoreItemActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemRestoreItemActionTo');
        throwIfNullOrUndefined(itemId, 'itemId', 'sharePointItemRestoreItemActionTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointItemRestoreItemActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/{itemId}/RestoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{itemId}', encodeURI(itemId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up SharePoint items to the original location.
     * Restore SharePoint Items to Original Location
     */
    sharePointItemRestoreItemsAction: ({ restoreSessionId, siteId, body }: SharePointItemApiSharePointItemRestoreItemsActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemRestoreItemsAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemRestoreItemsAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointItemRestoreItemsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up SharePoint items to another location.
     * Restore SharePoint Items to Another Location
     */
    sharePointItemRestoreItemsActionTo: ({ restoreSessionId, siteId, body }: SharePointItemApiSharePointItemRestoreItemsActionToRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointItemRestoreItemsActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointItemRestoreItemsActionTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointItemRestoreItemsActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Items/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

})
