// Copyright © Veeam Software Group GmbH

import { of  } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

import type { Observable } from 'rxjs';

import type { ServiceCache } from './types';

export class ServiceMemoryCache<TData, TParams> implements ServiceCache<TData, TParams> {
    private cacheService$: Observable<TData> | undefined;
    private prevParams: TParams | undefined;
    constructor(
        private valueGetter: (params: TParams) => Observable<TData>,
        private paramsEqual: (prevParams?: TParams, nextParams?: TParams) => boolean | undefined = () => true
    ) {}

    public getValue(params: TParams, invalidate?: boolean) {
        if (!this.cacheService$ || invalidate || this.paramsEqual?.(this.prevParams, params) !== true) {
            this.prevParams = params;
            this.cacheService$ = this.valueGetter(params).pipe(shareReplay(1), take(1));
        }

        return this.cacheService$ as Observable<TData>;
    }

    public setValue(value: TData): void {
        this.cacheService$ = of(value).pipe(shareReplay(1), take(1));
    }

    public clearValue(): void {
        this.cacheService$ = undefined;
    }

    public isEmpty(): boolean {
        return !this.cacheService$;
    }
}
