// Copyright © Veeam Software Group GmbH

import resourceController from 'infrastructure/resources';

export function never<T>(value: never): T {
    throw neverError(value);
}
export function neverUndef<T>(value: undefined): T {
    throw neverError(value);
}
export const neverError = (value: unknown): Error => new Error(resourceController.current.infrastructure.never.neverErrorXs.format(JSON.stringify(value)));
