// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { v4 as uuid } from 'uuid';

import type { Observable } from 'rxjs';

import { exploreSessionService } from '../../../exploreSessions';
import { Batch } from '../../../../infrastructure/rxjs';
import { TeamsNodeType } from '../../../models/Teams/TeamsNode';
import { Product } from '../../../models';
import { convertBase } from '../../baseConverter';

import type { TeamsRootNode } from '../../../models/Teams/TeamsNode';
import type { Node } from '../../../models';

export const getTeams = (): Observable<Batch<Node>> => {
    const { vetSession } = exploreSessionService.getSessions();

    if (!vetSession) {
        return of(Batch.empty());
    }

    return of(Batch.from([{
        product: Product.Teams,
        nodeType: TeamsNodeType.Teams,
        ...convertBase('Node', { name: 'Teams', version: uuid() }),
    } as TeamsRootNode]));
};
