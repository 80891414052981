// Copyright © Veeam Software Group GmbH

/**
 * Copyright © Veeam Software Group GmbH.
 */

import { DatetimeInput, FieldLayout, Icon, INDENT, SPACING_S, Spinner, STACK_GAP, StackView } from '@veeam/components';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CONTROL_SIZE } from '@veeam/components/lib/common/public';
import errorSrc from '@veeam/uikit-assets/src/icons/status/Error/Error.svg';
import { observer } from 'mobx-react-lite';

import { organizationRbacApi } from '../../../../api/rxjs';
import { useRbac } from '../../../../features/Rbac';
import { createInputControl } from './components/createInputControl';
import { createRightSection } from './components/createRightSection';
import { createFooter } from './components/createFooter';
import { exploreSessionService } from '../../../../services/exploreSessions';
import { useExplorePageStore } from '../../stores/explore-page-store';
import { doesDateHaveRestorePoints } from './helpers/does-date-have-restore-points';
import { findLatestRestorePoint } from './helpers/find-latest-restore-point';
import { useResources } from '../../../../infrastructure/resources';

import type { OrganizationRbacApiOrganizationRbacGetLoggedInUserRestorePointsRequest, RESTRestorePoint } from '../../../../api/rxjs';

export const RestorePointSelector = observer(() => {
    const explorePageStore = useExplorePageStore();
    const [restorePoints, setRestorePoints] = useState<RESTRestorePoint[]>([]);
    const restorePointDate = explorePageStore.restorePoint && new Date(explorePageStore.restorePoint.backupTime);
    const [value, setValue] = useState<Date | undefined>(restorePointDate);
    const { currentScope } = useRbac();
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [isApplyActionDisabled, setApplyActionDisabled] = useState(false);

    const resources = useResources().features.Explore.RestorePointSelector;
    const latestRestorePoint = useMemo(() => {
        if (restorePoints && restorePoints.length) {
            return findLatestRestorePoint(restorePoints);
        }

        return null;
    }, [restorePoints]);
    const latestRestorePointTime = latestRestorePoint && (new Date(latestRestorePoint.backupTime)).getTime();
    const latestRestorePointSuffix = restorePointDate?.getTime() === latestRestorePointTime ? ` ${resources.latestSuffix}` : '';

    const resetState = useCallback(() => {
        setRestorePoints([]);
        setValue(explorePageStore.restorePoint && new Date(explorePageStore.restorePoint.backupTime));
        setError(false);
        setLoading(true);
    }, []);

    const onSelectLatestRestorePoint = useCallback(() => {
        const latestRestorePointStr = latestRestorePoint.backupTime;
        const latestRestorePointDate = new Date(latestRestorePointStr);

        if (value?.getTime() !== latestRestorePointDate.getTime()) {
            exploreSessionService.setRestorePointToStorage(latestRestorePoint);
            setValue(latestRestorePointDate);
        }
    }, [restorePoints, value]);

    useEffect(() => {
        const requestParams: OrganizationRbacApiOrganizationRbacGetLoggedInUserRestorePointsRequest = {
            rbacItem: currentScope.item && JSON.stringify(currentScope.item),
            isCopy: false,
        };

        resetState();

        organizationRbacApi.organizationRbacGetLoggedInUserRestorePoints(requestParams).toPromise()
            .then(restorePoints => setRestorePoints(restorePoints.getResultOrThrow()))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [currentScope?.item?.id]);

    return (
        <FieldLayout
            label={'Restore Point:'}
            widthSize={CONTROL_SIZE.full}
            style={{
                padding: SPACING_S,
            }}
        >
            <StackView
                spaceVertical={INDENT.s}
                gap={STACK_GAP.s}
            >
                <DatetimeInput
                    value={value}
                    onChange={(date) => {
                        const restorePoint = restorePoints.find(point => new Date(point.backupTime).getTime() === date.getTime());

                        exploreSessionService.setRestorePointToStorage(restorePoint);

                        setValue(date);
                    }}
                    controlRenderer={createInputControl(latestRestorePointSuffix)}
                    rightSectionRenderer={createRightSection(restorePoints, setApplyActionDisabled)}
                    footerRenderer={createFooter(isApplyActionDisabled, onSelectLatestRestorePoint)}
                    manualCloseMode={true}
                    placeholder='Select Restore Point...'
                    disabled={explorePageStore.isSessionLoading || restorePoints.length === 0}
                    isDisabledDate={date => doesDateHaveRestorePoints(date, restorePoints)}
                />
                {isLoading && <Spinner size={16} />}
                {isError && <Icon src={errorSrc}/>}
            </StackView>
        </FieldLayout>
    );
});
