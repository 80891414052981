import { Observable } from "rxjs";
import { subjects } from "../runtime";
import { RestoreSessionConfig } from "../models";

export interface RestoreSessionEventsHub {
    restoreSessionStarted: Observable<RestoreSessionConfig>;
    restoreSessionStopped: Observable<RestoreSessionConfig>;
    historyCleared: Observable<unknown>;
}

export const restoreSessionEventsHub: RestoreSessionEventsHub = subjects.restoreSessionEventsHub;
