// Copyright © Veeam Software Group GmbH

import type { RestoreSessionStatus, RestoreSessionType } from 'services/restoreService';
import type { LocalizableString } from 'infrastructure/resources';
import type { EnumMap } from 'infrastructure/types';

import type { GridResources } from './resources';


export { restoreSessionStatusIconMap } from 'features/Shared/mappers';

export const getTypeToTitleMap = (resources: GridResources['types']): EnumMap<RestoreSessionType, LocalizableString> => ({
    Vex: resources.vex,
    Vesp: resources.vesp,
    Veod: resources.veod,
    Vet: resources.vet,
});

export const getStatusToTitleMap = (resources: GridResources['statuses']): EnumMap<RestoreSessionStatus, LocalizableString> => ({
    Running: resources.running,
    Success: resources.success,
    Warning: resources.warning,
    Failed: resources.failed,
    Stopping: resources.stopping,
});
