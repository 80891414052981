// Copyright © Veeam Software Group GmbH

import { v4 as uuid } from 'uuid';
import values from 'lodash/values';

import type { Dictionary } from 'infrastructure/types';

export interface Subscription {
    unsubscribe(): void;
}

type EventReturn = void | Promise<void>;

type EventHandler<TArg> = TArg extends void
    ? () => EventReturn
    : (arg: TArg) => EventReturn;

export interface Event<TArg = void> {
    subscribe(handler: EventHandler<TArg>): Subscription;
}

export interface EventHolder<TArg = void> extends Event<TArg> {
    raise(arg: TArg): Promise<void>;
    safeRaise(arg: TArg): Promise<Error | undefined>;
}

export function createEvent<TArg = void>(): EventHolder<TArg> {
    const handlers: Dictionary<EventHandler<TArg>> = {};
    const subscribe = (handler: EventHandler<TArg>): Subscription => {
        const id = uuid();
        handlers[id] = handler;
        const unsubscribe = (): void => {
            delete handlers[id];
        };
        return { unsubscribe };
    };
    const raise = async(args: TArg): Promise<void> => {
        await Promise.all(values(handlers).map(handler => handler?.(args)));
    };
    const safeRaise = async(args: TArg): Promise<Error | undefined> => {
        try {
            await raise(args);
            return undefined;
        } catch (error) {
            return error;
        }
    };
    return { subscribe, raise, safeRaise };
}
