// Copyright © Veeam Software Group GmbH

import type { NodeBase, ExplorePath, SegmentType } from 'services/models';

import { explorePath as _explorePath, getUniqueId } from 'services/models';

interface Base {
    id?: string;
    name?: string;
    version?: string;
}

export const convertBase = (type: `${SegmentType}`, base: Base, parent?: ExplorePath): NodeBase => {
    const uniqId = getUniqueId(base.id || '', base.version);
    const title = base.name || '';
    const explorePathTitle = type === 'Version' ? base.version || '' : title;
    const explorePath = _explorePath.create({
        uniqId,
        title: explorePathTitle,
        type: type as SegmentType,
    }, parent);
    return { uniqId, title, explorePath };
};
