import { Subject } from "rxjs";
import { RestoreSessionLogConfig } from "../models";

export const restoreSessionLogEventsHub = {
    addRestoreSessionLog: new Subject<RestoreSessionLogConfig>(),
    finishRestoreSessionLog: new Subject<RestoreSessionLogConfig>(),
    };

function onConnect(connection: SignalR.Hub.Connection): void {
    const proxy = connection.createHubProxy('RestoreSessionLogEventsHub');
    proxy.on('addRestoreSessionLog', (req) => restoreSessionLogEventsHub.addRestoreSessionLog.next(req));
    proxy.on('finishRestoreSessionLog', (req) => restoreSessionLogEventsHub.finishRestoreSessionLog.next(req));
}

export const restoreSessionLogEventsHubConnector = { onConnect };
