// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTGroup,
    RESTLinkHAL,
    RESTUser,
} from './';

/**
 * @export
 * @interface RESTAuditUserOrGroup
 */
export interface RESTAuditUserOrGroup {
    /**
     * ID of the audit item.
     * @type {string}
     * @memberof RESTAuditUserOrGroup
     */
    id?: string;
    /**
     * Type of the audit item.
     * @type {string}
     * @memberof RESTAuditUserOrGroup
     */
    type?: RESTAuditUserOrGroupTypeEnum;
    /**
     * @type {RESTGroup}
     * @memberof RESTAuditUserOrGroup
     */
    group?: RESTGroup;
    /**
     * @type {RESTUser}
     * @memberof RESTAuditUserOrGroup
     */
    user?: RESTUser;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTAuditUserOrGroup
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTAuditUserOrGroupTypeEnum {
    User = 'User',
    Group = 'Group'
}

