// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTRestoreSharePointDocumentConfig,
} from './';

/**
 * @export
 * @interface RESTOperatorRestoreSharePointDocumentsConfig
 */
export interface RESTOperatorRestoreSharePointDocumentsConfig {
    /**
     * Specifies settings for document restore.
     * @type {Array<RESTRestoreSharePointDocumentConfig>}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    documentsRestoreConfigs: Array<RESTRestoreSharePointDocumentConfig>;
    /**
     * Specifies the target SharePoint list.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    list?: string;
    /**
     * Defines whether the SharePoint documents will be restored with all permissions.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    restorePermissions?: boolean;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    sendSharedLinksNotification?: boolean;
    /**
     * Specifies what version of the SharePoint documents will be restored.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    documentVersion?: RESTOperatorRestoreSharePointDocumentsConfigDocumentVersionEnum;
    /**
     * Specifies the action that will be performed with the last version of the restored SharePoint document on the destination server.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    documentLastVersionAction?: RESTOperatorRestoreSharePointDocumentsConfigDocumentLastVersionActionEnum;
    /**
     * Defines whether to restore the documents that have been modified in the original location.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    restoreChangedItems?: boolean;
    /**
     * Defines whether to restore the documents that have been deleted in the original location.
     * @type {boolean}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    restoreDeletedItems?: boolean;
    /**
     * Specifies a reason for the restore operation.
     * @type {string}
     * @memberof RESTOperatorRestoreSharePointDocumentsConfig
     */
    reason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOperatorRestoreSharePointDocumentsConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTOperatorRestoreSharePointDocumentsConfigDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}

