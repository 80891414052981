// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { LoadPagesConfig } from 'infrastructure/rxjs';
import type { Node, OneDriveNode } from 'services/models';

import { Batch, loadPages } from 'infrastructure/rxjs';
import { convert } from './converters';
import { oneDriveApi } from './wrappedOneDriveApi';
import { exploreSessionService } from 'services/exploreSessions';
import { OneDriveNodeType } from 'services/models';

export const getOneDrives = (config: LoadPagesConfig | undefined): Observable<Batch<Node>> => {
    const { veodSession } = exploreSessionService.getSessions();
    if (!veodSession) return of(Batch.empty());
    return loadPages(oneDriveApi.getOneDrives, config)({
        restoreSessionId: veodSession,
    }).pipe(
        map(oneDrives => oneDrives.mapData<Node>(convert.oneDrive.fromRest)),
        catchError(() => of(Batch.empty<Node>())),
    );
};

export const getOneDriveNodes = (parent: OneDriveNode, config: LoadPagesConfig | undefined): Observable<Batch<Node>> => {
    const { veodSession } = exploreSessionService.getSessions();
    if (!veodSession) {
        console.error('');
        return of(Batch.empty());
    }
    return loadPages(oneDriveApi.getFolders, config)({
        restoreSessionId: veodSession,
        oneDriveId: parent.oneDriveId,
        parentId: parent.nodeType === OneDriveNodeType.OneDrive ? 'root' : parent.folderId,
    }).pipe(
        map(folders => folders.mapData<Node>(folder => convert.folder.fromRest(folder, parent.oneDriveId, parent.explorePath))),
    );
};
