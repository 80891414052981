// Copyright © Veeam Software Group GmbH

import type { Form } from '@veeam/core';
import type {
    RESTTeamsChannel,
    RESTTeamsFile,
    RESTTeamsNode,
    RESTTeamsPost,
    RestTeamsTab,
    RESTTeamsTeam,
} from 'api/rxjs';
import type { TeamsFileItem } from 'services/models/Teams/TeamsItem';

import { exploreSessionService } from 'services/exploreSessions';
import { checkItemsType } from './check-items-type';
import {
    RESTOperatorRestoreFilesOptionsFileVersionEnum,
    teamsChannelApi,
    teamsFileApi,
    teamsPostApi,
    teamsTabApi,
    teamsTeamApi,
} from 'api/rxjs';
import { findIdValueInUrl } from 'services/explore/products/teams/find-id-value-in-url';
import { TeamsItemType } from 'services/models/Teams/TeamsItem';

import type { TeamsRestoreForm } from '../interfaces/teams-restore-form';

export const getRestoreRequest = (formApi: Form<TeamsRestoreForm>) => {
    const formValue = formApi.getValue();
    const { vetSession } = exploreSessionService.getSessions();
    const items = formValue.items;

    switch (checkItemsType(formValue.items)) {
        case TeamsItemType.File:
        case TeamsItemType.Folder:
            return teamsFileApi.teamsFileOperatorRestore({
                teamId: findIdValueInUrl((items[0] as RESTTeamsFile)._links.self.href, 'teams'),
                restoreSessionId: vetSession,
                body: {
                    reason: formValue.reason,
                    files: (items as TeamsFileItem[]).map(item => ({
                        ...item,
                        version: item.versionId,
                    })),
                    fileVersion: formValue.restoreOnlyLatest ?
                        RESTOperatorRestoreFilesOptionsFileVersionEnum.Last : RESTOperatorRestoreFilesOptionsFileVersionEnum.All,
                    restoreChangedItems: formValue.restoreChangedItems,
                    restoreMissingItems: formValue.restoreMissingItems,
                },
            });
        case TeamsItemType.Post:
            return teamsPostApi.teamsPostOperatorRestore({
                teamId: findIdValueInUrl((items[0] as RESTTeamsPost)._links.self.href, 'teams'),
                restoreSessionId: vetSession,
                body: {
                    reason: formValue.reason,
                    channelId: findIdValueInUrl((items[0] as RESTTeamsPost)._links.channel.href, 'channels'),
                    posts: items as RESTTeamsPost[],
                },
            });
        case TeamsItemType.Tab:
            return teamsTabApi.teamsTabOperatorRestoreByMultipleChannels({
                teamId: findIdValueInUrl((items[0] as RestTeamsTab)._links.self.href, 'teams'),
                restoreSessionId: vetSession,
                body: {
                    reason: formValue.reason,
                    restoreChangedTabs: formValue.restoreChangedTabs,
                    restoreMissingTabs: formValue.restoreMissingTabs,
                    tabs: items as RestTeamsTab[],
                },
            });
        case TeamsItemType.Channel:
            return teamsChannelApi.teamsChannelOperatorRestore({
                teamId: findIdValueInUrl((items[0] as RESTTeamsChannel)._links.self.href, 'teams'),
                restoreSessionId: vetSession,
                channelId: findIdValueInUrl((items[0] as RESTTeamsChannel)._links.self.href, 'channels'),
                body: {
                    reason: formValue.reason,
                    restoreChangedItems: formValue.restoreChangedItems,
                    restoreMissingItems: formValue.restoreMissingItems,
                },
            });
        case TeamsItemType.Tabs:
            return teamsTabApi.teamsTabOperatorRestore({
                teamId: findIdValueInUrl((items[0] as RESTTeamsNode)._links.channel.href, 'teams'),
                restoreSessionId: vetSession,
                channelId: findIdValueInUrl((items[0] as RESTTeamsNode)._links.channel.href, 'channels'),
                body: {
                    reason: formValue.reason,
                    restoreChangedTabs: formValue.restoreChangedTabs,
                    restoreMissingTabs: formValue.restoreMissingTabs,
                },
            });
        case TeamsItemType.Files:
            return teamsFileApi.teamsFileOperatorRestore({
                teamId: findIdValueInUrl((items[0] as RESTTeamsNode)._links.channel.href, 'teams'),
                restoreSessionId: vetSession,
                body: {
                    reason: formValue.reason,
                    fileVersion: formValue.restoreOnlyLatest ?
                        RESTOperatorRestoreFilesOptionsFileVersionEnum.Last : RESTOperatorRestoreFilesOptionsFileVersionEnum.All,
                    restoreChangedItems: formValue.restoreChangedItems,
                    restoreMissingItems: formValue.restoreMissingItems,
                    channelId: findIdValueInUrl((items[0] as RESTTeamsNode)._links.channel.href, 'channels'),
                },
            });
        case TeamsItemType.Posts:
            return teamsPostApi.teamsPostOperatorRestore({
                teamId: findIdValueInUrl((items[0] as RESTTeamsNode)._links.channel.href, 'teams'),
                restoreSessionId: vetSession,
                body: {
                    reason: formValue.reason,
                    channelId: findIdValueInUrl((items[0] as RESTTeamsNode)._links.channel.href, 'channels'),
                },
            });
        case TeamsItemType.Team:
            return teamsTeamApi.teamsTeamOperatorRestore({
                restoreSessionId: vetSession,
                body: {
                    restoreChangedItems: formValue.restoreChangedItems,
                    restoreMembers: formValue.restoreMembers,
                    restoreMissingItems: formValue.restoreMissingItems,
                    restoreSettings: formValue.restoreSettings,
                    impersonationAccountName: '',
                    teams: items as RESTTeamsTeam[],
                    reason: formValue.reason,
                },
            });
    }
};
