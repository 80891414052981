// Copyright © Veeam Software Group GmbH

import { DixieDb } from './DixieDb';
import { createRecoveryListTable } from './recoveryListTable';

import type { SelfServicePortalDatabase } from '../database';

export const createDixieDatabase = async(): Promise<SelfServicePortalDatabase> => {
    const db = new DixieDb();
    await db.open();
    const recoveryList = createRecoveryListTable(db);
    const clear = async() => await db.delete();
    return { recoveryList, clear };
};
