// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies account that you want to use to acquire an access token from Google or Microsoft Azure.
 * @export
 * @enum {string}
 */
export enum RESTOAuth2ServiceKind {
    GoogleGmail = 'GoogleGmail',
    Microsoft365 = 'Microsoft365'
}

