// Copyright © Veeam Software Group GmbH

import React from 'react';
import { AppLayout } from '@veeam/components';

import type { FC } from 'react';

import { RestoreWizardsProvider } from 'features/Restore';
import { RbacContextProvider } from 'features/Rbac';
import { MainAppBar } from './components/MainAppBar';
import { useRouting } from './useRouting';
import { ExploreTreeProvider } from 'contexts/ExploreTreeProvider';

export const Main: FC = () => (
    <RbacContextProvider>
        <ExploreTreeProvider>
            <AppLayout
                header={<MainAppBar />}
                body={
                    <RestoreWizardsProvider>
                        {useRouting()}
                    </RestoreWizardsProvider>
                }
            />
        </ExploreTreeProvider>
    </RbacContextProvider>
);
