// Copyright © Veeam Software Group GmbH

import type { Moment } from 'moment';

import { RESTRestoreSessionTypeEnum as RestoreSessionType } from 'api/rxjs';

export { RestoreSessionType };

export type RestoreSessionId = string & { __restore_session_id: never; };

export enum RestoreSessionStatus {
    Running = 'Running',
    Success = 'Success',
    Warning = 'Warning',
    Failed = 'Failed',
    Stopping = 'Stopping',
}

export interface RestoreSession {
    id: RestoreSessionId;
    type: RestoreSessionType;
    status: RestoreSessionStatus;
    startTime: Moment;
    endTime?: Moment;
    reason?: string;
    details?: string;
    scopeName?: string;
}
