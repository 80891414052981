// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { Override1 } from 'infrastructure/types';
import type { OneDriveItem, VersionableItem } from 'services/models';

import { OneDriveItemType } from 'services/models';
import { Batch, loadPages } from 'infrastructure/rxjs';
import { convert } from './converters';
import { oneDriveApi } from './wrappedOneDriveApi';
import { exploreSessionService } from 'services/exploreSessions';

import type { GetItemVersionsRequest } from '../../interfaces/get-item-versions-request';


export type GetOneDriveDocumentVersionsRequest = Override1<GetItemVersionsRequest, 'parent', OneDriveItem>;

export const getOneDriveVersions = ({ parent, config }: GetOneDriveDocumentVersionsRequest): Observable<Batch<VersionableItem>> => {
    const { veodSession } = exploreSessionService.getSessions();
    if (!veodSession) {
        console.error('');
        return of(Batch.empty());
    }

    if (parent.itemType === OneDriveItemType.Folder) {
        return loadPages(oneDriveApi.getVersionsForFolders, config)({
            restoreSessionId: veodSession,
            oneDriveId: parent.oneDriveId,
            folderId: parent.id,
        }).pipe(
            map(folders => folders.mapData(folder => convert.folderItem.fromRest(folder, parent.oneDriveId, parent.explorePath, true))),
        );
    }

    return loadPages(oneDriveApi.getVersions, config)({
        restoreSessionId: veodSession,
        oneDriveId: parent.oneDriveId,
        documentId: parent.id,
    }).pipe(
        map(folders => folders.mapData(folder => convert.document.fromRest(folder, parent.oneDriveId, parent.explorePath, true))),
    );
};
