// Copyright © Veeam Software Group GmbH

/**
 * Copyright © Veeam Software Group GmbH.
 */

import { createContext } from 'react';

import type { ExplorePageStore } from './explore-page-store';

export const ExplorePageStoreContext = createContext<ExplorePageStore>(undefined);
