// Copyright © Veeam Software Group GmbH

import React, { useMemo } from 'react';
import { CheckboxField, FormLayout, LabelGroup, STACK_DIRECTION, Text } from '@veeam/components';

import type { FC } from 'react';
import type { Form } from '@veeam/core';
import type { WizardStepConfig } from 'components/wizard';
import type { Resources } from 'infrastructure/resources';

import { PageSection } from 'components/wizard';
import { checkItemsType } from '../helpers/check-items-type';
import { TeamsItemType } from 'services/models/Teams/TeamsItem';
import { useMessageBoxManagement } from '../../../../../infrastructure/error-management';

import type { TeamsRestoreForm } from '../interfaces/teams-restore-form';

type StepResources = Resources['features']['Restore']['Wizards']['TeamsRestoreWizard'];

const RestoreTypeStepForm: FC<{
    form: Form<TeamsRestoreForm>;
    resources: StepResources;
}> = ({ form, resources }) => {
    switch (checkItemsType(form.getValue().items)) {
        case TeamsItemType.File:
        case TeamsItemType.Files:
        case TeamsItemType.Folder:
            return (
                <PageSection title={resources.restoreType}>
                    <FormLayout>
                        <Text>{resources.restoreTheFollowingItems}:</Text>
                        <CheckboxField label={resources.changedItems} field={form.fields.restoreChangedItems} />
                        <CheckboxField label={resources.missingItems} field={form.fields.restoreMissingItems} />
                        <LabelGroup label={resources.historyRestoreOptions}>
                            <FormLayout direction={STACK_DIRECTION.column}>
                                <CheckboxField label={resources.restoreOnlyLatest} field={form.fields.restoreOnlyLatest} />
                            </FormLayout>
                        </LabelGroup>
                    </FormLayout>
                </PageSection>
            );
        case TeamsItemType.Tab:
        case TeamsItemType.Tabs:
            return (
                <PageSection title={resources.restoreType}>
                    <FormLayout>
                        <Text>{resources.restoreTheFollowingItems}:</Text>
                        <CheckboxField label={resources.changedItems} field={form.fields.restoreChangedTabs} />
                        <CheckboxField label={resources.missingItems} field={form.fields.restoreMissingTabs} />
                    </FormLayout>
                </PageSection>
            );
        case TeamsItemType.Channel:
            return (
                <PageSection title={resources.restoreType}>
                    <FormLayout>
                        <Text>{resources.restoreTheFollowingItems}:</Text>
                        <CheckboxField label={resources.changedItems} field={form.fields.restoreChangedItems} />
                        <CheckboxField label={resources.missingItems} field={form.fields.restoreMissingItems} />
                    </FormLayout>
                </PageSection>
            );
        case TeamsItemType.Team:
            return (
                <PageSection title={resources.restoreType}>
                    <FormLayout>
                        <Text>{resources.restoreTheFollowingItems}:</Text>
                        <CheckboxField label={resources.changedItems} field={form.fields.restoreChangedItems} />
                        <CheckboxField label={resources.missingItems} field={form.fields.restoreMissingItems} />
                        <Text>{resources.restoreTheFollowingSettings}:</Text>
                        <CheckboxField label={resources.restoreTeamSettings} field={form.fields.restoreSettings} />
                        <CheckboxField label={resources.restoreMembership} field={form.fields.restoreMembers} />
                    </FormLayout>
                </PageSection>
            );
    }
};

export const useRestoreTypeStep = (form: Form<TeamsRestoreForm>, resources: StepResources): WizardStepConfig => {
    const hasRestoreTypeStep = useMemo(() => {
        const itemType = checkItemsType(form.getValue().items);

        if (itemType === TeamsItemType.Post || itemType === TeamsItemType.Posts) {
            return false;
        }

        return true;
    }, []);
    const { show } = useMessageBoxManagement();

    if (hasRestoreTypeStep) { // "hidden" property doesn't works as we expect
        return {
            tabTitle: resources.restoreType,
            tag: 'restoreType',
            body: <RestoreTypeStepForm form={form} resources={resources} />,
            validate(): Promise<boolean> {
                const { restoreChangedItems, restoreMissingItems, restoreChangedTabs, restoreMissingTabs } = form.getValue();

                if (!restoreChangedItems && !restoreMissingItems && !restoreChangedTabs && !restoreMissingTabs) {
                    show({message: resources.oneOfRestoreOptionMustBeSelected});

                    return Promise.resolve(false);
                } else {
                    return Promise.resolve(true);
                }
            }
        };
    }
};

