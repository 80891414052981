// Copyright © Veeam Software Group GmbH

import React, { useRef } from 'react';
import { createBrowserHistory } from 'history';
import { NavigationRouter, NavigationRouterProvider } from '@veeam/core';

import type { FC } from 'react';

import { routerConfig } from './router-config';

export const Router: FC = ({ children }) => {
    const historyRef = useRef(createBrowserHistory());

    const routerRef = useRef(new NavigationRouter(
        routerConfig,
        historyRef.current as never,
        () => ({}),
        false,
    ));

    return (
        <NavigationRouterProvider router={routerRef.current}>
            {children}
        </NavigationRouterProvider>
    );
};
