/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTSharePointList,
    RESTExceptionInfo,
    RESTListRestoreStatistics,
    RESTRestoreListConfig,
    RESTRestoreToListConfig,
    RESTSharePointList,
    RESTVESPSearchOptions,
} from '../models';

export interface SharePointListApiSharePointListGetRequest {
    restoreSessionId: string;
    siteId: string;
    offset: number;
    limit: number;
}

export interface SharePointListApiSharePointListGetByIdRequest {
    restoreSessionId: string;
    siteId: string;
    listId: string;
}

export interface SharePointListApiSharePointListRestoreListActionRequest {
    restoreSessionId: string;
    siteId: string;
    listId: string;
    body: RESTRestoreListConfig;
}

export interface SharePointListApiSharePointListRestoreListActionToRequest {
    restoreSessionId: string;
    siteId: string;
    listId: string;
    body: RESTRestoreToListConfig;
}

export interface SharePointListApiSharePointListSearchListByOptionsRequest {
    restoreSessionId: string;
    siteId: string;
    listId: string;
    offset: number;
    limit: number;
    body: RESTVESPSearchOptions;
    setId?: string;
    itemType?: SharePointListSearchListByOptionsItemTypeEnum;
}

/**
 * no description
 */
export const sharePointListApi = ({

    /**
     * Returns a collection of backed-up SharePoint lists to explore and restore.
     * Get SharePoint Lists
     */
    sharePointListGet: ({ restoreSessionId, siteId, offset, limit }: SharePointListApiSharePointListGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointList>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointListGet');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointListGet');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointListGet');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointListGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTSharePointList>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Lists'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up SharePoint list with the specified ID.
     * Get SharePoint List
     */
    sharePointListGetById: ({ restoreSessionId, siteId, listId }: SharePointListApiSharePointListGetByIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointList>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointListGetById');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointListGetById');
        throwIfNullOrUndefined(listId, 'listId', 'sharePointListGetById');

        return requestAuthenticated<RESTSharePointList>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Lists/{listId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{listId}', encodeURI(listId)),
            method: 'GET',
        }, option);
    },

    /**
     * Restores a backed-up SharePoint list with the specified ID to the original location.
     * Restore SharePoint List to Original Location
     */
    sharePointListRestoreListAction: ({ restoreSessionId, siteId, listId, body }: SharePointListApiSharePointListRestoreListActionRequest, option?: RequestOption): Observable<Optional<RESTListRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointListRestoreListAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointListRestoreListAction');
        throwIfNullOrUndefined(listId, 'listId', 'sharePointListRestoreListAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointListRestoreListAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTListRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Lists/{listId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{listId}', encodeURI(listId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint list with the specified ID to another location.
     * Restore SharePoint List to Another Location
     */
    sharePointListRestoreListActionTo: ({ restoreSessionId, siteId, listId, body }: SharePointListApiSharePointListRestoreListActionToRequest, option?: RequestOption): Observable<Optional<RESTListRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointListRestoreListActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointListRestoreListActionTo');
        throwIfNullOrUndefined(listId, 'listId', 'sharePointListRestoreListActionTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointListRestoreListActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTListRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Lists/{listId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{listId}', encodeURI(listId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Searches for SharePoint items in a backed-up SharePoint list with the specified ID.
     * Search for SharePoint Items in SharePoint List
     */
    sharePointListSearchListByOptions: ({ restoreSessionId, siteId, listId, offset, limit, body, setId, itemType }: SharePointListApiSharePointListSearchListByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointListSearchListByOptions');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointListSearchListByOptions');
        throwIfNullOrUndefined(listId, 'listId', 'sharePointListSearchListByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointListSearchListByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointListSearchListByOptions');
        throwIfNullOrUndefined(body, 'body', 'sharePointListSearchListByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Lists/{listId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{listId}', encodeURI(listId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum SharePointListSearchListByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Items = 'Items'
}
