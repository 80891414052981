// Copyright © Veeam Software Group GmbH

import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

import { restoreSessionLogEventsHub as hub } from 'api/signalr';
import { convertEventFromSignalR } from './converters';

export const onEventChanged = merge(
    hub.addRestoreSessionLog,
    hub.finishRestoreSessionLog,
).pipe(map(convertEventFromSignalR));
