// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { DataGridColumnConfig } from '@veeam/components';
import type { RestoreSessionEvent } from 'services/restoreService';

import { iconCell, durationCell } from 'components/dataGridCells';
import { statusIconMap } from './mappers';

import type { GridResources } from './resources';


export const useColumns = (resources: GridResources): DataGridColumnConfig<RestoreSessionEvent>[] =>
    useMemo(
        () => [
            {
                id: 'message',
                title: resources.cells.name,
                cell: iconCell(row => ({ text: row.message, iconSrc: statusIconMap[row.status] })),
            },
            {
                id: 'duration',
                title: resources.cells.duration,
                cell: durationCell(row => row.duration),
                width: 200,
            },
        ],
        [resources]
    );
