// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTCommonDeviceCodeRequestOptions
 */
export interface RESTCommonDeviceCodeRequestOptions {
    /**
     * Specifies the region of the Microsoft 365 organization that you plan to add.
     * @type {string}
     * @memberof RESTCommonDeviceCodeRequestOptions
     */
    targetRegion?: RESTCommonDeviceCodeRequestOptionsTargetRegionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCommonDeviceCodeRequestOptionsTargetRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

