// Copyright © Veeam Software Group GmbH

export enum ExploreSortKeys {
    Title = 'Title',
    SizeBytes = 'SizeBytes',
    ModificationTime = 'ModificationTime',
    Version = 'Version',
    Subject = 'Subject',
    Date = 'Date',
    StartTime = 'StartTime',
    EndTime = 'EndTime',
    Organizer = 'Organizer',
    Location = 'Location',
    Attendees = 'Attendees',
    Attachments = 'Attachments',
    Status = 'Status',
    PercentComplete = 'PercentComplete',
    Owner = 'Owner',
    FullName = 'FullName',
    Company = 'Company',
    JobTitle = 'JobTitle',
    FileAs = 'FileAs',
    Email = 'Email',
    DisplayAs = 'DisplayAs',
    WebPage = 'WebPage',
    ImAddress = 'ImAddress',
    BusinessPhone = 'BusinessPhone',
    CreatedTime = 'CreatedTime',
    IsImportant = 'IsImportant',
    Importance = 'Importance',
    From = 'From',
    To = 'To',
    Cc = 'Cc',
    Bcc = 'Bcc',
    Received = 'Received',
    FileType = 'FileType',
    Modified = 'Modified',
    ModifiedBy = 'ModifiedBy',
    LastModifiedTime = 'LastModifiedTime',
    Author = 'Author',
    Type = 'Type',
    ContentUrl = 'ContentUrl',
    Description = 'Description',
    ItemType = 'ItemType',
    IsFolder = 'IsFolder',
}
