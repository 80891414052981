// Copyright © Veeam Software Group GmbH

import React, { useMemo } from 'react';

import type { CollectionToolbarCustomItem } from '@veeam/components';

import { RestoreButton } from 'features/Restore';

import type { BucketResources } from './resources';
import type { BucketItemPresentation, RestoreBucketModel } from './useModel';

export function useRestoreAction(resources: BucketResources, model: RestoreBucketModel): CollectionToolbarCustomItem {
    return useMemo(() => ({
        isAction: true,
        render: () => (
            <RestoreButton
                items={
                    model.getValue().selection
                        .map(id => model.getItemById(id))
                        .filter((bucketItem): bucketItem is BucketItemPresentation => !!bucketItem)
                }
            />
        ),
        hasOffset: true,
    }), [resources, model]);
}
