import { Subject } from "rxjs";
import { RestoreSessionConfig } from "../models";

export const restoreSessionEventsHub = {
    restoreSessionStarted: new Subject<RestoreSessionConfig>(),
    restoreSessionStopped: new Subject<RestoreSessionConfig>(),
    historyCleared: new Subject<unknown>(),
    };

function onConnect(connection: SignalR.Hub.Connection): void {
    const proxy = connection.createHubProxy('RestoreSessionEventsHub');
    proxy.on('restoreSessionStarted', (req) => restoreSessionEventsHub.restoreSessionStarted.next(req));
    proxy.on('restoreSessionStopped', (req) => restoreSessionEventsHub.restoreSessionStopped.next(req));
    proxy.on('historyCleared', (req) => restoreSessionEventsHub.historyCleared.next(req));
}

export const restoreSessionEventsHubConnector = { onConnect };
