// Copyright © Veeam Software Group GmbH

import { useEffect } from 'react';

import { restoreService } from 'services/restoreService';
import { convertFilter } from './useModel';

import type { EventsModel } from './useModel';

export function useSignalR(model: EventsModel): void {
    useEffect(() => {
        const subscription = restoreService.sessions.onEventChanged.subscribe((eventSession) => {
            if (model.getValue().session !== eventSession.sessionId) return;
            model.update((mutable) => {
                const events = [...mutable.items];
                const event = mutable.items.find(item => item.id === eventSession.id);
                if (event) {
                    event.status = eventSession.status;
                    event.endTime = eventSession.endTime;
                    event.duration = eventSession.duration;
                    event.message = eventSession.message;
                } else events.push(eventSession);
                const filteredEvents = restoreService.sessions.filterEvents(convertFilter(mutable.filters), events);
                const sortedEvents = restoreService.sessions.sortEvents(filteredEvents);
                mutable.items = sortedEvents; // can be optimized
            });
        });
        return () => subscription.unsubscribe();
    }, []);
}
