// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { RESTExchangeMailbox } from 'api/rxjs';
import type { LoadPagesConfig } from 'infrastructure/rxjs';
import type { ExchangeNode, Node } from 'services/models';

import { Batch, loadPages } from 'infrastructure/rxjs';
import { convert } from './converters';
import { exchangeApi } from './wrappedExchangeApi';
import { exploreSessionService } from 'services/exploreSessions';
import { ExchangeNodeType } from 'services/models';

export const getExchangeMailboxes = (config: LoadPagesConfig | undefined): Observable<Batch<Node>> => {
    const { vexSession } = exploreSessionService.getSessions();
    if (!vexSession) return of(Batch.empty());
    return loadPages(exchangeApi.getMailboxes, config)({
        restoreSessionId: vexSession,
    }).pipe(
        map(mailboxes => mailboxes.mapData<Node>(convert.mailbox.fromRest)),
        map((mailboxes) => {
            (mailboxes.data as RESTExchangeMailbox[]).sort((a: RESTExchangeMailbox, b: RESTExchangeMailbox) => {
                if (a.isArchive === true && b.isArchive === false) return 1;
                if (a.isArchive === false && b.isArchive === true) return -1;
                return 0;
            });

            return mailboxes;
        }),
        catchError(() => of(Batch.empty<Node>())),
    );
};

export const getExchangeNodes = (parent: ExchangeNode, config: LoadPagesConfig | undefined): Observable<Batch<Node>> => {
    const { vexSession } = exploreSessionService.getSessions();

    if (!vexSession) {
        console.error('');
        return of(Batch.empty());
    }

    return loadPages(exchangeApi.getFolders, config)({
        restoreSessionId: vexSession,
        mailboxId: parent.mailboxId,
        parentId: parent.nodeType === ExchangeNodeType.Mailbox ? 'root' : parent.folderId,
    }).pipe(
        map(folders => folders.mapData<Node>(folder => convert.folder.fromRest(folder, parent.mailboxId, parent.explorePath))),
        catchError(() => of(Batch.empty<Node>())),
    );
};
