// Copyright © Veeam Software Group GmbH

import { oneDriveApi as _oneDriveApi, oneDriveFolderApi, oneDriveDocumentApi } from 'api/rxjs';
import { exploreSessionService } from 'services/exploreSessions';
import { renewSession } from '../../renewSession';

import type { Fetch } from '../../renewSession';

const renewOneDriveSession = <TRequest, TResponse>(fetch: Fetch<TRequest, TResponse>): Fetch<TRequest, TResponse> =>
    renewSession(fetch, exploreSessionService.renewSession.oneDrive);

export const oneDriveApi = {
    getOneDrives: renewOneDriveSession(_oneDriveApi.oneDriveGet),
    getFolders: renewOneDriveSession(oneDriveFolderApi.oneDriveFolderGet),
    getDocuments: renewOneDriveSession(oneDriveDocumentApi.oneDriveDocumentGet),
    getVersions: renewOneDriveSession(oneDriveDocumentApi.oneDriveDocumentGetVersions),
    getVersionsForFolders: renewOneDriveSession(oneDriveFolderApi.oneDriveFolderGetVersions),
    searchIn: {
        oneDrive: renewOneDriveSession(_oneDriveApi.oneDriveSearchOneDriveByOptions),
        folder: renewOneDriveSession(oneDriveFolderApi.oneDriveFolderSearchOneDriveFolderByOptions),
    },
};
