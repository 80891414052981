// Copyright © Veeam Software Group GmbH

import React from 'react';
import { BASE_BORDER_WIDTH, Link, SPACING_M, SPACING_S, Text, TEXT_VIEW } from '@veeam/components';
import { colors } from '@veeam/components/lib/themes/colors/colors';
import styled from 'styled-components';
import { BASE_FONT_SEMI_BOLD_WEIGHT } from '@veeam/components/lib/helpers/const';

import type { CSSProperties } from 'react';
import type { SubheaderProps } from 'pages/ExplorePage/components/ExploreGrid/interfaces/subheader-props';

import { SubheaderType } from 'pages/ExplorePage/components/ExploreGrid/enums/subheader-type';

export const SubheaderWrapper = styled.div<Partial<SubheaderProps>>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 100%;
  padding-left: ${SPACING_S};
  background-color: ${props => props.subheaderType === SubheaderType.Default ? colors.G000 : colors.Y50};
`;

export const BoldText = styled.span`
  color: ${colors.G600};
  font-weight: ${BASE_FONT_SEMI_BOLD_WEIGHT};
`;

const textStyle: CSSProperties = {
    marginLeft: SPACING_M,
    paddingLeft: SPACING_M,
    borderLeft: `${BASE_BORDER_WIDTH} solid ${colors.G300}`,
};

export const Subheader = ({ subheaderType, selected, total, loadAllItems, resources }: SubheaderProps) => {
    switch (subheaderType) {
        case SubheaderType.LargeNumbersOfItems:
            return (
                <SubheaderWrapper subheaderType={subheaderType}>
                    <Text view={TEXT_VIEW.secondary}>
                        Selected: {selected.length > 0 ? <BoldText>{selected.length}</BoldText> : selected.length} of {total}
                    </Text>
                    <Text style={textStyle}>
                        {resources.limitNotification.manyItems}
                        <Link onClick={() => loadAllItems && loadAllItems()}>
                            {resources.limitNotification.clickHereToViewMoreItems}
                        </Link>
                    </Text>
                </SubheaderWrapper>
            );
        case SubheaderType.SearchIsUsed:
            return (
                <SubheaderWrapper subheaderType={subheaderType}>
                    <Text view={TEXT_VIEW.secondary}>
                        Selected: {selected.length > 0 ? <BoldText>{selected.length}</BoldText> : selected.length} of {total}
                    </Text>
                    <Text style={textStyle}>
                        {resources.limitNotification.manySearchResults}
                        <Link onClick={() => loadAllItems && loadAllItems()}>
                            {resources.limitNotification.clickHereToViewMoreResults}
                        </Link>
                    </Text>
                </SubheaderWrapper>
            );
        case SubheaderType.LimitIsReached:
            return (
                <SubheaderWrapper subheaderType={subheaderType}>
                    <Text view={TEXT_VIEW.secondary}>
                        Selected: {selected.length > 0 ? <BoldText>{selected.length}</BoldText> : selected.length} of {total}
                    </Text>
                    <Text style={textStyle}>
                        {resources.limitNotification.limitIsReached}
                    </Text>
                </SubheaderWrapper>
            );
        case SubheaderType.Default:
            return (
                <SubheaderWrapper subheaderType={subheaderType}>
                    <Text view={TEXT_VIEW.secondary}>
                        Selected: {selected.length > 0 ? <BoldText>{selected.length}</BoldText> : selected.length} of {total}
                    </Text>
                </SubheaderWrapper>
            );
    }
};
