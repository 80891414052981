// Copyright © Veeam Software Group GmbH

import type { RouterConfig } from '@veeam/core';

import { Routes } from './Routes';
import { STUB_PATHS } from './STUB_PATHS';

export const routerConfig: RouterConfig<{}> = {
    noMatchName: STUB_PATHS.noMatch,
    noMatchRedirect: { name: Routes.index },
    route: [
        {
            name: Routes.index,
            path: '/',
            redirect: { name: Routes.explore },
            child: [
                {
                    name: Routes.explore,
                    path: `/${Routes.explore}`,
                },
                {
                    name: Routes.restoreSessions,
                    path: `/${Routes.restoreSessions}`,
                },
                {
                    name: Routes.recoveryList,
                    path: `/${Routes.recoveryList}`,
                },
            ],
        },
    ],
};
