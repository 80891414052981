// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { Func1 } from 'infrastructure/types';
import type { RbacScope } from 'services/rbac';

import { useRbac } from '../useRbac';


const isCurrentScope = (currentScope: RbacScope) => (scope: RbacScope): boolean => scope.id === currentScope.id;

export const useIsCurrentScope = (): Func1<RbacScope, boolean> => {
    const { currentScope } = useRbac();
    return useMemo(() => isCurrentScope(currentScope), [currentScope]);
};
