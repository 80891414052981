// Copyright © Veeam Software Group GmbH

import { defaultDatetimeInputControlValueFormatter } from '@veeam/components';
import { DefaultDatetimeInputControlValueFormatterPayload } from '@veeam/components/lib/controls/DatetimeInput/renderer/types';

interface DatetimeFormatterParams extends DefaultDatetimeInputControlValueFormatterPayload {
    withSeconds?: boolean;
}

export const datetimeFormatter = (params: DatetimeFormatterParams) => {
    let result = defaultDatetimeInputControlValueFormatter(params);

    if (params.withSeconds) {
        const seconds = params.value.getSeconds();
        result += `:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    return result;
};
