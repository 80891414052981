// Copyright © Veeam Software Group GmbH

import React from 'react';
import { DataGrid } from '@veeam/components';

import type { ReactElement } from 'react';

import { useRestoreSessionRouting } from 'features/Routing';
import { useSessionsModel } from './useModel';
import { useColumns } from './useColumns';
import { useEvents } from './useEvent';
import { useToolbar } from './useToolbar';

import type { SessionsModel } from './useModel';
import type { GridResources } from './resources';

export function useSessionGrid(resources: GridResources): { grid: ReactElement; model: SessionsModel; } {
    const { sessionId } = useRestoreSessionRouting().getQuery();
    const model = useSessionsModel(sessionId);
    const columns = useColumns(resources);
    const toolbar = useToolbar(resources, model);
    useEvents(model);
    const grid = (
        <DataGrid
            model={model}
            columns={columns}
            toolbar={toolbar}
            config={{
                canResizeColumns: true,
                columnsToggleable: true,
            }}
        />
    );

    return { grid, model };
}
