// Copyright © Veeam Software Group GmbH

import { format } from './LocalizableString';
import dictionaries from './Languages';

import type { LanguageConstraint, Languages, Language } from './Types';

export type ChangeLanguageHandler<TLanguage extends LanguageConstraint> = (language: LanguageWithCode<TLanguage>) => void;

export type LanguageWithCode<T extends LanguageConstraint> = Language<T>;
class ResourcesService<TLanguage extends LanguageConstraint> {
    private languages: LanguageWithCode<TLanguage>[];
    private handlers: ChangeLanguageHandler<TLanguage>[] = [];
    public current: LanguageWithCode<TLanguage>;

    public constructor(languages: Languages<TLanguage>, defaultLanguage?: string) {
        this.languages = Object.values(languages) as any;

        this.current = defaultLanguage !== undefined ? this.getLang(defaultLanguage) : this.languages[0];

        (String.prototype as any).format = format;
    }

    public getLanguages = (): LanguageWithCode<TLanguage>[] => [...this.languages];

    public toggleLanguage = (langCode: string): LanguageWithCode<TLanguage> => {
        this.current = this.getLang(langCode);
        this.handlers.forEach(handler => handler(this.current));
        return this.current;
    };

    public getLang = (langCode: string): LanguageWithCode<TLanguage> => {
        const language = this.languages.find(lang => lang.langCode === langCode);
        // Resources dictionary is not defined
        if (language === undefined) throw new Error('Resources dictionary is not defined');
        return language;
    };

    public onChangeLanguages = (handler: ChangeLanguageHandler<TLanguage>): void => {
        this.handlers.push(handler);
    };
}

export default new ResourcesService(dictionaries);
