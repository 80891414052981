// Copyright © Veeam Software Group GmbH

import type { Moment } from 'moment';
import type {
    RESTAttachment as ExchangeMailAttachment } from 'api/rxjs';

import {
    RESTTaskItemStatus as ExchangeTaskStatus,
    RESTExchangeItemImportance as ExchangeMailImportance,
} from 'api/rxjs';

import type { ItemBase } from '../ItemBase';
import type { Product } from '../Product';
import type { MailboxId } from './MailboxId';

export { ExchangeTaskStatus, ExchangeMailImportance };
export type { ExchangeMailAttachment };

export enum ExchangeItemType {
    Task = 'Task',
    StickyNote = 'StickyNote',
    Contact = 'Contact',
    Appointment = 'Appointment',
    Mail = 'Mail',
}

interface ExchangeItemBase extends ItemBase {
    product: Product.Exchange;
    mailboxId: MailboxId;
    itemType: ExchangeItemType;
}

export interface ExchangeStickyNote extends ExchangeItemBase {
    itemType: ExchangeItemType.StickyNote;
    subject?: string;
    date?: Moment;
}

export interface ExchangeAppointment extends ExchangeItemBase {
    itemType: ExchangeItemType.Appointment;
    subject?: string;
    startTime?: Moment;
    endTime?: Moment;
    organizer?: string;
    location?: string;
    attendees?: string;
}

export interface ExchangeTask extends ExchangeItemBase {
    itemType: ExchangeItemType.Task;
    subject?: string;
    status?: ExchangeTaskStatus;
    percentComplete?: number;
    startDate?: Moment;
    dueDate?: Moment;
    owner?: string;
}

export interface ExchangeContact extends ExchangeItemBase {
    itemType: ExchangeItemType.Contact;
    fullName?: string;
    company?: string;
    jobTitle?: string;
    fileAs?: string;
    email?: string;
    displayAs?: string;
    webPage?: string;
    imAddress?: string;
    businessPhone?: string;
}

export interface ExchangeMail extends ExchangeItemBase {
    itemType: ExchangeItemType.Mail;
    from?: string;
    cc?: string;
    bcc?: string;
    to?: string;
    subject?: string;
    received?: Moment;
    sent?: Moment;
    importance?: ExchangeMailImportance;
    attachments?: ExchangeMailAttachment[];
}

export type ExchangeItem = ExchangeMail | ExchangeContact | ExchangeTask | ExchangeAppointment | ExchangeStickyNote;
