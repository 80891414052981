/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTRestoreSessionEvent,
    RESTExceptionInfo,
    RESTRestoreSessionEvent,
} from '../models';

export interface RestoreSessionEventApiRestoreSessionEventGetRequest {
    restoreSessionId: string;
    limit: number;
    offset: number;
}

export interface RestoreSessionEventApiRestoreSessionEventGetByIdRequest {
    restoreSessionId: string;
    eventId: string;
}

/**
 * no description
 */
export const restoreSessionEventApi = ({

    /**
     * Returns a collection of events for a restore session with the specified ID.
     * Get Restore Session Events
     */
    restoreSessionEventGet: ({ restoreSessionId, limit, offset }: RestoreSessionEventApiRestoreSessionEventGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTRestoreSessionEvent>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionEventGet');
        throwIfNullOrUndefined(limit, 'limit', 'restoreSessionEventGet');
        throwIfNullOrUndefined(offset, 'offset', 'restoreSessionEventGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        return requestAuthenticated<PageOfRESTRestoreSessionEvent>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Events'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of properties for the specified event of a restore session with the specified ID.
     * Get Restore Session Event
     */
    restoreSessionEventGetById: ({ restoreSessionId, eventId }: RestoreSessionEventApiRestoreSessionEventGetByIdRequest, option?: RequestOption): Observable<Optional<RESTRestoreSessionEvent>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionEventGetById');
        throwIfNullOrUndefined(eventId, 'eventId', 'restoreSessionEventGetById');

        return requestAuthenticated<RESTRestoreSessionEvent>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Events/{eventId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{eventId}', encodeURI(eventId)),
            method: 'GET',
        }, option);
    },

})
