/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    JobSessionChangesGetResponse,
    PageOfRESTJobSession,
    RESTExceptionInfo,
    RESTJobSession,
} from '../models';

export interface JobSessionApiJobSessionChangesGetRequest {
    limit: number;
    jobType?: JobSessionChangesGetJobTypeEnum;
    from?: string;
}

export interface JobSessionApiJobSessionGetRequest {
    limit: number;
    offset: number;
    jobId?: string;
    jobType?: JobSessionGetJobTypeEnum;
    endTimeLowerBound?: string;
    endTimeUpperBound?: string;
}

export interface JobSessionApiJobSessionGetByIdRequest {
    jobSessionsId: string;
}

/**
 * no description
 */
export const jobSessionApi = ({

    /**
     * Returns a collection of changes that appeared during job sessions starting from the initial backup/backup copy or from a specified change token.
     * Get Changes
     */
    jobSessionChangesGet: ({ limit, jobType, from }: JobSessionApiJobSessionChangesGetRequest, option?: RequestOption): Observable<Optional<JobSessionChangesGetResponse>> => {
        throwIfNullOrUndefined(limit, 'limit', 'jobSessionChangesGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
        };

        if (jobType != null) { query['jobType'] = jobType; }
        if (from != null) { query['from'] = from; }

        return requestAuthenticated<JobSessionChangesGetResponse>({
            url: '/v7/JobSessions/Changes',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a collection of all job sessions created for backup and backup copy jobs or a backup job with the specified ID.
     * Get Job Sessions
     */
    jobSessionGet: ({ limit, offset, jobId, jobType, endTimeLowerBound, endTimeUpperBound }: JobSessionApiJobSessionGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTJobSession>> => {
        throwIfNullOrUndefined(limit, 'limit', 'jobSessionGet');
        throwIfNullOrUndefined(offset, 'offset', 'jobSessionGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (jobId != null) { query['jobId'] = jobId; }
        if (jobType != null) { query['jobType'] = jobType; }
        if (endTimeLowerBound != null) { query['endTimeLowerBound'] = (endTimeLowerBound as any).toISOString(); }
        if (endTimeUpperBound != null) { query['endTimeUpperBound'] = (endTimeUpperBound as any).toISOString(); }

        return requestAuthenticated<PageOfRESTJobSession>({
            url: '/v7/JobSessions',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a job session with the specified ID.
     * Get Job Session by Session ID
     */
    jobSessionGetById: ({ jobSessionsId }: JobSessionApiJobSessionGetByIdRequest, option?: RequestOption): Observable<Optional<RESTJobSession>> => {
        throwIfNullOrUndefined(jobSessionsId, 'jobSessionsId', 'jobSessionGetById');

        return requestAuthenticated<RESTJobSession>({
            url: '/v7/JobSessions/{jobSessionsId}'.replace('{jobSessionsId}', encodeURI(jobSessionsId)),
            method: 'GET',
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum JobSessionChangesGetJobTypeEnum {
    Backup = 'Backup',
    Copy = 'Copy'
}
/**
 * @export
 * @enum {string}
 */
export enum JobSessionGetJobTypeEnum {
    Backup = 'Backup',
    Copy = 'Copy'
}
