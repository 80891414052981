// Copyright © Veeam Software Group GmbH

import { useEffect, useState } from 'react';
import { DataGridModel } from '@veeam/components';

import type { Observable, Subscription } from 'rxjs';
import type { RestoreSessionEvent, RestoreSessionEventId, RestoreSessionId } from 'services/restoreService';

import { createConverter, commonLoaderOrDefault } from 'infrastructure/grid';
import { restoreService, RestoreSessionEventsFilterSchema } from 'services/restoreService';


interface Extra {
    session?: RestoreSessionId;
}

export class EventsModel extends DataGridModel<RestoreSessionEvent, RestoreSessionEventId, never, Extra> {
    private static idGetter(data: RestoreSessionEvent): RestoreSessionEventId {
        return data.id;
    }

    private subscription: Subscription;

    public constructor(loader: EventsModel['loaderType'], session: Observable<RestoreSessionId | undefined>) {
        super(EventsModel.idGetter, loader, {});
        const onNewSession = (newSession: RestoreSessionId | undefined): void => {
            if (this.getValue().session !== newSession) {
                this.update((mutable) => {
                    mutable.session = newSession;
                });
                this.load();
            }
        };
        this.subscription = session.subscribe(onNewSession);
    }

    public destroy(): void {
        if (!this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }
}

export type Value = EventsModel['valueType'];

export const convertFilter = createConverter(RestoreSessionEventsFilterSchema);

const eventsLoader: EventsModel['loaderType'] = commonLoaderOrDefault(
    value =>
        value.session && {
            sessionId: value.session,
            filter: convertFilter(value.filters),
        },
    restoreService.sessions.getEvents,
    []
);

export function useEventsModel(session: Observable<RestoreSessionId | undefined>): EventsModel {
    const [model] = useState(new EventsModel(eventsLoader, session));
    useEffect(() => () => model.destroy(), []);
    return model;
}
