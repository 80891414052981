// Copyright © Veeam Software Group GmbH

import React, { createContext, useContext, useEffect } from 'react';
import { MESSAGE_BOX_ICON_VIEW, MessageBox, useModal } from '@veeam/components';
import { merge } from 'rxjs';
import { first } from 'rxjs/operators';

import type { FC } from 'react';

import { useResources } from 'infrastructure/resources';
import { useLogin } from './useLogin';
import { LoginPage } from './LoginPage';
import { exchangeSessionEventsHub, oneDriveSessionEventsHub, sharePointSessionEventsHub } from 'api/signalr';

interface LoginContextState {
    username: string;
    logout(): Promise<void>;
}

const LoginContext = createContext<LoginContextState>({
    username: '',
    logout: () => new Promise(() => {}),
});

export const useLoginContext = (): LoginContextState => useContext(LoginContext);

export const Login: FC = ({ children }) => {
    const resources = useResources();
    const [modal, action] = useModal({
        render: ({ deactivate }) => (
            <MessageBox
                header={resources.features.Main.productName}
                iconView={MESSAGE_BOX_ICON_VIEW.info}
                onRequestClose={deactivate}
                actions={[
                    {
                        text: 'OK',
                        onClick: deactivate,
                    },
                ]}
            >
                Some backup data is currently not available.
            </MessageBox>
        ),
    });
    const {
        username,
        logout,
        isAuthenticated,
        ...other
    } = useLogin();

    useEffect(() => {
        const onUnavailableData = merge(
            exchangeSessionEventsHub.onUnavailableDataFound,
            oneDriveSessionEventsHub.onUnavailableDataFound,
            sharePointSessionEventsHub.onUnavailableDataFound,
        );

        onUnavailableData
            .pipe(first())
            .subscribe(() => action.activate());
    }, []);

    useEffect(() => {
        if (!isAuthenticated && window.location.pathname !== '/') {
            window.history.replaceState({}, '', '/');
        }
    }, []);

    return (
        <LoginContext.Provider value={{ username, logout }}>
            {
                isAuthenticated
                    ? children
                    : <LoginPage {...other} />
            }
            {modal}
        </LoginContext.Provider>
    );
};
