// Copyright © Veeam Software Group GmbH

import { AjaxError } from 'rxjs/ajax';

import resources from 'infrastructure/resources';

export type UnknownError = string | Error | AjaxError;

export interface ErrorDescription {
    header?: string;
    message: string;
}

const mapErrors = (message: string | undefined): string => {
    const { unknownError, errorsMapping } = resources.current.infrastructure.errorManagement;
    if (message === undefined) return unknownError;
    const mapped = Object.entries(errorsMapping)
        .map(([key, value]) => ({ key, value }))
        .find(({ key }) => message.indexOf(key) >= 0);
    return mapped?.value || message;
};

const create = (messageRaw: string, header?: string): ErrorDescription => {
    const message = mapErrors(messageRaw);
    return {
        header, message,
    };
};

interface ServerError {
    message: string;
    messageDetail?: string;
}

interface ServerError2 {
    error: string;
    error_description: string;
}

interface OAuthError {
    error: string;
}

function isOAuthError(response: any): response is OAuthError {
    if (typeof response !== 'object') return false;
    if (typeof response.error !== 'string') return false;
    return true;
}

const createFromOAuthError = (error: OAuthError): ErrorDescription => create(error.error);

function isServerError(response: any): response is ServerError {
    if (typeof response !== 'object') return false;
    if (typeof response?.message !== 'string') return false;
    return true;
}

const createFromServerError = (error: ServerError): ErrorDescription => create(error.messageDetail ? error.messageDetail : error.message);

function isServerError2(response: any): response is ServerError2 {
    if (typeof response !== 'object') return false;
    if (typeof response.error !== 'string') return false;
    if (typeof response.error_description !== 'string') return false;
    return true;
}

const createFromServerError2 = (error: ServerError2): ErrorDescription => create(error.error_description);

export function getErrorMessage(error: unknown): ErrorDescription {
    if (typeof error === 'string') return create(error);
    if (error instanceof AjaxError) {
        if (isServerError(error.response)) return createFromServerError(error.response);
        if (isServerError2(error.response)) return createFromServerError2(error.response);
        if (isOAuthError(error.response)) return createFromOAuthError(error.response);
        return create(error.message);
    }
    if (error instanceof Error) return create(error.message);
    return create(resources.current.infrastructure.errorManagement.unknownError);
}
