// Copyright © Veeam Software Group GmbH

import { useEffect, useRef } from 'react';

import type { Observable, Subscription } from 'rxjs';
import type { Action1 } from 'infrastructure/types';

type Action<TRq, TData> = (request: TRq) => Observable<void>;

export function useObservableAction<TRq, TData>(observableAction: Action<TRq, TData>): Action1<TRq> {
    const subscriptions = useRef<Subscription[]>([]);
    useEffect(() => () => subscriptions.current.forEach(subscription => subscription.closed || subscription.unsubscribe()), []);

    return (request: TRq): void => {
        const subscription = observableAction(request).subscribe();
        subscriptions.current.push(subscription);
    };
}
