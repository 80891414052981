// Copyright © Veeam Software Group GmbH

import type { DataGridCellRenderer } from '@veeam/components';
import type { LocalizableString } from 'infrastructure/resources';
import type { Func1, EnumMap } from 'infrastructure/types';

import { textCellRender } from './textCell';
import { iconCellRender } from './iconCell';

export interface MappedEnumValue {
    title: LocalizableString;
    icon?: string;
}

export const enumCell = <TRowData, TEnum extends string>(
    value: Func1<TRowData, TEnum>,
    map: EnumMap<TEnum, MappedEnumValue>
): DataGridCellRenderer<TRowData> => (row, payload) => {
        const { title, icon } = map[value(row)];
        if (icon) return iconCellRender({ text: title, iconSrc: icon }, payload);
        return textCellRender(title, payload);
    };
