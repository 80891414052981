// Copyright © Veeam Software Group GmbH

import React from 'react';
import { useForm, useModel } from '@veeam/core';
import {
    RadioField,
    FormLayout,
    CheckboxField,
    LabelGroup,
    STACK_DIRECTION,
    NoLabelGroup,
    SidePanel,
    SidePanelBody,
    ACTION_VIEW,
} from '@veeam/components';

import type { Form, Model } from '@veeam/core';
import type { FC } from 'react';
import type { Resources } from 'infrastructure/resources';
import type { PanelProps } from 'components/wizard';

import { useResources } from 'infrastructure/resources';
import { useMessageBoxManagement } from 'infrastructure/error-management';

import type { FormWithRestoreMode } from '../RestoreModeStep';


type PanelResources = Resources['features']['Restore']['Wizards']['SharePointRestoreWizard']['RestoreOptionsPanel'];

const validateRestoreOptionsStep = (form: Form<FormWithRestoreMode>) => {
    const { restoreChangedItems, restoreMissingItems } = form.getValue();
    return restoreChangedItems || restoreMissingItems;
};

const RestoreOptionsForm: FC<{ form: Form<FormWithRestoreMode>; resources: PanelResources; }> = ({
    form,
    resources,
}) => {
    const { value: formValue } = useModel(form.model);
    return (
        <FormLayout>
            <FormLayout>
                <LabelGroup label={resources.restoreFollowingItems}>
                    <FormLayout direction={STACK_DIRECTION.column}>
                        <CheckboxField label={resources.changedItems} field={form.fields.restoreChangedItems} />
                        <CheckboxField label={resources.missingItems} field={form.fields.restoreMissingItems} />
                    </FormLayout>
                </LabelGroup>
            </FormLayout>
            <FormLayout>
                <LabelGroup label={resources.additionalOptions}>
                    <FormLayout direction={STACK_DIRECTION.column}>
                        <CheckboxField label={resources.restorePermissions} field={form.fields.restorePermission} />
                        <FormLayout>
                            <NoLabelGroup>
                                <CheckboxField
                                    disabled={!formValue.restorePermission}
                                    label={resources.sendNotifications}
                                    field={form.fields.sendNotification}
                                />
                            </NoLabelGroup>
                        </FormLayout>
                    </FormLayout>
                </LabelGroup>
            </FormLayout>
            <FormLayout>
                <LabelGroup label={resources.historyRestoreOptions}>
                    <FormLayout direction={STACK_DIRECTION.column}>
                        <CheckboxField label={resources.restoreOnlyLatest} field={form.fields.restoreOnlyLatest} />
                        <FormLayout>
                            <NoLabelGroup>
                                <RadioField
                                    disabled={!formValue.restoreOnlyLatest}
                                    label={resources.override}
                                    field={form.fields.overrideVersions}
                                    value={true}
                                />
                                <RadioField
                                    disabled={!formValue.restoreOnlyLatest}
                                    label={resources.merge}
                                    field={form.fields.overrideVersions}
                                    value={false}
                                />
                            </NoLabelGroup>
                        </FormLayout>
                    </FormLayout>
                </LabelGroup>
            </FormLayout>
        </FormLayout>
    );
};

export const RestoreOptionsPanel: FC<PanelProps<Model<FormWithRestoreMode>>> = ({
    formConfig: wizardFormModel,
    isActive,
    closePanel,
}) => {
    const resources = useResources().features.Restore.Wizards.SharePointRestoreWizard.RestoreOptionsPanel;
    const form = useForm(wizardFormModel.getValue());

    const { show } = useMessageBoxManagement();
    if (!isActive) {
        return null;
    }

    return (
        <SidePanel title={resources.title} description={resources.description} onRequestClose={closePanel}>
            <SidePanelBody
                actions={[
                    {
                        id: 'apply',
                        view: ACTION_VIEW.primary,
                        text: resources.applyLabel,
                        onClick: () => {
                            if (!validateRestoreOptionsStep(form)) {
                                show({ message: resources.oneOfRestoreOptionsShouldBeSpecified });
                                return;
                            }
                            const {
                                restoreChangedItems,
                                restoreMissingItems,
                                restorePermission,
                                sendNotification,
                                restoreOnlyLatest,
                                overrideVersions,
                            } = form.getValue();
                            wizardFormModel.setValue({
                                ...wizardFormModel.getValue(),
                                restoreChangedItems,
                                restoreMissingItems,
                                restorePermission,
                                sendNotification,
                                restoreOnlyLatest,
                                overrideVersions,
                            });
                            closePanel();
                        },
                    },
                    {
                        id: 'cancel',
                        view: ACTION_VIEW.secondary,
                        text: resources.cancelLabel,
                        onClick: () => {
                            closePanel();
                        },
                    },
                ]}
                content={<RestoreOptionsForm form={form} resources={resources} />}
            ></SidePanelBody>
        </SidePanel>
    );
};
