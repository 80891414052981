// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { RESTItemComposed } from 'api/rxjs';
import type { LoadPagesConfig } from 'infrastructure/rxjs';
import type { Override1 } from 'infrastructure/types';
import type { OneDriveItem, OneDriveNode, VeodSession, OneDrive, OneDriveFolder } from 'services/models';
import type { GetItemsRequest } from 'services/explore/interfaces/get-items-request';

import { OneDriveSearchOneDriveByOptionsItemTypeEnum, OneDriveFolderSearchOneDriveFolderByOptionsItemTypeEnum } from 'api/rxjs';
import { never } from 'infrastructure/never';
import { Batch, loadPages } from 'infrastructure/rxjs';
import { convert } from './converters';
import { oneDriveApi } from './wrappedOneDriveApi';
import { exploreSessionService } from 'services/exploreSessions';
import { OneDriveNodeType } from 'services/models';


export type GetOneDriveDocumentsRequest = Override1<GetItemsRequest, 'parent', OneDriveNode>;

const loadDocuments = (session: VeodSession, parent: OneDriveNode, config: LoadPagesConfig | undefined): Observable<Batch<RESTItemComposed>> =>
    loadPages(oneDriveApi.getDocuments, config)({
        includeFolders: true,
        restoreSessionId: session,
        oneDriveId: parent.oneDriveId,
        parentId: parent.nodeType === OneDriveNodeType.OneDrive ? 'root' : parent.folderId,
    });

const searchDocumentsInOneDrive = (
    session: VeodSession,
    parent: OneDrive,
    search: string,
    config: LoadPagesConfig | undefined
): Observable<Batch<RESTItemComposed>> =>
    loadPages(oneDriveApi.searchIn.oneDrive, config)({
        restoreSessionId: session,
        oneDriveId: parent.oneDriveId,
        itemType: OneDriveSearchOneDriveByOptionsItemTypeEnum.All,
        body: {
            query: search,
        },
    });

const searchDocumentsInFolder = (
    session: VeodSession,
    parent: OneDriveFolder,
    search: string,
    config: LoadPagesConfig | undefined
): Observable<Batch<RESTItemComposed>> =>
    loadPages(oneDriveApi.searchIn.folder, config)({
        restoreSessionId: session,
        oneDriveId: parent.oneDriveId,
        folderId: parent.folderId,
        itemType: OneDriveFolderSearchOneDriveFolderByOptionsItemTypeEnum.All,
        body: {
            query: search,
        },
    });

const searchDocuments = (
    session: VeodSession,
    parent: OneDriveNode,
    search: string,
    config: LoadPagesConfig | undefined
): Observable<Batch<RESTItemComposed>> => {
    switch (parent.nodeType) {
        case OneDriveNodeType.OneDrive: return searchDocumentsInOneDrive(session, parent, search, config);
        case OneDriveNodeType.Folder: return searchDocumentsInFolder(session, parent, search, config);
        default: return never(parent);
    }
};

export const getOneDriveItems = ({ parent, config, filter: { search } }: GetOneDriveDocumentsRequest): Observable<Batch<OneDriveItem>> => {
    const { veodSession } = exploreSessionService.getSessions();
    if (!veodSession) {
        console.error('');
        return of(Batch.empty());
    }

    const fetch = search === ''
        ? loadDocuments(veodSession, parent, config)
        : searchDocuments(veodSession, parent, search, config);

    return fetch.pipe(
        map(oneDriveItems => oneDriveItems.mapData((item) => {
            if (item.isFolder) {
                return convert.folderItem.fromRest(item, parent.oneDriveId, parent.explorePath, false);
            }

            return convert.document.fromRest(item, parent.oneDriveId, parent.explorePath, false);
        })),
    );
};
