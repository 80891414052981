// Copyright © Veeam Software Group GmbH

/**
 * Copyright © Veeam Software Group GmbH.
 */

import { useContext } from 'react';

import { ExplorePageStoreContext } from './explore-page-store-context';

import type { ExplorePageStore } from './explore-page-store';

export const useExplorePageStore = (): ExplorePageStore => useContext(ExplorePageStoreContext);
