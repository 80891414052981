/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTLogItem,
    RESTExceptionInfo,
    RESTLogItem,
} from '../models';

export interface JobSessionLogApiJobSessionLogGetBatchBySessionIdRequest {
    sessionId: string;
    offset: number;
    limit: number;
}

export interface JobSessionLogApiJobSessionLogGetBatchBySessionIdByLogItemIdRequest {
    sessionId: string;
    logItemId: string;
}

/**
 * no description
 */
export const jobSessionLogApi = ({

    /**
     * Returns information about the operations performed during a backup or backup copy job session with the specified ID.
     * Get Information on Operations by Session ID
     */
    jobSessionLogGetBatchBySessionId: ({ sessionId, offset, limit }: JobSessionLogApiJobSessionLogGetBatchBySessionIdRequest, option?: RequestOption): Observable<Optional<PageOfRESTLogItem>> => {
        throwIfNullOrUndefined(sessionId, 'sessionId', 'jobSessionLogGetBatchBySessionId');
        throwIfNullOrUndefined(offset, 'offset', 'jobSessionLogGetBatchBySessionId');
        throwIfNullOrUndefined(limit, 'limit', 'jobSessionLogGetBatchBySessionId');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTLogItem>({
            url: '/v7/JobSessions/{sessionId}/LogItems'.replace('{sessionId}', encodeURI(sessionId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns information about a specific operation performed during a backup or backup copy job session with the specified ID.
     * Get Information on Operation by LogItem ID
     */
    jobSessionLogGetBatchBySessionIdByLogItemId: ({ sessionId, logItemId }: JobSessionLogApiJobSessionLogGetBatchBySessionIdByLogItemIdRequest, option?: RequestOption): Observable<Optional<RESTLogItem>> => {
        throwIfNullOrUndefined(sessionId, 'sessionId', 'jobSessionLogGetBatchBySessionIdByLogItemId');
        throwIfNullOrUndefined(logItemId, 'logItemId', 'jobSessionLogGetBatchBySessionIdByLogItemId');

        return requestAuthenticated<RESTLogItem>({
            url: '/v7/JobSessions/{sessionId}/LogItems/{logItemId}'.replace('{sessionId}', encodeURI(sessionId)).replace('{logItemId}', encodeURI(logItemId)),
            method: 'GET',
        }, option);
    },

})
