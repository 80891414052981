// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies the organization type.
 * @export
 * @enum {string}
 */
export enum RestOrganizationType {
    Office365 = 'Office365',
    OnPremises = 'OnPremises',
    Hybrid = 'Hybrid'
}

