import { Subject } from "rxjs";
import "../models";

export const exchangeSessionEventsHub = {
    onUnavailableDataFound: new Subject<unknown>(),
    };

function onConnect(connection: SignalR.Hub.Connection): void {
    const proxy = connection.createHubProxy('ExchangeSessionEventsHub');
    proxy.on('onUnavailableDataFound', (req) => exchangeSessionEventsHub.onUnavailableDataFound.next(req));
}

export const exchangeSessionEventsHubConnector = { onConnect };
