// Copyright © Veeam Software Group GmbH

import type { EnumMap } from 'infrastructure/types';
import type { ExchangeMailAttachment, ExchangeMailImportance } from 'services';

import { attachmentSrc, highImportanceSrc, lowImportanceSrc } from 'assets';

const exchangeMailImportanceIconMap: EnumMap<ExchangeMailImportance, string | undefined> = {
    Low: lowImportanceSrc,
    Normal: undefined,
    High: highImportanceSrc,
};

export const mapMailImportance = (importance?: ExchangeMailImportance): string | undefined => {
    if (!importance) {
        return '';
    }
    return exchangeMailImportanceIconMap[importance];
};

export const mapAttachments = (attachments?: ExchangeMailAttachment[]): string =>
    attachments?.length ? attachmentSrc : '';
