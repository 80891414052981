// Copyright © Veeam Software Group GmbH

import moment from 'moment';

import type {
    RESTSharePointDocument,
    RESTSharePointFolder,
    RESTSharePointItem,
    RESTSharePointLibrary,
    RESTSharePointList,
    RESTSharePointSite,
    RESTItemComposed,
} from 'api/rxjs';
import type { Resources } from 'infrastructure/resources';
import type {
    ExplorePath,
    SharePointContent,
    SharePointDocument,
    SharePointFolder,
    SharePointFolderId,
    SharePointFolderItem,
    SharePointItem,
    SharePointItemId,
    SharePointLibrary,
    SharePointList,
    SharePointListFolderItem,
    SharePointListItem,
    SharePointNodeBase,
    SharePointSite,
    SharePointSiteId,
    SharePointSubSites,
} from 'services/models';

import { RESTSharePointFolderType } from 'api/rxjs';
import resources from 'infrastructure/resources';
import { explorePath, getUniqueId, Product, SegmentType, SharePointItemType, SharePointNodeType } from 'services/models';
import { never } from 'infrastructure/never';
import { convertBase } from '../../baseConverter';


const res = (): Resources['services']['explore']['sharePoint'] => resources.current.services.explore.sharePoint;

const createSubsites = (parent: SharePointSite): SharePointSubSites => {
    const uniqId = getUniqueId(`${parent.uniqId  }/SubSites`);
    const title = res().SubSitesTitle;
    const path = explorePath.create({ uniqId, title, type: SegmentType.Node }, parent.explorePath);
    return {
        uniqId,
        title,
        explorePath: path,
        product: Product.SharePoint,
        nodeType: SharePointNodeType.SubSites,
        siteId: parent.siteId,
    };
};

const createContent = (parent: SharePointSite): SharePointContent => {
    const uniqId = getUniqueId(`${parent.uniqId  }/Content`);
    const title = res().ContentTitle;
    const path = explorePath.create({ uniqId, title, type: SegmentType.Node }, parent.explorePath);
    return {
        uniqId,
        title,
        explorePath: path,
        product: Product.SharePoint,
        nodeType: SharePointNodeType.Content,
        siteId: parent.siteId,
    };
};

type RestNode = RESTSharePointSite | RESTSharePointList | RESTSharePointLibrary;

const convertSharePointSiteFromRest = (site: RESTSharePointSite, parent?: SharePointNodeBase): SharePointSite => ({
    ...convertBase('Node', site, parent?.explorePath),
    product: Product.SharePoint,
    siteId: site.id as SharePointSiteId,
    nodeType: SharePointNodeType.Site,
    url: site.url,
});

const convertSharePointBaseNodeFromRest = (item: RestNode, parent: SharePointNodeBase): SharePointNodeBase => ({
    ...convertBase('Node', item, parent.explorePath),
    product: Product.SharePoint,
    siteId: parent.siteId,
    nodeType: SharePointNodeType.Site,
});

const convertSharePointListFromRest = (list: RESTSharePointList, parent: SharePointNodeBase): SharePointList => ({
    ...convertSharePointBaseNodeFromRest(list, parent),
    id: list.id as SharePointFolderId,
    nodeType: SharePointNodeType.List,
});

const convertSharePointLibraryFromRest = (library: RESTSharePointLibrary, parent: SharePointNodeBase): SharePointLibrary => ({
    ...convertSharePointBaseNodeFromRest(library, parent),
    id: library.id as SharePointFolderId,
    nodeType: SharePointNodeType.Library,
});

const getFolderType = (parent: SharePointFolder): SharePointFolder['nodeType'] => {
    switch (parent.nodeType) {
        case SharePointNodeType.List:
        case SharePointNodeType.ListFolder: return SharePointNodeType.ListFolder;
        case SharePointNodeType.Library:
        case SharePointNodeType.LibraryFolder: return SharePointNodeType.LibraryFolder;
        default: return never(parent);
    }
};

const convertSharePointFolderFromRest = (library: RESTSharePointFolder, parent: SharePointFolder): SharePointFolder => ({
    ...convertSharePointBaseNodeFromRest(library, parent),
    id: library.id as SharePointFolderId,
    nodeType: getFolderType(parent),
});

const convertSharePointListItem = (
    listItem: RESTSharePointItem,
    siteId: SharePointSiteId,
    parentPath: ExplorePath,
    isVersion: boolean
): SharePointListItem => {
    const uniqId = getUniqueId(listItem.id || '', listItem.version);
    const title = listItem.title || listItem.name;
    const explorePathTitle = isVersion ? listItem.version || '' : title;
    const path = explorePath.create({
        uniqId,
        title: explorePathTitle,
        type: isVersion ? SegmentType.Version : SegmentType.Item,
    }, parentPath);
    return ({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _raw_rest: listItem,
        uniqId,
        title,
        explorePath: path,
        product: Product.SharePoint,
        itemType: SharePointItemType.ListItem,
        id: listItem.id as SharePointItemId,
        siteId,
        modifiedBy: listItem.modifiedBy,
        modificationTime: moment(listItem.modificationTime),
        version: listItem.version,
        isVersion,
    });
};

const convertSharePointListFolderItem = (
    listItem: RESTSharePointItem,
    siteId: SharePointSiteId,
    parentPath: ExplorePath,
    isVersion: boolean
): SharePointListFolderItem => {
    const uniqId = getUniqueId(listItem.id || '', listItem.version);
    const title = listItem.title || listItem.name;
    const explorePathTitle = isVersion ? listItem.version || '' : title;
    const path = explorePath.create({
        uniqId,
        title: explorePathTitle,
        type: isVersion ? SegmentType.Version : SegmentType.Item,
    }, parentPath);
    return ({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _raw_rest: listItem,
        uniqId,
        title,
        explorePath: path,
        product: Product.SharePoint,
        itemType: SharePointItemType.ListFolderItem,
        id: listItem.id as SharePointItemId,
        siteId,
        modifiedBy: listItem.modifiedBy,
        modificationTime: moment(listItem.modificationTime),
        version: listItem.version,
        isVersion,
    });
};

const convertSharePointDocument = (
    document: RESTSharePointDocument,
    siteId: SharePointSiteId,
    parentPath: ExplorePath,
    isVersion: boolean
): SharePointDocument => ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _raw_rest: document,
    ...convertBase(isVersion ? 'Version' : 'Item', document, parentPath),
    product: Product.SharePoint,
    itemType: SharePointItemType.Document,
    id: document.id as SharePointItemId,
    siteId,
    modifiedBy: document.modifiedBy,
    modificationTime: moment(document.modificationTime),
    sizeBytes: document.sizeBytes,
    version: document.version,
    isVersion,
});

const convertSharePointFolderItem = (
    folder: RESTSharePointDocument,
    siteId: SharePointSiteId,
    parentPath: ExplorePath,
    isVersion: boolean
): SharePointFolderItem => ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _raw_rest: folder,
    ...convertBase(isVersion ? 'Version' : 'Item', folder, parentPath),
    product: Product.SharePoint,
    itemType: SharePointItemType.Folder,
    id: folder.id as SharePointItemId,
    siteId,
    modifiedBy: folder.modifiedBy,
    modificationTime: moment(folder.modificationTime),
    version: folder.version,
    isVersion,
});

const convertSharePointItem = (item: RESTItemComposed, siteId: SharePointSiteId , parentPath: ExplorePath, isVersion: boolean): SharePointItem => {
    if (item.type === RESTSharePointFolderType.LibraryFolder) {
        return convertSharePointFolderItem(item, siteId, parentPath, isVersion);
    }

    if (item.type === RESTSharePointFolderType.ListFolder) {
        return convertSharePointListFolderItem(item, siteId, parentPath, isVersion);
    }

    if (item.sizeBytes) {
        return convertSharePointDocument(item as RESTSharePointDocument, siteId, parentPath, isVersion);
    }

    return convertSharePointListItem(item as RESTSharePointItem, siteId, parentPath, isVersion);
};

export const convert = {
    site: {
        fromRest: convertSharePointSiteFromRest,
    },
    subsites: {
        fromSite: createSubsites,
    },
    content: {
        fromSite: createContent,
    },
    list: {
        fromRest: convertSharePointListFromRest,
    },
    library: {
        fromRest: convertSharePointLibraryFromRest,
    },
    folder: {
        fromRest: convertSharePointFolderFromRest,
    },
    listItem: {
        fromRest: convertSharePointListItem,
    },
    listFolderItem: {
        fromRest: convertSharePointListFolderItem,
    },
    document: {
        fromRest: convertSharePointDocument,
    },
    folderItem: {
        fromRest: convertSharePointFolderItem,
    },
    composedItem: {
        fromRest: convertSharePointItem,
    },
};
