// Copyright © Veeam Software Group GmbH

import { map } from 'rxjs/operators';

import type { LoadPagesConfig, Batch } from 'infrastructure/rxjs';
import type { Observable } from 'rxjs';
import type { VetSession } from 'services';
import type { TeamsNode } from 'services/models/Teams/TeamsNode';

import { loadPages } from 'infrastructure/rxjs';
import { TeamsNodeType } from 'services/models/Teams/TeamsNode';
import { convertFileItemFromRest, convertFolderItemFromRest, convertPostItemFromRest, convertTabItemFromRest } from './converters';
import { teamsApi } from './wrapped-teams-api';
import { findIdValueInUrl } from './find-id-value-in-url';
import { RESTTeamsFileFileTypeEnum } from '../../../../api/rxjs';

import type { TeamsItem } from '../../../models/Teams';

export const searchTeamsItems = (
    restoreSessionId: VetSession,
    parent: TeamsNode,
    config: LoadPagesConfig | undefined,
    query: string,
): Observable<Batch<TeamsItem>> => {
    switch (parent.nodeType) {
        case TeamsNodeType.Files:
            return loadPages(teamsApi.searchFileItems, config)({
                teamId: findIdValueInUrl(parent._links.items.href, 'teams'),
                restoreSessionId,
                channelId: findIdValueInUrl(parent._links.channel.href, 'channels'),
                body: {
                    query,
                },
            })
                .pipe(
                    map(items => items.mapData((item) => {
                        if (item.fileType === RESTTeamsFileFileTypeEnum.Folder) {
                            return convertFolderItemFromRest(item, false, parent);
                        }

                        return convertFileItemFromRest(item, false, parent);
                    })),
                );
        case TeamsNodeType.Folder:
            return loadPages(teamsApi.searchFileItems, config)({
                teamId: findIdValueInUrl(parent._links.items.href, 'teams'),
                restoreSessionId,
                channelId: parent.channelId,
                parentId: parent.id,
                body: {
                    query,
                },
            })
                .pipe(
                    map(items => items.mapData((item) => {
                        if (item.fileType === RESTTeamsFileFileTypeEnum.Folder) {
                            return convertFolderItemFromRest(item, false, parent);
                        }

                        return convertFileItemFromRest(item, false, parent);
                    })),
                );
        case TeamsNodeType.Tabs:
            return loadPages(teamsApi.searchTabItems, config)({
                teamId: findIdValueInUrl(parent._links.items.href, 'teams'),
                restoreSessionId,
                channelId: findIdValueInUrl(parent._links.items.href, 'channels'),
                body: {
                    query,
                },
            }).pipe(
                map(tabs => tabs.mapData(item => convertTabItemFromRest(item, parent))),
            );
        case TeamsNodeType.Posts:
            return loadPages(teamsApi.searchPostItems, config)({
                teamId: findIdValueInUrl(parent._links.items.href, 'teams'),
                restoreSessionId,
                body: {
                    query,
                },
            }).pipe(
                map(posts => posts.mapData(item => convertPostItemFromRest(item, parent))),
            );
    }
};
