// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTCommonRestoreStatistics,
    RESTExchangeMailbox,
} from './';

/**
 * @export
 * @interface RESTBulkMailboxRestoreStatistics
 */
export interface RESTBulkMailboxRestoreStatistics {
    /**
     * @type {RESTExchangeMailbox}
     * @memberof RESTBulkMailboxRestoreStatistics
     */
    mailbox?: RESTExchangeMailbox;
    /**
     * Status of the restore operation.
     * @type {string}
     * @memberof RESTBulkMailboxRestoreStatistics
     */
    status?: RESTBulkMailboxRestoreStatisticsStatusEnum;
    /**
     * Error occured during the restore operation.
     * @type {string}
     * @memberof RESTBulkMailboxRestoreStatistics
     */
    error?: string;
    /**
     * @type {RESTCommonRestoreStatistics}
     * @memberof RESTBulkMailboxRestoreStatistics
     */
    details?: RESTCommonRestoreStatistics;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTBulkMailboxRestoreStatisticsStatusEnum {
    Failed = 'Failed',
    Warning = 'Warning',
    Success = 'Success'
}

