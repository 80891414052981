// Copyright © Veeam Software Group GmbH

import type { Moment } from 'moment';
import type { RESTSharePointItemComposed } from 'api/rxjs';
import type { Product, ItemBase } from '..';

import type { SharePointSiteId } from './SharePointSiteId';

export enum SharePointItemType {
    ListItem = 'ListItem',
    ListFolderItem = 'ListFolderItem',
    Document = 'Document',
    Folder = 'Folder',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type SharePointItemId = string & { __sharepoint_item_id: never; };

interface SharePointItemBase extends ItemBase {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _raw_rest: RESTSharePointItemComposed;
    product: Product.SharePoint;
    id: SharePointItemId;
    itemType: SharePointItemType;
    siteId: SharePointSiteId;
    version?: string;
    modificationTime: Moment;
    modifiedBy: string;
    isVersion: boolean;
}

export interface SharePointListItem extends SharePointItemBase {
    itemType: SharePointItemType.ListItem;
}

export interface SharePointFolderItem extends SharePointItemBase {
    itemType: SharePointItemType.Folder;
}

export interface SharePointListFolderItem extends SharePointItemBase {
    itemType: SharePointItemType.ListFolderItem;
}

export interface SharePointDocument extends SharePointItemBase {
    itemType: SharePointItemType.Document;
    sizeBytes?: number;
}

export type SharePointItem = SharePointListItem | SharePointDocument | SharePointFolderItem | SharePointListFolderItem;
