// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTRestoreOneDriveDocumentConfig,
} from './';

/**
 * @export
 * @interface RESTOperatorRestoreToOriginalOneDriveDocuments
 */
export interface RESTOperatorRestoreToOriginalOneDriveDocuments {
    /**
     * Specifies the action that will be performed in case the restore destination contains the restored documents.
     * @type {string}
     * @memberof RESTOperatorRestoreToOriginalOneDriveDocuments
     */
    documentAction: RESTOperatorRestoreToOriginalOneDriveDocumentsDocumentActionEnum;
    /**
     * Specifies settings for document restore.
     * @type {Array<RESTRestoreOneDriveDocumentConfig>}
     * @memberof RESTOperatorRestoreToOriginalOneDriveDocuments
     */
    documentsRestoreConfigs: Array<RESTRestoreOneDriveDocumentConfig>;
    /**
     * Specifies a reason for the restore operation.
     * @type {string}
     * @memberof RESTOperatorRestoreToOriginalOneDriveDocuments
     */
    reason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOperatorRestoreToOriginalOneDriveDocumentsDocumentActionEnum {
    Overwrite = 'Overwrite',
    Keep = 'Keep'
}

