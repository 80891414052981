// Copyright © Veeam Software Group GmbH

import React, { useMemo } from 'react';
import { map } from 'rxjs/operators';
import first from 'lodash/first';
import { BodyLayout, SplitLayout, TitleH2, StackView, STACK_DIRECTION, INDENT } from '@veeam/components';

import type { FC } from 'react';

import { useResources } from 'infrastructure/resources';
import { useSessionGrid } from './SessionsGrid';
import { useEventsGrid } from './EventsGrid';

export const RestoreSessions: FC = () => {
    const resources = useResources().features.RestoreSessions;
    const { grid: restoreSessionsGrid, model: restoreSessionsModel } = useSessionGrid(resources.SessionsGrid);
    const session = useMemo(
        () => restoreSessionsModel.asObservable().pipe(map(value => first(value.selection))),
        [],
    );
    const restoreSessionEventsGrid = useEventsGrid(resources.EventsGrid, session);

    const restoreSessions = (
        <StackView spaceBottom={INDENT.s} size={{ height: '100%', width: '100%' }} direction={STACK_DIRECTION.column}>
            {restoreSessionsGrid}
        </StackView>
    );

    const restoreSessionEvents = (
        <StackView spaceTop={INDENT.m} size={{ height: '100%', width: '100%' }} direction={STACK_DIRECTION.column}>
            <TitleH2>{'Session Log: '}</TitleH2>
            {restoreSessionEventsGrid}
        </StackView>
    );

    return (
        <BodyLayout>
            <SplitLayout
                direction={STACK_DIRECTION.column}
                start={restoreSessions}
                startConfig={{
                    size: 0.7,
                    minSize: 0.3,
                }}
                end={restoreSessionEvents}
                endConfig={{
                    minSize: 0.3,
                }}
            />
        </BodyLayout>
    );
};
