// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { DataGridColumnConfig } from '@veeam/components';
import type { RbacScope } from 'services/rbac';

import { getTypeToIconMap, getTypeToTitleMap } from './mappers';
import { textCell, iconCell } from 'components/dataGridCells';
import { RbacScopeSortKeys, rbacService } from 'services/rbac';

import type { GridResources } from './resources';


export const useColumns = (resources: GridResources): DataGridColumnConfig<RbacScope>[] =>
    useMemo(() => {
        const titlesMap = getTypeToTitleMap(resources.types);
        const iconsMap = getTypeToIconMap();
        return [
            {
                id: 'name',
                title: resources.cells.scope,
                sortKey: RbacScopeSortKeys.Title,
                cell: iconCell(row => ({
                    text: `${row.title}${row.id === rbacService.info.operatorId ? ' (current user)' : ''}`,
                    iconSrc: iconsMap[row.type] })),
            },
            {
                id: 'type',
                title: resources.cells.type,
                sortKey: RbacScopeSortKeys.Type,
                cell: textCell(row => titlesMap[row.type]),
                width: 200,
            },
            {
                id: 'details',
                title: resources.cells.details,
                sortKey: RbacScopeSortKeys.Details,
                cell: textCell(row => row.details),
            },
        ];
    }, [resources]);
