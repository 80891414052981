// Copyright © Veeam Software Group GmbH

import { map } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import { zip } from 'rxjs';

import type { Observable } from 'rxjs';
import type { Batch, LoadPagesConfig } from 'infrastructure/rxjs';
import type { GetItemsRequest } from 'services/explore/interfaces/get-items-request';

import { LoadPagesMode, mergeBatches } from 'infrastructure/rxjs';
// eslint-disable-next-line import/no-cycle
import { exploreItemsCache, sortExploreItems } from './getItems';
import { Product } from '../models';
import { getExchangeItems, getExchangeMailboxes, getExchangeNodes } from './products/exchange';
import { getOneDriveItems, getOneDriveNodes, getOneDrives, getOneDriveVersions } from './products/oneDrive';
import { getRootSites, getSharePointItems, getSharePointNodes, getSharePointVersions } from './products/sharePoint';
import { getTeamsNodes } from './products/teams/get-teams-nodes';
import { never } from '../../infrastructure/never';
import { getTeams } from './products/teams/get-teams';
import { getTeamsItems } from './products/teams/get-teams-items';
import { getTeamsVersions } from './products/teams/get-teams-versions';

import type { GetItemVersionsRequest } from './interfaces/get-item-versions-request';
import type { Item, Node, VersionableItem } from '../models';

export class ExploreService {
    getNodes(parent?: Node, config?: LoadPagesConfig): Observable<Batch<Node>> {
        if (parent === undefined) {
            return this.getRootFolders(config);
        }

        switch (parent.product) {
            case Product.Exchange: return getExchangeNodes(parent, config);
            case Product.OneDrive: return getOneDriveNodes(parent, config);
            case Product.SharePoint: return getSharePointNodes(parent, config);
            case Product.Teams: return getTeamsNodes(parent, config);
            default: return never(parent);
        }
    }

    getItems(request: GetItemsRequest): Observable<Batch<Item>> {
        if (request.config?.mode === LoadPagesMode.Async) {
            return this.getProductItems(request);
        }

        return exploreItemsCache.getValue(request)
            .pipe(
                map((batch) => {
                    const clonedItems = cloneDeep(batch.data);

                    const data = sortExploreItems(request.sorting, clonedItems);

                    return { ...batch, data };
                })
            );
    }

    getVersions({ parent, ...other }: GetItemVersionsRequest): Observable<Batch<VersionableItem>> {
        switch (parent.product) {
            case Product.OneDrive: return getOneDriveVersions({ parent, ...other });
            case Product.SharePoint: return getSharePointVersions({ parent, ...other });
            case Product.Teams: return getTeamsVersions({ parent, ...other });
            default: return never(parent);
        }
    }

    getProductItems({ parent, ...other }: GetItemsRequest): Observable<Batch<Item>> {
        switch (parent.product) {
            case Product.Exchange:
                return getExchangeItems({ parent, ...other });
            case Product.OneDrive:
                return getOneDriveItems({ parent, ...other });
            case Product.SharePoint:
                return getSharePointItems({ parent, ...other });
            case Product.Teams:
                return getTeamsItems({ parent, ...other });
            default:
                return never(parent);
        }
    }

    private getRootFolders(config?: LoadPagesConfig): Observable<Batch<Node>> {
        return zip(
            getExchangeMailboxes(config),
            getOneDrives(config),
            getRootSites(config),
            getTeams(),
        ).pipe(
            map(mergeBatches)
        );
    }

}

export const exploreService = new ExploreService();
