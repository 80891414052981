// Copyright © Veeam Software Group GmbH

/**
 * Copyright © Veeam Software Group GmbH.
 */


import { useEffect, useState } from 'react';
import { DataGridModel, SORT_DIRECTION } from '@veeam/components';
import cloneDeep from 'lodash/cloneDeep';

import type { Observable } from 'rxjs';

import { commonLoaderOrDefault } from '../../../infrastructure/grid';
import { exploreService } from '../../../services/explore';
import { ServiceMemoryCache } from '../../../infrastructure/serviceCache';
import { Batch } from '../../../infrastructure/rxjs';
import { sortExploreItems } from '../../../services/explore/getItems';
import { ExploreSortKeys } from '../../../services/explore/enums/explore-sort-keys';

import type { Sorting } from '../../../infrastructure/client-side-operations';
import type { UniqueId, VersionableItem } from '../../../services';

interface Extra {
    parent: VersionableItem;
    sorting?: Sorting<typeof ExploreSortKeys>;
}

export const getVersionsCached = new ServiceMemoryCache(exploreService.getVersions);

export class VersionGridModel extends DataGridModel<VersionableItem, UniqueId, ExploreSortKeys, Extra> {
    unselectAll = (): void => {
        this.update((mutable) => {
            mutable.selection = [];
        });
    };

    public load = async(): Promise<void> => {
        super.load()
            .then(() => {
                this.update((mutable) => {
                    const clonedItems = cloneDeep(mutable.items);

                    mutable.items = sortExploreItems(mutable.sorting[0], clonedItems) as VersionableItem[];

                    getVersionsCached.setValue(Batch.from(mutable.items));
                });
            });
    };

    public destroy(): void {
        super.destroy();
        getVersionsCached.clearValue();
    }
}

const idGetter = (data: VersionableItem): UniqueId => data.uniqId;

const versionsLoader: VersionGridModel['loaderType'] = commonLoaderOrDefault(
    value => ({
        parent: value.parent,
        sorting: value.sorting[0],
    }),
    (request: Extra): Observable<Batch<VersionableItem>> => {
        if (getVersionsCached.isEmpty()) {
            return exploreService.getVersions(request);
        }

        return getVersionsCached.getValue(request);
    },
    []
);

export function useVersionGridModel(parent: VersionableItem): VersionGridModel {
    const [model] = useState(() => new VersionGridModel(idGetter, versionsLoader, { parent })
        .withSorting({ direction: SORT_DIRECTION.desc, key: ExploreSortKeys.Version })
    );

    useEffect(() => () => model.destroy(), []);

    return model;
}
