// Copyright © Veeam Software Group GmbH

import React, { useState, useRef } from 'react';
import last from 'lodash/last';
import { useForm, Model, conditions, required } from '@veeam/core';
import { WizardSummary } from '@veeam/components';
import { finalize } from 'rxjs/operators';

import type { FC } from 'react';
import type { Form } from '@veeam/core';
import type { WizardStepConfig } from 'components/wizard';
import type { Resources } from 'infrastructure/resources';
import type { Action0, Fieldset } from 'infrastructure/types';
import type {
    SharePointRestoreOptions } from 'services/restoreService';
import type {
    SharePointDocument,
    SharePointItem,
    SharePointListItem,
    SharePointFolderItem,
    SharePointListFolderItem,
} from 'services';

import { useResources } from 'infrastructure/resources';
import { useRestoreModeStep, RestoreMode, getRestoreModeSummary } from './RestoreModeStep';
import { useReasonStep, getReasonSummary, useItemsStep } from '../Shared';
import { useRestoreSessionRouting } from 'features/Routing';
import { RestoreOptionsPanel } from './RestoreOptionsPanel';
import { useMessageBoxManagement } from 'infrastructure/error-management';
import { RestoreWizardPanelsEnum } from 'features/Restore/Wizards/types';
import { never } from 'infrastructure/never';
import { Wizard, WIZARD_TYPE, usePanel } from 'components/wizard';
import { renderMultilineText } from 'components/renders';
import {
    restoreService,
    SharePointLastVersionAction,
    SharePointVersion,
} from 'services/restoreService';
import { SharePointItemType } from 'services';
import { exploreSessionService } from '../../../../services/exploreSessions';

import type { FormWithRestoreMode } from './RestoreModeStep';
import type { FormWithReason } from '../Shared';


interface SharePointRestoreForm extends FormWithRestoreMode, FormWithReason {
    items: SharePointItem[];
}

const getInitialFormValue = (items: SharePointItem[]): SharePointRestoreForm => ({
    items,
    restoreMode: RestoreMode.Original,
    reason: '',
    listName: '',
    restoreChangedItems: true,
    restoreMissingItems: true,
    restorePermission: true,
    sendNotification: true,
    restoreOnlyLatest: false,
    overrideVersions: true,
});

type WizardResources = Resources['features']['Restore']['Wizards'];

const getItemsSummary = (
    value: SharePointRestoreForm,
    resources: WizardResources['SharePointRestoreWizard']
): Fieldset => ({
    title: resources.itemsTitle,
    fields: [
        {
            label: resources.itemsSummary.label,
            value: renderMultilineText(
                value.items
                    .map(item => resources.itemsSummary.messageXtitleXversion.format(item.title, item.version))
                    .join('\n')
            ),
        },
    ],
});

const Summary: FC<{
    form: Form<SharePointRestoreForm>;
    resources: WizardResources;
}> = ({ form, resources }) => {
    const value = form.getValue();
    return (
        <WizardSummary
            fieldsets={[
                getItemsSummary(value, resources.SharePointRestoreWizard),
                getRestoreModeSummary(value, resources.SharePointRestoreWizard.RestoreModeStep),
                getReasonSummary(value, resources.Shared.ReasonStep),
            ]}
        />
    );
};

function convertToDocLastVersionAction(
    restoreOnlyLatest: boolean,
    overrideVersions: boolean
): SharePointLastVersionAction | undefined {
    if (!restoreOnlyLatest) return undefined;
    return overrideVersions ? SharePointLastVersionAction.Overwrite : SharePointLastVersionAction.Merge;
}

const getRestoreOptions = (formValue: SharePointRestoreForm): SharePointRestoreOptions => ({
    list: formValue.restoreMode === RestoreMode.AnotherPlace ? formValue.listName : undefined,
    restorePermissions: formValue.restorePermission,
    sendSharedLinksNotification: formValue.restorePermission ? formValue.sendNotification : undefined,
    restoreChangedItems: formValue.restoreChangedItems,
    restoreDeletedItems: formValue.restoreMissingItems,
    documentVersion: formValue.restoreOnlyLatest ? SharePointVersion.Last : SharePointVersion.All,
    documentLastVersionAction: convertToDocLastVersionAction(
        formValue.restoreOnlyLatest,
        formValue.overrideVersions
    ),
    reason: formValue.reason,
});

function separate(items: SharePointItem[]): [(SharePointDocument | SharePointFolderItem)[], (SharePointListItem | SharePointListFolderItem)[]] {
    const docs: (SharePointDocument | SharePointFolderItem)[] = [];
    const listItems: (SharePointListItem | SharePointListFolderItem)[] = [];
    items.forEach((item) => {
        switch (item.itemType) {
            case SharePointItemType.Document:
            case SharePointItemType.Folder:
                docs.push(item);
                break;
            case SharePointItemType.ListItem:
            case SharePointItemType.ListFolderItem:
                listItems.push(item);
                break;
            default:
                never(item);
        }
    });
    return [docs, listItems];
}

const useSharePointRestoreForm = (
    formModel: Model<SharePointRestoreForm>,
    resources: WizardResources
): Form<SharePointRestoreForm> =>
    useForm(formModel, {
        validationSchema: {
            listName: [
                conditions(
                    { restoreMode: RestoreMode.AnotherPlace },
                    required(resources.SharePointRestoreWizard.listNameShouldNotBeEmpty),
                ),
            ],
        },
    });

export const SharePointRestoreWizard: FC<{
    close: Action0;
    items: SharePointItem[];
}> = ({ items, close }) => {
    const { vespSession } = exploreSessionService.getSessions();
    const [loading, setLoading] = useState<boolean>(false);
    const formModel = useRef<Model<SharePointRestoreForm>>(new Model(getInitialFormValue(items))).current;
    const resources = useResources().features.Restore.Wizards;
    const formApi = useSharePointRestoreForm(formModel, resources);
    const { goTo } = useRestoreSessionRouting();
    const { show } = useMessageBoxManagement();
    const [disabledWizardActions, setDisabledWizardActions] = useState<boolean>(false);

    const [panel, panelActions, panelMeta] = usePanel({
        formConfig: formModel,
        showError: (message: string) => {
            show({ message });
        },
        setDisabledWizardActions,
        closingConfirmationDialogActions: {
            show: () => {},
            hide: () => {},
        },
        panels: {
            [RestoreWizardPanelsEnum.RestoreOptions]: RestoreOptionsPanel,
        },
    });

    const steps: WizardStepConfig[] = [
        useItemsStep(formApi, resources.Shared.ItemsStep),
        useRestoreModeStep(formApi, resources.SharePointRestoreWizard.RestoreModeStep, panelActions),
        useReasonStep(formApi, resources.Shared.ReasonStep),
        {
            tabTitle: 'Summary',
            tag: 'summary',
            body: <Summary form={formApi} resources={resources} />,
            description: 'Summary',
        },
    ];
    function submit(): void {
        if (!vespSession) throw new Error();
        setLoading(true);
        const formValue = formApi.getValue();
        const options = getRestoreOptions(formValue);
        const [docs, listItems] = separate(formValue.items);

        (docs.length > 0
            ? restoreService.restore.sharePoint.docs(vespSession, docs, options)
            : restoreService.restore.sharePoint.listItems(vespSession, listItems, options)
        )
            .pipe(finalize(() => setLoading(false)))
            .subscribe((sessionId) => {
                close();
                goTo(last(sessionId));
            });
    }
    return (
        <Wizard
            pageTitle='SharePoint Restore'
            loading={loading}
            type={WIZARD_TYPE.page}
            onRequestClose={close}
            onExitPage={close}
            onDone={submit}
            steps={steps}
            panel={panel}
            panelMinWidth={panelMeta.panelMinWidth}
            buttons={{
                previous: { disabled: disabledWizardActions },
                next: { disabled: disabledWizardActions },
                done: { disabled: loading || disabledWizardActions },
                cancel: { disabled: disabledWizardActions },
            }}
        />
    );
};
