// Copyright © Veeam Software Group GmbH

import moment, { duration } from 'moment';
import { useEffect } from 'react';
import { interval } from 'rxjs';

import type { EventsModel } from './useModel';

export function useDurationUpdate(model: EventsModel): void {
    useEffect(() => {
        const subscription = interval(1000).subscribe(() => {
            model.update((mutable) => {
                mutable.items.filter(item => item.status === 'Running').forEach((mutableItem) => {
                    mutableItem.duration = duration(moment().diff(mutableItem.startTime));
                });
            });
        });
        return () => subscription.unsubscribe();
    }, []);
}
