// Copyright © Veeam Software Group GmbH

import { v4 as uuid } from 'uuid';
import moment from 'moment';

import { Product } from '../../../models';
import {
    TeamsNodeType,
    TeamsItemType,
} from '../../../models/Teams';
import { convertBase } from '../../baseConverter';

import type {
    TeamsChannelItem,
    TeamsFileItem,
    TeamsFilesItem,
    TeamsPostItem,
    TeamsPostsItem,
    TeamsTabItem,
    TeamsTabsItem,
    TeamsTeamItem,
    TeamsFolderItem,
    TeamsChannelNode,
    TeamsFilesNode,
    TeamsPostsNode,
    TeamsTabsNode,
    TeamsNode,
    TeamsFolderNode,
} from '../../../models/Teams';
import type { RESTTeamsChannelPreview, RESTTeamsFile, RESTTeamsNode, RESTTeamsPost, RestTeamsTab, RESTTeamsTeam } from '../../../../api/rxjs';

export const convertTeamNodeFromRest = (team: RESTTeamsTeam, parent: TeamsNode): TeamsNode => ({
    ...convertBase('Node', { name: team.displayName, id: team.id }, parent?.explorePath),
    product: Product.Teams,
    nodeType: TeamsNodeType.Team,
    ...team,
});

export const convertTeamItemFromRest = (team: RESTTeamsTeam, parent: TeamsNode): TeamsTeamItem => ({
    ...convertBase('Item', { name: team.displayName, id: team.id }, parent?.explorePath),
    product: Product.Teams,
    itemType: TeamsItemType.Team,
    ...team,
});

export const convertChannelNodeFromRest = (channel: RESTTeamsChannelPreview, parent: TeamsNode): TeamsChannelNode => ({
    ...convertBase('Node', { name: channel.displayName, id: channel.id }, parent?.explorePath),
    product: Product.Teams,
    nodeType: TeamsNodeType.Channel,
    ...channel,
});

export const convertFolderNodeFromRest = (folder: RESTTeamsFile, parent: TeamsNode): TeamsFolderNode => ({
    ...convertBase('Node', { name: folder.name, id: folder.id }, parent?.explorePath),
    product: Product.Teams,
    nodeType: TeamsNodeType.Folder,
    ...folder,
});

export const convertChannelItemFromRest = (channel: RESTTeamsChannelPreview, parent: TeamsNode): TeamsChannelItem => ({
    ...convertBase('Item', { name: channel.displayName, id: channel.id }, parent?.explorePath),
    product: Product.Teams,
    itemType: TeamsItemType.Channel,
    ...channel,
});

export const convertTabNodeFromRest = (tab: RESTTeamsNode, parent: TeamsNode): TeamsTabsNode => ({
    ...convertBase('Node', { name: tab.displayName, id: uuid() }, parent?.explorePath),
    product: Product.Teams,
    nodeType: TeamsNodeType.Tabs,
    ...tab,
});

export const convertTabItemFromRest = (tab: RestTeamsTab, parent: TeamsNode): TeamsTabItem => ({
    ...convertBase('Item', { name: tab.displayName, id: tab.id }, parent?.explorePath),
    product: Product.Teams,
    itemType: TeamsItemType.Tab,
    ...tab,
});

export const convertFileNodeFromRest = (file: RESTTeamsNode, parent: TeamsNode): TeamsFilesNode => ({
    ...convertBase('Node', { name: file.displayName, id: uuid() }, parent?.explorePath),
    product: Product.Teams,
    nodeType: TeamsNodeType.Files,
    ...file,
});

export const convertFileItemFromRest = (file: RESTTeamsFile, isVersion: boolean, parent: TeamsNode): TeamsFileItem => ({
    ...convertBase(isVersion ? 'Version' : 'Item', { name: file.name, version: file.uiVersion, id: file.id }, parent?.explorePath),
    product: Product.Teams,
    isVersion,
    ...file,
    itemType: TeamsItemType.File,
    version: file.uiVersion,
    versionId: file.version,
    modificationTime: moment(file.modified),
});

export const convertFolderItemFromRest = (file: RESTTeamsFile, isVersion: boolean, parent: TeamsNode): TeamsFolderItem => ({
    ...convertBase(isVersion ? 'Version' : 'Item', { name: file.name, version: file.uiVersion, id: file.id }, parent?.explorePath),
    product: Product.Teams,
    isVersion,
    ...file,
    itemType: TeamsItemType.Folder,
    version: file.uiVersion,
    versionId: file.version,
    modificationTime: moment(file.modified),
});

export const convertPostNodeFromRest = (post: RESTTeamsNode, parent: TeamsNode): TeamsPostsNode => ({
    ...convertBase('Node', { name: post.displayName, id: uuid() }, parent?.explorePath),
    product: Product.Teams,
    nodeType: TeamsNodeType.Posts,
    ...post,
});

export const convertPostItemFromRest = (post: RESTTeamsPost, parent: TeamsNode, isReply = false): TeamsPostItem => {
    const parentInfo = {
        parentId: null,
        parentChangeKey: null,
    };
    if (isReply) {
        const parentAsPost = parent as any as TeamsPostItem;
        parentInfo.parentId = parentAsPost.postId;
        parentInfo.parentChangeKey = parentAsPost.changeKey;
    }
    return {
        ...convertBase('Item', {
            name: post.author,
            id: `${post.postId.toString()}-${post.lastModifiedTime}`,
        }, parent?.explorePath),
        product: Product.Teams,
        itemType: TeamsItemType.Post,
        id: post.postId.toString(),
        ...post,
        ...parentInfo,
    };
};

export const convertFilesItemFromRest = (node: RESTTeamsNode, parent: TeamsNode): TeamsFilesItem => ({
    ...convertBase('Item', { name: node.displayName, id: uuid() }, parent?.explorePath),
    product: Product.Teams,
    itemType: TeamsItemType.Files,
    ...node,
});

export const convertTabsItemFromRest = (node: RESTTeamsNode, parent: TeamsNode): TeamsTabsItem => ({
    ...convertBase('Item', { name: node.displayName, id: uuid() }, parent?.explorePath),
    product: Product.Teams,
    itemType: TeamsItemType.Tabs,
    ...node,
});

export const convertPostsItemFromRest = (node: RESTTeamsNode, parent: TeamsNode): TeamsPostsItem => ({
    ...convertBase('Item', { name: node.displayName, id: uuid() }, parent?.explorePath),
    product: Product.Teams,
    itemType: TeamsItemType.Posts,
    ...node,
});
