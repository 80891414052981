// Copyright © Veeam Software Group GmbH

import { DataGridModel } from '@veeam/components';

import type React from 'react';
import type { Observable, Subscription } from 'rxjs';
import type { ExtraData } from 'pages/ExplorePage/components/ExploreGrid/interfaces/extra-data';
import type { ExploreSortKeys } from 'services/explore/enums/explore-sort-keys';
import type { Item, ItemBase, Node, UniqueId } from 'services';
import type { Subscription as EventSubscription } from 'infrastructure/event';

import { SubheaderType } from 'pages/ExplorePage/components/ExploreGrid/enums/subheader-type';
import { EXPLORE_PAGE_LIMIT } from '../components/ExploreGrid/consts/explore-page-limit';
import { EXPLORE_MAX_LIMIT } from 'pages/ExplorePage/components/ExploreGrid/consts/explore-max-limit';
import { Batch } from 'infrastructure/rxjs';
import { rbacService } from 'services/rbac';
import { exploreItemsCache, sortExploreItems } from 'services/explore/getItems';


export class ExploreGridModel extends DataGridModel<Item, UniqueId, ExploreSortKeys, ExtraData> {
    private subscriptions: Subscription[];
    private asyncLoading: Subscription | undefined;
    private eventSubscription: EventSubscription;

    public constructor(
        loader: ExploreGridModel['loaderType'],
        node: Observable<Node | undefined>,
        private setAsyncLoading: React.Dispatch<React.SetStateAction<boolean>>,
        private setSubheaderType: React.Dispatch<React.SetStateAction<SubheaderType>>
    ) {
        super(ExploreGridModel.idGetter, loader, { limit: EXPLORE_PAGE_LIMIT });

        this.subscriptions = [
            node.subscribe((newNode) => {
                if (this.getValue().node !== newNode) {
                    this.update((mutable) => {
                        mutable.node = newNode;
                    });

                    this.setSubheaderType(SubheaderType.Default);

                    this.setFilter('search', null);

                    this.load();
                }
            }),

            this.asObservable().subscribe(({ totalCount, limit, loadState, filters }) => {
                if (!limit || !totalCount) return;

                if (loadState?.hasNextBatch) {
                    const searchFilterValue = filters.getFilter('search')?.value;

                    this.setSubheaderType(searchFilterValue ? SubheaderType.SearchIsUsed : SubheaderType.LargeNumbersOfItems);
                }
            }),

            this.fieldChanges('filters').subscribe(() => exploreItemsCache.clearValue()),
        ];

        this.eventSubscription = rbacService.onScopeChanged.subscribe(() => this.cancelAsyncLoading());
    }

    private static idGetter(data: ItemBase): UniqueId {
        return data.uniqId;
    }

    public cancelAsyncLoading = () => {
        if (this.asyncLoading && !this.asyncLoading.closed) {
            this.asyncLoading.unsubscribe();
        }

        this.setAsyncLoading(false);
    };

    public load = async(): Promise<void> => {
        this.update((mutable) => {
            mutable.limit = EXPLORE_PAGE_LIMIT;
            mutable.loadState = undefined;
            mutable.totalCount = 0;
        });

        this.cancelAsyncLoading();

        this.setSubheaderType(SubheaderType.Default);

        return super.load();
    };

    public destroy(): void {
        super.destroy();

        this.eventSubscription.unsubscribe();

        this.setSubheaderType(SubheaderType.Default);

        exploreItemsCache.clearValue();

        this.cancelAsyncLoading();

        this.subscriptions.filter(sub => !sub.closed).forEach(sub => sub.unsubscribe());
    }

    public loadAllItems(): void {
        this.update((mutable) => {
            mutable.limit = undefined;
            mutable.selection = [];
        });

        this.setAsyncLoading(true);

        let batchedItems: Item[] = [];

        this.asyncLoading = this.loader(this.getValue()).subscribe({
            next: ({ data: batch }) => {
                this.update((mutable) => {
                    batchedItems = [...batchedItems, ...batch.items];
                    mutable.loadState = batch.loadState;
                });
            },
            complete: () => {
                this.update((mutable) => {
                    const items = [...mutable.items, ...batchedItems];
                    mutable.items = sortExploreItems(mutable.sorting[0], items);
                    mutable.totalCount = mutable.items.length;
                    exploreItemsCache.setValue(Batch.from(mutable.items));
                });

                if (this.getValue().loadState?.totalCount === EXPLORE_MAX_LIMIT && this.getValue().loadState?.hasNextBatch) {
                    this.setSubheaderType(SubheaderType.LimitIsReached);
                } else {
                    this.setSubheaderType(SubheaderType.Default);
                }

                this.cancelAsyncLoading();
            },
            error: () => {
                batchedItems = [];

                this.cancelAsyncLoading();
            },
        });
    }

    unselectAll = (): void => {
        this.update((mutable) => {
            mutable.selection = [];
        });
    };
}
