// Copyright © Veeam Software Group GmbH

import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import {
    GRID_COLUMN_JUSTIFY,
    Icon,
    ICON_SIZES,
    Link,
    StackView,
    STACK_ALIGN,
    STACK_DIRECTION,
} from '@veeam/components';

import type {
    DataGridCellRenderer,
    Theme } from '@veeam/components';
import type { ThemeProps } from 'styled-components';
import type { Action0, Func1 } from 'infrastructure/types';

import { textCellRender } from './textCell';
import { neverError } from 'infrastructure/never';

import type { CellRender, CellRendererProps } from './CellRender';

export const mapGridJustifyToStackAlign = (justify: GRID_COLUMN_JUSTIFY) => {
    switch (justify) {
        case GRID_COLUMN_JUSTIFY.left:
            return STACK_ALIGN.start;
        case GRID_COLUMN_JUSTIFY.right:
            return STACK_ALIGN.end;
        case GRID_COLUMN_JUSTIFY.center:
            return STACK_ALIGN.center;
        default:
            neverError(justify);
    }
};

const CellWithIconStyle = styled.div<{ srcIcon: string; }>`
    padding-left: ${({ theme }: ThemeProps<Theme>) => theme.spacing.l};
    background-image: url(${(props: { srcIcon: string; }) => props.srcIcon});
    background-repeat: no-repeat;
    background-size: ${({ theme }: ThemeProps<Theme>) => theme.spacing.m};
    background-position: left center;
    overflow: hidden;
    & a {
        &:hover,
        &:active {
            text-decoration: underline !important;
        }
        & > * {
            color: ${({ theme }: ThemeProps<Theme>) => theme.colorLink} !important;
        }
    }
`;

export interface IconCellRenderProps {
    text?: string | null;
    iconSrc?: string;
    action?: Action0;
}

export function iconCellRender(iconCell: IconCellRenderProps, props: CellRendererProps, isSortable = false): CellRender {
    const { iconSrc, text, action } = iconCell;
    if (iconSrc) {
        if (text !== null) {
            return (
                <CellWithIconStyle srcIcon={iconSrc}>
                    {action ? <Link onClick={action}>{textCellRender(text, props)}</Link> : textCellRender(text, props)}
                </CellWithIconStyle>
            );
        }

        const { justify = GRID_COLUMN_JUSTIFY.center } = props;
        const cellStyle: CSSProperties = {
            marginRight: '-16px',  // wrong padding from kit
        }

        if (isSortable) {
            cellStyle.marginLeft = '14px';
        }

        return (
            <StackView
                direction={STACK_DIRECTION.column}
                align={mapGridJustifyToStackAlign(justify)}
                style={cellStyle}
            >
                <Icon src={iconSrc} size={ICON_SIZES.m} />
            </StackView>
        );
    }
    if (text !== null) {
        return textCellRender(text, props);
    }
    return null;
}

export function iconCell<TRow>(value: Func1<TRow, IconCellRenderProps>, isSortable = false): DataGridCellRenderer<TRow> {
    return (row, payload) => iconCellRender(value(row), payload, isSortable);
}
