/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTDataRetrievalSession,
    RESTDataRetrievalSession,
    RESTExceptionInfo,
} from '../models';

export interface DataRetrievalSessionApiDataRetrievalSessionGetRequest {
    limit: number;
    offset: number;
    dataRetrievalId?: string;
}

export interface DataRetrievalSessionApiDataRetrievalSessionGetByIdRequest {
    dataRetrievalSessionId: string;
}

/**
 * no description
 */
export const dataRetrievalSessionApi = ({

    /**
     * Returns a collection of all sessions created for retrieval jobs.
     * Get Data Retrieval Sessions
     */
    dataRetrievalSessionGet: ({ limit, offset, dataRetrievalId }: DataRetrievalSessionApiDataRetrievalSessionGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTDataRetrievalSession>> => {
        throwIfNullOrUndefined(limit, 'limit', 'dataRetrievalSessionGet');
        throwIfNullOrUndefined(offset, 'offset', 'dataRetrievalSessionGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (dataRetrievalId != null) { query['dataRetrievalId'] = dataRetrievalId; }

        return requestAuthenticated<PageOfRESTDataRetrievalSession>({
            url: '/v7/DataRetrievalSessions',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a data retrieval session with the specified ID.
     * Get Data Retrieval Session by Session ID
     */
    dataRetrievalSessionGetById: ({ dataRetrievalSessionId }: DataRetrievalSessionApiDataRetrievalSessionGetByIdRequest, option?: RequestOption): Observable<Optional<RESTDataRetrievalSession>> => {
        throwIfNullOrUndefined(dataRetrievalSessionId, 'dataRetrievalSessionId', 'dataRetrievalSessionGetById');

        return requestAuthenticated<RESTDataRetrievalSession>({
            url: '/v7/DataRetrievalSessions/{dataRetrievalSessionId}'.replace('{dataRetrievalSessionId}', encodeURI(dataRetrievalSessionId)),
            method: 'GET',
        }, option);
    },

})
