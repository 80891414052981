// Copyright © Veeam Software Group GmbH

import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isEqual } from 'lodash';

import type { Observable } from 'rxjs';
import type { RbacScope } from 'services/rbac';

import { defaultRbacScopeId, rbacService } from 'services/rbac';
import { authController } from 'infrastructure/auth';
import { exploreSessionStorage, rbacStorage, restorePointStorage } from 'infrastructure/storage';
import { RESTOperatorExploreOptionsTypeEnum as SessionType, restoreSessionApi } from '../../api/rxjs';
import { createEvent } from '../../infrastructure/event';
import { errorManager } from '../../infrastructure/error-management';

import type { ErrorKeeper, Optional, RESTRestoreSession, ResultKeeper , RESTRestorePoint } from '../../api/rxjs';
import type { ExploreSession, VeodSession, VespSession, VetSession, VexSession } from '../models';
import type { Func2 } from '../../infrastructure/types';

class ExploreSessionService {
    events = {
        session: {
            changed: createEvent<ExploreSession>(),
            renewed: createEvent<ExploreSession>(),
            changing: createEvent(),
            error: createEvent(),
        },
        restorePoint: {
            changed: createEvent<RESTRestorePoint | undefined>(),
        },
    };

    private renewedSessionHolder: {
        exchange?: Observable<VexSession>;
        sharePoint?: Observable<VespSession>;
        oneDrive?: Observable<VeodSession>;
        teams?: Observable<VetSession>;
    } = {};

    private isSessionClosing = false;

    constructor() {
        const restorePoint = this.getRestorePoint();

        if (restorePoint) {
            this.openSessions(rbacStorage.get().scope);
        }

        rbacService.onScopeChanged.subscribe(this.disposeSessions.bind(this));
        authController.events.logout.before.subscribe(this.disposeSessions.bind(this));
        authController.events.logout.after.subscribe(restorePointStorage.clear);
        this.events.restorePoint.changed.subscribe(() => this.openSessions(rbacStorage.get().scope, true));
    }

    getSessions(): ExploreSession {
        const sessions = exploreSessionStorage.get();

        if (sessions === undefined) {
            return { scopeId: defaultRbacScopeId };
        }

        return sessions;
    }

    async openSessions(scope: RbacScope, force?: boolean): Promise<void> {
        const sessions = exploreSessionStorage.get();

        if (force || sessions === undefined || sessions.scopeId !== scope.id) {
            await this.reopenSessions(scope);
        } else {
            await this.ensureSessionsOpened(sessions, scope);
        }
    }

    async disposeSessions(): Promise<void> {
        await this.closeSessions();

        exploreSessionStorage.clear();
    }

    get renewSession() {
        return {
            exchange: () => {
                if (this.renewedSessionHolder.exchange === undefined) {
                    this.renewedSessionHolder.exchange =
                        from(this.renewSessionInternal(SessionType.Vex, (sessions, id) => sessions.vexSession = id as VexSession))
                            .pipe(tap(() => this.renewedSessionHolder.exchange = undefined));
                }

                return this.renewedSessionHolder.exchange;
            },
            sharePoint: () => {
                if (this.renewedSessionHolder.sharePoint === undefined) {
                    this.renewedSessionHolder.sharePoint =
                        from(this.renewSessionInternal(SessionType.Vesp, (sessions, id) => sessions.vespSession = id as VespSession))
                            .pipe(tap(() => this.renewedSessionHolder.sharePoint = undefined));
                }

                return this.renewedSessionHolder.sharePoint;
            },
            oneDrive: () => {
                if (this.renewedSessionHolder.oneDrive === undefined) {
                    this.renewedSessionHolder.oneDrive =
                        from(this.renewSessionInternal(SessionType.Veod, (sessions, id) => sessions.veodSession = id as VeodSession))
                            .pipe(tap(() => this.renewedSessionHolder.oneDrive = undefined));
                }

                return this.renewedSessionHolder.oneDrive;
            },
            team: () => {
                if (this.renewedSessionHolder.teams === undefined) {
                    this.renewedSessionHolder.teams =
                        from(this.renewSessionInternal(SessionType.Vet, (sessions, id) => sessions.vetSession = id as VetSession))
                            .pipe(tap(() => this.renewedSessionHolder.teams = undefined));
                }

                return this.renewedSessionHolder.teams;
            },
        };
    }

    getRestorePoint(): RESTRestorePoint {
        const scopeId = rbacService.info.scope.id;
        const storage = restorePointStorage.get();

        return storage && storage[scopeId];
    }

    setRestorePointToStorage(restorePoint: RESTRestorePoint): void {
        const scopeId = rbacService.info.scope.id;
        const storage = restorePointStorage.get();

        restorePointStorage.save({
            ...storage,
            [scopeId]: restorePoint,
        });

        this.events.restorePoint.changed.raise(restorePoint);
    }

    private openExchangeSession = (scope: RbacScope, restorePoint: RESTRestorePoint) => scope.has.exchange ?
        this.openSession(scope, restorePoint, SessionType.Vex) : undefined;

    private openOneDriveSession = (scope: RbacScope, restorePoint: RESTRestorePoint) => scope.has.oneDrive ?
        this.openSession(scope, restorePoint, SessionType.Veod) : undefined;

    private openSharePointSession = (scope: RbacScope, restorePoint: RESTRestorePoint) => scope.has.sharePoint ?
        this.openSession(scope, restorePoint, SessionType.Vesp) : undefined;

    private openTeamsSession = (scope: RbacScope, restorePoint: RESTRestorePoint) => scope.has.teams ?
        this.openSession(scope, restorePoint, SessionType.Vet) : undefined;

    private getSessionId = <T extends string>(opt: Optional<RESTRestoreSession> | undefined): T | undefined => {
        if (opt === undefined) return undefined;
        if (opt.isError) {
            errorManager.register(opt.error, { silent: true });
            return undefined;
        }
        return (opt as ResultKeeper<RESTRestoreSession>).data.id as T;
    };

    private ensureSessionsOpened = async(sessions: ExploreSession, scope: RbacScope): Promise<void> => {
        const restorePoint = this.getRestorePoint();
        const newSessions = { ...sessions };
        await Promise.all([
            sessions.vexSession || this.openExchangeSession(scope, restorePoint)?.then(opt => this.getSessionId<VexSession>(opt))
                .then(id => newSessions.vexSession = id),
            sessions.veodSession || this.openOneDriveSession(scope, restorePoint)?.then(opt => this.getSessionId<VeodSession>(opt))
                .then(id => newSessions.veodSession = id),
            sessions.vespSession || this.openSharePointSession(scope, restorePoint)?.then(opt => this.getSessionId<VespSession>(opt))
                .then(id => newSessions.vespSession = id),
            sessions.vetSession || this.openTeamsSession(scope, restorePoint)?.then(opt => this.getSessionId<VetSession>(opt))
                .then(id => newSessions.vetSession = id),
        ]);

        if (!isEqual(sessions, newSessions)) {
            exploreSessionStorage.save(newSessions);
            this.events.session.changed.raise(newSessions);
        }
    };

    private openSession = (scope: RbacScope, restorePoint: RESTRestorePoint, type: SessionType): Promise<Optional<RESTRestoreSession>> =>
        restoreSessionApi.restoreSessionOperatorExploreAction({
            body: {
                type,
                scope: scope.item,
                dateTime: restorePoint.backupTime,
                showAllVersions: true,
                showDeleted: true,
            },
        }).toPromise();

    private reopenSessions = async(scope: RbacScope): Promise<void> => {
        const closePromise = this.closeSessions();
        const openPromise = this.internalOpenSessions(scope);
        await closePromise;

        const newSessions = await openPromise;

        if (newSessions) {
            exploreSessionStorage.save(newSessions);
            this.events.session.changed.raise(newSessions);
        }
    };

    private closeSession = (restoreSessionId: string): Promise<Optional<void>> =>
        restoreSessionApi.restoreSessionStopAction({ restoreSessionId }, { registerError: false }).toPromise();

    private closeSessions = async(): Promise<void> => {
        const sessions = exploreSessionStorage.get();
        if (sessions === undefined || this.isSessionClosing) return;
        this.isSessionClosing = true;

        const results = await Promise.all([
            sessions.vexSession && this.closeSession(sessions.vexSession),
            sessions.vespSession && this.closeSession(sessions.vespSession),
            sessions.veodSession && this.closeSession(sessions.veodSession),
            sessions.vetSession && this.closeSession(sessions.vetSession),
        ]);
        results
            .filter((res): res is ErrorKeeper<object> => res?.isError || false)
            .forEach(res => errorManager.register(res.error, { silent: true }));

        this.isSessionClosing = false;
    };

    private async internalOpenSessions(scope: RbacScope): Promise<ExploreSession> {
        const restorePoint = this.getRestorePoint();

        if (restorePoint) {
            await this.events.session.changing.raise();

            const [vexSessionOpt, vespSessionOpt, veodSessionOpt, vetSessionOpt] = await Promise.all([
                this.openExchangeSession(scope, restorePoint),
                this.openSharePointSession(scope, restorePoint),
                this.openOneDriveSession(scope, restorePoint),
                this.openTeamsSession(scope, restorePoint),
            ]);

            const vexSession = this.getSessionId<VexSession>(vexSessionOpt);
            const vespSession = this.getSessionId<VespSession>(vespSessionOpt);
            const veodSession = this.getSessionId<VeodSession>(veodSessionOpt);
            const vetSession = this.getSessionId<VetSession>(vetSessionOpt);

            if (!vexSession && !vespSession && !veodSession && !vetSession) {
                errorManager.register('Cannot open session');
                this.events.session.error.raise();
            }

            return {
                scopeId: scope.id,
                vexSession,
                vespSession,
                veodSession,
                vetSession,
            };
        }
    }

    private async renewSessionInternal<T extends string>(type: SessionType, mutate: Func2<ExploreSession, string, T>): Promise<T> {
        const sessions = exploreSessionStorage.get();
        if (sessions === undefined) throw new Error();

        const { scope } = rbacService.info;

        const restorePoint = this.getRestorePoint();
        const opt = await this.openSession(scope, restorePoint, type);

        const sessionId = opt.getResultOrThrow().id;

        const result = mutate(sessions, sessionId);
        exploreSessionStorage.save(sessions);
        this.events.session.renewed.raise(sessions);

        return result;
    }
}

export const exploreSessionService = new ExploreSessionService();
