/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTAzureLocation,
    RESTAzureResourceGroup,
    RESTAzureSubnet,
    RESTAzureSubscription,
    RESTAzureVirtualMachineSize,
    RESTAzureVirtualNetwork,
    RESTDeviceCodeInfo,
    RESTDeviceCodeInfoRequest,
    RESTExceptionInfo,
    RESTServiceAccountCreationData,
    RESTServiceAccountInfo,
    RESTServiceAccountUpdateData,
} from '../models';

export interface AzureServiceAccountsApiAzureServiceAccountsAddRequest {
    body: RESTServiceAccountCreationData;
}

export interface AzureServiceAccountsApiAzureServiceAccountsDeleteRequest {
    id: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetRequest {
    displayName?: string;
    description?: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetByIdRequest {
    id: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetDeviceCodeRequest {
    body: RESTDeviceCodeInfoRequest;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetLocationByNameRequest {
    serviceAccountId: string;
    subscriptionId: string;
    locationName: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetLocationsRequest {
    serviceAccountId: string;
    subscriptionId: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetResourceGroupByNameRequest {
    serviceAccountId: string;
    subscriptionId: string;
    resourceGroupName: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetResourceGroupsRequest {
    serviceAccountId: string;
    subscriptionId: string;
    locationName?: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetSubnetByNameRequest {
    serviceAccountId: string;
    subscriptionId: string;
    resourceGroupName: string;
    virtualNetworkName: string;
    subnetName: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetSubnetsRequest {
    serviceAccountId: string;
    subscriptionId: string;
    resourceGroupName: string;
    virtualNetworkName: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetSubscriptionByIdRequest {
    serviceAccountId: string;
    subscriptionId: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetSubscriptionsRequest {
    serviceAccountId: string;
    subscriptionName?: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetVirtualMachineSizeByNameRequest {
    serviceAccountId: string;
    subscriptionId: string;
    locationName: string;
    vmSizeName: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetVirtualMachineSizesRequest {
    serviceAccountId: string;
    subscriptionId: string;
    locationName: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetVirtualNetworkByNameRequest {
    serviceAccountId: string;
    subscriptionId: string;
    resourceGroupName: string;
    virtualNetworkName: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsGetVirtualNetworksRequest {
    serviceAccountId: string;
    subscriptionId: string;
    resourceGroupName: string;
    locationName?: string;
}

export interface AzureServiceAccountsApiAzureServiceAccountsUpdateRequest {
    id: string;
    body: RESTServiceAccountUpdateData;
}

/**
 * no description
 */
export const azureServiceAccountsApi = ({

    /**
     * Adds a new Microsoft Azure service account.
     * Add Azure Service Account
     */
    azureServiceAccountsAdd: ({ body }: AzureServiceAccountsApiAzureServiceAccountsAddRequest, option?: RequestOption): Observable<Optional<RESTServiceAccountInfo>> => {
        throwIfNullOrUndefined(body, 'body', 'azureServiceAccountsAdd');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTServiceAccountInfo>({
            url: '/v7/AzureServiceAccounts',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Removes Microsoft Azure service account with the specified ID.
     * Remove Azure Service Account
     */
    azureServiceAccountsDelete: ({ id }: AzureServiceAccountsApiAzureServiceAccountsDeleteRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(id, 'id', 'azureServiceAccountsDelete');

        return requestAuthenticated<void>({
            url: '/v7/AzureServiceAccounts/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, option);
    },

    /**
     * Returns a list of existing Microsoft Azure service accounts.
     * Get Azure Service Accounts
     */
    azureServiceAccountsGet: ({ displayName, description }: AzureServiceAccountsApiAzureServiceAccountsGetRequest, option?: RequestOption): Observable<Optional<Array<RESTServiceAccountInfo>>> => {

        const query: HttpQuery = {};

        if (displayName != null) { query['displayName'] = displayName; }
        if (description != null) { query['description'] = description; }

        return requestAuthenticated<Array<RESTServiceAccountInfo>>({
            url: '/v7/AzureServiceAccounts',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a list of properties for Microsoft Azure service account with the specified ID.
     * Get Azure Service Account Properties by Account ID
     */
    azureServiceAccountsGetById: ({ id }: AzureServiceAccountsApiAzureServiceAccountsGetByIdRequest, option?: RequestOption): Observable<Optional<RESTServiceAccountInfo>> => {
        throwIfNullOrUndefined(id, 'id', 'azureServiceAccountsGetById');

        return requestAuthenticated<RESTServiceAccountInfo>({
            url: '/v7/AzureServiceAccounts/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, option);
    },

    /**
     * Allows you to obtain a device code from Microsoft Azure to sign in to the Microsoft authentication portal.
     * Get Device Code
     */
    azureServiceAccountsGetDeviceCode: ({ body }: AzureServiceAccountsApiAzureServiceAccountsGetDeviceCodeRequest, option?: RequestOption): Observable<Optional<RESTDeviceCodeInfo>> => {
        throwIfNullOrUndefined(body, 'body', 'azureServiceAccountsGetDeviceCode');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDeviceCodeInfo>({
            url: '/v7/AzureServiceAccounts/DeviceCode',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns information about a Microsoft Azure region with the specified name.
     * Get Location by Name
     */
    azureServiceAccountsGetLocationByName: ({ serviceAccountId, subscriptionId, locationName }: AzureServiceAccountsApiAzureServiceAccountsGetLocationByNameRequest, option?: RequestOption): Observable<Optional<RESTAzureLocation>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetLocationByName');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetLocationByName');
        throwIfNullOrUndefined(locationName, 'locationName', 'azureServiceAccountsGetLocationByName');

        return requestAuthenticated<RESTAzureLocation>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/Locations/{locationName}'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{locationName}', encodeURI(locationName)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a list of Microsoft Azure regions.
     * Get Locations
     */
    azureServiceAccountsGetLocations: ({ serviceAccountId, subscriptionId }: AzureServiceAccountsApiAzureServiceAccountsGetLocationsRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureLocation>>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetLocations');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetLocations');

        return requestAuthenticated<Array<RESTAzureLocation>>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/Locations'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns information about a resource group with the specified name.
     * Get Resource Group by Name
     */
    azureServiceAccountsGetResourceGroupByName: ({ serviceAccountId, subscriptionId, resourceGroupName }: AzureServiceAccountsApiAzureServiceAccountsGetResourceGroupByNameRequest, option?: RequestOption): Observable<Optional<RESTAzureResourceGroup>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetResourceGroupByName');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetResourceGroupByName');
        throwIfNullOrUndefined(resourceGroupName, 'resourceGroupName', 'azureServiceAccountsGetResourceGroupByName');

        return requestAuthenticated<RESTAzureResourceGroup>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/ResourceGroups/{resourceGroupName}'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{resourceGroupName}', encodeURI(resourceGroupName)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a list of resource groups that will be associated with the Azure archiver appliance.
     * Get Resource Groups
     */
    azureServiceAccountsGetResourceGroups: ({ serviceAccountId, subscriptionId, locationName }: AzureServiceAccountsApiAzureServiceAccountsGetResourceGroupsRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureResourceGroup>>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetResourceGroups');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetResourceGroups');

        const query: HttpQuery = {};

        if (locationName != null) { query['locationName'] = locationName; }

        return requestAuthenticated<Array<RESTAzureResourceGroup>>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/ResourceGroups'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns information about a subnet with the specified name within a virtual network with the specified name.
     * Get Subnet by Name
     */
    azureServiceAccountsGetSubnetByName: ({ serviceAccountId, subscriptionId, resourceGroupName, virtualNetworkName, subnetName }: AzureServiceAccountsApiAzureServiceAccountsGetSubnetByNameRequest, option?: RequestOption): Observable<Optional<RESTAzureSubnet>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetSubnetByName');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetSubnetByName');
        throwIfNullOrUndefined(resourceGroupName, 'resourceGroupName', 'azureServiceAccountsGetSubnetByName');
        throwIfNullOrUndefined(virtualNetworkName, 'virtualNetworkName', 'azureServiceAccountsGetSubnetByName');
        throwIfNullOrUndefined(subnetName, 'subnetName', 'azureServiceAccountsGetSubnetByName');

        return requestAuthenticated<RESTAzureSubnet>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/ResourceGroups/{resourceGroupName}/VirtualNetworks/{virtualNetworkName}/Subnets/{subnetName}'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{resourceGroupName}', encodeURI(resourceGroupName)).replace('{virtualNetworkName}', encodeURI(virtualNetworkName)).replace('{subnetName}', encodeURI(subnetName)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a list of subnets within a virtual network with the specified name.
     * Get Subnets
     */
    azureServiceAccountsGetSubnets: ({ serviceAccountId, subscriptionId, resourceGroupName, virtualNetworkName }: AzureServiceAccountsApiAzureServiceAccountsGetSubnetsRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureSubnet>>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetSubnets');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetSubnets');
        throwIfNullOrUndefined(resourceGroupName, 'resourceGroupName', 'azureServiceAccountsGetSubnets');
        throwIfNullOrUndefined(virtualNetworkName, 'virtualNetworkName', 'azureServiceAccountsGetSubnets');

        return requestAuthenticated<Array<RESTAzureSubnet>>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/ResourceGroups/{resourceGroupName}/VirtualNetworks/{virtualNetworkName}/Subnets'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{resourceGroupName}', encodeURI(resourceGroupName)).replace('{virtualNetworkName}', encodeURI(virtualNetworkName)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns information about Microsoft Azure subscription with the specified ID.
     * Get Subscription by ID
     */
    azureServiceAccountsGetSubscriptionById: ({ serviceAccountId, subscriptionId }: AzureServiceAccountsApiAzureServiceAccountsGetSubscriptionByIdRequest, option?: RequestOption): Observable<Optional<RESTAzureSubscription>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetSubscriptionById');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetSubscriptionById');

        return requestAuthenticated<RESTAzureSubscription>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns Microsoft Azure subscriptions associated with Microsoft Azure service account with the specified ID.
     * Get Subscriptions
     */
    azureServiceAccountsGetSubscriptions: ({ serviceAccountId, subscriptionName }: AzureServiceAccountsApiAzureServiceAccountsGetSubscriptionsRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureSubscription>>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetSubscriptions');

        const query: HttpQuery = {};

        if (subscriptionName != null) { query['subscriptionName'] = subscriptionName; }

        return requestAuthenticated<Array<RESTAzureSubscription>>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions'.replace('{serviceAccountId}', encodeURI(serviceAccountId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns information about the Azure archiver appliance - an auxiliary virtual machine with the specified name.
     * Get Virtual Machine Size by Name
     */
    azureServiceAccountsGetVirtualMachineSizeByName: ({ serviceAccountId, subscriptionId, locationName, vmSizeName }: AzureServiceAccountsApiAzureServiceAccountsGetVirtualMachineSizeByNameRequest, option?: RequestOption): Observable<Optional<RESTAzureVirtualMachineSize>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetVirtualMachineSizeByName');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetVirtualMachineSizeByName');
        throwIfNullOrUndefined(locationName, 'locationName', 'azureServiceAccountsGetVirtualMachineSizeByName');
        throwIfNullOrUndefined(vmSizeName, 'vmSizeName', 'azureServiceAccountsGetVirtualMachineSizeByName');

        return requestAuthenticated<RESTAzureVirtualMachineSize>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/Locations/{locationName}/VirtualMachineSizes/{vmSizeName}'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{locationName}', encodeURI(locationName)).replace('{vmSizeName}', encodeURI(vmSizeName)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a list of Azure archiver appliances. The Azure archiver appliance is a small auxiliary machine in Microsoft Azure that is deployed and configured automatically by Veeam Backup for Microsoft 365.
     * Get Virtual Machine Sizes
     */
    azureServiceAccountsGetVirtualMachineSizes: ({ serviceAccountId, subscriptionId, locationName }: AzureServiceAccountsApiAzureServiceAccountsGetVirtualMachineSizesRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureVirtualMachineSize>>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetVirtualMachineSizes');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetVirtualMachineSizes');
        throwIfNullOrUndefined(locationName, 'locationName', 'azureServiceAccountsGetVirtualMachineSizes');

        return requestAuthenticated<Array<RESTAzureVirtualMachineSize>>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/Locations/{locationName}/VirtualMachineSizes'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{locationName}', encodeURI(locationName)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns information about a virtual network with the specified name.
     * Get Virtual Network by Name
     */
    azureServiceAccountsGetVirtualNetworkByName: ({ serviceAccountId, subscriptionId, resourceGroupName, virtualNetworkName }: AzureServiceAccountsApiAzureServiceAccountsGetVirtualNetworkByNameRequest, option?: RequestOption): Observable<Optional<RESTAzureVirtualNetwork>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetVirtualNetworkByName');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetVirtualNetworkByName');
        throwIfNullOrUndefined(resourceGroupName, 'resourceGroupName', 'azureServiceAccountsGetVirtualNetworkByName');
        throwIfNullOrUndefined(virtualNetworkName, 'virtualNetworkName', 'azureServiceAccountsGetVirtualNetworkByName');

        return requestAuthenticated<RESTAzureVirtualNetwork>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/ResourceGroups/{resourceGroupName}/VirtualNetworks/{virtualNetworkName}'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{resourceGroupName}', encodeURI(resourceGroupName)).replace('{virtualNetworkName}', encodeURI(virtualNetworkName)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a list of available virtual networks to which the Azure archiver appliance will be connected.
     * Get Virtual Networks
     */
    azureServiceAccountsGetVirtualNetworks: ({ serviceAccountId, subscriptionId, resourceGroupName, locationName }: AzureServiceAccountsApiAzureServiceAccountsGetVirtualNetworksRequest, option?: RequestOption): Observable<Optional<Array<RESTAzureVirtualNetwork>>> => {
        throwIfNullOrUndefined(serviceAccountId, 'serviceAccountId', 'azureServiceAccountsGetVirtualNetworks');
        throwIfNullOrUndefined(subscriptionId, 'subscriptionId', 'azureServiceAccountsGetVirtualNetworks');
        throwIfNullOrUndefined(resourceGroupName, 'resourceGroupName', 'azureServiceAccountsGetVirtualNetworks');

        const query: HttpQuery = {};

        if (locationName != null) { query['locationName'] = locationName; }

        return requestAuthenticated<Array<RESTAzureVirtualNetwork>>({
            url: '/v7/AzureServiceAccounts/{serviceAccountId}/Subscriptions/{subscriptionId}/ResourceGroups/{resourceGroupName}/VirtualNetworks'.replace('{serviceAccountId}', encodeURI(serviceAccountId)).replace('{subscriptionId}', encodeURI(subscriptionId)).replace('{resourceGroupName}', encodeURI(resourceGroupName)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Modifies properties of Microsoft Azure service account with the specified ID.
     * Edit Azure Service Account
     */
    azureServiceAccountsUpdate: ({ id, body }: AzureServiceAccountsApiAzureServiceAccountsUpdateRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(id, 'id', 'azureServiceAccountsUpdate');
        throwIfNullOrUndefined(body, 'body', 'azureServiceAccountsUpdate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/AzureServiceAccounts/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: body,
        }, option);
    },

})
