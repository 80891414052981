// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTOneDriveDocument,
} from './';

/**
 * @export
 * @interface RESTRestoreOneDriveDocumentConfig
 */
export interface RESTRestoreOneDriveDocumentConfig {
    /**
     * @type {RESTOneDriveDocument}
     * @memberof RESTRestoreOneDriveDocumentConfig
     */
    document: RESTOneDriveDocument;
    /**
     * Specifies what version of the OneDrive document will be restored.
     * @type {string}
     * @memberof RESTRestoreOneDriveDocumentConfig
     */
    documentVersion?: RESTRestoreOneDriveDocumentConfigDocumentVersionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreOneDriveDocumentConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}

