// Copyright © Veeam Software Group GmbH

import type { Product } from '../Product';
import type { NodeBase } from '../NodeBase';
import type { SharePointSiteId } from './SharePointSiteId';

export enum SharePointNodeType {
    Site = 'Site',
    SubSites = 'SubSites',
    Content = 'Content',
    List = 'List',
    Library = 'Library',
    LibraryFolder = 'LibraryFolder',
    ListFolder = 'ListFolder',
}

export type SharePointFolderId = string & { __sharepoint_item_id: never; };

export interface SharePointNodeBase extends NodeBase {
    product: Product.SharePoint;
    nodeType: SharePointNodeType;
    siteId: SharePointSiteId;
}

export interface SharePointSite extends SharePointNodeBase {
    nodeType: SharePointNodeType.Site;
    url?: string;
}

export interface SharePointSubSites extends SharePointNodeBase {
    nodeType: SharePointNodeType.SubSites;
}

export interface SharePointContent extends SharePointNodeBase {
    nodeType: SharePointNodeType.Content;
}

export interface SharePointFolderBase extends SharePointNodeBase {
    id: SharePointFolderId;
}

export interface SharePointList extends SharePointFolderBase {
    nodeType: SharePointNodeType.List;
}

export interface SharePointLibrary extends SharePointFolderBase {
    nodeType: SharePointNodeType.Library;
}

export interface SharePointLibraryFolder extends SharePointFolderBase {
    nodeType: SharePointNodeType.LibraryFolder;
}

export interface SharePointListFolder extends SharePointFolderBase {
    nodeType: SharePointNodeType.ListFolder;
}

export type SharePointFolder = SharePointList | SharePointLibrary | SharePointLibraryFolder | SharePointListFolder;
export type SharePointNode = SharePointSite | SharePointSubSites | SharePointContent | SharePointFolder;
