/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTSharePointFolder,
    RESTExceptionInfo,
    RESTItemRestoreStatistics,
    RESTRestoreFoldersConfig,
    RESTRestoreItemConfig,
    RESTRestoreToFoldersConfig,
    RESTRestoreToItemConfig,
    RESTSaveSharePointFoldersOptions,
    RESTSendAsMsgOptionsV6,
    RESTSharePointFolder,
    RESTSharePointSendFoldersAsMsgOptions,
    RESTVESPSearchOptions,
} from '../models';

export interface SharePointFolderApiSharePointFolderGetRequest {
    restoreSessionId: string;
    siteId: string;
    offset: number;
    limit: number;
    parentId?: string;
}

export interface SharePointFolderApiSharePointFolderGetByIdRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
}

export interface SharePointFolderApiSharePointFolderGetByIdByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    versionId: number;
}

export interface SharePointFolderApiSharePointFolderGetVersionsRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    offset: number;
    limit: number;
}

export interface SharePointFolderApiSharePointFolderRestoreFolderActionRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    body: RESTRestoreItemConfig;
}

export interface SharePointFolderApiSharePointFolderRestoreFolderActionByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    versionId: number;
    body: RESTRestoreItemConfig;
}

export interface SharePointFolderApiSharePointFolderRestoreFolderActionByVersionIdToRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    versionId: number;
    body: RESTRestoreToItemConfig;
}

export interface SharePointFolderApiSharePointFolderRestoreFolderActionToRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    body: RESTRestoreToItemConfig;
}

export interface SharePointFolderApiSharePointFolderRestoreFoldersActionRequest {
    restoreSessionId: string;
    siteId: string;
    body: RESTRestoreFoldersConfig;
}

export interface SharePointFolderApiSharePointFolderRestoreFoldersActionToRequest {
    restoreSessionId: string;
    siteId: string;
    body: RESTRestoreToFoldersConfig;
}

export interface SharePointFolderApiSharePointFolderSaveFolderActionRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
}

export interface SharePointFolderApiSharePointFolderSaveFolderActionByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    versionId: number;
}

export interface SharePointFolderApiSharePointFolderSaveFoldersActionRequest {
    restoreSessionId: string;
    siteId: string;
    body: RESTSaveSharePointFoldersOptions;
}

export interface SharePointFolderApiSharePointFolderSearchFolderByOptionsRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    offset: number;
    limit: number;
    body: RESTVESPSearchOptions;
    setId?: string;
    itemType?: SharePointFolderSearchFolderByOptionsItemTypeEnum;
}

export interface SharePointFolderApiSharePointFolderSendFolderActionRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    body: RESTSendAsMsgOptionsV6;
}

export interface SharePointFolderApiSharePointFolderSendFolderActionByVersionIdRequest {
    restoreSessionId: string;
    siteId: string;
    folderId: string;
    versionId: number;
    body: RESTSendAsMsgOptionsV6;
}

export interface SharePointFolderApiSharePointFolderSendFoldersActionRequest {
    restoreSessionId: string;
    siteId: string;
    body: RESTSharePointSendFoldersAsMsgOptions;
}

/**
 * no description
 */
export const sharePointFolderApi = ({

    /**
     * Returns a collection of backed-up SharePoint folders to explore and restore.
     * Get SharePoint Folders
     */
    sharePointFolderGet: ({ restoreSessionId, siteId, offset, limit, parentId }: SharePointFolderApiSharePointFolderGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderGet');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderGet');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointFolderGet');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointFolderGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }

        return requestAuthenticated<PageOfRESTSharePointFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up SharePoint folder with the specified ID.
     * Get SharePoint Folder
     */
    sharePointFolderGetById: ({ restoreSessionId, siteId, folderId }: SharePointFolderApiSharePointFolderGetByIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderGetById');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderGetById');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderGetById');

        return requestAuthenticated<RESTSharePointFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a resource representation of a specific version of a backed-up SharePoint folder with the specified ID.
     * Get Specific Version of SharePoint Folder
     */
    sharePointFolderGetByIdByVersionId: ({ restoreSessionId, siteId, folderId, versionId }: SharePointFolderApiSharePointFolderGetByIdByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderGetByIdByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderGetByIdByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderGetByIdByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointFolderGetByIdByVersionId');

        return requestAuthenticated<RESTSharePointFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/Versions/{versionId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a collection of versions of a backed-up SharePoint folder with the specified ID.  When you get SharePoint folder versions, the server returns information about previous versions of the folder. To get the latest version, use either [Get SharePoint Folder](#tag/SharePointFolder/operation/SharePointFolder_GetById) or [Get Specific Version of SharePoint Folder](#tag/SharePointFolder/operation/SharePointFolder_GetByIdByVersionId). 
     * Get All Versions of SharePoint Folder
     */
    sharePointFolderGetVersions: ({ restoreSessionId, siteId, folderId, offset, limit }: SharePointFolderApiSharePointFolderGetVersionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderGetVersions');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderGetVersions');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderGetVersions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointFolderGetVersions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointFolderGetVersions');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTSharePointFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/Versions'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint folder with the specified ID to the original location.
     * Restore SharePoint Folder to Original Location
     */
    sharePointFolderRestoreFolderAction: ({ restoreSessionId, siteId, folderId, body }: SharePointFolderApiSharePointFolderRestoreFolderActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderRestoreFolderAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderRestoreFolderAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderRestoreFolderAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderRestoreFolderAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up SharePoint folder to the original location.
     * Restore Version of SharePoint Folder to Original Location
     */
    sharePointFolderRestoreFolderActionByVersionId: ({ restoreSessionId, siteId, folderId, versionId, body }: SharePointFolderApiSharePointFolderRestoreFolderActionByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderRestoreFolderActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/Versions/{versionId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up SharePoint folder to another location.
     * Restore Version of SharePoint Folder to Another Location
     */
    sharePointFolderRestoreFolderActionByVersionIdTo: ({ restoreSessionId, siteId, folderId, versionId, body }: SharePointFolderApiSharePointFolderRestoreFolderActionByVersionIdToRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderRestoreFolderActionByVersionIdTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderRestoreFolderActionByVersionIdTo');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderRestoreFolderActionByVersionIdTo');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointFolderRestoreFolderActionByVersionIdTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderRestoreFolderActionByVersionIdTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/Versions/{versionId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint folder with the specified ID to another location.
     * Restore SharePoint Folder to Another Location
     */
    sharePointFolderRestoreFolderActionTo: ({ restoreSessionId, siteId, folderId, body }: SharePointFolderApiSharePointFolderRestoreFolderActionToRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderRestoreFolderActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderRestoreFolderActionTo');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderRestoreFolderActionTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderRestoreFolderActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up SharePoint folders to the original location.
     * Restore SharePoint Folders to Original Location
     */
    sharePointFolderRestoreFoldersAction: ({ restoreSessionId, siteId, body }: SharePointFolderApiSharePointFolderRestoreFoldersActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderRestoreFoldersAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderRestoreFoldersAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderRestoreFoldersAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up SharePoint folders to another location.
     * Restore SharePoint Folders to Another Location
     */
    sharePointFolderRestoreFoldersActionTo: ({ restoreSessionId, siteId, body }: SharePointFolderApiSharePointFolderRestoreFoldersActionToRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderRestoreFoldersActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderRestoreFoldersActionTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderRestoreFoldersActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Save a backed-up SharePoint library folder with the specified ID.  SharePoint library folders are always saved in a ZIP archive. When you save a backed-up SharePoint library folder, the request command archives the library folder and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save SharePoint Folder
     */
    sharePointFolderSaveFolderAction: ({ restoreSessionId, siteId, folderId }: SharePointFolderApiSharePointFolderSaveFolderActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderSaveFolderAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderSaveFolderAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderSaveFolderAction');

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
        }, option);
    },

    /**
     * Saves a specific version of a backed-up SharePoint library folder with the specified ID.  SharePoint library folders are always saved in a ZIP archive. When you save a backed-up SharePoint library folder, the request command archives the library folder and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save Version of SharePoint Folder
     */
    sharePointFolderSaveFolderActionByVersionId: ({ restoreSessionId, siteId, folderId, versionId }: SharePointFolderApiSharePointFolderSaveFolderActionByVersionIdRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderSaveFolderActionByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderSaveFolderActionByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderSaveFolderActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointFolderSaveFolderActionByVersionId');

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/Versions/{versionId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
        }, option);
    },

    /**
     * Saves backed-up SharePoint library folders.  SharePoint library folders are always saved in a ZIP archive. When you save library folders, the request command archives the library folders and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages.  If downloading of the octet-stream was interrupted for some reason, you can send the request again and download the file from the temporary folder. 
     * Save SharePoint Folders
     */
    sharePointFolderSaveFoldersAction: ({ restoreSessionId, siteId, body }: SharePointFolderApiSharePointFolderSaveFoldersActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderSaveFoldersAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderSaveFoldersAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderSaveFoldersAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Searches for SharePoint documents in a backed-up SharePoint folder with the specified ID.
     * Search for SharePoint Documents in SharePoint Folder
     */
    sharePointFolderSearchFolderByOptions: ({ restoreSessionId, siteId, folderId, offset, limit, body, setId, itemType }: SharePointFolderApiSharePointFolderSearchFolderByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderSearchFolderByOptions');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderSearchFolderByOptions');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderSearchFolderByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointFolderSearchFolderByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointFolderSearchFolderByOptions');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderSearchFolderByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

    /**
     * Sends a backed-up SharePoint library folder with the specified ID as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft SharePoint email settings. For more information, see [Edit Email Notification Settings](#tag/VespEmailSettings/operation/VespEmailSettings_Update). </div> 
     * Send SharePoint Folder
     */
    sharePointFolderSendFolderAction: ({ restoreSessionId, siteId, folderId, body }: SharePointFolderApiSharePointFolderSendFolderActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderSendFolderAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderSendFolderAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderSendFolderAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderSendFolderAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Sends a specific version of a backed-up SharePoint folder as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft SharePoint email settings. For more information, see [Edit Email Notification Settings](#tag/VespEmailSettings/operation/VespEmailSettings_Update). </div> 
     * Send Version of SharePoint Folder
     */
    sharePointFolderSendFolderActionByVersionId: ({ restoreSessionId, siteId, folderId, versionId, body }: SharePointFolderApiSharePointFolderSendFolderActionByVersionIdRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'sharePointFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'sharePointFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderSendFolderActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/{folderId}/Versions/{versionId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Sends backed-up SharePoint library folders as attachments in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft SharePoint email settings. For more information, see [Edit Email Notification Settings](#tag/VespEmailSettings/operation/VespEmailSettings_Update). </div> 
     * Send SharePoint Folders
     */
    sharePointFolderSendFoldersAction: ({ restoreSessionId, siteId, body }: SharePointFolderApiSharePointFolderSendFoldersActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointFolderSendFoldersAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointFolderSendFoldersAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointFolderSendFoldersAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Folders/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum SharePointFolderSearchFolderByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Items = 'Items'
}
