// Copyright © Veeam Software Group GmbH

import React from 'react';
import { CONTROL_SIZE, FormLayout, MultilineTextField } from '@veeam/components';

import type { Form } from '@veeam/core';
import type { FC } from 'react';
import type { WizardStepConfig } from 'components/wizard';
import type { Fieldset } from 'infrastructure/types';
import type { Resources } from 'infrastructure/resources';

import { PageSection } from 'components/wizard';

export interface FormWithReason {
    reason: string;
}

type StepResources = Resources['features']['Restore']['Wizards']['Shared']['ReasonStep'];

const ReasonStepForm: FC<{
    form: Form<FormWithReason>;
    resources: StepResources;
}> = ({ form, resources }) => (
    <PageSection title={resources.title} description={resources.description}>
        <FormLayout>
            <MultilineTextField
                label={resources.label}
                field={form.fields.reason}
                widthSize={CONTROL_SIZE.full}
                rows={16}
            />
        </FormLayout>
    </PageSection>
);

export interface ReasonStepProps {
    form: Form<FormWithReason>;

}

export const useReasonStep = (form: Form<FormWithReason>, resources: StepResources): WizardStepConfig => ({
    tabTitle: resources.title,
    tag: 'reason',
    body: <ReasonStepForm form={form} resources={resources} />,
});

export const getReasonSummary = (value: FormWithReason, resources: StepResources): Fieldset => ({
    title: resources.title,
    fields: [
        { label: resources.title, value: value.reason },
    ],
});
