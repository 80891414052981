import { Subject } from "rxjs";
import "../models";

export const oneDriveSessionEventsHub = {
    onUnavailableDataFound: new Subject<unknown>(),
    };

function onConnect(connection: SignalR.Hub.Connection): void {
    const proxy = connection.createHubProxy('OneDriveSessionEventsHub');
    proxy.on('onUnavailableDataFound', (req) => oneDriveSessionEventsHub.onUnavailableDataFound.next(req));
}

export const oneDriveSessionEventsHubConnector = { onConnect };
