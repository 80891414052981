// Copyright © Veeam Software Group GmbH

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    LinkButton,
    NotificationMessage,
    useStateValue,
} from '@veeam/components';

import type {
    AppBarNotificationMessage,
    AppBarNotificationProps } from '@veeam/components';
import type { LocalizableString, Resources } from 'infrastructure/resources';

import { useResources } from 'infrastructure/resources';
import { useRestoreSessionRouting } from 'features/Routing';
import { restoreSessionStatusIconMap } from 'features/Shared/mappers';
import { never } from 'infrastructure/never';
import { notificationsStorage } from 'infrastructure/storage';
import { restoreService, useRestoreSessionTitle, RestoreSessionStatus } from 'services/restoreService';

type NotificationResources = Resources['features']['Main']['notification'];

function getText(name: string, status: RestoreSessionStatus, resources: NotificationResources): LocalizableString {
    switch (status) {
        case RestoreSessionStatus.Running:
            return resources.runningMessageXSessionName.format(name);
        case RestoreSessionStatus.Success:
            return resources.successMessageXSessionName.format(name);
        case RestoreSessionStatus.Warning:
            return resources.warningMessageXSessionName.format(name);
        case RestoreSessionStatus.Failed:
            return resources.failedMessageXSessionName.format(name);
        case RestoreSessionStatus.Stopping:
            return resources.stoppingMessageXSessionName.format(name);
        default:
            return never(status);
    }
}

export function useNotification(): AppBarNotificationProps {
    const resources = useResources().features.Main.notification;
    const titleMapper = useRestoreSessionTitle();

    const messagesHolder = useRef<AppBarNotificationMessage[]>([]);
    const [messages, setMessages] = useState<AppBarNotificationMessage[]>([]);
    const { goTo } = useRestoreSessionRouting();
    const [notificationState, setNotificationState] = useState({
        shownState: false,
        unread: false,
    });
    const { getValue: getAutoDisplay, setValue: setAutoDisplay } = useStateValue(
        notificationsStorage.get()?.autoDisplayMessages ?? false
    );

    const excludeMessage = (id: string): AppBarNotificationMessage[] =>
        messagesHolder.current.filter(message => message.id !== id);
    function updateMessages(newMessages: AppBarNotificationMessage[]): void {
        messagesHolder.current = newMessages;
        setMessages(newMessages);
    }
    const addMessage = (id: string, message: AppBarNotificationMessage): void =>
        updateMessages([message, ...excludeMessage(id)]);
    const closeMessage = (id: string): void => updateMessages(excludeMessage(id));

    useEffect(() => {
        const subscription = restoreService.sessions.onSessionChanged.subscribe((session) => {
            const name = titleMapper(session);
            const message: AppBarNotificationMessage = {
                id: session.id,
                render: () => (
                    <NotificationMessage
                        title={name}
                        titleIcon={restoreSessionStatusIconMap[session.status]}
                        onCloseClick={() => closeMessage(session.id)}
                        render={() => (
                            <LinkButton onClick={() => goTo(session.id)}>
                                {getText(name, session.status, resources)}
                            </LinkButton>
                        )}
                    />
                ),
            };

            addMessage(session.id, message);

            const isAutoDisplayMessage = getAutoDisplay();

            if (isAutoDisplayMessage) {
                setNotificationState(() => ({
                    shownState: true,
                    unread: false,
                }));
            } else {
                setNotificationState(prevValue => ({
                    shownState: prevValue.shownState,
                    unread: !prevValue.shownState,
                }));
            }
        });

        return () => subscription.unsubscribe();
    }, []);

    const onAutoDisplayChange = useCallback((autoDisplay: boolean): void => {
        setAutoDisplay(autoDisplay);
        notificationsStorage.save({ autoDisplayMessages: autoDisplay });
    }, []);

    const onDidShow = useCallback((): void => {
        setNotificationState(() => ({
            shownState: true,
            unread: false,
        }));
    }, []);

    const onDidHide = useCallback((): void => {
        setNotificationState(prevValue => ({
            shownState: false,
            unread: prevValue.unread,
        }));
    }, []);

    return {
        shownState: notificationState.shownState,
        unread: notificationState.unread,
        messages,
        isAutoDisplay: getAutoDisplay(),
        onAutoDisplayChange,
        onDidShow,
        onDidHide,
    };
}
