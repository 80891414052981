// Copyright © Veeam Software Group GmbH

import type {
    ExchangeItem,
    Item,
    SharePointDocument,
    SharePointListItem,
    OneDriveDocument,
    OneDriveFolderItem,
    SharePointListFolderItem,
    SharePointFolderItem,
} from 'services';

import { OneDriveItemType, Product, SharePointItemType } from 'services';
import { never } from 'infrastructure/never';
import { TeamsItemType } from '../models/Teams';

import type {
    TeamsChannelItem,
    TeamsFileItem,
    TeamsFilesItem,
    TeamsPostItem,
    TeamsPostsItem,
    TeamsTabItem,
    TeamsTabsItem,
    TeamsTeamItem,
    TeamsFolderItem,
} from '../models/Teams';

export interface RestorableGroups {
    canRestore: boolean;
    isMultiple: boolean;
    exchange: {
        items: ExchangeItem[];
    };
    sharePoint: {
        listItems: SharePointListItem[];
        docs: SharePointDocument[];
        listFolderItems: SharePointListFolderItem[];
        sharePointFolders: SharePointFolderItem[];
    };
    oneDrive: {
        oneDriveDocuments: OneDriveDocument[];
        oneDriveFolders: OneDriveFolderItem[];
    };
    teams: {
        files: TeamsFileItem[];
        folders: TeamsFolderItem[];
        filesFolder: TeamsFilesItem[];
        tabs: TeamsTabItem[];
        tabsFolder: TeamsTabsItem[];
        posts: TeamsPostItem[];
        postsFolder: TeamsPostsItem[];
        channels: TeamsChannelItem[];
        teams: TeamsTeamItem[];
    };
}

export const groupByRestoreType = (items: Item[]): RestorableGroups => {
    const exchangeItems: ExchangeItem[] = [];

    const listItems: SharePointListItem[] = [];
    const listFolderItems: SharePointListFolderItem[] = [];
    const docs: SharePointDocument[] = [];
    const sharePointFolders: SharePointFolderItem[] = [];

    const oneDriveDocuments: OneDriveDocument[] = [];
    const oneDriveFolders: OneDriveFolderItem[] = [];

    const files: TeamsFileItem[] = [];
    const folders: TeamsFolderItem[] = [];
    const teams: TeamsTeamItem[] = [];
    const tabs: TeamsTabItem[] = [];
    const channels: TeamsChannelItem[] = [];
    const posts: TeamsPostItem[] = [];
    const filesFolder: TeamsFilesItem[] = [];
    const tabsFolder: TeamsTabsItem[] = [];
    const postsFolder: TeamsPostsItem[] = [];

    items.forEach((item) => {
        switch (item.product) {
            case Product.Exchange: exchangeItems.push(item); break;
            case Product.OneDrive:
                switch (item.itemType) {
                    case OneDriveItemType.Document: oneDriveDocuments.push(item); break;
                    case OneDriveItemType.Folder: oneDriveFolders.push(item); break;
                }
                break;
            case Product.SharePoint:
                switch (item.itemType) {
                    case SharePointItemType.ListFolderItem: listFolderItems.push(item); break;
                    case SharePointItemType.Folder: sharePointFolders.push(item); break;
                    case SharePointItemType.ListItem: listItems.push(item); break;
                    case SharePointItemType.Document: docs.push(item); break;
                    default: never(item);
                }
                break;
            case Product.Teams:
                switch (item.itemType) {
                    case TeamsItemType.File: files.push(item); break;
                    case TeamsItemType.Tab: tabs.push(item); break;
                    case TeamsItemType.Channel: channels.push(item); break;
                    case TeamsItemType.Post: {
                        if (!item.isDeleted) {
                            posts.push(item);
                        }
                    } break;
                    case TeamsItemType.Files: filesFolder.push(item); break;
                    case TeamsItemType.Tabs: tabsFolder.push(item); break;
                    case TeamsItemType.Posts: postsFolder.push(item); break;
                    case TeamsItemType.Team: teams.push(item); break;
                    case TeamsItemType.Folder: folders.push(item); break;
                }
                break;
            default: never(item);
        }
    });

    const groupsWithItems = [
        exchangeItems,
        listItems,
        docs,
        oneDriveDocuments,
        oneDriveFolders,
        files,
        folders,
        tabs,
        channels,
        posts,
        filesFolder,
        tabsFolder,
        postsFolder,
        teams,
        listFolderItems,
        sharePointFolders,
    ].filter(group => group.length > 0);

    return {
        canRestore: groupsWithItems.length > 0,
        isMultiple: groupsWithItems.length > 1,

        exchange: {
            items: exchangeItems,
        },
        oneDrive: {
            oneDriveDocuments,
            oneDriveFolders,
        },
        sharePoint: {
            listItems,
            docs,
            listFolderItems,
            sharePointFolders,
        },
        teams: {
            files,
            tabs,
            channels,
            posts,
            filesFolder,
            tabsFolder,
            postsFolder,
            teams,
            folders,
        },
    };
};
