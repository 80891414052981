// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTBackupRepositoryFromClient
 */
export interface RESTBackupRepositoryFromClient {
    /**
     * Specifies the object storage ID.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    objectStorageId?: string;
    /**
     * Specifies a path to the directory of the backup repository on a backup proxy server.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    objectStorageCachePath?: string;
    /**
     * Defines whether the object storage encryption is enabled.
     * @type {boolean}
     * @memberof RESTBackupRepositoryFromClient
     */
    objectStorageEncryptionEnabled?: boolean;
    /**
     * Specifies the encryption key ID.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    encryptionKeyId?: string;
    /**
     * Specifies the backup repository ID.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    id?: string;
    /**
     * Specifies the backup repository name.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    name?: string;
    /**
     * Specifies the backup repository description.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    description?: string;
    /**
     * Specifies the full path to the folder where the backups will be stored. If the target folder does not exist, Veeam Backup for Microsoft 365 creates it.  **Note**: This property is not required when extending a backup repository with an object storage.  
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    path?: string;
    /**
     * Specifies the retention period type.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    retentionPeriodType?: RESTBackupRepositoryFromClientRetentionPeriodTypeEnum;
    /**
     * Specifies the retention period in days. This property is valid if `retentionPeriodType` is set to *Daily*.
     * @type {number}
     * @memberof RESTBackupRepositoryFromClient
     */
    dailyRetentionPeriod?: number;
    /**
     * Specifies the retention period in months. This property is valid if `retentionPeriodType` is set to *Monthly*.
     * @type {number}
     * @memberof RESTBackupRepositoryFromClient
     */
    monthlyRetentionPeriod?: number;
    /**
     * Specifies the retention period in years. This property is valid if `retentionPeriodType` is set to *Yearly*. If set to *Keep*, the backup job will back up all selected items and will never remove them.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    yearlyRetentionPeriod?: RESTBackupRepositoryFromClientYearlyRetentionPeriodEnum;
    /**
     * Specifies the clean-up schedule type. The following types are available: <ul> <li>*Daily*. Veeam Backup for Microsoft 365 checks and removes the outdated backups once a day. Use the `dailyTime` property to set the time of the day for performing clean-up. Use the `dailyType` property to set the days for performing clean-up.</li> <li>*Monthly*. Veeam Backup for Microsoft 365 checks and removes the outdated backups once a month. Use the `monthlyTime` property to set the time of the day for performing clean-up. Use the `monthlyDaynumber` and `monthlyDayofweek` properties to set the day for performing clean-up.</li> </ul> 
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    retentionFrequencyType?: RESTBackupRepositoryFromClientRetentionFrequencyTypeEnum;
    /**
     * Specifies the type of the retention policy.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    retentionType: RESTBackupRepositoryFromClientRetentionTypeEnum;
    /**
     * For *Daily* clean-up schedule. Specifies the time of the day when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    dailyTime?: string;
    /**
     * For *Daily* clean-up schedule. Specifies the days when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    dailyType?: RESTBackupRepositoryFromClientDailyTypeEnum;
    /**
     * For *Monthly* clean-up schedule. Specifies the time of the day when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    monthlyTime?: string;
    /**
     * For *Monthly* clean-up schedule. Specifies the order number for the day of the week when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    monthlyDaynumber?: RESTBackupRepositoryFromClientMonthlyDaynumberEnum;
    /**
     * For *Monthly* clean-up schedule. Specifies the day of the week when clean-up must be performed.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    monthlyDayofweek?: RESTBackupRepositoryFromClientMonthlyDayofweekEnum;
    /**
     * Specifies the backup proxy ID. Veeam Backup for Microsoft 365 will add a backup repository to this proxy server.
     * @type {string}
     * @memberof RESTBackupRepositoryFromClient
     */
    proxyId?: string;
    /**
     * Defines whether to add a backup repository that was associated with a different backup proxy. If set to *true*, indicates that Veeam Backup for Microsoft 365 will assign a backup repository with backups to the backup proxy with the specified ID and add it to the backup infrastructure. 
     * @type {boolean}
     * @memberof RESTBackupRepositoryFromClient
     */
    attachUsedRepository?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryFromClientRetentionPeriodTypeEnum {
    Monthly = 'Monthly',
    Daily = 'Daily',
    Yearly = 'Yearly'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryFromClientYearlyRetentionPeriodEnum {
    Year1 = 'Year1',
    Years2 = 'Years2',
    Years3 = 'Years3',
    Years5 = 'Years5',
    Years7 = 'Years7',
    Years10 = 'Years10',
    Years25 = 'Years25',
    Keep = 'Keep'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryFromClientRetentionFrequencyTypeEnum {
    Daily = 'Daily',
    Monthly = 'Monthly'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryFromClientRetentionTypeEnum {
    ItemLevel = 'ItemLevel',
    SnapshotBased = 'SnapshotBased'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryFromClientDailyTypeEnum {
    Everyday = 'Everyday',
    Workdays = 'Workdays',
    Weekends = 'Weekends',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryFromClientMonthlyDaynumberEnum {
    First = 'First',
    Second = 'Second',
    Third = 'Third',
    Fourth = 'Fourth',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTBackupRepositoryFromClientMonthlyDayofweekEnum {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

