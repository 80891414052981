// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTInternetProxySettings,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTProxy
 */
export interface RESTProxy {
    /**
     * Type of the backup proxy server.
     * @type {string}
     * @memberof RESTProxy
     */
    type?: RESTProxyTypeEnum;
    /**
     * Defines whether Veeam Backup for Microsoft 365 will use an internet proxy server to process backup and backup copy jobs.
     * @type {boolean}
     * @memberof RESTProxy
     */
    useInternetProxy?: boolean;
    /**
     * Type of the internet proxy server.
     * @type {string}
     * @memberof RESTProxy
     */
    internetProxyType?: RESTProxyInternetProxyTypeEnum;
    /**
     * @type {RESTInternetProxySettings}
     * @memberof RESTProxy
     */
    internetProxySettings?: RESTInternetProxySettings;
    /**
     * Backup proxy server ID.
     * @type {string}
     * @memberof RESTProxy
     */
    id?: string;
    /**
     * DNS name or IP address of the backup proxy server.
     * @type {string}
     * @memberof RESTProxy
     */
    hostName?: string;
    /**
     * Fully qualified domain name of the backup proxy server.
     * @type {string}
     * @memberof RESTProxy
     */
    fqdn?: string;
    /**
     * Description of the backup proxy server.
     * @type {string}
     * @memberof RESTProxy
     */
    description?: string;
    /**
     * Port number to connect to the backup proxy server.
     * @type {number}
     * @memberof RESTProxy
     */
    port?: number;
    /**
     * Number of threads that the backup proxy server can process.
     * @type {number}
     * @memberof RESTProxy
     */
    threadsNumber?: number;
    /**
     * Defines whether Veeam Backup for Microsoft 365 limits the network bandwidth for performance optimization.
     * @type {boolean}
     * @memberof RESTProxy
     */
    enableNetworkThrottling?: boolean;
    /**
     * Value of the network bandwidth limit.
     * @type {number}
     * @memberof RESTProxy
     */
    throttlingValue?: number;
    /**
     * Measuring unit for the network bandwidth limit.
     * @type {string}
     * @memberof RESTProxy
     */
    throttlingUnit?: RESTProxyThrottlingUnitEnum;
    /**
     * Status of the backup proxy server.
     * @type {string}
     * @memberof RESTProxy
     */
    status?: RESTProxyStatusEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTProxy
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTProxyTypeEnum {
    Local = 'Local',
    Domain = 'Domain',
    Workgroup = 'Workgroup'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTProxyInternetProxyTypeEnum {
    FromManagementServer = 'FromManagementServer',
    Custom = 'Custom'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTProxyThrottlingUnitEnum {
    Mbps = 'Mbps',
    Mbs = 'MBs',
    Kbs = 'KBs'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTProxyStatusEnum {
    Online = 'Online',
    Offline = 'Offline'
}

