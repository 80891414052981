// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';

import type { Observable } from 'rxjs';
import type { Override1 } from 'infrastructure/types';
import type { GetItemsRequest } from 'services/explore/interfaces/get-items-request';
import type { SharePointItem, SharePointNode } from 'services/models';

import { Batch } from 'infrastructure/rxjs';
import { loadSharePointItems } from './loadSharePointItems';
import { searchSharePointItems } from './searchSharePointItems';
import { exploreSessionService } from 'services/exploreSessions';


export type GetSharePointItemsRequest = Override1<GetItemsRequest, 'parent', SharePointNode>;

export const getSharePointItems = ({ parent, config, filter: { search } }: GetSharePointItemsRequest): Observable<Batch<SharePointItem>> => {
    const { vespSession } = exploreSessionService.getSessions();
    if (!vespSession) {
        console.error('');
        return of(Batch.empty());
    }
    return search === ''
        ? loadSharePointItems(vespSession, parent, config)
        : searchSharePointItems(vespSession, parent, search, config);

};
