// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTOneDrive,
} from './';

/**
 * @export
 * @interface RESTBulkRestoreConfig
 */
export interface RESTBulkRestoreConfig {
    /**
     * Specifies the action that will be performed in case the restore destination contains the restored documents.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    documentAction?: RESTBulkRestoreConfigDocumentActionEnum;
    /**
     * Defines whether the unresolved items will be skipped.
     * @type {boolean}
     * @memberof RESTBulkRestoreConfig
     */
    skipUnresolved?: boolean;
    /**
     * Specifies OneDrives that you want to restore.
     * @type {Array<RESTOneDrive>}
     * @memberof RESTBulkRestoreConfig
     */
    oneDrives?: Array<RESTOneDrive>;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    officeUsername?: string;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    userCode?: string;
    /**
     * Specifies the ID of the Azure AD application that you want to use for restore.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    applicationId?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Azure AD application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    applicationCertificate?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    officeUserpassword?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the on-premises organization.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    onpremisesUsername?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTBulkRestoreConfig
     */
    onpremisesUserpassword?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTBulkRestoreConfigDocumentActionEnum {
    Overwrite = 'Overwrite',
    Keep = 'Keep'
}

