/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTChannelEntity,
    PageOfRESTTeamsChannelPreview,
    PageOfRESTTeamsNode,
    RESTExceptionInfo,
    RESTOperatorRestoreChannelOptions,
    RESTOperatorStartExportTaskResponse,
    RESTRestoreChannelOptions,
    RESTRestoreStatistics,
    RESTTeamsChannel,
    RESTTeamsOperatorRestoreSessionResponse,
    RESTTeamsSearchOptions,
} from '../models';

export interface TeamsChannelApiTeamsChannelDownloadExportedPostsRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    requestExportId: string;
    authCode: string;
}

export interface TeamsChannelApiTeamsChannelGetRequest {
    restoreSessionId: string;
    teamId: string;
    offset: number;
    limit: number;
    displayName?: string;
}

export interface TeamsChannelApiTeamsChannelGetByChannelIdRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
}

export interface TeamsChannelApiTeamsChannelGetChildrenPageRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    offset: number;
    limit: number;
}

export interface TeamsChannelApiTeamsChannelOperatorRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    body: RESTOperatorRestoreChannelOptions;
}

export interface TeamsChannelApiTeamsChannelOperatorStartExportTaskRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
}

export interface TeamsChannelApiTeamsChannelRestoreRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    body: RESTRestoreChannelOptions;
}

export interface TeamsChannelApiTeamsChannelSearchRequest {
    restoreSessionId: string;
    teamId: string;
    channelId: string;
    offset: number;
    limit: number;
    body: RESTTeamsSearchOptions;
}

/**
 * no description
 */
export const teamsChannelApi = ({

    /**
     * Returns a file in the HTML format which contains exported posts of a backed-up channel with the specified ID.
     * Get Exported Posts by Restore Operator
     */
    teamsChannelDownloadExportedPosts: ({ restoreSessionId, teamId, channelId, requestExportId, authCode }: TeamsChannelApiTeamsChannelDownloadExportedPostsRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelDownloadExportedPosts');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelDownloadExportedPosts');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsChannelDownloadExportedPosts');
        throwIfNullOrUndefined(requestExportId, 'requestExportId', 'teamsChannelDownloadExportedPosts');
        throwIfNullOrUndefined(authCode, 'authCode', 'teamsChannelDownloadExportedPosts');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'authCode': authCode,
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/operatorExport/{requestExportId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)).replace('{requestExportId}', encodeURI(requestExportId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a collection of channels of a Microsoft Teams team with the specified ID to explore and restore.
     * Get Channels
     */
    teamsChannelGet: ({ restoreSessionId, teamId, offset, limit, displayName }: TeamsChannelApiTeamsChannelGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsChannelPreview>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelGet');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelGet');
        throwIfNullOrUndefined(offset, 'offset', 'teamsChannelGet');
        throwIfNullOrUndefined(limit, 'limit', 'teamsChannelGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (displayName != null) { query['displayName'] = displayName; }

        return requestAuthenticated<PageOfRESTTeamsChannelPreview>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a channel with the specified ID.
     * Get Channel
     */
    teamsChannelGetByChannelId: ({ restoreSessionId, teamId, channelId }: TeamsChannelApiTeamsChannelGetByChannelIdRequest, option?: RequestOption): Observable<Optional<RESTTeamsChannel>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelGetByChannelId');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelGetByChannelId');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsChannelGetByChannelId');

        return requestAuthenticated<RESTTeamsChannel>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a collection of tabs, posts and files contained in a channel with the specified ID.
     * Get Teams Items of Channel
     */
    teamsChannelGetChildrenPage: ({ restoreSessionId, teamId, channelId, offset, limit }: TeamsChannelApiTeamsChannelGetChildrenPageRequest, option?: RequestOption): Observable<Optional<PageOfRESTTeamsNode>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelGetChildrenPage');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelGetChildrenPage');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsChannelGetChildrenPage');
        throwIfNullOrUndefined(offset, 'offset', 'teamsChannelGetChildrenPage');
        throwIfNullOrUndefined(limit, 'limit', 'teamsChannelGetChildrenPage');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTTeamsNode>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/children'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Restores a backed-up Microsoft Teams channel using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=70) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong>  </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div>   
     * Restore Channel by Restore Operator
     */
    teamsChannelOperatorRestore: ({ restoreSessionId, teamId, channelId, body }: TeamsChannelApiTeamsChannelOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTTeamsOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelOperatorRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelOperatorRestore');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsChannelOperatorRestore');
        throwIfNullOrUndefined(body, 'body', 'teamsChannelOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTTeamsOperatorRestoreSessionResponse>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Creates a task to export posts of a backed-up channel with the specified ID and returns the task ID.
     * Get Export Task ID
     */
    teamsChannelOperatorStartExportTask: ({ restoreSessionId, teamId, channelId }: TeamsChannelApiTeamsChannelOperatorStartExportTaskRequest, option?: RequestOption): Observable<Optional<RESTOperatorStartExportTaskResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelOperatorStartExportTask');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelOperatorStartExportTask');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsChannelOperatorStartExportTask');

        return requestAuthenticated<RESTOperatorStartExportTaskResponse>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/startOperatorExportPosts'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'POST',
        }, option);
    },

    /**
     * Restores a channel with the specified ID.
     * Restore Channel
     */
    teamsChannelRestore: ({ restoreSessionId, teamId, channelId, body }: TeamsChannelApiTeamsChannelRestoreRequest, option?: RequestOption): Observable<Optional<RESTRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelRestore');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelRestore');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsChannelRestore');
        throwIfNullOrUndefined(body, 'body', 'teamsChannelRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Searches for Microsoft Teams items of a backed-up channel with the specified ID.
     * Search for Teams Items in Channel
     */
    teamsChannelSearch: ({ restoreSessionId, teamId, channelId, offset, limit, body }: TeamsChannelApiTeamsChannelSearchRequest, option?: RequestOption): Observable<Optional<PageOfRESTChannelEntity>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'teamsChannelSearch');
        throwIfNullOrUndefined(teamId, 'teamId', 'teamsChannelSearch');
        throwIfNullOrUndefined(channelId, 'channelId', 'teamsChannelSearch');
        throwIfNullOrUndefined(offset, 'offset', 'teamsChannelSearch');
        throwIfNullOrUndefined(limit, 'limit', 'teamsChannelSearch');
        throwIfNullOrUndefined(body, 'body', 'teamsChannelSearch');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTChannelEntity>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/teams/{teamId}/channels/{channelId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{teamId}', encodeURI(teamId)).replace('{channelId}', encodeURI(channelId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

})
