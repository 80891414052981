// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTCredentialToSend
 */
export interface RESTCredentialToSend {
    /**
     * Specifies a cloud account type.
     * @type {string}
     * @memberof RESTCredentialToSend
     */
    accountType?: RESTCredentialToSendAccountTypeEnum;
    /**
     * Specifies a cloud account user name. Must contain only lowercase characters.
     * @type {string}
     * @memberof RESTCredentialToSend
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTCredentialToSend
     */
    password?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTCredentialToSend
     */
    newPassword?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTCredentialToSend
     */
    oldPassword?: string;
    /**
     * Specifies a cloud account ID.
     * @type {string}
     * @memberof RESTCredentialToSend
     */
    id?: string;
    /**
     * Specifies a cloud account description.
     * @type {string}
     * @memberof RESTCredentialToSend
     */
    description?: string;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTCredentialToSend
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCredentialToSendAccountTypeEnum {
    AzureBlobAccount = 'azureBlobAccount',
    AmazonS3Account = 'amazonS3Account',
    AmazonS3CompatibleAccount = 'amazonS3CompatibleAccount'
}

