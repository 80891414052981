// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { Override1 } from 'infrastructure/types';
import type { VersionableItem } from 'services/models';

import { Batch, loadPages } from 'infrastructure/rxjs';
import { convertFileItemFromRest, convertFolderItemFromRest } from './converters';
import { exploreSessionService } from 'services/exploreSessions';
import { teamsApi } from './wrapped-teams-api';
import { findIdValueInUrl } from './find-id-value-in-url';

import type { TeamsFileItem } from '../../../models/Teams';
import type { GetItemVersionsRequest } from '../../interfaces/get-item-versions-request';
import { RESTTeamsFileFileTypeEnum } from '../../../../api/rxjs';

export type GetTeamsVersionsRequest = Override1<GetItemVersionsRequest, 'parent', TeamsFileItem>;

export const getTeamsVersions = ({ parent, config }: GetTeamsVersionsRequest): Observable<Batch<VersionableItem>> => {
    const { vetSession } = exploreSessionService.getSessions();

    if (!vetSession) {
        console.error('');
        return of(Batch.empty());
    }

    return loadPages(teamsApi.getFileVersions, config)({
        restoreSessionId: vetSession,
        fileId: parent.id,
        teamId: findIdValueInUrl(parent._links['self'].href, 'teams'),
    }).pipe(
        map(files => files.mapData(
            (file) => {
                if (file.fileType === RESTTeamsFileFileTypeEnum.Folder) {
                    return convertFolderItemFromRest(file, true, parent as any);
                }

                return convertFileItemFromRest(file, true, parent as any);
            }
        )),
    );
};
