// Copyright © Veeam Software Group GmbH

import React from 'react';
import { BodyLayout } from '@veeam/components';

import type { FC } from 'react';

import { useResources } from 'infrastructure/resources';
import { useRecoveryListGrid } from './useGrid';

export const RecoveryList: FC = () => {
    const resources = useResources().features.RecoveryList;
    const { grid: restoreSessionsGrid } = useRecoveryListGrid(resources);

    return (
        <BodyLayout>
            {restoreSessionsGrid}
        </BodyLayout>
    );
};
