// Copyright © Veeam Software Group GmbH

import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from '@veeam/core';
import { delayWhen, filter, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';

import type { WizardStepConfig } from 'components/wizard';

import { Wizard, WIZARD_TYPE } from 'components/wizard';
import { useResources } from 'infrastructure/resources';
import { useItemsStep, useReasonStep } from '../Shared';
import { useSummaryStep } from './components/SummaryStep';
import { useRestoreSessionRouting } from '../../../Routing';
import { useRestoreTypeStep } from './components/RestoreTypeStep';
import { getInitialFormValue } from './helpers/get-initial-form-value';
import { getRestoreRequest } from './helpers/get-restore-request';
import { getDatabase } from '../../../../services/database';

import type { RestoreSessionId } from '../../../../services/restoreService';
import type { TeamsRestoreWizardProps } from './interfaces/teams-restore-wizard-props';

export const TeamsRestoreWizard: React.FC<TeamsRestoreWizardProps> = ({ items, close }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const initialFormValue = useMemo(() => getInitialFormValue(items), []);
    const formApi = useForm(initialFormValue);
    const resources = useResources().features.Restore.Wizards;
    const { goTo } = useRestoreSessionRouting();
    const steps: WizardStepConfig[] = [
        useItemsStep(formApi, resources.Shared.ItemsStep),
        useRestoreTypeStep(formApi, resources.TeamsRestoreWizard),
        useReasonStep(formApi, resources.Shared.ReasonStep),
        useSummaryStep(formApi, resources),
    ].filter(Boolean);

    const submit = useCallback(() => {
        const request = getRestoreRequest(formApi);

        setLoading(true);

        request
            .pipe(
                finalize(() => setLoading(false)),
                map(response => response.map(undefined)?.sessionId as RestoreSessionId),
                filter((response): response is RestoreSessionId => !!response),
                delayWhen(sessionId => of(Promise.all(items.map(async item => await(await getDatabase()).recoveryList.setSessionId(item.uniqId, sessionId)))))
            )
            .subscribe((sessionId) => {
                close();
                goTo(sessionId as RestoreSessionId);
            });
    }, []);

    return (
        <Wizard
            pageTitle={resources.TeamsRestoreWizard.microsoftTeamsRestore}
            loading={loading}
            type={WIZARD_TYPE.page}
            onRequestClose={close}
            onExitPage={close}
            onDone={submit}
            steps={steps}
            buttons={{ done: { disabled: loading } }}
        />
    );
};
