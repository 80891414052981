// Copyright © Veeam Software Group GmbH

import { useRouterActions } from '@veeam/core';

import type { RestoreSessionId } from 'services/restoreService';

import { Routes } from '..';

export interface RestoreSessionsRouting {
    goTo(sessionId?: RestoreSessionId): void;
    getQuery(): { sessionId?: RestoreSessionId; };
}

export function useRestoreSessionRouting(): RestoreSessionsRouting {
    const router = useRouterActions();
    const goTo = (sessionId?: RestoreSessionId): void => {
        if (router.isActive(Routes.restoreSessions)) return;
        router.goTo(Routes.restoreSessions, undefined, sessionId ? { sessionId } : undefined);
    };
    const getQuery = (): { sessionId?: RestoreSessionId; } => {
        const sessionId = router.getQuery().sessionId;
        if (typeof sessionId === 'string') return { sessionId: sessionId as RestoreSessionId };
        return {};
    };
    return { goTo, getQuery };
}
