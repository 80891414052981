// Copyright © Veeam Software Group GmbH

import { textCellRenderer as _textCellRenderer } from '@veeam/components';

import type { DataGridCellRenderer } from '@veeam/components';
import type { Func1 } from 'infrastructure/types';

import { noTextWrap } from './noValue';

import type { CellRender, CellRendererProps } from './CellRender';

export const textCellRender = (value: string | undefined, props: CellRendererProps): CellRender => _textCellRenderer({
    ...props,
    cellData: noTextWrap(value),
});

export const textCell = <TRowData>(
    value: Func1<TRowData, string | undefined>,
): DataGridCellRenderer<TRowData> => (row, payload) => textCellRender(value(row), payload);
