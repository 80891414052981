// Copyright © Veeam Software Group GmbH

import { useEffect, useMemo } from 'react';
import { Subject } from 'rxjs';
import first from 'lodash/first';
import uniq from 'lodash/uniq';
import { DataGridModel, SORT_DIRECTION } from '@veeam/components';

import type { Observable, Subscription } from 'rxjs';
import type { BucketItem, RestoreSessionId, RestoreSession } from 'services/restoreService';
import type { UniqueId } from 'services';

import { errorManager } from 'infrastructure/error-management';
import { createConverter, useModel, commonLoaderWithMap } from 'infrastructure/grid';
import { restoreService, BucketItemsFilterSchema, BucketItemSortKeys, BucketItemStatus } from 'services/restoreService';

export interface BucketItemSessionStatus {
    restoreSessionStatusIsLoading: boolean;
    restoreSessionStatus: BucketItemStatus;
}

export type BucketItemPresentation = BucketItem & BucketItemSessionStatus;

const idGetter = (data: BucketItemPresentation): UniqueId => data.uniqId;
export class RestoreBucketModel extends DataGridModel<BucketItemPresentation, UniqueId, BucketItemSortKeys> {
    public constructor(loader: RestoreBucketModel['loaderType']) {
        super(idGetter, loader, {});
    }
}

export type Value = RestoreBucketModel['valueType'];

export const convertFilter = createConverter(BucketItemsFilterSchema);

interface SessionStatus {
    subscription?: Subscription;
    isFetching: boolean;
    status: BucketItemStatus;
}

interface RestoreSessionsController {
    dispose(): void;
    mapBucketItems(items: BucketItem[], statusFilter: BucketItemStatus[]): BucketItemPresentation[];
    onItemStatusChanged: Observable<{ sessionId: RestoreSessionId; sessionStatus: BucketItemSessionStatus; }>;
}

class RestoreSessionsControllerImpl implements RestoreSessionsController {
    private statuses: Map<RestoreSessionId, SessionStatus>;
    private onItemStatusChangedSubj: Subject<{ sessionId: RestoreSessionId; sessionStatus: BucketItemSessionStatus; }>;
    private onSessionChangedSubscription: Subscription;
    constructor() {
        this.statuses = new Map<RestoreSessionId, SessionStatus>();
        this.onItemStatusChangedSubj = new Subject<{ sessionId: RestoreSessionId; sessionStatus: BucketItemSessionStatus; }>();
        this.onItemStatusChanged = this.onItemStatusChangedSubj;
        this.onSessionChangedSubscription = restoreService.sessions.onSessionChanged.subscribe(this.onSessionChanged);
    }

    onItemStatusChanged: Observable<{ sessionId: RestoreSessionId; sessionStatus: BucketItemSessionStatus; }>;
    private unsubscribe(status: SessionStatus) {
        if (status.subscription && !status.subscription?.closed)
            status.subscription.unsubscribe();
    }
    dispose(): void {
        Array.from(this.statuses.values())
            .forEach(status => this.unsubscribe(status));
        this.onSessionChangedSubscription.unsubscribe();
        this.statuses.clear();
    }
    private getStatus(sessionId: RestoreSessionId | undefined): BucketItemSessionStatus {
        const neverStarted = {
            restoreSessionStatusIsLoading: false,
            restoreSessionStatus: BucketItemStatus.NeverStarted,
        };
        if (!sessionId) return neverStarted;
        const sessionStatus = this.statuses.get(sessionId);
        if (!sessionStatus) {
            errorManager.register('Try to get session status but dictionary hasn\'t got status by id', { silent: true });
            return neverStarted;
        }
        return {
            restoreSessionStatusIsLoading: sessionStatus.isFetching,
            restoreSessionStatus: sessionStatus.status,
        };
    }
    private onReload(items: BucketItem[]): void {
        const sessionsToCancel = this.getSessionsToCancel(items);
        const sessionsToFetch = this.getSessionsToFetch(items);
        this.cancelSession(sessionsToCancel);
        this.fetchSessions(sessionsToFetch);
    }
    private getSessionsToCancel = (bucketItems: BucketItem[]): RestoreSessionId[] =>
        Array.from(this.statuses.keys())
            .filter(sessionId => !bucketItems.some(item => item.sessionId === sessionId));
    private getSessionsToFetch = (bucketItems: BucketItem[]): RestoreSessionId[] => uniq(
        bucketItems
            .map(item => item.sessionId)
            .filter((sessionId): sessionId is RestoreSessionId => !!sessionId && !this.statuses.has(sessionId)),
    );
    private cancelSession = (sessionsToCancel: RestoreSessionId[]): void => {
        sessionsToCancel.forEach((sessionId) => {
            const sessionStatus = this.statuses.get(sessionId);
            if (sessionStatus) {
                this.unsubscribe(sessionStatus);
                this.statuses.delete(sessionId);
            }
        });
    };
    private fetchSessions = (sessionsToFetch: RestoreSessionId[]): void => {
        sessionsToFetch.forEach((sessionId) => {
            const subscription = restoreService.sessions.getSession(sessionId).subscribe(this.onSessionChanged);
            this.statuses.set(sessionId, { isFetching: true, status: BucketItemStatus.NeverStarted, subscription });
        });
    };
    private onSessionChanged = (session: RestoreSession | undefined) => {
        if (!session) return;
        const status = this.statuses.get(session.id);
        if (status) {
            this.statuses.set(session.id, { isFetching: false, status: session.status as unknown as BucketItemStatus });
            this.onItemStatusChangedSubj.next({ sessionId: session.id, sessionStatus: this.getStatus(session.id) });
        }
    };
    mapBucketItems = (items: BucketItem[], statusFilter: BucketItemStatus[]): BucketItemPresentation[] => {
        this.onReload(items);
        const mapped = items.map(item => ({
            ...item,
            ...this.getStatus(item.sessionId),
        }));
        return statusFilter.length === 0
            ? mapped
            : mapped.filter(item => statusFilter.some(status => item.restoreSessionStatus === status));
    };
}

const useRestoreBucketLoader = (sessionsController: RestoreSessionsController): RestoreBucketModel['loaderType'] =>
    useMemo(
        () =>
            commonLoaderWithMap(
                value => ({
                    sorting: first(value.sorting),
                    filter: convertFilter(value.filters),
                }),
                restoreService.bucket.getItems,
                (data, { filter }) => sessionsController.mapBucketItems(data, filter.status),
                undefined,
                true,
            ),
        []
    );

const useOnItemStatusChanged = (sessionsController: RestoreSessionsController, model: RestoreBucketModel) => {
    useEffect(() => {
        const subscription = sessionsController.onItemStatusChanged.subscribe(({ sessionId, sessionStatus }) => {
            model.update((mutable) => {
                mutable.items.forEach((item) => {
                    if (item.sessionId === sessionId) {
                        item.restoreSessionStatus = sessionStatus.restoreSessionStatus;
                        item.restoreSessionStatusIsLoading = sessionStatus.restoreSessionStatusIsLoading;
                    }
                });
                const filter = convertFilter(mutable.filters);

                if (filter.status.length !== 0) {
                    mutable.items = mutable.items.filter(item => !item.restoreSessionStatusIsLoading || filter.status.some(status => item.restoreSessionStatus === status));
                    model.load();
                }
            });
        });
        return () => subscription.unsubscribe();
    }, []);
};

export const useRestoreBucketModel = (): RestoreBucketModel => {
    const sessionController = useMemo<RestoreSessionsController>(() => new RestoreSessionsControllerImpl(), []);
    useEffect(() => () => sessionController.dispose(), []);
    const bucketItemsLoader = useRestoreBucketLoader(sessionController);
    const model = useModel(() => new RestoreBucketModel(bucketItemsLoader).withSorting({ key: BucketItemSortKeys.Title, direction: SORT_DIRECTION.asc }));
    useOnItemStatusChanged(sessionController, model);
    return model;
};
