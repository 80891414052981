// Copyright © Veeam Software Group GmbH

import { doubleToolbar } from '@veeam/components';
import { useMemo } from 'react';

import type { CollectionToolbarConfig } from '@veeam/components';

import { useFilter } from './useFilter';
import { useRemoveAction } from './useRemoveAction';
import { useRestoreAction } from './useRestoreAction';

import type { RestoreBucketModel } from './useModel';
import type { BucketResources } from './resources';

export function useToolbar(resources: BucketResources, model: RestoreBucketModel): CollectionToolbarConfig {
    const filters = useFilter(resources);
    const removeBucketItemAction = useRemoveAction(resources, model);
    const restoreAction = useRestoreAction(resources, model);
    return useMemo(() => ({
        decorator: doubleToolbar(),
        items: [...filters, restoreAction, removeBucketItemAction],
    }), []);
}
