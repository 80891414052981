// Copyright © Veeam Software Group GmbH

import React, { useState, useRef } from 'react';
import first from 'lodash/first';
import { useForm, Model, conditions, required } from '@veeam/core';
import { WizardSummary } from '@veeam/components';
import { finalize } from 'rxjs/operators';

import type { FC } from 'react';
import type { Form } from '@veeam/core';
import type { WizardStepConfig } from 'components/wizard';
import type { Resources } from 'infrastructure/resources';
import type { Action0, Fieldset } from 'infrastructure/types';
import type { ExchangeRestoreOptions } from 'services/restoreService/restore';
import type { ExchangeItem } from 'services';

import { useResources } from 'infrastructure/resources';
import { useRestoreModeStep, RestoreMode, getRestoreModeSummary } from './RestoreModeStep';
import { useReasonStep, getReasonSummary, useItemsStep } from '../Shared';
import { useRestoreSessionRouting } from 'features/Routing';
import { RestoreOptionsPanel } from './RestoreOptionsPanel';
import { useMessageBoxManagement } from 'infrastructure/error-management';
import { RestoreWizardPanelsEnum } from 'features/Restore/Wizards/types';
import { never } from 'infrastructure/never';
import { Wizard, WIZARD_TYPE, usePanel } from 'components/wizard';
import { renderMultilineText } from 'components/renders';
import { restoreService } from 'services/restoreService';
import { ExchangeItemType } from 'services';
import { exploreSessionService } from '../../../../services/exploreSessions';

import type { FormWithRestoreMode } from './RestoreModeStep';
import type { FormWithReason } from '../Shared';
import type { FormWithRestoreOptions } from './RestoreOptionsPanel';


interface ExchangeRestoreForm extends FormWithRestoreMode, FormWithReason, FormWithRestoreOptions {
    items: ExchangeItem[];
}

const getInitialFormValue = (items: ExchangeItem[]): ExchangeRestoreForm => ({
    items,
    restoreMode: RestoreMode.Original,
    reason: '',
    folderName: '',
    restoreChangedItems: true,
    restoreMissingItems: true,
    markRestoredItems: true,
});

type WizardResources = Resources['features']['Restore']['Wizards'];

const formatExchangeItemSummary = (item: ExchangeItem, resources: WizardResources['ExchangeRestoreWizard']): string => {
    const formatSubj = (subject?: string) => (subject ? ` (${subject})` : '');
    switch (item.itemType) {
        case ExchangeItemType.Appointment:
            return resources.itemsSummary.AppointmentXtitleXsubj.format(item.title, formatSubj(item.subject));
        case ExchangeItemType.Contact:
            return resources.itemsSummary.ContactXtitle.format(item.displayAs ?? item.title);
        case ExchangeItemType.Mail:
            return resources.itemsSummary.MailXtitleXfromXto.format(item.title, item.from ?? '', item.to ?? '');
        case ExchangeItemType.StickyNote:
            return resources.itemsSummary.StickyNoteXtitleXsubj.format(item.title, formatSubj(item.subject));
        case ExchangeItemType.Task:
            return resources.itemsSummary.TaskXtitleXsubj.format(item.title, formatSubj(item.subject));
        default:
            return never(item);
    }
};

const getItemsSummary = (
    value: ExchangeRestoreForm,
    resources: WizardResources['ExchangeRestoreWizard']
): Fieldset => ({
    title: resources.itemsTitle,
    fields: [
        {
            label: resources.itemsSummary.label,
            value: renderMultilineText(
                value.items.map(item => formatExchangeItemSummary(item, resources)).join('\n')
            ),
        },
    ],
});

const Summary: FC<{
    form: Form<ExchangeRestoreForm>;
    resources: WizardResources;
}> = ({ form, resources }) => {
    const value = form.getValue();
    return (
        <WizardSummary
            fieldsets={[
                getItemsSummary(value, resources.ExchangeRestoreWizard),
                getRestoreModeSummary(value, resources.ExchangeRestoreWizard.RestoreModeStep),
                getReasonSummary(value, resources.Shared.ReasonStep),
            ]}
        />
    );
};

const getExchangeRestoreOptions = (form: ExchangeRestoreForm): ExchangeRestoreOptions => ({
    folder: form.restoreMode === RestoreMode.Original ? undefined : form.folderName,
    changedItems: form.restoreChangedItems,
    deletedItems: form.restoreMissingItems,
    markRestoredAsUnread: form.markRestoredItems,
    reason: form.reason,
});

const useExchangeRestoreForm = (
    formModel: Model<ExchangeRestoreForm>,
    resources: WizardResources
): Form<ExchangeRestoreForm> =>
    useForm(formModel, {
        validationSchema: {
            folderName: [
                conditions(
                    { restoreMode: RestoreMode.AnotherPlace },
                    required(resources.ExchangeRestoreWizard.folderNameShouldNotBeEmpty),
                ),
            ],
        },
    });

export const ExchangeRestoreWizard: FC<{
    close: Action0;
    items: ExchangeItem[];
    // eslint-disable-next-line max-lines-per-function
}> = ({ items, close }) => {
    const { vexSession } = exploreSessionService.getSessions();
    const [loading, setLoading] = useState<boolean>(false);
    const formModel = useRef<Model<ExchangeRestoreForm>>(new Model(getInitialFormValue(items))).current;
    const resources = useResources().features.Restore.Wizards;
    const formApi = useExchangeRestoreForm(formModel, resources);
    const [disabledWizardActions, setDisabledWizardActions] = useState<boolean>(false);
    const { goTo } = useRestoreSessionRouting();
    const { show } = useMessageBoxManagement();

    const [panel, panelActions, panelMeta] = usePanel({
        formConfig: formModel,
        showError: (message: string) => {
            show({ message });
        },
        setDisabledWizardActions,
        closingConfirmationDialogActions: {
            show: () => {},
            hide: () => {},
        },
        panels: {
            [RestoreWizardPanelsEnum.RestoreOptions]: RestoreOptionsPanel,
        },
    });

    const steps: WizardStepConfig[] = [
        useItemsStep(formApi, resources.Shared.ItemsStep),
        useRestoreModeStep(formApi, resources.ExchangeRestoreWizard.RestoreModeStep, panelActions),
        useReasonStep(formApi, resources.Shared.ReasonStep),
        {
            tabTitle: 'Summary',
            tag: 'summary',
            body: <Summary form={formApi} resources={resources} />,
            description: 'Summary',
        },
    ];
    function submit(): void {
        if (!vexSession) throw new Error();
        setLoading(true);
        const formValue = formApi.getValue();
        const options = getExchangeRestoreOptions(formValue);

        restoreService.restore
            .exchange(vexSession, formValue.items, options)
            .pipe(finalize(() => setLoading(false)))
            .subscribe((sessionId) => {
                close();
                goTo(first(sessionId));
            });
    }
    return (
        <Wizard
            pageTitle='Exchange Restore'
            loading={loading}
            type={WIZARD_TYPE.page}
            onRequestClose={close}
            onExitPage={close}
            onDone={submit}
            steps={steps}
            panel={panel}
            panelMinWidth={panelMeta.panelMinWidth}
            buttons={{
                previous: { disabled: disabledWizardActions },
                next: { disabled: disabledWizardActions },
                done: { disabled: loading || disabledWizardActions },
                cancel: { disabled: disabledWizardActions },
            }}
        />
    );
};
