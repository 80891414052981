// Copyright © Veeam Software Group GmbH

import React from 'react';
import { DataGrid, doubleToolbar } from '@veeam/components';

import type { Observable } from 'rxjs';
import type { ReactElement } from 'react';
import type { RestoreSessionId } from 'services/restoreService';

import { useColumns } from './useColumns';
import { useFilter } from './useFilter';
import { useEventsModel } from './useModel';
import { useSignalR } from './useEvent';
import { useDurationUpdate } from './useDurationUpdate';

import type { GridResources } from './resources';

export function useEventsGrid(resources: GridResources, session: Observable<RestoreSessionId | undefined>): ReactElement {
    const model = useEventsModel(session);
    useSignalR(model);
    useDurationUpdate(model);
    const columns = useColumns(resources);
    const filters = useFilter(resources);
    return (
        <DataGrid
            model={model}
            columns={columns}
            toolbar={{
                decorator: doubleToolbar(),
                items: [...filters],
            }}
            config={{
                canResizeColumns: true,
                columnsToggleable: true,
            }}
            texts={{
                empty: resources.emptyGridMessage,
            }}
        />
    );
}
