// Copyright © Veeam Software Group GmbH

import type { Moment } from 'moment';

import type { ItemBase } from '../ItemBase';
import type { Product } from '../Product';
import type { RESTTeamsFile, RESTTeamsNode, RESTTeamsPost, RestTeamsTab, RESTTeamsTeam } from '../../../api/rxjs';

export enum TeamsItemType {
    Team = 'Team',
    Channel = 'Channel',
    Post = 'Post',
    File = 'File',
    Folder = 'Folder',
    Tab = 'Tab',
    Posts = 'Posts',
    Files = 'Files',
    Tabs = 'Tabs',
}

export interface TeamsChannelItem extends ItemBase {
    product: Product.Teams;
    itemType: TeamsItemType.Channel;
}

export interface TeamsFileItem extends ItemBase, Omit<RESTTeamsFile, 'version'> {
    product: Product.Teams;
    itemType: TeamsItemType.File;
    isVersion: boolean;
    version?: string;
    versionId?: number;
    modificationTime: Moment;
}

export interface TeamsFolderItem extends ItemBase, Omit<RESTTeamsFile, 'version'> {
    product: Product.Teams;
    itemType: TeamsItemType.Folder;
    isVersion: boolean;
    version?: string;
    versionId?: number;
    modificationTime: Moment;
}

export interface TeamsFilesItem extends ItemBase, RESTTeamsNode {
    product: Product.Teams;
    itemType: TeamsItemType.Files;
}

export interface TeamsPostItem extends ItemBase, RESTTeamsPost {
    product: Product.Teams;
    itemType: TeamsItemType.Post;
    isDeleted?: boolean;
    id: string;
}

export interface TeamsPostsItem extends ItemBase, RESTTeamsNode {
    product: Product.Teams;
    itemType: TeamsItemType.Posts;
}

export interface TeamsTabItem extends ItemBase, RestTeamsTab {
    product: Product.Teams;
    itemType: TeamsItemType.Tab;
}

export interface TeamsTabsItem extends ItemBase, RESTTeamsNode {
    product: Product.Teams;
    itemType: TeamsItemType.Tabs;
}

export interface TeamsTeamItem extends ItemBase, RESTTeamsTeam {
    product: Product.Teams;
    itemType: TeamsItemType.Team;
}

export type TeamsItem =
    TeamsTeamItem |
    TeamsChannelItem |
    TeamsPostItem |
    TeamsFileItem |
    TeamsTabItem |
    TeamsPostsItem |
    TeamsFilesItem |
    TeamsTabsItem |
    TeamsFolderItem;
