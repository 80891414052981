// Copyright © Veeam Software Group GmbH

import { map } from 'rxjs/operators';
import { z } from 'zod';

import type { TypeOf } from 'zod';
import type { Observable } from 'rxjs';

import { restoreSessionEventApi } from 'api/rxjs';
import { createFilterEngine, filters } from 'infrastructure/client-side-operations';
import { loadPages } from 'infrastructure/rxjs';
import { RestoreSessionEventsStatus } from '../models';
import { convertEventFromRest } from './converters';

import type { RestoreSessionEvent, RestoreSessionId } from '../models';

export const RestoreSessionEventsFilterSchema = z.object({
    search: z.string().nullish()
        .transform(val => val || ''),
    status: z.array(z.nativeEnum(RestoreSessionEventsStatus)).nullish()
        .transform(val => val || []),
});

export type RestoreSessionEventsFilter = TypeOf<typeof RestoreSessionEventsFilterSchema>;

export const filterEvents = createFilterEngine<RestoreSessionEvent, RestoreSessionEventsFilter>({
    search: filters.search(session => session.message),
    status: filters.enum(session => session.status),
});

export const sortEvents = (events: RestoreSessionEvent[]): RestoreSessionEvent[] => events.sort((one, two) => one.order - two.order);

interface GetEventsRequest {
    sessionId: RestoreSessionId;
    filter: RestoreSessionEventsFilter;
}

export function getEvents({ sessionId, filter }: GetEventsRequest): Observable<RestoreSessionEvent[]> {
    return loadPages(restoreSessionEventApi.restoreSessionEventGet)({
        restoreSessionId: sessionId,
    }).pipe(
        map(events => events.data.map(event => convertEventFromRest(sessionId, event))),
        map(sessions => filterEvents(filter, sessions)),
        map(sessions => sortEvents(sessions)),
    );
}
