// Copyright © Veeam Software Group GmbH

import type { Duration } from 'moment';
import type { DataGridCellRenderer } from '@veeam/components';
import type { Func1 } from 'infrastructure/types';

import { textCellRender } from './textCell';

import type { CellRender, CellRendererProps } from './CellRender';

export const durationCellRender = (value: Duration | undefined, props: CellRendererProps): CellRender => textCellRender(
    value ? value.humanize() : undefined,
    props,
);

export const durationCell = <TRowData>(
    value: Func1<TRowData, Duration | undefined>,
): DataGridCellRenderer<TRowData> => (row, payload) => durationCellRender(value(row), payload);
