// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTGroup
 */
export interface RESTGroup {
    /**
     * Organization group ID.
     * @type {string}
     * @memberof RESTGroup
     */
    id?: string;
    /**
     * ID of the organization group in the on-premises organization.
     * @type {string}
     * @memberof RESTGroup
     */
    onPremisesSid?: string;
    /**
     * Display name of the organization group.
     * @type {string}
     * @memberof RESTGroup
     */
    displayName?: string;
    /**
     * Name of the organization group.
     * @type {string}
     * @memberof RESTGroup
     */
    name?: string;
    /**
     * User who manages the organization group.
     * @type {string}
     * @memberof RESTGroup
     */
    managedBy?: string;
    /**
     * Site of the organization group.
     * @type {string}
     * @memberof RESTGroup
     */
    site?: string;
    /**
     * Type of the organization group.
     * @type {string}
     * @memberof RESTGroup
     */
    type?: RESTGroupTypeEnum;
    /**
     * Microsoft 365 organization deployment type.
     * @type {string}
     * @memberof RESTGroup
     */
    locationType?: RESTGroupLocationTypeEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTGroup
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTGroupTypeEnum {
    Office365 = 'Office365',
    Security = 'Security',
    Distribution = 'Distribution',
    DynamicDistribution = 'DynamicDistribution'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTGroupLocationTypeEnum {
    OnPremises = 'OnPremises',
    Cloud = 'Cloud',
    Hybrid = 'Hybrid'
}

