// Copyright © Veeam Software Group GmbH

import { map } from 'rxjs/operators';
import { DataTreeModel } from '@veeam/components';

import type { Subscription } from 'infrastructure/event';
import type { Node, UniqueId } from 'services/models';

import { exploreService } from 'services/explore';
import { exploreSessionService } from 'services/exploreSessions';
import { exploreSessionStorage } from '../../../infrastructure/storage';

export interface ExploreTreeNode {
    model: Node;
    children?: ExploreTreeNode[];
    isAsync: boolean;
}

const idGetter = (node: ExploreTreeNode) => (node.model.product + node.model.uniqId) as UniqueId;
const childrenGetter = (parent: ExploreTreeNode) => parent.children;

export class ExploreTreeModel extends DataTreeModel<ExploreTreeNode, UniqueId> {
    private subscription: Subscription;
    constructor(loader: ExploreTreeModel['loaderType']) {
        super(idGetter, childrenGetter, loader, {});

        const storage = exploreSessionStorage.get();

        this.subscription = exploreSessionService.events.session.changed.subscribe(() => {
            this.update(mutable => mutable.selection = []);
            this.load();
        });

        if (storage?.vexSession || storage?.veodSession || storage?.vespSession || storage?.vetSession) {
            this.load();
        }
    }

    public destroy() {
        super.destroy();
        this.subscription.unsubscribe();
    }
}

const convertToTreeNode = (node: Node): ExploreTreeNode => ({
    model: node,
    isAsync: true,
});

const updateNode = (parent: ExploreTreeNode | undefined, children: Node[]): ExploreTreeNode[] => {
    const treeChildren = children.map(convertToTreeNode);
    return parent
        ? [{
            ...parent,
            isAsync: false,
            children: treeChildren,
        }]
        : treeChildren;
};

export const exploreLoader: ExploreTreeModel['loaderType'] = (model, asyncNode) => {
    const parent = asyncNode ? asyncNode.data : undefined;
    return exploreService.getNodes(parent?.model, { reThrowError: true }).pipe(
        map(children => ({
            data: {
                ...model,
                items: updateNode(parent, children.data),
            },
        }))
    );
};

