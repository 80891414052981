/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTDataRetrievalComposed,
    RESTDataRetrievalFromClient,
    RESTDataRetrievalMailbox,
    RESTDataRetrievalOneDrive,
    RESTDataRetrievalTeam,
    RESTDataRetrievalUpdateFromClient,
    RESTDataRetrievalWeb,
    RESTExceptionInfo,
    RESTRestoreSession,
} from '../models';

export interface DataRetrievalApiDataRetrievalCreateRequest {
    body: RESTDataRetrievalFromClient;
}

export interface DataRetrievalApiDataRetrievalDeleteDataRetrievalRequest {
    dataRetrievalId: string;
}

export interface DataRetrievalApiDataRetrievalExploreActionRequest {
    dataRetrievalId: string;
}

export interface DataRetrievalApiDataRetrievalGetDataRetrievalRequest {
    dataRetrievalId: string;
}

export interface DataRetrievalApiDataRetrievalGetDataRetrievalsRequest {
    organizationId?: string;
    repositoryId?: string;
    dataState?: DataRetrievalGetDataRetrievalsDataStateEnum;
}

export interface DataRetrievalApiDataRetrievalGetMailboxesRequest {
    dataRetrievalId: string;
}

export interface DataRetrievalApiDataRetrievalGetOneDrivesRequest {
    dataRetrievalId: string;
}

export interface DataRetrievalApiDataRetrievalGetTeamsRequest {
    dataRetrievalId: string;
}

export interface DataRetrievalApiDataRetrievalGetWebsRequest {
    dataRetrievalId: string;
}

export interface DataRetrievalApiDataRetrievalUpdateRequest {
    dataRetrievalId: string;
    body: RESTDataRetrievalUpdateFromClient;
}

/**
 * no description
 */
export const dataRetrievalApi = ({

    /**
     * Creates a retrieval job. <div class=\"note\"><strong>NOTE</strong>  </br> You must create individual retrieval jobs for different types of objects whose backed-up data you want to retrieve. </div> 
     * Create Data Retrieval Job
     */
    dataRetrievalCreate: ({ body }: DataRetrievalApiDataRetrievalCreateRequest, option?: RequestOption): Observable<Optional<RESTDataRetrievalComposed>> => {
        throwIfNullOrUndefined(body, 'body', 'dataRetrievalCreate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDataRetrievalComposed>({
            url: '/v7/DataRetrievals',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Removes a retrieval job with the specified ID.
     * Remove Data Retrieval Job
     */
    dataRetrievalDeleteDataRetrieval: ({ dataRetrievalId }: DataRetrievalApiDataRetrievalDeleteDataRetrievalRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalDeleteDataRetrieval');

        return requestAuthenticated<void>({
            url: '/v7/DataRetrievals/{dataRetrievalId}'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'DELETE',
        }, option);
    },

    /**
     * Creates and starts a restore session for a retrieval job with the specified ID.
     * Create Restore Session
     */
    dataRetrievalExploreAction: ({ dataRetrievalId }: DataRetrievalApiDataRetrievalExploreActionRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalExploreAction');

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v7/DataRetrievals/{dataRetrievalId}/explore'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'POST',
        }, option);
    },

    /**
     * Returns a resource representation of a retrieval job with the specified ID.
     * Get Data Retrieval Job
     */
    dataRetrievalGetDataRetrieval: ({ dataRetrievalId }: DataRetrievalApiDataRetrievalGetDataRetrievalRequest, option?: RequestOption): Observable<Optional<RESTDataRetrievalComposed>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalGetDataRetrieval');

        return requestAuthenticated<RESTDataRetrievalComposed>({
            url: '/v7/DataRetrievals/{dataRetrievalId}'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a list of configured retrieval jobs.
     * Get Retrieval Jobs
     */
    dataRetrievalGetDataRetrievals: ({ organizationId, repositoryId, dataState }: DataRetrievalApiDataRetrievalGetDataRetrievalsRequest, option?: RequestOption): Observable<Optional<Array<RESTDataRetrievalComposed>>> => {

        const query: HttpQuery = {};

        if (organizationId != null) { query['organizationId'] = organizationId; }
        if (repositoryId != null) { query['repositoryId'] = repositoryId; }
        if (dataState != null) { query['dataState'] = dataState; }

        return requestAuthenticated<Array<RESTDataRetrievalComposed>>({
            url: '/v7/DataRetrievals',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of mailboxes whose backed-up data was retrieved from object storage by a retrieval job with the specified ID.
     * Get Mailboxes
     */
    dataRetrievalGetMailboxes: ({ dataRetrievalId }: DataRetrievalApiDataRetrievalGetMailboxesRequest, option?: RequestOption): Observable<Optional<Array<RESTDataRetrievalMailbox>>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalGetMailboxes');

        return requestAuthenticated<Array<RESTDataRetrievalMailbox>>({
            url: '/v7/DataRetrievals/{dataRetrievalId}/mailboxes'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a resource representation of OneDrives whose backed-up data was retrieved from object storage by a retrieval job with the specified ID.
     * Get OneDrives
     */
    dataRetrievalGetOneDrives: ({ dataRetrievalId }: DataRetrievalApiDataRetrievalGetOneDrivesRequest, option?: RequestOption): Observable<Optional<Array<RESTDataRetrievalOneDrive>>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalGetOneDrives');

        return requestAuthenticated<Array<RESTDataRetrievalOneDrive>>({
            url: '/v7/DataRetrievals/{dataRetrievalId}/onedrives'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a resource representation of teams whose backed-up data was retrieved from object storage by a retrieval job with the specified ID.
     * Get Teams
     */
    dataRetrievalGetTeams: ({ dataRetrievalId }: DataRetrievalApiDataRetrievalGetTeamsRequest, option?: RequestOption): Observable<Optional<Array<RESTDataRetrievalTeam>>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalGetTeams');

        return requestAuthenticated<Array<RESTDataRetrievalTeam>>({
            url: '/v7/DataRetrievals/{dataRetrievalId}/teams'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a resource representation of sites whose backed-up data was retrieved from object storage by a retrieval job with the specified ID.
     * Get Sites
     */
    dataRetrievalGetWebs: ({ dataRetrievalId }: DataRetrievalApiDataRetrievalGetWebsRequest, option?: RequestOption): Observable<Optional<Array<RESTDataRetrievalWeb>>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalGetWebs');

        return requestAuthenticated<Array<RESTDataRetrievalWeb>>({
            url: '/v7/DataRetrievals/{dataRetrievalId}/webs'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'GET',
        }, option);
    },

    /**
     * Modifies settings of a retrieval job with the specified ID.
     * Edit Data Retrieval Job Settings
     */
    dataRetrievalUpdate: ({ dataRetrievalId, body }: DataRetrievalApiDataRetrievalUpdateRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(dataRetrievalId, 'dataRetrievalId', 'dataRetrievalUpdate');
        throwIfNullOrUndefined(body, 'body', 'dataRetrievalUpdate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/DataRetrievals/{dataRetrievalId}'.replace('{dataRetrievalId}', encodeURI(dataRetrievalId)),
            method: 'PUT',
            headers,
            body: body,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum DataRetrievalGetDataRetrievalsDataStateEnum {
    Retrieving = 'Retrieving',
    Retrieved = 'Retrieved',
    ChangingAvailabilityPeriod = 'ChangingAvailabilityPeriod',
    Removing = 'Removing'
}
