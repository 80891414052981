// Copyright © Veeam Software Group GmbH

import first from 'lodash/first';
import { DataGridModel, SORT_DIRECTION } from '@veeam/components';
import { FilterQuery } from '@veeam/core';

import type {
    RestoreSession, RestoreSessionId } from 'services/restoreService';

import { createConverter, commonLoader, useModel } from 'infrastructure/grid';
import {
    restoreService, RestoreSessionsSortKeys, RestoreSessionsFilterSchema, GetRestoreSessionsPeriod,
} from 'services/restoreService';


export class SessionsModel extends DataGridModel<RestoreSession, RestoreSessionId, RestoreSessionsSortKeys> {
    private static idGetter(data: RestoreSession): RestoreSessionId {
        return data.id;
    }
    public constructor(loader: SessionsModel['loaderType'], preSelectSessionId: RestoreSessionId | undefined) {
        super(SessionsModel.idGetter, loader, {});
        if (preSelectSessionId) {
            this.update(mutable => mutable.selection.push(preSelectSessionId));
        }
    }
}

export type Value = SessionsModel['valueType'];

export const convertFilter = createConverter(RestoreSessionsFilterSchema);

const sessionsLoader: SessionsModel['loaderType'] = commonLoader(
    value => ({
        filter: convertFilter(value.filters),
        sorting: first(value.sorting),
    }),
    restoreService.sessions.getSessions
);

export const useSessionsModel = (preSelectSessionId: RestoreSessionId | undefined): SessionsModel =>
    useModel(() => new SessionsModel(sessionsLoader, preSelectSessionId)
        .withSorting({ direction: SORT_DIRECTION.desc, key: RestoreSessionsSortKeys.StartTime })
        .withFilters(FilterQuery.fromArray([{ property: 'period', value: [GetRestoreSessionsPeriod.LastWeek] }])))
        .withFiltersStorage('sessions-grid');
