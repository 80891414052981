// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTJobItemComposed,
    RESTJobSchedulePolicy,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTJob
 */
export interface RESTJob {
    /**
     * Description of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    description?: string;
    /**
     * Type of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    backupType?: RESTJobBackupTypeEnum;
    /**
     * Defines whether the job will run right after it is created.
     * @type {boolean}
     * @memberof RESTJob
     */
    runNow?: boolean;
    /**
     * Array of objects that you want to back up.
     * @type {Array<RESTJobItemComposed>}
     * @memberof RESTJob
     */
    selectedItems?: Array<RESTJobItemComposed>;
    /**
     * Array of objects that you want to exclude from a backup job scope.
     * @type {Array<RESTJobItemComposed>}
     * @memberof RESTJob
     */
    excludedItems?: Array<RESTJobItemComposed>;
    /**
     * @type {RESTJobSchedulePolicy}
     * @memberof RESTJob
     */
    schedulePolicy?: RESTJobSchedulePolicy;
    /**
     * Backup job ID.
     * @type {string}
     * @memberof RESTJob
     */
    id?: string;
    /**
     * Backup repository ID.
     * @type {string}
     * @memberof RESTJob
     */
    repositoryId?: string;
    /**
     * Name of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    name?: string;
    /**
     * Date and time of the last run of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    lastRun?: string;
    /**
     * Date and time of the next run of the backup job per schedule.
     * @type {string}
     * @memberof RESTJob
     */
    nextRun?: string;
    /**
     * Date and time of the last successful run of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    lastBackup?: string;
    /**
     * Defines whether the backup job is enabled.
     * @type {boolean}
     * @memberof RESTJob
     */
    isEnabled?: boolean;
    /**
     * Latest status of the backup job.
     * @type {string}
     * @memberof RESTJob
     */
    lastStatus?: RESTJobLastStatusEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTJob
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTJobBackupTypeEnum {
    EntireOrganization = 'EntireOrganization',
    SelectedItems = 'SelectedItems'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTJobLastStatusEnum {
    Stopped = 'Stopped',
    Running = 'Running',
    Success = 'Success',
    Failed = 'Failed',
    Warning = 'Warning',
    NotConfigured = 'NotConfigured',
    Disconnected = 'Disconnected',
    Queued = 'Queued'
}

