// Copyright © Veeam Software Group GmbH

import en from './roots/en.res.root';

import type { Languages } from './Types';

const languages: Languages<typeof en> = {
    en,
};

export default languages;
