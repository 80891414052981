// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTCredential
 */
export interface RESTCredential {
    /**
     * Cloud account type.
     * @type {string}
     * @memberof RESTCredential
     */
    accountType?: RESTCredentialAccountTypeEnum;
    /**
     * Cloud account user name.
     * @type {string}
     * @memberof RESTCredential
     */
    userName?: string;
    /**
     * Cloud account ID.
     * @type {string}
     * @memberof RESTCredential
     */
    id?: string;
    /**
     * Cloud account description.
     * @type {string}
     * @memberof RESTCredential
     */
    description?: string;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTCredential
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCredentialAccountTypeEnum {
    AzureBlobAccount = 'azureBlobAccount',
    AmazonS3Account = 'amazonS3Account',
    AmazonS3CompatibleAccount = 'amazonS3CompatibleAccount'
}

