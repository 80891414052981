// Copyright © Veeam Software Group GmbH

import * as React from 'react';
import { DataTree, FONT_SIZE_M, INDENT, SPACE_FILL, STACK_DIRECTION, StackView } from '@veeam/components';
import { colors } from '@veeam/components/lib/themes/colors/colors';
import { observer } from 'mobx-react-lite';

import type { SharePointSite } from 'services';

import { getErrorMessage } from 'infrastructure/error-management';
import { getIcon } from '../../helpers/icon-mappers';
import { useExploreTreeModel } from 'contexts/ExploreTreeProvider';
import { RestorePointSelector } from '../RestorePointSelector';
import { useExplorePageStore } from '../../stores/explore-page-store';

export const ExploreTree = observer(() => {
    const model = useExploreTreeModel();
    const explorePageStore = useExplorePageStore();

    return (
        <StackView
            hasYScroll
            spaceRight={INDENT.s}
            spaceFill={SPACE_FILL.all}
            direction={STACK_DIRECTION.column}
        >
            <RestorePointSelector />
            {explorePageStore.restorePoint &&
            <DataTree
                model={model}
                config={{
                    asyncGetter: node => node.data.isAsync,
                    nodeRenderer: (node) => {
                        const url = (node.data.model as SharePointSite).url;

                        return (
                            <div
                                title={url}
                                style={{
                                    fontSize: FONT_SIZE_M,
                                    color: colors.G600,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {node.data.model.title}
                            </div>
                        );
                    },
                    iconRenderer: opt => getIcon(opt.node.data.model),
                    nodeWarningGetter: (node, error) => getErrorMessage(error).message,
                    autoLoad: false,
                }}
                loading={explorePageStore.isSessionLoading}
            />
            }
        </StackView>
    );
});
