// Copyright © Veeam Software Group GmbH

import React from 'react';
import { AppBar, APP_BAR_ITEM_TYPE, AppBarNotification } from '@veeam/components';

import type { FC } from 'react';

import { useResources } from 'infrastructure/resources';
import { logoSrc } from 'assets';
import { LoginProfile } from './Profile';
import { useNavigation } from './useNavigation';
import { useNotification } from './useNotification';

export const MainAppBar: FC = () => {
    const resources = useResources().features.Main;
    const navigation = useNavigation(resources.bar);
    const notification = useNotification();
    return (
        <AppBar
            logo={logoSrc}
            items={[
                ...navigation,
                {
                    type: APP_BAR_ITEM_TYPE.fillSpace,
                },
                {
                    type: APP_BAR_ITEM_TYPE.custom,
                    render: () => (<LoginProfile />),
                },
                {
                    type: APP_BAR_ITEM_TYPE.separator,
                },
                {
                    type: APP_BAR_ITEM_TYPE.custom,
                    render: () => (
                        <AppBarNotification {...notification} />
                    ),
                },
            ]}
        />
    );
};
