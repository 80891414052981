// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { LoadPagesConfig } from 'infrastructure/rxjs';
import type {
    SharePointItem,
    SharePointNode,
    VespSession,
    SharePointFolder,
    SharePointListItem,
    SharePointDocument,
    SharePointFolderItem,
    SharePointListFolderItem,
} from 'services/models';

import { Batch, loadPages } from 'infrastructure/rxjs';
import { never } from 'infrastructure/never';
import { convert } from './converters';
import { sharePointApi } from './wrappedSharePointApi';
import { SharePointNodeType } from 'services/models';

const loadListItems = (
    session: VespSession,
    parent: SharePointFolder,
    config: LoadPagesConfig | undefined
): Observable<Batch<SharePointListItem | SharePointListFolderItem>> =>
    loadPages(sharePointApi.getItems, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        parentId: parent.id,
        includeFolders: true,
    }).pipe(
        map(listItems => listItems.mapData((listItem) => {
            if (listItem.isFolder) {
                return convert.listFolderItem.fromRest(listItem, parent.siteId, parent.explorePath, false);
            }

            return convert.listItem.fromRest(listItem, parent.siteId, parent.explorePath, false);
        })),
    );

const loadDocuments = (
    session: VespSession,
    parent: SharePointFolder,
    config: LoadPagesConfig | undefined
): Observable<Batch<SharePointDocument | SharePointFolderItem>> =>
    loadPages(sharePointApi.getDocuments, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        parentId: parent.id,
        includeFolders: true,
    }).pipe(
        map(sharePointItems => sharePointItems.mapData((item) => {
            if (item.isFolder) {
                return convert.folderItem.fromRest(item, parent.siteId, parent.explorePath, false);
            }

            return convert.document.fromRest(item, parent.siteId, parent.explorePath, false);
        })),
    );

export const loadSharePointItems = (
    session: VespSession,
    parent: SharePointNode,
    config: LoadPagesConfig | undefined
): Observable<Batch<SharePointItem>> => {
    switch (parent.nodeType) {
        case SharePointNodeType.Site:
        case SharePointNodeType.SubSites:
        case SharePointNodeType.Content: return of(Batch.empty());
        case SharePointNodeType.List:
        case SharePointNodeType.ListFolder: return loadListItems(session, parent, config);
        case SharePointNodeType.Library:
        case SharePointNodeType.LibraryFolder: return loadDocuments(session, parent, config);
        default: return never(parent);
    }
};
