// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';
import first from 'lodash/first';
import { toolbarAction, toolbarItemDecorators } from '@veeam/components';

import type { CollectionToolbarTypedItem } from '@veeam/components';

import { stopSrc } from 'assets';
import { useObservableAction } from 'infrastructure/hooks';
import { RestoreSessionStatus, restoreService } from 'services/restoreService';

import type { GridResources } from './resources';
import type { SessionsModel } from './useModel';


export function useStopSession(resources: GridResources, model: SessionsModel): CollectionToolbarTypedItem {
    const stopSession = useObservableAction(restoreService.sessions.stopSession);
    return useMemo(() => {
        function stop(): void {
            const restoreSessionId = first(model.getValue().selection);
            if (!restoreSessionId) return;
            model.update((mutable) => {
                const session = mutable.items.find(xxx => xxx.id === restoreSessionId);
                if (session?.status === RestoreSessionStatus.Running) {
                    session.status = RestoreSessionStatus.Stopping;
                }
            });
            stopSession(restoreSessionId);
        }

        return toolbarAction({
            title: resources.stopActionTitle,
            icon: stopSrc,
            decorators: [
                toolbarItemDecorators.disallowWithoutSingleSelection(),
                ({ selectedItems }) => ({ disabled: first(selectedItems)?.status !== RestoreSessionStatus.Running }),
            ],
            handler: stop,
        });
    }, [resources, model]);
}
