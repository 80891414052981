// Copyright © Veeam Software Group GmbH

import last from 'lodash/last';

import type { UniqueId } from './UniqueId';

export enum SegmentType {
    Node = 'Node',
    Item = 'Item',
    Version = 'Version',
}

export type Segment = {
    uniqId: UniqueId;
    title: string;
    type: SegmentType;
};

export type ExplorePath = Segment[];

const getActualSegments = (segType: SegmentType, path: ExplorePath): Segment[] => {
    switch (segType) {
        case SegmentType.Item:
            return path
                .slice(0, -1)
                .map((item, i, list) => {
                    if (i === list.length - 1 && item.type === SegmentType.Item) {
                        return {
                            uniqId: item.uniqId,
                            type: item.type,
                            title: 'Reply',
                        }
                    }

                    return item;
                });
        default: return path;
    }
};

export const explorePath = {
    create: (segment: Segment, location?: ExplorePath): ExplorePath => [...(location || []), segment],
    stringify: (location: ExplorePath): string => {
        const lastSegmentType = last(location)?.type;
        if (lastSegmentType === undefined) return '';
        return getActualSegments(lastSegmentType, location).map(seg => seg.title)
            .join('/');
    },
};
