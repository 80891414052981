export enum DayPeriod {
    Everyday = 0,
    Workdays = 1,
    Weekends = 2,
    Monday = 3,
    Tuesday = 4,
    Wednesday = 5,
    Thursday = 6,
    Friday = 7,
    Saturday = 8,
    Sunday = 9
}
