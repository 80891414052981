// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRbacUser
 */
export interface RESTRbacUser {
    /**
     * ID of the organization user.
     * @type {string}
     * @memberof RESTRbacUser
     */
    id: string;
    /**
     * ID of the organization user in the on-premises organization.
     * @type {string}
     * @memberof RESTRbacUser
     */
    onPremisesSid?: string;
    /**
     * Display name of the organization user.
     * @type {string}
     * @memberof RESTRbacUser
     */
    displayName: string;
    /**
     * Email address of the organization user.
     * @type {string}
     * @memberof RESTRbacUser
     */
    name: string;
    /**
     * Type of the user account.
     * @type {string}
     * @memberof RESTRbacUser
     */
    type: RESTRbacUserTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRbacUserTypeEnum {
    User = 'User',
    SharedMailbox = 'SharedMailbox',
    PublicMailbox = 'PublicMailbox'
}

