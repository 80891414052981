// Copyright © Veeam Software Group GmbH

import type { Item } from 'services';

import { OneDriveItemType, Product, SharePointItemType } from 'services';
import { TeamsItemType } from 'services/models/Teams';

export const isRestoreListAvailable = (item: Item): boolean => {
    switch (item.product) {
        case Product.Teams:
            switch (item.itemType) {
                case TeamsItemType.Folder:
                case TeamsItemType.Team:
                case TeamsItemType.Files:
                case TeamsItemType.Posts:
                case TeamsItemType.Tabs:
                case TeamsItemType.Channel:
                    return false;
            }
            break;
        case Product.OneDrive:
            switch (item.itemType) {
                case OneDriveItemType.Folder:
                    return false;
            }
            break;
        case Product.SharePoint:
            switch (item.itemType) {
                case SharePointItemType.Folder:
                case SharePointItemType.ListFolderItem:
                    return false;
            }
            break;
    }

    return true; // default
};
