// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { CollectionToolbarItem } from '@veeam/components';
import type { RestoreSessionEventsFilter } from 'services/restoreService';

import { createConfigMaker, createToolbarFilters } from 'infrastructure/grid';
import { statusIconMap } from './mappers';
import { RestoreSessionEventsStatus } from 'services/restoreService';

import type { GridResources } from './resources';


function createFilter(resources: GridResources): CollectionToolbarItem[] {
    const configMaker = createConfigMaker<RestoreSessionEventsFilter>();
    const searchConfig = configMaker.search('search', { fieldConfig: { placeholder: resources.filter.message } });
    const statusesConfig = configMaker.basic('status', {
        title: resources.filter.statusTitle,
        items: [
            { title: resources.statuses.running, icon: statusIconMap.Running, value: RestoreSessionEventsStatus.Running },
            { title: resources.statuses.success, icon: statusIconMap.Success, value: RestoreSessionEventsStatus.Success },
            { title: resources.statuses.warning, icon: statusIconMap.Warning, value: RestoreSessionEventsStatus.Warning },
            { title: resources.statuses.failed, icon: statusIconMap.Failed, value: RestoreSessionEventsStatus.Failed },
        ],
    });
    return createToolbarFilters(searchConfig, statusesConfig);
}

export const useFilter = (resources: GridResources): CollectionToolbarItem[] =>
    useMemo(() => createFilter(resources), [resources]);
