// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTOneDrive,
} from './';

/**
 * @export
 * @interface RESTCopyToFolder
 */
export interface RESTCopyToFolder {
    /**
     * Defines whether all versions of OneDrive items will be copied.
     * @type {boolean}
     * @memberof RESTCopyToFolder
     */
    changedItems?: boolean;
    /**
     * Defines whether the deleted OneDrive items will be copied.
     * @type {boolean}
     * @memberof RESTCopyToFolder
     */
    deletedItems?: boolean;
    /**
     * Defines whether the OneDrive items will be copied with all permissions.
     * @type {boolean}
     * @memberof RESTCopyToFolder
     */
    restorePermissions?: boolean;
    /**
     * Defines whether the shared links notifications will be sent.
     * @type {boolean}
     * @memberof RESTCopyToFolder
     */
    sendSharedLinksNotification?: boolean;
    /**
     * @type {RESTOneDrive}
     * @memberof RESTCopyToFolder
     */
    onedrive?: RESTOneDrive;
    /**
     * Specifies the target folder for the copied OneDrive data.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    folder?: string;
    /**
     * Specifies what version of the OneDrive documents will be copied.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    documentVersion?: RESTCopyToFolderDocumentVersionEnum;
    /**
     * Specifies the action that will be performed with the last version of the copied OneDrive document on the production server.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    documentLastVersionAction?: RESTCopyToFolderDocumentLastVersionActionEnum;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    userCode?: string;
    /**
     * Specifies the ID of the Azure AD application that you want to use for restore.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    applicationId?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the SSL certificate configured for the Azure AD application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    applicationCertificate?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    userPassword?: string;
    /**
     * Specifies the region of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    officeRegion?: RESTCopyToFolderOfficeRegionEnum;
    /**
     * Specifies the name of the target Microsoft 365 organization.
     * @type {string}
     * @memberof RESTCopyToFolder
     */
    organizationName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCopyToFolderDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTCopyToFolderDocumentLastVersionActionEnum {
    Overwrite = 'Overwrite',
    Merge = 'Merge'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTCopyToFolderOfficeRegionEnum {
    Worldwide = 'Worldwide',
    Germany = 'Germany',
    China = 'China',
    UsDefence = 'USDefence',
    UsGovernment = 'USGovernment'
}

