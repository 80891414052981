// Copyright © Veeam Software Group GmbH

import type { AdvancedFilterTexts } from '@veeam/components';
import type { FILTER_COLLATION, FILTER_OPERATION } from '@veeam/core';
import type { Flatten } from 'infrastructure/types';
import type { LocalizableString } from 'infrastructure/resources';

import { FilterType } from './toolbarFilter';

export interface ToolbarAdvancedFilterGroupItem<TValue> {
    title: string;
    value: TValue;
    icon?: string;
    testId?: string;
}

export interface ToolbarAdvancedFilterGroupsBase {
    operation?: FILTER_OPERATION;
    collation?: FILTER_COLLATION;
    columnIndex?: number;
    title?: string;
}

export interface ToolbarAdvancedFilterGroups<TValue> extends ToolbarAdvancedFilterGroupsBase {
    property: string;
    groupId?: string;
    items: ToolbarAdvancedFilterGroupItem<TValue>[];
}

export interface GeneralAdvancedFilterConfig {
    valueTitle: LocalizableString;
    filtersTitle: LocalizableString;
    texts?: AdvancedFilterTexts;
}

export interface ToolbarAdvancedFilterConfig<TValue> extends GeneralAdvancedFilterConfig {
    groups: ToolbarAdvancedFilterGroups<TValue>[];
    filterType: FilterType.Advanced;
}

type AdvancedFilterType<TFilter, TProp extends string & keyof TFilter> =
    TFilter[TProp] extends unknown[] ? Flatten<TFilter[TProp]> : never;

export interface AdvancedFilterGroup<TFilter, TProp extends string & keyof TFilter> extends ToolbarAdvancedFilterGroupsBase {
    property: TProp;
    items: ToolbarAdvancedFilterGroupItem<AdvancedFilterType<TFilter, TProp>>[];
}

export interface AdvancedFilterConfigBuilder<TFilter> extends ToolbarAdvancedFilterConfig<unknown> {
    group<TProperty extends string & keyof TFilter>(
        config: AdvancedFilterGroup<TFilter, TProperty>,
    ): AdvancedFilterConfigBuilder<TFilter>;
}

export function toolbarAdvancedFilterConfigBuilder<TFilter>(
    config: GeneralAdvancedFilterConfig,
    groups: ToolbarAdvancedFilterGroups<unknown>[] = [],
): AdvancedFilterConfigBuilder<TFilter> {
    const group = <TProp extends string & keyof TFilter>(groupConf: AdvancedFilterGroup<TFilter, TProp>): AdvancedFilterConfigBuilder<TFilter> =>
        toolbarAdvancedFilterConfigBuilder(config, [...groups, groupConf]);

    return {
        ...config,
        groups,
        group,
        filterType: FilterType.Advanced,
    };
}
