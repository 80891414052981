// Copyright © Veeam Software Group GmbH

import {
    getSession, getSessions, useRestoreSessionTitle, filterSessions, sortSessions,
    RestoreSessionsFilterSchema, RestoreSessionsSortKeys, GetRestoreSessionsPeriod,
} from './getSessions';
import { stopSession } from './stopSession';
import { onSessionChanged } from './onSessionChanged';
import { getEvents, filterEvents, sortEvents, RestoreSessionEventsFilterSchema } from './getEvents';
import { onEventChanged } from './onEventChanged';

export type { RestoreSessionsFilter } from './getSessions';
export type { RestoreSessionEventsFilter } from './getEvents';
export * from '../models';
export { useRestoreSessionTitle, RestoreSessionsFilterSchema, RestoreSessionsSortKeys, GetRestoreSessionsPeriod, RestoreSessionEventsFilterSchema };

export const sessionsService = {
    getSession,
    getSessions,
    filterSessions,
    sortSessions,
    stopSession,
    onSessionChanged,
    getEvents,
    filterEvents,
    sortEvents,
    onEventChanged,
};
