// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTRestorePointUser
 */
export interface RESTRestorePointUser {
    /**
     * Defines whether the user mailbox was backed-up.
     * @type {boolean}
     * @memberof RESTRestorePointUser
     */
    isMailboxBackedUp?: boolean;
    /**
     * Defines whether the user OneDrive was backed-up.
     * @type {boolean}
     * @memberof RESTRestorePointUser
     */
    isOneDriveBackedUp?: boolean;
    /**
     * Defines whether the archived user mailbox was backed-up.
     * @type {boolean}
     * @memberof RESTRestorePointUser
     */
    isArchiveBackedUp?: boolean;
    /**
     * Defines whether the user personal site was backed-up.
     * @type {boolean}
     * @memberof RESTRestorePointUser
     */
    isPersonalSiteBackedUp?: boolean;
    /**
     * Type of the user account.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    accountType?: RESTRestorePointUserAccountTypeEnum;
    /**
     * Name of the user archive.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    archiveName?: string;
    /**
     * User ID.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    id?: string;
    /**
     * User account ID.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    accountId?: string;
    /**
     * Display name of the backed-up user.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    displayName?: string;
    /**
     * User email.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    email?: string;
    /**
     * Array of URLs for the backed-up Microsoft OneDrive components.
     * @type {Array<string>}
     * @memberof RESTRestorePointUser
     */
    oneDriveUrl?: Array<string>;
    /**
     * Array of URLs for the backed-up personal sites.
     * @type {Array<string>}
     * @memberof RESTRestorePointUser
     */
    personalSiteUrl?: Array<string>;
    /**
     * Backed-up organization ID.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    organizationId?: string;
    /**
     * ID of the backed-up organization in the backup.
     * @type {string}
     * @memberof RESTRestorePointUser
     */
    backedUpOrganizationId?: string;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTRestorePointUser
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestorePointUserAccountTypeEnum {
    User = 'User',
    SharedMailbox = 'SharedMailbox',
    PublicMailbox = 'PublicMailbox'
}

