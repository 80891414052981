// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';

import type { Observable } from 'rxjs';

import { Batch } from '../../../../infrastructure/rxjs';
import { exploreSessionService } from '../../../exploreSessions';
import { searchTeamsItems } from './search-teams-items';
import { loadTeamsItems } from './load-teams-items';

import type { TeamsItem } from '../../../models/Teams/TeamsItem';
import type { Override1 } from '../../../../infrastructure/types';
import type { GetItemsRequest } from '../../interfaces/get-items-request';
import type { TeamsNode } from '../../../models/Teams';

type GetTeamsItemsRequest = Override1<GetItemsRequest, 'parent', TeamsNode>;

export const getTeamsItems = ({ parent, config, filter: { search } }: GetTeamsItemsRequest): Observable<Batch<TeamsItem>> => {
    const { vetSession } = exploreSessionService.getSessions();

    if (!vetSession) {
        return of(Batch.empty());
    }

    return search === ''
        ? loadTeamsItems(vetSession, parent, config)
        : searchTeamsItems(vetSession, parent, config, search);
};

