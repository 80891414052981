/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTUser,
    RESTExceptionInfo,
    RESTUser,
} from '../models';

export interface OrganizationUserApiOrganizationUserGetRequest {
    organizationId: string;
    limit: number;
    offset: number;
    setId?: string;
    userName?: string;
    displayName?: string;
    locationFilter?: OrganizationUserGetLocationFilterEnum;
    dataSource?: OrganizationUserGetDataSourceEnum;
}

export interface OrganizationUserApiOrganizationUserGetByIdRequest {
    organizationId: string;
    userId: string;
    dataSource?: OrganizationUserGetByIdDataSourceEnum;
}

/**
 * no description
 */
export const organizationUserApi = ({

    /**
     * Returns a collection of an organization users.
     * Get Organization Users
     */
    organizationUserGet: ({ organizationId, limit, offset, setId, userName, displayName, locationFilter, dataSource }: OrganizationUserApiOrganizationUserGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTUser>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationUserGet');
        throwIfNullOrUndefined(limit, 'limit', 'organizationUserGet');
        throwIfNullOrUndefined(offset, 'offset', 'organizationUserGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (setId != null) { query['setId'] = setId; }
        if (userName != null) { query['userName'] = userName; }
        if (displayName != null) { query['displayName'] = displayName; }
        if (locationFilter != null) { query['locationFilter'] = locationFilter; }
        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<PageOfRESTUser>({
            url: '/v7/Organizations/{organizationId}/Users'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization user with the specified ID.
     * Get Organization User
     */
    organizationUserGetById: ({ organizationId, userId, dataSource }: OrganizationUserApiOrganizationUserGetByIdRequest, option?: RequestOption): Observable<Optional<RESTUser>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationUserGetById');
        throwIfNullOrUndefined(userId, 'userId', 'organizationUserGetById');

        const query: HttpQuery = {};

        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<RESTUser>({
            url: '/v7/Organizations/{organizationId}/Users/{userId}'.replace('{organizationId}', encodeURI(organizationId)).replace('{userId}', encodeURI(userId)),
            method: 'GET',
            query,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum OrganizationUserGetLocationFilterEnum {
    Cloud = 'Cloud',
    OnPremises = 'OnPremises',
    Hybrid = 'Hybrid',
    CloudOrHybrid = 'CloudOrHybrid',
    OnPremisesOrHybrid = 'OnPremisesOrHybrid',
    Any = 'Any'
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationUserGetDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationUserGetByIdDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
