// Copyright © Veeam Software Group GmbH

import type { RESTRestorePoint } from 'api/rxjs';

export function findDateInRestorePoints(value: Date, restorePoints: RESTRestorePoint[]): string | undefined {
    if (!value || !restorePoints) return;

    const point = restorePoints.find(point => new Date(point.backupTime).getTime() === value.getTime());

    if (point) {
        return point.backupTime;
    }
}
