// Copyright © Veeam Software Group GmbH

import moment from 'moment';
import { Text, Tooltip } from '@veeam/components';
import React from 'react';

import type { Moment } from 'moment';
import type { DataGridCellRenderer } from '@veeam/components';
import type { ControlInjectProps } from '@veeam/components/lib/Tooltip/types';
import type { Func1 } from 'infrastructure/types';

import type { CellRender, CellRendererProps } from './CellRender';


export const utcCellRender = (value: Moment | undefined, props: CellRendererProps): CellRender => {
    if (!value) {
        return <>-</>;
    }

    const date = moment(value);
    const localTime = date.format('LLL');
    const utcTime = date.clone().utc()
        .format('LLL');

    return (
        <Tooltip
            renderContent={() =>
                <>
                    <Text>{localTime} <b>(Local)</b></Text>
                    <Text>{utcTime} <b>(UTC)</b></Text>
                </>
            }
            render={(tooltipProps: ControlInjectProps<HTMLDivElement>) => (
                <Text
                    {...tooltipProps}
                    truncate
                >
                    {localTime}
                </Text>
            )}
        />
    );
};

export const utcDateCell = <TRowData,>(
    value: Func1<TRowData, Moment | undefined>,
): DataGridCellRenderer<TRowData> => (row, payload) => utcCellRender(value(row), payload);
