// Copyright © Veeam Software Group GmbH

import type { ExploreSession } from 'services/models';

import { StorageKeys } from '../StorageKeys';
import { getLocalStorage } from '../rawStorage';

import type { RESTRestorePoint } from '../../../api/rxjs';

interface RestorePointsStorage {
    [key: string]: RESTRestorePoint;
}

export const exploreSessionStorage = getLocalStorage<ExploreSession>(StorageKeys.ExploreSessions);

export const restorePointStorage = getLocalStorage<RestorePointsStorage>(StorageKeys.RestorePoints);
