// Copyright © Veeam Software Group GmbH

import type { Observable } from 'rxjs';
import type { OAuthTokenResponse } from 'api/rxjs';
import type { Event } from 'infrastructure/event';

export interface AuthInfo {
    username: string;
    accessToken: string;
    refreshToken: string;
}

export interface Events {
    login: {
        before: Event;
        afterMsalLogin: Event<string>;
        after: Event;
    };
    logout: {
        before: Event<string | undefined>;
        after: Event<string | undefined>;
    };
    renewToken: Event<string>;
}

export enum AuthState {
    Authenticated = 'authenticated',
    Authenticating = 'authenticating',
    Logouting = 'logouting',
    NotAuthenticated = 'not-authenticated',
}

export interface Common {
    emergencyLogout(reason?: string): Promise<void>;
    events: Events;
}

export interface AuthenticatedController extends Common {
    status: AuthState.Authenticated;
    info: AuthInfo;

    refreshTokens(): Observable<OAuthTokenResponse>;
    logout(): Promise<void>;
}

export interface LoginResult {
    username: string;
}

export interface AuthenticatingController extends Common {
    status: AuthState.Authenticating;

    endLogin(): Promise<LoginResult>;
}

export interface LogoutingController extends Common {
    status: AuthState.Logouting;

    info: AuthInfo;
}

export interface NotAuthenticatedController extends Common {
    status: AuthState.NotAuthenticated;

    beginLogin(userMail: string): Promise<undefined>;
}

export type AuthController =
    | AuthenticatedController
    | AuthenticatingController
    | LogoutingController
    | NotAuthenticatedController;
