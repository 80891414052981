// Copyright © Veeam Software Group GmbH

import type { UniqueId } from 'services';
import type { BucketItem } from 'services/restoreService';

import { serializeComplexType, deserializeComplexType } from 'infrastructure/serialization';
import { exploreSessionService } from '../../exploreSessions';
import { rbacService } from '../../rbac';

import type { RecoveryListTable } from '../RecoveryListTable';
import type { DixieDb } from './DixieDb';


export const createRecoveryListTable = (db: DixieDb): RecoveryListTable => ({
    getItemsCount: async() => await db.recoveryList.count(),
    getItems: async() => {
        const items = await db.recoveryList.toArray();
        const deserialized = deserializeComplexType(items) as BucketItem[];
        return deserialized;
    },
    addItems: async(items)  => {
        const serialized = serializeComplexType(items) as BucketItem[];
        await db.recoveryList.bulkPut(serialized);
    },
    deleteItems: async ids => await db.recoveryList.bulkDelete(ids),
    setSessionId: async(uniqueId, sessionId) => {
        db.transaction('rw', db.recoveryList, async() => {
            const item = await db.recoveryList.get(uniqueId);
            if (item) {
                item.sessionId = sessionId;
                await db.recoveryList.put(item);
            }
        });
    },
    clear: async() => await db.recoveryList.clear(),
    isItemExist: async(id: UniqueId): Promise<boolean> => {
        const item = await db.recoveryList.get(id);
        const currentScopeId = rbacService.info.scope.id;
        const restorePoint = exploreSessionService.getRestorePoint();

        return item && item.scopeId === currentScopeId && item.restorePointId === restorePoint.id;
    },
});
