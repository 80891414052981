// Copyright © Veeam Software Group GmbH

import { SORT_DIRECTION } from '@veeam/core';

import type { Item, Node } from 'services';
import { ExchangeFolder, Product } from 'services';
import { TeamsNodeType, TeamsPostItem } from 'services/models/Teams';
import { ExploreSortKeys } from '../../../../../services/explore/enums/explore-sort-keys';
import { RESTExchangeFolderCategoryEnum, RESTExchangeFolderTypeEnum } from '../../../../../api/rxjs';

interface ExploreGridConfig {
    multiSelectAvailable: boolean;
    searchAvailable: boolean;
    exportAvailable: boolean;
    exportAllPostsAvailable?: boolean;
    sorting?: {
        key: ExploreSortKeys;
        direction: SORT_DIRECTION;
    };
    isDisabledItem?: (item: Item) => boolean;
}

export const getExploreGridConfig = (node?: Node): ExploreGridConfig => {
    const defaultResult: ExploreGridConfig = {
        multiSelectAvailable: true,
        searchAvailable: true,
        exportAvailable: false,
    };

    switch (node?.product) {
        case Product.Teams:
            switch (node?.nodeType) {
                case TeamsNodeType.Channel:
                    return {
                        multiSelectAvailable: false,
                        searchAvailable: false,
                        exportAvailable: false,
                        exportAllPostsAvailable: true,
                        sorting: {
                            key: ExploreSortKeys.Title,
                            direction: SORT_DIRECTION.asc,
                        },
                    };
                case TeamsNodeType.Team:
                case TeamsNodeType.Teams:
                    return {
                        multiSelectAvailable: false,
                        searchAvailable: false,
                        exportAvailable: false,
                        sorting: {
                            key: ExploreSortKeys.Title,
                            direction: SORT_DIRECTION.asc,
                        },
                    };
                case TeamsNodeType.Posts:
                    return {
                        multiSelectAvailable: true,
                        searchAvailable: true,
                        exportAvailable: true,
                        sorting: {
                            key: ExploreSortKeys.CreatedTime,
                            direction: SORT_DIRECTION.desc,
                        },
                        isDisabledItem: (item: Item) => {
                            const teamsPost = item as TeamsPostItem;
                            return teamsPost.isDeleted;
                        }
                    };
                default: {
                    return Object.assign({
                        sorting: {
                            key: ExploreSortKeys.Title,
                            direction: SORT_DIRECTION.asc,
                        },
                    }, defaultResult);
                }
            }
        case Product.Exchange: {
            if ((node as ExchangeFolder)?.folderCategory) {
                switch ((node as ExchangeFolder)?.folderCategory) {
                    case RESTExchangeFolderCategoryEnum.Inbox:
                        return Object.assign({
                            sorting: {
                                key: ExploreSortKeys.Received,
                                direction: SORT_DIRECTION.desc,
                            },
                        }, defaultResult);
                }
            }

            if ((node as ExchangeFolder)?.folderType) {
                switch ((node as ExchangeFolder)?.folderType) {
                    case RESTExchangeFolderTypeEnum.Contact:
                        return Object.assign({
                            sorting: {
                                key: ExploreSortKeys.FullName,
                                direction: SORT_DIRECTION.asc,
                            },
                        }, defaultResult);
                    case RESTExchangeFolderTypeEnum.Appointment:
                        return Object.assign({
                            sorting: {
                                key: ExploreSortKeys.StartTime,
                                direction: SORT_DIRECTION.asc,
                            },
                        }, defaultResult);
                    case RESTExchangeFolderTypeEnum.StickyNote:
                        return Object.assign({
                            sorting: {
                                key: ExploreSortKeys.Date,
                                direction: SORT_DIRECTION.desc,
                            },
                        }, defaultResult);
                }
            }
        }
        case Product.OneDrive:
        case Product.SharePoint:
            return Object.assign({
                sorting: {
                    key: ExploreSortKeys.Title,
                    direction: SORT_DIRECTION.asc,
                },
            }, defaultResult);
    }

    // default
    return defaultResult;
};
