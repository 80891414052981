// Copyright © Veeam Software Group GmbH

import {
    RESTExchangeFolderTypeEnum as ExchangeFolderType,
    RESTExchangeFolderCategoryEnum as ExchangeFolderCategory,
} from 'api/rxjs';

import type { Product } from '../Product';
import type { NodeBase } from '../NodeBase';
import type { MailboxId } from './MailboxId';

export { ExchangeFolderType, ExchangeFolderCategory };

export enum ExchangeNodeType {
    Mailbox = 'Mailbox',
    Folder = 'Folder',
}

export type ExchangeFolderId = string & { __exchange_folder_id: never; };

interface ExchangeNodeBase extends NodeBase {
    product: Product.Exchange;
    nodeType: ExchangeNodeType;
    mailboxId: MailboxId;
}

export interface ExchangeMailbox extends ExchangeNodeBase {
    nodeType: ExchangeNodeType.Mailbox;
    isArchive?: boolean;
}

export interface ExchangeFolder extends ExchangeNodeBase {
    nodeType: ExchangeNodeType.Folder;
    folderId: ExchangeFolderId;
    folderType: ExchangeFolderType;
    folderCategory: ExchangeFolderCategory;
}

export type ExchangeNode = ExchangeMailbox | ExchangeFolder;
