// Copyright © Veeam Software Group GmbH

// eslint-disable-next-line @typescript-eslint/naming-convention
import { exploreSessionService } from '../exploreSessions';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type UniqueId = string & { __unique_id: never; };

export const getUniqueId = (id: string, version?: string): UniqueId => {
    let result = id;
    const restorePoint = exploreSessionService.getRestorePoint();

    if (version) {
        result += `/${version}`;
    }

    if (restorePoint) {
        result += `/${restorePoint.id}`;
    }

    return result as UniqueId;
};

export const removeRestorePointIdFromUniqueId = (uniqueId: string): string => {
    const restorePointId = uniqueId.split('/').at(-1);

    return uniqueId.replace(`/${restorePointId}`, '');
};
