// Copyright © Veeam Software Group GmbH

import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { BodyLayout, SplitLayout } from '@veeam/components';

import { ExploreGrid } from './components/ExploreGrid';
import { ExploreTree } from './components/ExploreTree';
import { ScopeHeader } from './components/ScopeHeader';
import { ExplorePageStore, ExplorePageStoreContext } from './stores/explore-page-store';

export const ExplorePage: React.FC = () => {
    const explorePageStore = useMemo(() => new ExplorePageStore(), []);

    useEffect(() => () => explorePageStore.destroy(), []);

    return (
        <ExplorePageStoreContext.Provider value={explorePageStore}>
            <ScopeHeader/>
            <BodyLayout >
                <SplitLayout
                    start={
                        <ExploreTree />
                    }
                    startConfig={{ size: 0.15, minSize: 0.15 }}
                    end={
                        <ExploreGrid />
                    }
                    endConfig={{ size: 0.85, minSize: 0.5 }}
                    data-selector-id='explore-page-split-layout'
                />
            </BodyLayout>
        </ExplorePageStoreContext.Provider>
    );
};
