// Copyright © Veeam Software Group GmbH

import React, { createContext, useState, useContext } from 'react';

import type { FC } from 'react';

import { exploreLoader , ExploreTreeModel } from '../pages/ExplorePage/models/explore-tree-model';

const ExploreTreePersistentContext = createContext<ExploreTreeModel>(undefined as any);

export const useExploreTreeModel = () => useContext(ExploreTreePersistentContext);

export const ExploreTreeProvider: FC = ({ children }) => {
    const [model] = useState(() => new ExploreTreeModel(exploreLoader));
    return (
        <ExploreTreePersistentContext.Provider value={model}>
            {children}
        </ExploreTreePersistentContext.Provider>
    );
};
