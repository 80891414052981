// Copyright © Veeam Software Group GmbH

import { Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { z } from 'zod';

import type { Observable } from 'rxjs';
import type { TypeOf } from 'zod';
import type {
    Sorting } from 'infrastructure/client-side-operations';
import type {
    Item,
    UniqueId,
    ExchangeMail } from 'services';
import type { RbacScopeId } from 'services/rbac';
import type { Resources } from 'infrastructure/resources';

import { authController } from 'infrastructure/auth';
import {
    comparators,
    createFilterEngine,
    createSortEngine,
    filters,
} from 'infrastructure/client-side-operations';
import { never } from 'infrastructure/never';
import resources from 'infrastructure/resources';
import { getDatabase } from 'services/database';
import {
    ExchangeItemType,
    Product,
    explorePath as exp,
    SharePointItemType,
    OneDriveItemType,
} from 'services';
import { rbacService } from 'services/rbac';
import { TeamsItemType } from '../../models/Teams';
import { exploreSessionService } from '../../exploreSessions';

import type { RestoreSessionId } from '../models';

export enum BucketItemStatus {
    NeverStarted = 'never-started',
    Running = 'Running',
    Success = 'Success',
    Warning = 'Warning',
    Failed = 'Failed',
    Stopping = 'Stopping',
}

export type BucketItem = Item & {
    bucketTitle: string;
    bucketType: string;
    sessionId?: RestoreSessionId;
    scopeId: RbacScopeId;
    restorePointId: string;
};

const res = (): Resources['services']['restoreService']['bucket'] => resources.current.services.restoreService.bucket;

export const mapItemType = (item: Item): string => {
    switch (item.product) {
        case Product.OneDrive:
            switch (item.itemType) {
                case OneDriveItemType.Document:
                    return res().types.oneDriveDocument;
                case OneDriveItemType.Folder:
                    return res().types.oneDriveFolder;
            }
            break;
        case Product.Exchange: {
            switch (item.itemType) {
                case ExchangeItemType.Appointment:
                    return res().types.exchange.Appointment;
                case ExchangeItemType.Contact:
                    return res().types.exchange.Contact;
                case ExchangeItemType.Mail:
                    return res().types.exchange.Mail;
                case ExchangeItemType.StickyNote:
                    return res().types.exchange.StickyNote;
                case ExchangeItemType.Task:
                    return res().types.exchange.task;
            }
            break;
        }
        case Product.SharePoint: {
            switch (item.itemType) {
                case SharePointItemType.ListItem:
                    return res().types.sharePoint.listItem;
                case SharePointItemType.ListFolderItem:
                    return res().types.sharePoint.listFolderItem;
                case SharePointItemType.Document:
                    return res().types.sharePoint.document;
                case SharePointItemType.Folder:
                    return res().types.sharePoint.folder;
            }
            break;
        }
        case Product.Teams: {
            switch (item.itemType) {
                case TeamsItemType.File:
                    return res().types.teams.file;
                case TeamsItemType.Tab:
                    return res().types.teams.tab;
                case TeamsItemType.Post:
                    return res().types.teams.post;
                case TeamsItemType.Channel:
                    return res().types.teams.channel;
                case TeamsItemType.Posts:
                    return res().types.teams.posts;
                case TeamsItemType.Files:
                    return res().types.teams.files;
                case TeamsItemType.Tabs:
                    return res().types.teams.tabs;
                case TeamsItemType.Team:
                    return res().types.teams.team;
                case TeamsItemType.Folder:
                    return res().types.teams.folder;
            }
            break;
        }
        default:
            return never(item);
    }
};

export const isExchangeMail = (item: Item): item is ExchangeMail =>
    item.product === Product.Exchange && item.itemType === ExchangeItemType.Mail;

export enum BucketItemSortKeys {
    Title = 'Title',
    Type = 'Type',
    Location = 'Location',
    Sent = 'Sent',
    Received = 'Received',
}

export const BucketItemsFilterSchema = z.object({
    search: z
        .string()
        .nullish()
        .transform(val => val || ''),
    type: z
        .string()
        .nullish()
        .transform(val => val || ''),
    status: z
        .array(z.nativeEnum(BucketItemStatus))
        .nullish()
        .transform(val => val || []),
});

export type BucketItemFilter = TypeOf<typeof BucketItemsFilterSchema>;

interface GetBucketItemsRequest {
    filter: BucketItemFilter;
    sorting: Sorting<typeof BucketItemSortKeys> | undefined;
}

export const filterBucketItems = createFilterEngine<BucketItem, BucketItemFilter>({
    search: filters.search(item => item.bucketTitle),
    status: filters.skip,
    type: filters.search(item => item.bucketType),
});

export const sortBucketItems = createSortEngine<BucketItem, typeof BucketItemSortKeys>({
    Title: (one, two) => comparators.string(one.bucketTitle, two.bucketTitle),
    Type: (one, two) => comparators.string(one.bucketType, two.bucketType),
    Location: (one, two) => comparators.string(exp.stringify(one.explorePath), exp.stringify(two.explorePath)),
    Sent: (one, two) =>
        comparators.date(isExchangeMail(one) ? one.sent : undefined, isExchangeMail(two) ? two.sent : undefined),
    Received: (one, two) =>
        comparators.date(
            isExchangeMail(one) ? one.received : undefined,
            isExchangeMail(two) ? two.received : undefined
        ),
});

class BucketService {
    onItemsCountChangedSubject = new Subject<number>();

    constructor() {
        authController.events.logout.after.subscribe(async() => (await getDatabase()).recoveryList.clear());

        rbacService.onScopeChanged.subscribe(async() => {
            this.onItemsCountChangedSubject.next(await this.getItemsCount());
        });

        exploreSessionService.events.restorePoint.changed.subscribe(async() => {
            this.onItemsCountChangedSubject.next(await this.getItemsCount());
        });

        this.getItems = this.getItems.bind(this);
    }

    async addItems(newItems: Item[]): Promise<void> {
        const restorePoint = exploreSessionService.getRestorePoint();
        const db = await getDatabase();
        const currentItems = await this.getItemsForCurrentRestorePoint();
        const currentScopeId = rbacService.info.scope.id;

        const bucketItems = newItems
            .filter(newItem => currentItems.every(currentItem => currentItem.uniqId !== newItem.uniqId))
            .map(item => ({
                ...item,
                bucketTitle: item.title,
                bucketType: mapItemType(item),
                scopeId: currentScopeId,
                restorePointId: restorePoint.id,
            } as BucketItem));

        await db.recoveryList.addItems(bucketItems);

        this.onItemsCountChangedSubject.next(await this.getItemsCount());
    }

    async removeItem(ids: UniqueId[]): Promise<void> {
        const db = await getDatabase();
        await db.recoveryList.deleteItems(ids);

        this.onItemsCountChangedSubject.next(await this.getItemsCount());
    }

    private async getItemsForCurrentRestorePoint(): Promise<BucketItem[]> {
        const db = await getDatabase();
        const items = await db.recoveryList.getItems();
        const currentScopeId = rbacService.info.scope.id;
        const restorePoint = exploreSessionService.getRestorePoint();

        return items
            .filter(item => item.scopeId === currentScopeId)
            .filter(item => item.restorePointId === restorePoint.id);
    }

    getItems({ sorting, filter }: GetBucketItemsRequest): Observable<BucketItem[]> {
        return from(this.getItemsForCurrentRestorePoint()).pipe(
            map(items => filterBucketItems(filter, items)),
            map(items => sortBucketItems(sorting, items))
        );
    }

    async getItemsCount(): Promise<number> {
        const restorePoint = exploreSessionService.getRestorePoint();

        if (!restorePoint) return 0;

        const items = await this.getItemsForCurrentRestorePoint();

        return items.length;
    }

    async isItemInRestoreList(id: UniqueId): Promise<boolean> {
        const db = await getDatabase();

        return await db.recoveryList.isItemExist(id);
    }
}

export const bucket = new BucketService();
