// Copyright © Veeam Software Group GmbH

import React from 'react';
import { useRouter } from '@veeam/core';

import type { ReactElement } from 'react';

import { Routes } from 'features/Routing';
import { ExplorePage } from 'pages/ExplorePage';
import { RestoreSessions } from 'features/RestoreSessions';
import { RecoveryList } from 'features/RecoveryList';

export function useRouting(): ReactElement | null {
    const { content } = useRouter({
        match: {
            [Routes.explore]: () => <ExplorePage />,
            [Routes.restoreSessions]: () => <RestoreSessions />,
            [Routes.recoveryList]: () => <RecoveryList />,
        },
    });
    return content;
}
