// Copyright © Veeam Software Group GmbH

import { restoreService as restore,
    OneDriveRestoreType,
    SharePointVersion, SharePointLastVersionAction,
} from './restore';
import {
    sessionsService, useRestoreSessionTitle,
    RestoreSessionsFilterSchema, RestoreSessionsSortKeys, GetRestoreSessionsPeriod,
    RestoreSessionEventsFilterSchema,
} from './sessions';
import { bucket, BucketItemSortKeys, BucketItemStatus, BucketItemsFilterSchema } from './bucket';
import { groupByRestoreType } from './groupByRestoreType';

export type { ExchangeRestoreOptions, OneDriveRestoreOptions, SharePointRestoreOptions } from './restore';
export type { RestorableGroups } from './groupByRestoreType';
export type { BucketItem, BucketItemFilter } from './bucket';
export type { RestoreSessionsFilter, RestoreSessionEventsFilter } from './sessions';

export * from './models';
export {
    useRestoreSessionTitle,
    RestoreSessionsFilterSchema, RestoreSessionsSortKeys, GetRestoreSessionsPeriod,
    RestoreSessionEventsFilterSchema,
    BucketItemSortKeys, BucketItemStatus, BucketItemsFilterSchema,
    OneDriveRestoreType,
    SharePointVersion, SharePointLastVersionAction,
};

export const restoreService = {
    restore,
    sessions: sessionsService,
    bucket,
    groupByRestoreType,
};
