// Copyright © Veeam Software Group GmbH

import type { BucketItem } from 'services/restoreService';
import type { UniqueId } from 'services';

import { createRecoveryListStorage } from './recoveryListStorage';
import { exploreSessionService } from '../../exploreSessions';
import { rbacService } from '../../rbac';

import type { RecoveryListTable } from '../RecoveryListTable';

export const createRecoveryListTable = (): RecoveryListTable => {
    const recoveryListStorage = createRecoveryListStorage();
    const getItems = (): BucketItem[] => recoveryListStorage.get() || [];
    return {
        getItems: () => new Promise((resolve) => {
            const items = getItems();
            resolve(items);
        }),
        getItemsCount: () => new Promise((resolve) => {
            const items = getItems();
            resolve(items.length);
        }),
        clear: () => new Promise((resolve) => {
            recoveryListStorage.clear();
            resolve();
        }),
        addItems: newItems => new Promise((resolve) => {
            const oldItems = getItems();
            const items = [...oldItems, ...newItems];
            recoveryListStorage.save(items);
            resolve();
        }),
        deleteItems: ids => new Promise((resolve) => {
            const oldItems = getItems();
            const items = oldItems.filter(item => !ids.some(id => id === item.uniqId));
            recoveryListStorage.save(items);
            resolve();
        }),
        setSessionId: (id, sessionId) => new Promise((resolve) => {
            const items = getItems();
            const found = items.find(item => item.uniqId === id);
            if (found === undefined) resolve();
            else {
                found.sessionId = sessionId;
                recoveryListStorage.save(items);
            }
        }),
        isItemExist: async(id: UniqueId): Promise<boolean> => {
            const items = getItems();
            const item = items.find(item => item.uniqId = id);
            const currentScopeId = rbacService.info.scope.id;
            const restorePoint = exploreSessionService.getRestorePoint();

            return item && item.scopeId === currentScopeId && item.restorePointId === restorePoint.id;
        },
    };
};
