// Copyright © Veeam Software Group GmbH

import type { Storage } from 'infrastructure/storage';
import type { BucketItem } from 'services/restoreService';

import { serializeComplexType, deserializeComplexType } from 'infrastructure/serialization';
import { StorageKeys } from 'infrastructure/storage';


export const createRecoveryListStorage = (): Storage<BucketItem[]> => ({
    get: () => {
        const raw = localStorage.getItem(StorageKeys.RecoveryListTable);
        if (raw === null) return undefined;
        const serializedItems = JSON.parse(raw);
        const deserializedItems = deserializeComplexType(serializedItems) as BucketItem[];
        return deserializedItems;
    },
    save: (items) => {
        const serializedItems = serializeComplexType(items);
        const raw = JSON.stringify(serializedItems);
        localStorage.setItem(StorageKeys.RecoveryListTable, raw);
    },
    clear: () => localStorage.removeItem(StorageKeys.RecoveryListTable),
});
