// Copyright © Veeam Software Group GmbH

import Dexie from 'dexie';

import type { UniqueId } from 'services/models';
import type { BucketItem } from 'services/restoreService/bucket';

export class DixieDb extends Dexie {
    recoveryList: Dexie.Table<BucketItem, UniqueId>;
    constructor() {
        super('SelfServicePortal');
        this.version(1).stores({
            recoveryList: 'uniqId, scopeId',
        });
        this.recoveryList = this.table('recoveryList');
    }
}
