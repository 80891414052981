/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTSite,
    RESTExceptionInfo,
    RESTSite,
} from '../models';

export interface OrganizationSiteApiOrganizationSiteGetRequest {
    organizationId: string;
    limit: number;
    offset: number;
    setId?: string;
    includePersonalSites?: boolean;
    parentId?: string;
    locationFilter?: OrganizationSiteGetLocationFilterEnum;
    filter?: string;
    dataSource?: OrganizationSiteGetDataSourceEnum;
}

export interface OrganizationSiteApiOrganizationSiteGetByIdRequest {
    organizationId: string;
    siteId: string;
    dataSource?: OrganizationSiteGetByIdDataSourceEnum;
}

/**
 * no description
 */
export const organizationSiteApi = ({

    /**
     * Returns a collection of an organization sites.
     * Get SharePoint Sites
     */
    organizationSiteGet: ({ organizationId, limit, offset, setId, includePersonalSites, parentId, locationFilter, filter, dataSource }: OrganizationSiteApiOrganizationSiteGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTSite>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationSiteGet');
        throwIfNullOrUndefined(limit, 'limit', 'organizationSiteGet');
        throwIfNullOrUndefined(offset, 'offset', 'organizationSiteGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (setId != null) { query['setId'] = setId; }
        if (includePersonalSites != null) { query['includePersonalSites'] = includePersonalSites; }
        if (parentId != null) { query['parentId'] = parentId; }
        if (locationFilter != null) { query['locationFilter'] = locationFilter; }
        if (filter != null) { query['filter'] = filter; }
        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<PageOfRESTSite>({
            url: '/v7/Organizations/{organizationId}/Sites'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization site with the specified ID.
     * Get SharePoint Site
     */
    organizationSiteGetById: ({ organizationId, siteId, dataSource }: OrganizationSiteApiOrganizationSiteGetByIdRequest, option?: RequestOption): Observable<Optional<RESTSite>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationSiteGetById');
        throwIfNullOrUndefined(siteId, 'siteId', 'organizationSiteGetById');

        const query: HttpQuery = {};

        if (dataSource != null) { query['dataSource'] = dataSource; }

        return requestAuthenticated<RESTSite>({
            url: '/v7/Organizations/{organizationId}/Sites/{siteId}'.replace('{organizationId}', encodeURI(organizationId)).replace('{siteId}', encodeURI(siteId)),
            method: 'GET',
            query,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum OrganizationSiteGetLocationFilterEnum {
    Cloud = 'Cloud',
    OnPremises = 'OnPremises',
    Any = 'Any'
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationSiteGetDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
/**
 * @export
 * @enum {string}
 */
export enum OrganizationSiteGetByIdDataSourceEnum {
    PreferLocal = 'PreferLocal',
    Production = 'Production',
    PreferLocalResynced = 'PreferLocalResynced'
}
