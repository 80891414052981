// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { Optional, RequestOption } from 'api/rxjs';
import type { Func0 } from 'infrastructure/types';

import { errorManager, getErrorMessage } from 'infrastructure/error-management';

export interface Fetch<TRequest, TResponse>{
    (request: TRequest, options?: RequestOption): Observable<Optional<TResponse>>;
}

const isSessionError = (error: unknown): boolean => {
    const errorDescription = getErrorMessage(error);

    return errorManager.isSessionError(errorDescription);
};

export function renewSession<TRequest, TResponse>(fetch: Fetch<TRequest, TResponse>, renew: Func0<Observable<string>>): Fetch<TRequest, TResponse> {
    return rq => fetch(rq, { registerError: false }).pipe(
        switchMap((opt) => {
            if (opt.isError && isSessionError(opt.error)) {
                return renew().pipe(
                    switchMap(newSessionId => fetch({ ...rq, restoreSessionId: newSessionId })),
                );
            }
            return of(opt);
        })
    );
}
