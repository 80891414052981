// Copyright © Veeam Software Group GmbH

/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ACTION_VIEW, DialogActions } from '@veeam/components';

import type { DatetimeInputFooterRendererProps } from '@veeam/components';

import { selectLatestRestorePointSrc } from 'assets';

export function createFooter(isApplyActionDisabled: boolean, onSelectLatestRestorePoint: () => void) {
    return function footerControl({
        apply,
        cancel,
    }: DatetimeInputFooterRendererProps) {
        return (
            <DialogActions
                secondary={[
                    {
                        text: 'Select Latest Point',
                        iconSrc: selectLatestRestorePointSrc,
                        view: ACTION_VIEW.text,
                        onClick: () => {
                            onSelectLatestRestorePoint();
                            cancel();
                        },
                    },
                ]}
                primary={[
                    {
                        text: 'Apply',
                        onClick: apply,
                        disabled: isApplyActionDisabled,
                    },
                    {
                        text: 'Cancel',
                        view: ACTION_VIEW.secondary,
                        onClick: cancel,
                    },
                ]}
            />
        );
    };
}
