// Copyright © Veeam Software Group GmbH

import React, { createContext, useContext, useEffect, useState } from 'react';
import { Subject } from 'rxjs';

import type { FC } from 'react';
import type { RbacScope } from 'services/rbac';

import { getDefaultScope, rbacService } from 'services/rbac';

export interface RbacContext {
    isOperator?: boolean;
    currentScope: RbacScope;
    currentScopeLabel: string;
    changeScope(): void;
}

const RbacContextReact = createContext<RbacContext>({
    isOperator: false,
    currentScope: getDefaultScope(),
    currentScopeLabel: '',
    changeScope: () => { },
});

export const useRbac = (): RbacContext => useContext(RbacContextReact);

const onScopeChangeRequest = new Subject<{}>();

const getCurrentScopeLabel = (scope: RbacScope): string => scope.id === rbacService.info.operatorId ? '' : scope.title;

export const RbacContextProvider: FC = ({ children }) => {
    const [isOperator, setOperator] = useState(rbacService.info.isOperator);
    const [currentScope, setCurrentScope] = useState<RbacScope>(rbacService.info.scope);
    const [currentScopeLabel, setCurrentScopeLabel] = useState<string>(getCurrentScopeLabel(rbacService.info.scope));

    useEffect(() => {
        const subscription = rbacService.onScopeChanged.subscribe((newScope) => {
            setCurrentScope(newScope);
            setCurrentScopeLabel(getCurrentScopeLabel(newScope));
        });

        if (rbacService.info.isOperator === undefined) {
            rbacService.fetchOperatorInfo()
                .then((info) => {
                    if (info) {
                        setOperator(info.isOperator);
                        setCurrentScope(info.scope);
                        setCurrentScopeLabel(getCurrentScopeLabel(info.scope));
                    }
                });
        }

        return subscription.unsubscribe;
    }, []);

    const changeScope = () => onScopeChangeRequest.next({});

    return (
        <RbacContextReact.Provider value={{
            isOperator,
            currentScope,
            currentScopeLabel,
            changeScope,
        }}>
            <>
                {children}
            </>
        </RbacContextReact.Provider>
    );
};
