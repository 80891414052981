// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTTeamsSearchOptions
 */
export interface RESTTeamsSearchOptions {
    /**
     * Specifies the type of Microsoft Teams items that you want to find.
     * @type {string}
     * @memberof RESTTeamsSearchOptions
     */
    type: RESTTeamsSearchOptionsTypeEnum;
    /**
     * Specifies query parameters used to search for Microsoft Teams items.
     * @type {string}
     * @memberof RESTTeamsSearchOptions
     */
    query: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTTeamsSearchOptionsTypeEnum {
    Tabs = 'Tabs',
    Files = 'Files',
    Posts = 'Posts'
}

