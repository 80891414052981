// Copyright © Veeam Software Group GmbH

import { useEffect, useState } from 'react';

import type { Model } from '@veeam/core';
import type { Func0 } from 'infrastructure/types';

export function useModel<TModel extends Model<unknown>>(factory: Func0<TModel>): TModel {
    const [model] = useState(factory);
    useEffect(() => () => model.destroy(), []);
    return model;
}
