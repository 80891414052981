// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTCopyJobSchedulePolicy,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTCopyJob
 */
export interface RESTCopyJob {
    /**
     * Backup job ID.
     * @type {string}
     * @memberof RESTCopyJob
     */
    backupJobId?: string;
    /**
     * @type {RESTCopyJobSchedulePolicy}
     * @memberof RESTCopyJob
     */
    schedulePolicy?: RESTCopyJobSchedulePolicy;
    /**
     * Backup copy job ID.
     * @type {string}
     * @memberof RESTCopyJob
     */
    id?: string;
    /**
     * ID of the backup repository extended with Azure Blob Storage Archive access tier, Amazon S3 Glacier Instant Retrieval, Amazon S3 Glacier Flexible Retrieval or Amazon S3 Glacier Deep Archive storage classes.
     * @type {string}
     * @memberof RESTCopyJob
     */
    repositoryId?: string;
    /**
     * Name of the backup copy job.
     * @type {string}
     * @memberof RESTCopyJob
     */
    name?: string;
    /**
     * Date and time of the last run of the backup copy job.
     * @type {string}
     * @memberof RESTCopyJob
     */
    lastRun?: string;
    /**
     * Date and time of the next run of the backup copy job per schedule.
     * @type {string}
     * @memberof RESTCopyJob
     */
    nextRun?: string;
    /**
     * Date and time of the last successful run of the backup copy job.
     * @type {string}
     * @memberof RESTCopyJob
     */
    lastBackup?: string;
    /**
     * Defines whether the backup copy job is enabled.
     * @type {boolean}
     * @memberof RESTCopyJob
     */
    isEnabled?: boolean;
    /**
     * Latest status of the backup copy job.
     * @type {string}
     * @memberof RESTCopyJob
     */
    lastStatus?: RESTCopyJobLastStatusEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTCopyJob
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTCopyJobLastStatusEnum {
    Stopped = 'Stopped',
    Running = 'Running',
    Success = 'Success',
    Failed = 'Failed',
    Warning = 'Warning',
    NotConfigured = 'NotConfigured',
    Disconnected = 'Disconnected',
    Queued = 'Queued'
}

