// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';
import { toolbarAction, toolbarSearchFilter } from '@veeam/components';

import type { Form } from '@veeam/core';
import type { Resources } from 'infrastructure/resources';
import type { RestoreItemsModel } from 'features/Restore/Wizards/Shared/ItemsStep/useModel';
import type { BucketItemPresentation } from 'features/RecoveryList/useModel';
import type { BucketItem } from 'services/restoreService';
import type { FormWithBucketItems } from 'features/Restore/Wizards/Shared';

import { deleteSrc } from 'assets';


interface ToolbarProps {
    form: Form<FormWithBucketItems>;
    model: RestoreItemsModel;
    resources: Resources['features']['Restore']['Wizards']['Shared']['ItemsStep'];
}

export const useToolbar = ({ form, model, resources }: ToolbarProps) => useMemo(() => {
    const searchFilter = toolbarSearchFilter({
        fieldConfig: { placeholder: resources.name },
        property: 'search',
    });
    const removeAction = toolbarAction({
        title: resources.removeItemActionTitle,
        icon: deleteSrc,
        decorators: [(payload) => {
            let disabled = false;

            if (payload.selectedItems?.length === 0 || form.getValue().items.length === 1) disabled = true;

            return { disabled };
        }],
        handler: () => {
            const selectedItemId = model.getValue().selection[0];
            const newValue = (form.getValue().items as BucketItem[]).filter(item => item.uniqId !== selectedItemId);

            model.update((mutable) => {
                mutable.items = newValue as BucketItemPresentation[];
            });

            form.fields.items.update(newValue as FormWithBucketItems['items']);

            model.load();
        },
    });

    return {
        items: [
            searchFilter,
            removeAction,
        ],
    };
}, []);
