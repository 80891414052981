// Copyright © Veeam Software Group GmbH

import React from 'react';
import { AppBarProfile, Icon } from '@veeam/components';

import type { FC } from 'react';

import { useResources } from 'infrastructure/resources';
import { logOut16Src } from 'assets';
import { useLogin } from 'features/Login';
import { useRbac } from 'features/Rbac';

export const LoginProfile: FC = () => {
    const { username, logout } = useLogin();
    const { isOperator } = useRbac();
    const resources = useResources().features.Main.Profile;

    return (
        <AppBarProfile
            title={username}
            description={isOperator && resources.operatorDescription}
            menuItems={[
                { text: resources.logout, onClick: logout, icon: <Icon src={logOut16Src} /> },
            ]}
        />
    );
};
