// Copyright © Veeam Software Group GmbH

import React from 'react';
import { Link } from '@veeam/components';

import type { DataGridCellRenderer } from '@veeam/components';
import type { Action0, Func1 } from 'infrastructure/types';

import { noTextWrap } from './noValue';

import type { CellRender, CellRendererProps } from './CellRender';

export function actionCellRender(
    value: string | undefined,
    props: CellRendererProps,
    action: Action0,
): CellRender {
    return <Link onClick={action}>{noTextWrap(value)}</Link>;
}

export interface ActionCell {
    text?: string;
    action: Action0;
}

export function actionCell<TRow>(value: Func1<TRow, ActionCell>): DataGridCellRenderer<TRow> {
    return (row, payload) => {
        const { text, action } = value(row);
        return actionCellRender(text, payload, action);
    };
}
