// Copyright © Veeam Software Group GmbH

import React from 'react';
import { WizardSummary } from '@veeam/components';
import { renderMultilineText } from '@veeam/vbc-components';

import type { FC } from 'react';
import type { Form } from '@veeam/core';
import type { Resources } from 'infrastructure/resources';
import type { Fieldset } from 'infrastructure/types';

import { getReasonSummary } from '../../Shared';

import type { TeamsRestoreForm } from '../interfaces/teams-restore-form';

type StepResources = Resources['features']['Restore']['Wizards'];

const getItemsSummary = (
    value: TeamsRestoreForm,
    resources: StepResources
): Fieldset => ({
    title: resources.TeamsRestoreWizard.itemsTitle,
    fields: [
        {
            label: resources.TeamsRestoreWizard.itemsSummaryLabel,
            value: renderMultilineText(
                value.items.map(item => resources.TeamsRestoreWizard.documentXname.format(item.title)).join('\n')
            ),
        },
    ],
});

export const SummaryStep: FC<{
    form: Form<TeamsRestoreForm>;
    resources: StepResources;
}> = ({
    form,
    resources,
}) => {
    const value = form.getValue();
    return (
        <WizardSummary
            fieldsets={[
                getItemsSummary(value, resources),
                getReasonSummary(value, resources.Shared.ReasonStep),
            ]}
        />
    );
};

export const useSummaryStep = (formApi: Form<TeamsRestoreForm>, resources: StepResources) => ({
    tabTitle: resources.TeamsRestoreWizard.summary,
    tag: 'summary',
    body: <SummaryStep form={formApi} resources={resources} />,
    description: resources.TeamsRestoreWizard.summary,
});

