// Copyright © Veeam Software Group GmbH

import { sprintf } from 'sprintf-js';

export interface LocalizableStringExtension {
    format(...args: unknown[]): LocalizableString;
}

export type LocalizableString = string & LocalizableStringExtension;

export function format(this: string, ...args: unknown[]): LocalizableString {
    // eslint-disable-next-line babel/no-invalid-this
    return sprintf(this, ...args) as LocalizableString;
}
