// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTAction,
} from './';

/**
 * @export
 * @interface RESTLoggedInOrganization
 */
export interface RESTLoggedInOrganization {
    /**
     * ID of the organization added to Veeam Backup for Microsoft 365.
     * @type {string}
     * @memberof RESTLoggedInOrganization
     */
    id?: string;
    /**
     * Name of the Microsoft organization.
     * @type {string}
     * @memberof RESTLoggedInOrganization
     */
    name?: string;
    /**
     * Description of the Microsoft organization.
     * @type {string}
     * @memberof RESTLoggedInOrganization
     */
    description?: string;
    /**
     * Type of the organization.
     * @type {string}
     * @memberof RESTLoggedInOrganization
     */
    type?: RESTLoggedInOrganizationTypeEnum;
    /**
     * Defines whether the organizations was backed up.
     * @type {boolean}
     * @memberof RESTLoggedInOrganization
     */
    isBackedup?: boolean;
    /**
     * Date and time when the first backup was created for the organization.
     * @type {string}
     * @memberof RESTLoggedInOrganization
     */
    firstBackuptime?: string;
    /**
     * Date and time when the last backup was created for the organization.
     * @type {string}
     * @memberof RESTLoggedInOrganization
     */
    lastBackuptime?: string;
    /**
     * @type {{ [key: string]: RESTAction; }}
     * @memberof RESTLoggedInOrganization
     */
    readonly _actions?: { [key: string]: RESTAction; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTLoggedInOrganizationTypeEnum {
    OnPremises = 'OnPremises',
    Office365 = 'Office365',
    Hybrid = 'Hybrid'
}

