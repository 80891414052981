// Copyright © Veeam Software Group GmbH

import React from 'react';
import { FormLayout, RadioField } from '@veeam/components';

import type { Form } from '@veeam/core';
import type { FC } from 'react';
import type { WizardStepConfig } from 'components/wizard';
import type { Fieldset } from 'infrastructure/types';
import type { Resources, LocalizableString } from 'infrastructure/resources';

import { never } from 'infrastructure/never';
import { PageSection } from 'components/wizard';

export enum OneDriveRestoreMode {
    Keep = 'Keep',
    Overwrite = 'Overwrite',
}

export interface FormWithOneDriveRestoreMode {
    restoreMode: OneDriveRestoreMode;
}

type WizardResources = Resources['features']['Restore']['Wizards']['OneDriveRestoreWizard']['OneDriveRestoreModeStep'];

function mapLabel(type: OneDriveRestoreMode, resources: WizardResources): LocalizableString {
    switch (type) {
        case OneDriveRestoreMode.Overwrite:
            return resources.overwriteLabel;
        case OneDriveRestoreMode.Keep:
            return resources.keepLabel;
        default:
            return never(type);
    }
}

const OneDriveRestoreModeForm: FC<{
    form: Form<FormWithOneDriveRestoreMode>;
    resources: WizardResources;
}> = ({ form, resources }) => (
    <PageSection title={resources.title} description={resources.description}>
        <FormLayout>
            <RadioField
                label={resources.overwriteLabel}
                helpText={resources.overwriteDescription}
                field={form.fields.restoreMode}
                value={OneDriveRestoreMode.Overwrite}
            />
            <RadioField
                label={resources.keepLabel}
                helpText={resources.keepDescription}
                field={form.fields.restoreMode}
                value={OneDriveRestoreMode.Keep}
            />
        </FormLayout>
    </PageSection>
);

export const useOneDriveRestoreModeStep = (
    form: Form<FormWithOneDriveRestoreMode>,
    resources: WizardResources
): WizardStepConfig => ({
    tabTitle: resources.title,
    tag: 'items',
    body: <OneDriveRestoreModeForm form={form} resources={resources} />,
});

export const getOneDriveRestoreModeSummary = (
    value: FormWithOneDriveRestoreMode,
    resources: WizardResources
): Fieldset => ({
    title: resources.restoreSummary,
    fields: [{ label: resources.title, value: mapLabel(value.restoreMode, resources) }],
});
