// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRbacGroup
 */
export interface RESTRbacGroup {
    /**
     * @type {string}
     * @memberof RESTRbacGroup
     */
    id: string;
    /**
     * @type {string}
     * @memberof RESTRbacGroup
     */
    onPremisesSid?: string;
    /**
     * @type {string}
     * @memberof RESTRbacGroup
     */
    displayName: string;
    /**
     * @type {string}
     * @memberof RESTRbacGroup
     */
    name: string;
    /**
     * @type {string}
     * @memberof RESTRbacGroup
     */
    type: RESTRbacGroupTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRbacGroupTypeEnum {
    Office365 = 'Office365',
    Security = 'Security',
    Distribution = 'Distribution',
    DynamicDistribution = 'DynamicDistribution',
    Unknown = 'Unknown'
}

