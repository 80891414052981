// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTRepositorySynchronizeSession
 */
export interface RESTRepositorySynchronizeSession {
    /**
     * Synchronization session ID.
     * @type {string}
     * @memberof RESTRepositorySynchronizeSession
     */
    id?: string;
    /**
     * ID of the backup repository being synchronized.
     * @type {string}
     * @memberof RESTRepositorySynchronizeSession
     */
    repositoryId?: string;
    /**
     * Synchronization state.
     * @type {string}
     * @memberof RESTRepositorySynchronizeSession
     */
    state?: RESTRepositorySynchronizeSessionStateEnum;
    /**
     * Synchronization progress in percents.
     * @type {number}
     * @memberof RESTRepositorySynchronizeSession
     */
    progressPercent?: number;
    /**
     * Error occured when synchronizing the backup repository.
     * @type {string}
     * @memberof RESTRepositorySynchronizeSession
     */
    error?: string;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTRepositorySynchronizeSession
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRepositorySynchronizeSessionStateEnum {
    None = 'None',
    Synchronizing = 'Synchronizing',
    Failed = 'Failed'
}

