// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RESTRestoreFileOptions
 */
export interface RESTRestoreFileOptions {
    /**
     * Defines whether to restore files that have been modified in the original location since the time when the backup was created.
     * @type {boolean}
     * @memberof RESTRestoreFileOptions
     */
    restoreChangedItems: boolean;
    /**
     * Defines whether to restore files that are missed in the original location.
     * @type {boolean}
     * @memberof RESTRestoreFileOptions
     */
    restoreMissingItems: boolean;
    /**
     * Specifies what version of files will be restored.
     * @type {string}
     * @memberof RESTRestoreFileOptions
     */
    fileVersion: RESTRestoreFileOptionsFileVersionEnum;
    /**
     * Specifies the authentication code. For more information on how to get a device code, see [Get Device Code](#tag/RestoreSession/operation/RestoreSession_DeviceCodeAction). This property is required if you want to use a device code for data restore. 
     * @type {string}
     * @memberof RESTRestoreFileOptions
     */
    userCode?: string;
    /**
     * Specifies the ID of the Azure AD application that you want to use for restore.
     * @type {string}
     * @memberof RESTRestoreFileOptions
     */
    applicationId?: string;
    /**
     * Specifies the SSL certificate configured for the Azure AD application that you want to use for data restore. You must provide the certificate as a Base64 string.
     * @type {string}
     * @memberof RESTRestoreFileOptions
     */
    applicationCertificate?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreFileOptions
     */
    applicationCertificatePassword?: string;
    /**
     * Specifies the user name that you want to use for authenticating to the organization.
     * @type {string}
     * @memberof RESTRestoreFileOptions
     */
    userName?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTRestoreFileOptions
     */
    userPassword?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreFileOptionsFileVersionEnum {
    All = 'All',
    Last = 'Last'
}

