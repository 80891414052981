/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTAmazonBucketS3Aws,
    RESTAmazonFolderToReceiveS3Aws,
    RESTAmazonFolderToSend,
    RESTExceptionInfo,
} from '../models';

export interface AmazonS3ObjectStorageApiAmazonS3ObjectStorageAddFolderRequest {
    bucketName: string;
    accountId: string;
    regionType: AmazonS3ObjectStorageAddFolderRegionTypeEnum;
    body: RESTAmazonFolderToSend;
}

export interface AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetBucketRequest {
    name: string;
    accountId: string;
    regionType: AmazonS3ObjectStorageGetBucketRegionTypeEnum;
}

export interface AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetBucketsRequest {
    accountId: string;
    regionType: AmazonS3ObjectStorageGetBucketsRegionTypeEnum;
    regionId?: string;
    name?: string;
}

export interface AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetFolderRequest {
    name: string;
    bucketName: string;
    accountId: string;
    regionType: AmazonS3ObjectStorageGetFolderRegionTypeEnum;
}

export interface AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetFoldersRequest {
    bucketName: string;
    accountId: string;
    regionType: AmazonS3ObjectStorageGetFoldersRegionTypeEnum;
    name?: string;
}

/**
 * no description
 */
export const amazonS3ObjectStorageApi = ({

    /**
     * Creates a new folder in the specified Amazon bucket.
     * Create Folders
     */
    amazonS3ObjectStorageAddFolder: ({ bucketName, accountId, regionType, body }: AmazonS3ObjectStorageApiAmazonS3ObjectStorageAddFolderRequest, option?: RequestOption): Observable<Optional<RESTAmazonFolderToReceiveS3Aws>> => {
        throwIfNullOrUndefined(bucketName, 'bucketName', 'amazonS3ObjectStorageAddFolder');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3ObjectStorageAddFolder');
        throwIfNullOrUndefined(regionType, 'regionType', 'amazonS3ObjectStorageAddFolder');
        throwIfNullOrUndefined(body, 'body', 'amazonS3ObjectStorageAddFolder');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        return requestAuthenticated<RESTAmazonFolderToReceiveS3Aws>({
            url: '/v7/S3Resources/buckets/{bucketName}/folders'.replace('{bucketName}', encodeURI(bucketName)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

    /**
     * Returns information about an Amazon S3 bucket with the specified name.
     * Get Bucket by Name
     */
    amazonS3ObjectStorageGetBucket: ({ name, accountId, regionType }: AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetBucketRequest, option?: RequestOption): Observable<Optional<RESTAmazonBucketS3Aws>> => {
        throwIfNullOrUndefined(name, 'name', 'amazonS3ObjectStorageGetBucket');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3ObjectStorageGetBucket');
        throwIfNullOrUndefined(regionType, 'regionType', 'amazonS3ObjectStorageGetBucket');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        return requestAuthenticated<RESTAmazonBucketS3Aws>({
            url: '/v7/S3Resources/buckets/{Name}'.replace('{Name}', encodeURI(name)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a list of Amazon S3 buckets.
     * Get Buckets
     */
    amazonS3ObjectStorageGetBuckets: ({ accountId, regionType, regionId, name }: AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetBucketsRequest, option?: RequestOption): Observable<Optional<Array<RESTAmazonBucketS3Aws>>> => {
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3ObjectStorageGetBuckets');
        throwIfNullOrUndefined(regionType, 'regionType', 'amazonS3ObjectStorageGetBuckets');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        if (regionId != null) { query['RegionId'] = regionId; }
        if (name != null) { query['Name'] = name; }

        return requestAuthenticated<Array<RESTAmazonBucketS3Aws>>({
            url: '/v7/S3Resources/buckets',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns information about an Amazon folder with the specified name.
     * Get Folder by Name
     */
    amazonS3ObjectStorageGetFolder: ({ name, bucketName, accountId, regionType }: AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetFolderRequest, option?: RequestOption): Observable<Optional<RESTAmazonFolderToReceiveS3Aws>> => {
        throwIfNullOrUndefined(name, 'name', 'amazonS3ObjectStorageGetFolder');
        throwIfNullOrUndefined(bucketName, 'bucketName', 'amazonS3ObjectStorageGetFolder');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3ObjectStorageGetFolder');
        throwIfNullOrUndefined(regionType, 'regionType', 'amazonS3ObjectStorageGetFolder');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        return requestAuthenticated<RESTAmazonFolderToReceiveS3Aws>({
            url: '/v7/S3Resources/buckets/{bucketName}/folders/{Name}'.replace('{Name}', encodeURI(name)).replace('{bucketName}', encodeURI(bucketName)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a list of Amazon folders created in the specified bucket.
     * Get Folders
     */
    amazonS3ObjectStorageGetFolders: ({ bucketName, accountId, regionType, name }: AmazonS3ObjectStorageApiAmazonS3ObjectStorageGetFoldersRequest, option?: RequestOption): Observable<Optional<Array<RESTAmazonFolderToReceiveS3Aws>>> => {
        throwIfNullOrUndefined(bucketName, 'bucketName', 'amazonS3ObjectStorageGetFolders');
        throwIfNullOrUndefined(accountId, 'accountId', 'amazonS3ObjectStorageGetFolders');
        throwIfNullOrUndefined(regionType, 'regionType', 'amazonS3ObjectStorageGetFolders');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'accountId': accountId,
            'RegionType': regionType,
        };

        if (name != null) { query['Name'] = name; }

        return requestAuthenticated<Array<RESTAmazonFolderToReceiveS3Aws>>({
            url: '/v7/S3Resources/buckets/{bucketName}/folders'.replace('{bucketName}', encodeURI(bucketName)),
            method: 'GET',
            query,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum AmazonS3ObjectStorageAddFolderRegionTypeEnum {
    Global = 'Global',
    UsGovernment = 'USGovernment',
    China = 'China'
}
/**
 * @export
 * @enum {string}
 */
export enum AmazonS3ObjectStorageGetBucketRegionTypeEnum {
    Global = 'Global',
    UsGovernment = 'USGovernment',
    China = 'China'
}
/**
 * @export
 * @enum {string}
 */
export enum AmazonS3ObjectStorageGetBucketsRegionTypeEnum {
    Global = 'Global',
    UsGovernment = 'USGovernment',
    China = 'China'
}
/**
 * @export
 * @enum {string}
 */
export enum AmazonS3ObjectStorageGetFolderRegionTypeEnum {
    Global = 'Global',
    UsGovernment = 'USGovernment',
    China = 'China'
}
/**
 * @export
 * @enum {string}
 */
export enum AmazonS3ObjectStorageGetFoldersRegionTypeEnum {
    Global = 'Global',
    UsGovernment = 'USGovernment',
    China = 'China'
}
