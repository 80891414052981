// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Retrieval policy that is selected for Amazon S3 Glacier Instant Retrieval, Amazon S3 Glacier Flexible Retrieval and Amazon S3 Glacier Deep Archive object storage.
 * @export
 * @enum {string}
 */
export enum RESTAmazonS3GlacierRetrievalPolicy {
    Expedited = 'Expedited',
    Standard = 'Standard',
    Bulk = 'Bulk'
}

