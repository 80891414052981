// Copyright © Veeam Software Group GmbH

import type { RESTTeamsFile , RESTTeamsChannelPreview, RESTTeamsNode, RESTTeamsTeam } from '../../../api/rxjs';
import type { NodeBase } from '../NodeBase';
import type { Product } from '../Product';

export enum TeamsNodeType {
    Teams = 'Teams',
    Team = 'Team',
    Channel = 'Channel',
    Tabs = 'Tabs',
    Files = 'Files',
    Posts = 'Posts',
    Folder = 'Folder',
}

export interface TeamsRootNode extends NodeBase {
    product: Product.Teams;
    nodeType: TeamsNodeType.Teams;
}

export interface TeamsTeamNode extends RESTTeamsTeam, NodeBase {
    product: Product.Teams;
    nodeType: TeamsNodeType.Team;
}

export interface TeamsChannelNode extends RESTTeamsChannelPreview, NodeBase {
    product: Product.Teams;
    nodeType: TeamsNodeType.Channel;
}

export interface TeamsTabsNode extends RESTTeamsNode, NodeBase {
    product: Product.Teams;
    nodeType: TeamsNodeType.Tabs;
}

export interface TeamsFilesNode extends RESTTeamsNode, NodeBase {
    product: Product.Teams;
    nodeType: TeamsNodeType.Files;
}

export interface TeamsPostsNode extends RESTTeamsNode, NodeBase {
    product: Product.Teams;
    nodeType: TeamsNodeType.Posts;
}

export interface TeamsFolderNode extends RESTTeamsFile, NodeBase {
    product: Product.Teams;
    nodeType: TeamsNodeType.Folder;
}

export type TeamsNode = TeamsRootNode | TeamsTeamNode | TeamsChannelNode | TeamsTabsNode | TeamsFilesNode | TeamsPostsNode | TeamsFolderNode;
