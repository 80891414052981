// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { CollectionToolbarItem } from '@veeam/components';
import type { BucketItemFilter } from 'services/restoreService';

import { neverStartedSrc } from 'assets';
import { restoreSessionStatusIconMap } from 'features/Shared/mappers';
import { createConfigMaker, createToolbarFilters } from 'infrastructure/grid';
import { BucketItemStatus } from 'services/restoreService';

import type { BucketResources } from './resources';


const createFilter = (resources: BucketResources): CollectionToolbarItem[] => {
    const configMaker = createConfigMaker<BucketItemFilter>();
    const searchConfig = configMaker.search('search', { fieldConfig: { placeholder: resources.filter.item } });
    const statusesConfig = configMaker.basic('status', {
        title: resources.filter.statusTitle,
        items: [
            { title: resources.statuses.neverStarted, icon: neverStartedSrc, value: BucketItemStatus.NeverStarted },
            { title: resources.statuses.running, icon: restoreSessionStatusIconMap.Running, value: BucketItemStatus.Running },
            { title: resources.statuses.success, icon: restoreSessionStatusIconMap.Success, value: BucketItemStatus.Success },
            { title: resources.statuses.warning, icon: restoreSessionStatusIconMap.Warning, value: BucketItemStatus.Warning },
            { title: resources.statuses.failed, icon: restoreSessionStatusIconMap.Failed, value: BucketItemStatus.Failed },
        ],
    });
    return createToolbarFilters(searchConfig, statusesConfig);
};

export const useFilter = (resources: BucketResources): CollectionToolbarItem[] =>
    useMemo(() => createFilter(resources), [resources]);
