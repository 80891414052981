// Copyright © Veeam Software Group GmbH

import { forkJoin, of } from 'rxjs';
import { delayWhen, filter, map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type {
    RESTRestoreSharePointDocumentConfig,
    RESTRestoreSharePointItemConfig } from 'api/rxjs';
import type {
    SharePointDocument,
    SharePointFolderItem,
    SharePointListFolderItem,
    SharePointListItem,
    SharePointSiteId,
    VespSession,
} from 'services/models';

import {
    sharePointDocumentApi,
    RESTOperatorRestoreSharePointItemsConfigDocumentVersionEnum as SharePointVersion,
    RESTOperatorRestoreSharePointItemsConfigDocumentLastVersionActionEnum as SharePointLastVersionAction,
    RESTOperatorRestoreSharePointDocumentsConfigDocumentVersionEnum as SharePointDocVersion,
    RESTOperatorRestoreSharePointDocumentsConfigDocumentLastVersionActionEnum as SharePointDocLastVersionAction,
    sharePointItemApi,
    RESTRestoreSharePointDocumentConfigDocumentVersionEnum,
    RESTOperatorRestoreSharePointDocumentsConfigDocumentVersionEnum,
    RESTRestoreSharePointItemConfigDocumentVersionEnum,
    RESTOperatorRestoreSharePointItemsConfigDocumentVersionEnum,
} from 'api/rxjs';
import { groupBy } from 'infrastructure/helpers';
import { never } from 'infrastructure/never';
import { getDatabase } from 'services/database';

import type { RestoreSessionId } from '../models';


export { SharePointVersion, SharePointLastVersionAction };

interface SharePointRestoreOptionsBase {
    list?: string;
    restorePermissions?: boolean;
    sendSharedLinksNotification?: boolean;
    restoreChangedItems?: boolean;
    restoreDeletedItems?: boolean;
    reason: string;
}

export interface SharePointRestoreOptions extends SharePointRestoreOptionsBase {
    documentVersion?: SharePointVersion;
    documentLastVersionAction?: SharePointLastVersionAction;
}

interface SharePointDocRestoreOptions extends SharePointRestoreOptionsBase {
    documentVersion?: SharePointDocVersion;
    documentLastVersionAction?: SharePointDocLastVersionAction;
}

const convertDocumentVersion = (documentVersion: SharePointVersion | undefined): SharePointDocVersion | undefined => {
    if (documentVersion === undefined) return undefined;
    switch (documentVersion) {
        case SharePointVersion.All: return SharePointDocVersion.All;
        case SharePointVersion.Last: return SharePointDocVersion.Last;
        default: return never(documentVersion);
    }
};

const convertDocumentLastVersionAction = (documentLastVersionAction: SharePointLastVersionAction | undefined): SharePointDocLastVersionAction | undefined => {
    if (documentLastVersionAction === undefined) return undefined;
    switch (documentLastVersionAction) {
        case SharePointLastVersionAction.Merge: return SharePointDocLastVersionAction.Merge;
        case SharePointLastVersionAction.Overwrite: return SharePointDocLastVersionAction.Overwrite;
        default: return never(documentLastVersionAction);
    }
};

const convertToDocOptions = (options: SharePointRestoreOptions): SharePointDocRestoreOptions => ({
    ...options,
    documentVersion: convertDocumentVersion(options.documentVersion),
    documentLastVersionAction: convertDocumentLastVersionAction(options.documentLastVersionAction),
});

const restoreByDocSiteId = (vespSession: VespSession, siteId: SharePointSiteId, documents: (SharePointDocument | SharePointFolderItem)[], options: SharePointDocRestoreOptions): Observable<RestoreSessionId | undefined> =>
    sharePointDocumentApi.sharePointDocumentOperatorRestore({
        restoreSessionId: vespSession,
        siteId,
        body: {
            documentsRestoreConfigs: createDocumentsRestoreConfigs(documents, options),
            ...options,
        },
    }).pipe(
        map(response => response.map(undefined)?.sessionId as RestoreSessionId),
        filter((response): response is RestoreSessionId => !!response),
        delayWhen(sessionId => of(Promise.all(documents.map(async doc => await(await getDatabase()).recoveryList.setSessionId(doc.uniqId, sessionId)))))
    );

const createDocumentsRestoreConfigs = (documents: (SharePointDocument | SharePointFolderItem)[], options: SharePointDocRestoreOptions): RESTRestoreSharePointDocumentConfig[] => documents.map(document =>
    ({
        document: document._raw_rest,
        documentVersion: convertDocumentVersionOptions(document, options.documentVersion),
    })
);

const convertDocumentVersionOptions = (document: SharePointDocument | SharePointFolderItem, versionOption?: RESTOperatorRestoreSharePointDocumentsConfigDocumentVersionEnum): RESTRestoreSharePointDocumentConfigDocumentVersionEnum => {
    if (document.isVersion) return RESTRestoreSharePointDocumentConfigDocumentVersionEnum.Last;
    if (versionOption === undefined) return RESTRestoreSharePointDocumentConfigDocumentVersionEnum.All;
    switch (versionOption) {
        case RESTOperatorRestoreSharePointDocumentsConfigDocumentVersionEnum.All: return RESTRestoreSharePointDocumentConfigDocumentVersionEnum.All;
        case RESTOperatorRestoreSharePointDocumentsConfigDocumentVersionEnum.Last: return RESTRestoreSharePointDocumentConfigDocumentVersionEnum.Last;
        default: return never(versionOption);
    }
};

const restoreDocuments = (vespSession: VespSession, documents: (SharePointDocument | SharePointFolderItem)[], options: SharePointRestoreOptions): Observable<RestoreSessionId[]> => {
    const groups = groupBy(documents, doc => doc.siteId);
    const docOptions = convertToDocOptions(options);
    const requests = groups.map(([sharePointDocSiteId, items]) => restoreByDocSiteId(vespSession, sharePointDocSiteId, items, docOptions));
    return forkJoin(requests).pipe(map(responses => responses.filter((xx): xx is RestoreSessionId => !!xx)));
};

const restoreBySiteId = (vespSession: VespSession, siteId: SharePointSiteId, items: (SharePointListItem | SharePointListFolderItem)[], options: SharePointRestoreOptions): Observable<RestoreSessionId | undefined> =>
    sharePointItemApi.sharePointItemOperatorRestore({
        restoreSessionId: vespSession,
        siteId,
        body: {
            itemsRestoreConfigs: createItemsRestoreConfigs(items, options),
            ...options,
        },
    }).pipe(
        map(response => response.map(undefined)?.sessionId as RestoreSessionId),
        filter((response): response is RestoreSessionId => !!response),
        delayWhen(sessionId => of(Promise.all(items.map(async item => await (await getDatabase()).recoveryList.setSessionId(item.uniqId, sessionId))))),
    );

const createItemsRestoreConfigs = (items: (SharePointListItem | SharePointListFolderItem)[], options: SharePointRestoreOptions): RESTRestoreSharePointItemConfig[] => items.map(item =>
    ({
        item: item._raw_rest,
        documentVersion: convertItemVersionOptions(item, options.documentVersion),
    })
);

const convertItemVersionOptions = (item: SharePointListItem | SharePointListFolderItem, versionOption?: RESTOperatorRestoreSharePointItemsConfigDocumentVersionEnum): RESTRestoreSharePointItemConfigDocumentVersionEnum => {
    if (item.isVersion) return RESTRestoreSharePointItemConfigDocumentVersionEnum.Last;
    if (versionOption === undefined) return RESTRestoreSharePointItemConfigDocumentVersionEnum.All;
    switch (versionOption) {
        case RESTOperatorRestoreSharePointItemsConfigDocumentVersionEnum.All: return RESTRestoreSharePointItemConfigDocumentVersionEnum.All;
        case RESTOperatorRestoreSharePointItemsConfigDocumentVersionEnum.Last: return RESTRestoreSharePointItemConfigDocumentVersionEnum.Last;
        default: return never(versionOption);
    }
};

const restoreListItems = (vespSession: VespSession, items: (SharePointListItem | SharePointListFolderItem)[], options: SharePointRestoreOptions): Observable<RestoreSessionId[]>  => {
    const groups = groupBy(items, item => item.siteId);
    const requests = groups.map(([sharePointSiteId, items]) => restoreBySiteId(vespSession, sharePointSiteId, items, options));
    return forkJoin(requests).pipe(map(responses => responses.filter((xx): xx is RestoreSessionId => !!xx)));
};

export const restoreSharePoint = {
    docs: restoreDocuments,
    listItems: restoreListItems,
};
