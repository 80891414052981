/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    RESTExceptionInfo,
    RESTOrganizationSyncOptions,
    RESTOrganizationSyncState,
} from '../models';

export interface OrganizationSyncApiOrganizationSyncGetStateRequest {
    organizationId: string;
}

export interface OrganizationSyncApiOrganizationSyncStartRequest {
    organizationId: string;
    body: RESTOrganizationSyncOptions;
}

/**
 * no description
 */
export const organizationSyncApi = ({

    /**
     * Returns status of synchronization of Microsoft organization objects with the organization cache database.
     * Get Synchronization Status
     */
    organizationSyncGetState: ({ organizationId }: OrganizationSyncApiOrganizationSyncGetStateRequest, option?: RequestOption): Observable<Optional<RESTOrganizationSyncState>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationSyncGetState');

        return requestAuthenticated<RESTOrganizationSyncState>({
            url: '/v7/Organizations/{organizationId}/SyncState'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
        }, option);
    },

    /**
     * Starts synchronization of Microsoft organization objects with the organization cache database.
     * Start Synchronization
     */
    organizationSyncStart: ({ organizationId, body }: OrganizationSyncApiOrganizationSyncStartRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationSyncStart');
        throwIfNullOrUndefined(body, 'body', 'organizationSyncStart');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/Organizations/{organizationId}/Sync'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

})
