// Copyright © Veeam Software Group GmbH

import { z } from 'zod';

import type { TypeOf } from 'zod';

export const ExploreItemsFilterSchema = z.object({
    search: z.string().nullish()
        .transform(val => val || ''),
});

export type ExploreItemsFilter = TypeOf<typeof ExploreItemsFilterSchema>;
