/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTOrganizationStorageSpaceUsage,
    RESTCheckDeviceCode,
    RESTCommonDeviceCodeRequestOptions,
    RESTDeviceCodeCheckResponse,
    RESTDeviceCodeResponse,
    RESTExceptionInfo,
    RESTExploreOptions,
    RESTLoggedInOrganization,
    RESTRestoreSession,
    RestOrganizationComposed,
} from '../models';

export interface OrganizationApiOrganizationAddRequest {
    body: RestOrganizationComposed;
}

export interface OrganizationApiOrganizationCheckDeviceCodeRequest {
    body: RESTCheckDeviceCode;
}

export interface OrganizationApiOrganizationExploreActionRequest {
    organizationId: string;
    body: RESTExploreOptions;
}

export interface OrganizationApiOrganizationGetRequest {
    extendedView?: boolean;
}

export interface OrganizationApiOrganizationGetByIdRequest {
    organizationId: string;
    extendedView?: boolean;
}

export interface OrganizationApiOrganizationGetStorageSpaceUsageRequest {
    organizationId: string;
    limit: number;
    offset: number;
}

export interface OrganizationApiOrganizationOrganizationDeviceCodeRequest {
    body: RESTCommonDeviceCodeRequestOptions;
}

export interface OrganizationApiOrganizationRemoveRequest {
    organizationId: string;
}

export interface OrganizationApiOrganizationUpdateRequest {
    organizationId: string;
    body: RestOrganizationComposed;
}

/**
 * no description
 */
export const organizationApi = ({

    /**
     * Adds a Microsoft organization to the Veeam Backup for Microsoft 365 infrastructure.
     * Add Organization
     */
    organizationAdd: ({ body }: OrganizationApiOrganizationAddRequest, option?: RequestOption): Observable<Optional<RestOrganizationComposed>> => {
        throwIfNullOrUndefined(body, 'body', 'organizationAdd');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RestOrganizationComposed>({
            url: '/v7/Organizations',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Allows to check whether a device code was used to sign in to the Microsoft authentication portal.
     * Check Device Code
     */
    organizationCheckDeviceCode: ({ body }: OrganizationApiOrganizationCheckDeviceCodeRequest, option?: RequestOption): Observable<Optional<RESTDeviceCodeCheckResponse>> => {
        throwIfNullOrUndefined(body, 'body', 'organizationCheckDeviceCode');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDeviceCodeCheckResponse>({
            url: '/v7/DeviceCode/check',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Creates and starts a restore session for an organization with the specified ID.
     * Create Restore Session for Organization by Organization ID
     */
    organizationExploreAction: ({ organizationId, body }: OrganizationApiOrganizationExploreActionRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationExploreAction');
        throwIfNullOrUndefined(body, 'body', 'organizationExploreAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v7/Organizations/{organizationId}/explore'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a resource representation of all organizations added to the Veeam Backup for Microsoft 365 infrastructure.
     * Get Organizations
     */
    organizationGet: ({ extendedView }: OrganizationApiOrganizationGetRequest, option?: RequestOption): Observable<Optional<Array<RestOrganizationComposed>>> => {

        const query: HttpQuery = {};

        if (extendedView != null) { query['extendedView'] = extendedView; }

        return requestAuthenticated<Array<RestOrganizationComposed>>({
            url: '/v7/Organizations',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization with the specified ID.
     * Get Organization by Organization ID
     */
    organizationGetById: ({ organizationId, extendedView }: OrganizationApiOrganizationGetByIdRequest, option?: RequestOption): Observable<Optional<RestOrganizationComposed>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationGetById');

        const query: HttpQuery = {};

        if (extendedView != null) { query['extendedView'] = extendedView; }

        return requestAuthenticated<RestOrganizationComposed>({
            url: '/v7/Organizations/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns information about Microsoft 365 and on-premises Microsoft organizations that were added as tenants by service providers.  To use this resource, make sure to obtain a token using the same credentials that were used to add the organization instead of credentials of an administrative account. For more information on how to obtain a token, see the Veeam Backup for Microsoft 365 REST API Reference, section [Authorization for Organizations with Modern Authentication and Legacy Protocols or Basic Authentication](https://helpcenter.veeam.com/docs/vbo365/rest/authorization_basic_legacy.html?ver=70).  
     * Get Tenant Organization
     */
    organizationGetCurrentOrganization: (option?: RequestOption): Observable<Optional<RESTLoggedInOrganization>> => {
        return requestAuthenticated<RESTLoggedInOrganization>({
            url: '/v7/Organization',
            method: 'GET',
        }, option);
    },

    /**
     * Returns a collection of used backup repositories for an organization with the specified ID.
     * Get Organization Repositories
     */
    organizationGetStorageSpaceUsage: ({ organizationId, limit, offset }: OrganizationApiOrganizationGetStorageSpaceUsageRequest, option?: RequestOption): Observable<Optional<PageOfRESTOrganizationStorageSpaceUsage>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationGetStorageSpaceUsage');
        throwIfNullOrUndefined(limit, 'limit', 'organizationGetStorageSpaceUsage');
        throwIfNullOrUndefined(offset, 'offset', 'organizationGetStorageSpaceUsage');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        return requestAuthenticated<PageOfRESTOrganizationStorageSpaceUsage>({
            url: '/v7/Organizations/{organizationId}/usedRepositories'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Allows you to obtain a device code from Microsoft Azure to sign in to the Microsoft authentication portal.
     * Get Device Code
     */
    organizationOrganizationDeviceCode: ({ body }: OrganizationApiOrganizationOrganizationDeviceCodeRequest, option?: RequestOption): Observable<Optional<RESTDeviceCodeResponse>> => {
        throwIfNullOrUndefined(body, 'body', 'organizationOrganizationDeviceCode');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDeviceCodeResponse>({
            url: '/v7/DeviceCode',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Removes an organization with the specified ID.
     * Remove Organization
     */
    organizationRemove: ({ organizationId }: OrganizationApiOrganizationRemoveRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationRemove');

        return requestAuthenticated<void>({
            url: '/v7/Organizations/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'DELETE',
        }, option);
    },

    /**
     * Modifies settings of an organization with the specified ID.
     * Edit Organization
     */
    organizationUpdate: ({ organizationId, body }: OrganizationApiOrganizationUpdateRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'organizationUpdate');
        throwIfNullOrUndefined(body, 'body', 'organizationUpdate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/Organizations/{organizationId}'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'PUT',
            headers,
            body: body,
        }, option);
    },

})
