// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTRestoreSessionEvent
 */
export interface RESTRestoreSessionEvent {
    /**
     * Name of the restored item.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    itemName?: string;
    /**
     * Type of the restored item.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    itemType?: string;
    /**
     * Size of the restored item in *Bytes*.
     * @type {number}
     * @memberof RESTRestoreSessionEvent
     */
    itemSizeBytes?: number;
    /**
     * Source path of the restored item.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    source?: string;
    /**
     * Target path for the item restore.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    target?: string;
    /**
     * ID of the restore session event.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    id: string;
    /**
     * Type of the restore session event.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    type?: RESTRestoreSessionEventTypeEnum;
    /**
     * Status of the restore session.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    status: RESTRestoreSessionEventStatusEnum;
    /**
     * Date and time when the restore session was started.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    startTime: string;
    /**
     * Date and time when the restore session ended.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    endTime?: string;
    /**
     * Duration of the restore session.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    duration?: string;
    /**
     * Title of the restore session event.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    title?: string;
    /**
     * Message of the restore session event.
     * @type {string}
     * @memberof RESTRestoreSessionEvent
     */
    message: string;
    /**
     * Order number of the restore session event.
     * @type {number}
     * @memberof RESTRestoreSessionEvent
     */
    order: number;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTRestoreSessionEvent
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreSessionEventTypeEnum {
    None = 'None',
    Save = 'Save',
    Export = 'Export',
    Send = 'Send',
    Restore = 'Restore',
    View = 'View'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreSessionEventStatusEnum {
    Running = 'Running',
    Success = 'Success',
    Warning = 'Warning',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
    Skipped = 'Skipped'
}

