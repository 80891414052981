// Copyright © Veeam Software Group GmbH

import React, { useState } from 'react';

import type { FC } from 'react';

import ResourcesContext from './ResourcesContext';
import resources from './';

export const ResourcesContextProvider: FC = (props): JSX.Element => {
    const [lang, change] = useState(resources.current);
    const toggleLang = (languageName: string): void => change(resources.toggleLanguage(languageName));

    return (
        <ResourcesContext.Provider value={{ resources: lang, toggleLang }}>
            {props.children}
        </ResourcesContext.Provider>
    );
};

