// Copyright © Veeam Software Group GmbH

import type { ExploreGridModel } from 'pages/ExplorePage/models/explore-grid-model';
import type { GetItemsRequest } from 'services/explore/interfaces/get-items-request';

import { EXPLORE_PAGE_LIMIT } from 'pages/ExplorePage/components/ExploreGrid/consts/explore-page-limit';
import { EXPLORE_MAX_LIMIT } from 'pages/ExplorePage/components/ExploreGrid/consts/explore-max-limit';
import { commonLoaderOrDefault, createConverter } from 'infrastructure/grid';
import { LoadPagesMode } from 'infrastructure/rxjs';
import { ExploreItemsFilterSchema, exploreService } from 'services/explore';


const convertFilter = createConverter(ExploreItemsFilterSchema);

const getRequest = (value: ExploreGridModel['valueType']): GetItemsRequest | undefined => {
    const mode = value.limit === undefined ? LoadPagesMode.Async : LoadPagesMode.Sync;

    return value.node && {
        sorting: value.sorting[0],
        parent: value.node,
        filter: convertFilter(value.filters),
        config: {
            mode,
            stopLimit: value.limit,
            prevState: value.loadState,
            pageSize: EXPLORE_PAGE_LIMIT,
            maxLimit: EXPLORE_MAX_LIMIT,
        },
    };
};

export const itemsLoader: ExploreGridModel['loaderType'] = commonLoaderOrDefault(
    value => getRequest(value),
    (...args) => exploreService.getItems(...args),
    []
);
