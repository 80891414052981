// Copyright © Veeam Software Group GmbH

import React, { createContext, useContext } from 'react';
import { MODAL_VIEW, useModal } from '@veeam/components';

import type { FC } from 'react';
import type { ExchangeItem, OneDriveItem, SharePointItem } from 'services';
import type { TeamsItem } from 'services/models/Teams/TeamsItem';

import { ExchangeRestoreWizard, OneDriveRestoreWizard, SharePointRestoreWizard , TeamsRestoreWizard } from './Wizards';

export interface RestoreWizardsContext {
    openExchangeWizard(items: ExchangeItem[]): void;
    openOneDriveWizard(items: OneDriveItem[]): void;
    openSharePointWizard(items: SharePointItem[]): void;
    openTeamsWizard(items: TeamsItem[]): void;
}

const defaultContextValue: RestoreWizardsContext = {
    openExchangeWizard: () => { },
    openOneDriveWizard: () => { },
    openSharePointWizard: () => { },
    openTeamsWizard: () => { },
};

const RestoreContext = createContext(defaultContextValue);

export const useRestoreWizards = (): RestoreWizardsContext => useContext(RestoreContext);

export const RestoreWizardsProvider: FC = ({ children }) => {
    const [exchangeRestoreWizard, exchangeRestore] = useModal<ExchangeItem[]>({
        view: MODAL_VIEW.page,
        render: ({ data, deactivate }) => <ExchangeRestoreWizard items={data || []} close={deactivate} />,
    });
    const [oneDriveRestoreWizard, oneDriveRestore] = useModal<OneDriveItem[]>({
        view: MODAL_VIEW.page,
        render: ({ data, deactivate }) => <OneDriveRestoreWizard items={data || []} close={deactivate} />,
    });
    const [sharePointRestoreWizard, sharePointRestore] = useModal<SharePointItem[]>({
        view: MODAL_VIEW.page,
        render: ({ data, deactivate }) => <SharePointRestoreWizard items={data || []} close={deactivate} />,
    });
    const [teamsRestoreWizard, teamsRestore] = useModal<TeamsItem[]>({
        view: MODAL_VIEW.page,
        render: ({ data, deactivate }) => <TeamsRestoreWizard items={data || []} close={deactivate} />,
    });

    const contextValue: RestoreWizardsContext = {
        openExchangeWizard: (items: ExchangeItem[]): void => exchangeRestore.activate(items),
        openOneDriveWizard: (items: OneDriveItem[]): void => oneDriveRestore.activate(items),
        openSharePointWizard: (items: SharePointItem[]): void => sharePointRestore.activate(items),
        openTeamsWizard: (items: TeamsItem[]): void => teamsRestore.activate(items),
    };

    return (
        <RestoreContext.Provider value={contextValue}>
            {children}
            {exchangeRestoreWizard}
            {oneDriveRestoreWizard}
            {sharePointRestoreWizard}
            {teamsRestoreWizard}
        </RestoreContext.Provider>
    );
};
