// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTSharePointItem,
} from './';

/**
 * @export
 * @interface RESTRestoreSharePointItemConfig
 */
export interface RESTRestoreSharePointItemConfig {
    /**
     * @type {RESTSharePointItem}
     * @memberof RESTRestoreSharePointItemConfig
     */
    item: RESTSharePointItem;
    /**
     * Specifies what version of the SharePoint item will be restored.
     * @type {string}
     * @memberof RESTRestoreSharePointItemConfig
     */
    documentVersion?: RESTRestoreSharePointItemConfigDocumentVersionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRestoreSharePointItemConfigDocumentVersionEnum {
    All = 'All',
    Last = 'Last'
}

