// Copyright © Veeam Software Group GmbH

/**
 * Copyright © Veeam Software Group GmbH.
 */

import * as React from 'react';
import first from 'lodash/first';
import { useEffect, useState } from 'react';
import { ACTION_VIEW, DataGrid, Dialog, DIALOG_SIZE, INDENT, SelectLink, STACK_ALIGN, STACK_GAP, StackView, Text } from '@veeam/components';
import { VIEW_BORDER } from '@veeam/components/lib/View/types';
import { observer } from 'mobx-react-lite';

import { useRbac } from '../../../../features/Rbac';
import { rbacService } from '../../../../services/rbac';
import { useResources } from '../../../../infrastructure/resources';
import { useRbacScopesModel } from '../../../../features/Rbac/ScopesGrid/useModel';
import { useIsCurrentScope } from '../../../../features/Rbac/ScopesGrid/useIsCurrentScope';
import { useColumns } from '../../../../features/Rbac/ScopesGrid/useColumns';
import { useToolbar } from '../../../../features/Rbac/ScopesGrid/useToolbar';
import { getTypeToIconMap } from '../../../../features/Rbac/ScopesGrid/mappers';
import { useExplorePageStore } from '../../stores/explore-page-store';

import type { RbacScopeId } from '../../../../services/rbac';
import type { ScopesModel } from '../../../../features/Rbac/ScopesGrid';

export const ScopeHeader: React.FC = observer(() => {
    const explorePageStore = useExplorePageStore();
    const { currentScope, currentScopeLabel, isOperator } = useRbac();
    const iconsMap = getTypeToIconMap();

    const changeScope = async(model: ScopesModel) => {
        const selection = first(model.getValue().selection);
        if (!selection) return;
        const selected = model.getItemById(selection);
        if (!selected) return;
        await rbacService.changeScope(selected);
    };
    const resources = useResources().features.Rbac;
    const model = useRbacScopesModel();
    const isCurrentScope = useIsCurrentScope();
    const columns = useColumns(resources.ScopesGrid);
    const toolbar = useToolbar(
        resources.ScopesGrid,
        () => changeScope(model),
        () => {
            model.update((values) => {
                values.nextReloadForce = true;
            });
            model.load();
        }
    );
    const [selectedItems, setSelectedItems] = useState<RbacScopeId[]>([]);

    useEffect(() => {
        const subscription = model.asObservable().subscribe((model) => {
            setSelectedItems(model.selection);
        });

        return () => subscription.unsubscribe();
    }, []);

    if (!isOperator) return null;

    return (
        <StackView
            gap={STACK_GAP.m}
            align={STACK_ALIGN.center}
            style={{ height: 48 }}
            borderBottom={VIEW_BORDER.normal}
            spaceLeft={INDENT.m}
        >
            <Text>Scope:</Text>
            <SelectLink
                contentIconBefore={iconsMap[currentScope.type]}
                renderContent={() => currentScopeLabel || 'You'}
                renderModal={(props) => {
                    const deactivate = () => {
                        props.deactivate();
                        model.update((mutable) => {
                            mutable.selection = [];
                        });
                    };

                    return (
                        <Dialog
                            size={DIALOG_SIZE.xxl}
                            onRequestClose={deactivate}
                            header='Specify Scope'
                            title='Select user or object to restore data for.'
                            actions={[
                                {
                                    text: 'Select',
                                    view: ACTION_VIEW.primary,
                                    disabled: selectedItems?.length === 0,
                                    onClick: () => {
                                        changeScope(model);
                                        deactivate();
                                    },
                                },
                                { text: 'Cancel', view: ACTION_VIEW.secondary, onClick: deactivate },
                            ]}
                            className='change-scope-dialog'
                        >
                            <DataGrid
                                model={model}
                                columns={columns}
                                toolbar={toolbar}
                                config={{
                                    canResizeColumns: true,
                                    columnsToggleable: true,
                                }}
                                isRowDisabled={({ rowData }) => isCurrentScope(rowData)}
                            />
                        </Dialog>
                    );
                }}
                disabled={explorePageStore.isSessionLoading}
                style={{ maxWidth: 'calc(100% - 115px)' }}
            />
        </StackView>
    );
});
