// Copyright © Veeam Software Group GmbH

import { toolbarBasicFilter, toolbarAdvancedFilter, toolbarSearchFilter, toolbarComboboxFilter } from '@veeam/components';

import type { CollectionToolbarCustomItem } from '@veeam/components';

import { never } from 'infrastructure/never';
import { toolbarAdvancedFilterConfigBuilder } from './advancedFilter';

import type { AdvancedFilterConfigBuilder, GeneralAdvancedFilterConfig, ToolbarAdvancedFilterConfig } from './advancedFilter';
import type { BasicFilterConfig, ToolbarBasicFilterConfig } from './basicFilter';
import type { SearchFilterConfig, ToolbarSearchFilterConfig } from './searchFilter';
import type { ComboboxFilterConfig, ToolbarComboboxFilterConfig } from './comboboxFilter';

export enum FilterType {
    Advanced = 'advanced',
    Basic = 'basic',
    Search = 'search',
    Combobox = 'combobox',
}

type FilterConfig = ToolbarBasicFilterConfig | ToolbarSearchFilterConfig | ToolbarComboboxFilterConfig | ToolbarAdvancedFilterConfig<any>;

export interface ConfigMaker<TFilter> {
    basic<TProp extends string & keyof TFilter>(property: TProp, config: BasicFilterConfig<TFilter, TProp>): ToolbarBasicFilterConfig;
    search<TProp extends string & keyof TFilter>(property: TProp, config?: SearchFilterConfig<TFilter, TProp>): ToolbarSearchFilterConfig;
    combobox<TProp extends string & keyof TFilter>(property: TProp, config: ComboboxFilterConfig<TFilter, TProp>): ToolbarComboboxFilterConfig;
    advanced(config: GeneralAdvancedFilterConfig): AdvancedFilterConfigBuilder<TFilter>;
}

export const createConfigMaker = <TFilter>(): ConfigMaker<TFilter> => ({
    basic: (property, config) => ({ ...config, property, filterType: FilterType.Basic }),
    search: (property, config) => ({ ...config, property, filterType: FilterType.Search }),
    combobox: (property, config) => ({ ...config, property, filterType: FilterType.Combobox }),
    advanced: config => toolbarAdvancedFilterConfigBuilder(config),
});

export const createToolbarFilters = (...configs: FilterConfig[]): CollectionToolbarCustomItem[] =>
    configs.map((config) => {
        switch (config.filterType) {
            case FilterType.Basic: return toolbarBasicFilter(config);
            case FilterType.Search: return toolbarSearchFilter(config);
            case FilterType.Combobox: return toolbarComboboxFilter(config);
            case FilterType.Advanced: return toolbarAdvancedFilter(config);
            default: return never(config);
        }
    });
