// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTInternetProxySettingsFromClient,
} from './';

/**
 * @export
 * @interface RESTProxyFromClient
 */
export interface RESTProxyFromClient {
    /**
     * Defines the type of the backup proxy server to add. The following values are available: <ul> <li>*true* - domain backup proxy, that is, a backup proxy server that resides in the same domain as the Veeam Backup for Microsoft 365 server or in a trusted domain.</li> <li>*false* - workgroup backup proxy, that is, a backup proxy server that resides in a workgroup.</li> </ul> If you omit this property, a workgroup backup proxy will be added. 
     * @type {boolean}
     * @memberof RESTProxyFromClient
     */
    useDomainNetwork?: boolean;
    /**
     * Defines whether Veeam Backup for Microsoft 365 will use an internet proxy server to process backup and backup copy jobs.
     * @type {boolean}
     * @memberof RESTProxyFromClient
     */
    useInternetProxy?: boolean;
    /**
     * Specifies the type of the internet proxy server. The following types are available: <ul> <li>*FromManagementServer* - the internet proxy server uses connection settings from the Veeam Backup for Microsoft 365 server.</li> <li>*Custom* - the internet proxy server uses connection settings configured on the Veeam Backup for Microsoft 365 proxy.</li> </ul>  **Note**: The `useInternetProxy` property value must be set to *true*. 
     * @type {string}
     * @memberof RESTProxyFromClient
     */
    internetProxyType?: RESTProxyFromClientInternetProxyTypeEnum;
    /**
     * @type {RESTInternetProxySettingsFromClient}
     * @memberof RESTProxyFromClient
     */
    internetProxySettings?: RESTInternetProxySettingsFromClient;
    /**
     * Specifies the ID of the backup proxy server.
     * @type {string}
     * @memberof RESTProxyFromClient
     */
    id?: string;
    /**
     * Specifies the DNS name or IP address of the backup proxy server.
     * @type {string}
     * @memberof RESTProxyFromClient
     */
    hostName?: string;
    /**
     * Specifies a description of the backup proxy server.
     * @type {string}
     * @memberof RESTProxyFromClient
     */
    description?: string;
    /**
     * Specifies the port number to connect to the backup proxy server. The default port is *9193*.
     * @type {number}
     * @memberof RESTProxyFromClient
     */
    port?: number;
    /**
     * Specifies the user name to access the backup proxy server.
     * @type {string}
     * @memberof RESTProxyFromClient
     */
    username?: string;
    /**
     * Specifies a password.
     * @type {string}
     * @memberof RESTProxyFromClient
     */
    password?: string;
    /**
     * Specifies the number of threads that the backup proxy backup can process.
     * @type {number}
     * @memberof RESTProxyFromClient
     */
    threadsNumber?: number;
    /**
     * Defines whether Veeam Backup for Microsoft 365 will limit the network bandwidth for performance optimization. Use the `throttlingValue` property to set the network throttling value.
     * @type {boolean}
     * @memberof RESTProxyFromClient
     */
    enableNetworkThrottling?: boolean;
    /**
     * Specifies the network bandwidth limit value.  **Note**: The `enableNetworkThrottling` property value must be set to true. Otherwise, the network bandwidth limit value will not be applied. 
     * @type {number}
     * @memberof RESTProxyFromClient
     */
    throttlingValue?: number;
    /**
     * Specifies the measuring unit for the network bandwidth limit.   **Note**: The `enableNetworkThrottling` property value must be set to *true*. 
     * @type {string}
     * @memberof RESTProxyFromClient
     */
    throttlingUnit?: RESTProxyFromClientThrottlingUnitEnum;
    /**
     * Adds a backup proxy server that is already managed by another Veeam Backup for Microsoft 365 server. Defines whether Veeam Backup for Microsoft 365 will take ownership of the backup proxy server and add it to the backup infrastructure.
     * @type {boolean}
     * @memberof RESTProxyFromClient
     */
    attachUsedProxy?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTProxyFromClientInternetProxyTypeEnum {
    FromManagementServer = 'FromManagementServer',
    Custom = 'Custom'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTProxyFromClientThrottlingUnitEnum {
    Mbps = 'Mbps',
    Mbs = 'MBs',
    Kbs = 'KBs'
}

