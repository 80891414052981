// Copyright © Veeam Software Group GmbH

import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';

import { restoreSessionApi } from 'api/rxjs';

import type { RestoreSessionId } from '../models';

export const stopSession = (restoreSessionId: RestoreSessionId): Observable<void> =>
    restoreSessionApi.restoreSessionStopAction({ restoreSessionId }).pipe(map(() => {}));
