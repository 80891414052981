// Copyright © Veeam Software Group GmbH

import { authController } from 'infrastructure/auth';
import { createDixieDatabase } from './dixie';
import { createLocalStorageDatabase } from './localstorage';

import type { RecoveryListTable } from './RecoveryListTable';

export interface SelfServicePortalDatabase {
    recoveryList: RecoveryListTable;
    clear(): Promise<void>;
}

let db: SelfServicePortalDatabase;

// export const getDatabase = (): SelfServicePortalDatabase => {
//     if(db === undefined) throw new Error();
//     return db;
// }

export const getDatabase = async(): Promise<SelfServicePortalDatabase> => {
    if (db === undefined) {
        try {
            db = await createDixieDatabase();
        } catch {
            db = createLocalStorageDatabase();
        }
    }
    return db;
};

const disposeDb = async() => {
    if (db) await db.clear();
};

// window.addEventListener('load', createDatabase);
// authController.events.login.after.subscribe(createDatabase);
authController.events.logout.before.subscribe(disposeDb);
