// Copyright © Veeam Software Group GmbH

import first from 'lodash/first';
import { DataGridModel } from '@veeam/components';
import { useCallback, useMemo } from 'react';
import { map } from 'rxjs/operators';
import { z } from 'zod';
import cloneDeep from 'lodash/cloneDeep';
import { of } from 'rxjs';

import type {
    Sorting,
} from 'infrastructure/client-side-operations';
import type { TypeOf } from 'zod';
import type { Form } from '@veeam/core';
import type { OneDriveDocument, UniqueId } from 'services';
import type { CollectionLoader } from '@veeam/components';
import type { BucketItem } from 'services/restoreService';
import type { FormWithBucketItems } from 'features/Restore/Wizards/Shared';

import {
    comparators,
    createFilterEngine,
    createSortEngine,
    filters,
} from 'infrastructure/client-side-operations';
import { commonLoaderWithMap, createConverter, useModel } from 'infrastructure/grid';
import { explorePath as path } from 'services';
import { mapItemType } from 'services/restoreService/bucket';

export class RestoreItemsModel extends DataGridModel<BucketItem, UniqueId, RestoreItemsSortKeys> {
    public constructor(loader: RestoreItemsModel['loaderType']) {
        super(RestoreItemsModel.idGetter, loader, {});
    }

    private static idGetter(data: BucketItem): UniqueId {
        return data.uniqId;
    }
}

export enum RestoreItemsSortKeys {
    Name = 'Name',
    Size = 'Size',
    Type = 'Type',
    Location = 'Location',
}

export const RestoreItemsFilterSchema = z.object({
    search: z
        .string()
        .nullish()
        .transform(val => val || ''),
});

export type RestoreItemsFilter = TypeOf<typeof RestoreItemsFilterSchema>;

interface GetRestoreItemsRequest {
    filter: RestoreItemsFilter;
    sorting: Sorting<typeof RestoreItemsSortKeys> | undefined;
}

export const filterRestoreItems = createFilterEngine<BucketItem, RestoreItemsFilter>({
    search: filters.search(item => item.title),
});

export const sortRestoreItems = createSortEngine<BucketItem, typeof RestoreItemsSortKeys>({
    Name: (one, two) => comparators.string(one.title, two.title),
    Size: (one, two) => comparators.number((one as OneDriveDocument).sizeBytes, (two as OneDriveDocument).sizeBytes),
    Type: (one, two) => comparators.string(mapItemType(one), mapItemType(two)),
    Location: (one, two) => comparators.string(path.stringify(one.explorePath), path.stringify(two.explorePath)),
});

export const convertFilter = createConverter(RestoreItemsFilterSchema);

export const useRestoreItemsModel = (form: Form<FormWithBucketItems>): RestoreItemsModel => {
    const getItems = useCallback(({ sorting, filter }: GetRestoreItemsRequest) => of(form.getValue().items as BucketItem[])
        .pipe(
            map(items => cloneDeep(items)),
            map(items => filterRestoreItems(filter, items)),
            map(items => sortRestoreItems(sorting, items))
        ), []);

    const getRequest = useCallback(value => ({
        sorting: first(value.sorting) as Sorting<typeof RestoreItemsSortKeys> | undefined,
        filter: convertFilter(value.filters) as RestoreItemsFilter,
    }), []);

    const loader: CollectionLoader<BucketItem, UniqueId, RestoreItemsSortKeys> = useMemo(() => commonLoaderWithMap(
        getRequest,
        getItems,
        items => items,
    ), []);

    return useModel(() => new RestoreItemsModel(loader));
};
