// Copyright © Veeam Software Group GmbH

/**
 * Copyright © Veeam Software Group GmbH.
 */

import { action, computed, observable, runInAction } from 'mobx';

import { exploreSessionService } from '../../../../services/exploreSessions';
import { rbacService } from '../../../../services/rbac';
import { ExploreSession } from '../../../../services';
import { RESTRestorePoint } from '../../../../api/rxjs';

import type { Subscription } from '../../../../infrastructure/event';

export class ExplorePageStore {
    @observable restorePoint: RESTRestorePoint = exploreSessionService.getRestorePoint();
    @observable sessions: ExploreSession = exploreSessionService.getSessions();
    @observable isSessionLoading = false;

    private subscriptions: { [key: string]: Subscription; } = {
        onRestorePointChanged: undefined,
        onScopeChanged: undefined,
        onSessionChanged: undefined,
        onSessionRenewed: undefined,
        onSessionChanging: undefined,
        onSessionError: undefined,
    };

    constructor() {
        this.keepUpToDateRestorePoint();
        this.keepUpToDateSessions();
    }

    private keepUpToDateRestorePoint(): void {
        this.subscriptions.onRestorePointChanged = exploreSessionService.events.restorePoint.changed.subscribe((restorePoint) => {
            runInAction(() => this.restorePoint = restorePoint);
        });

        this.subscriptions.onScopeChanged = rbacService.onScopeChanged.subscribe(() => {
            const restorePoint = exploreSessionService.getRestorePoint();

            runInAction(() => {
                this.restorePoint = restorePoint;
            });

            exploreSessionService.events.restorePoint.changed.raise(restorePoint);
        });
    }

    private keepUpToDateSessions(): void {
        this.subscriptions.onSessionChanged = exploreSessionService.events.session.changed.subscribe(this.setSessions);
        this.subscriptions.onSessionRenewed = exploreSessionService.events.session.renewed.subscribe(this.setSessions);
        this.subscriptions.onSessionChanging = exploreSessionService.events.session.changing.subscribe(this.resetSessions);
        this.subscriptions.onSessionError = exploreSessionService.events.session.error
            .subscribe(() => this.setSessions(exploreSessionService.getSessions()));
    }

    @action.bound
    private resetSessions(): void {
        this.sessions = undefined;
        this.isSessionLoading = true;
    }

    @action.bound
    private setSessions(sessions: ExploreSession): void {
        this.sessions = sessions;
        this.isSessionLoading = false;
    }

    destroy(): void {
        for (const [,subscription] of Object.entries(this.subscriptions)) {
            subscription?.unsubscribe();
        }
    }

    @computed
    get hasSessions(): boolean {
        return this.sessions &&
            Boolean(this.sessions.veodSession || this.sessions.vespSession || this.sessions.vexSession || this.sessions.vetSession);
    }
}
