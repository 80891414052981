// Copyright © Veeam Software Group GmbH

import {
    AppBar,
    AppLayout,
    SPACE_FILL,
    Spinner,
    StackView,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    Text,
    TEXT_SIZE,
} from '@veeam/components';
import React from 'react';

import type { FC } from 'react';

import { loginBackgroundSrc, logoSrc } from 'assets';
import { useResources } from 'infrastructure/resources';

export const LogoutProgress: FC<{
    error?: string;
}> = ({ error }) => {
    const loginResources = useResources().features.Login;
    return (
        <AppLayout
            header={<AppBar logo={logoSrc} title={loginResources.productName} />}
            body={
                <StackView
                    direction={STACK_DIRECTION.column}
                    distribution={STACK_DISTRIBUTION.start}
                    align={STACK_ALIGN.center}
                    spaceFill={SPACE_FILL.all}
                >
                    <div style={{ height: 'calc(30%)', minHeight: '16px' }}></div>
                    <StackView gap={STACK_GAP.l}>
                        <Spinner size={64} />
                        <StackView direction={STACK_DIRECTION.column} align={STACK_ALIGN.start} gap={STACK_GAP.s}>
                            <Text size={TEXT_SIZE.xl}>{loginResources.logoutForm.title}</Text>
                            <Text size={TEXT_SIZE.l}>{loginResources.logoutForm.description}</Text>
                        </StackView>
                    </StackView>
                </StackView>
            }
        />
    );
};
