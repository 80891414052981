// Copyright © Veeam Software Group GmbH

import { doubleToolbar } from '@veeam/components';
import { useMemo } from 'react';

import type { CollectionToolbarConfig } from '@veeam/components';

import { useFilter } from './useFilter';
import { useStopSession } from './useStopSession';

import type { SessionsModel } from './useModel';
import type { GridResources } from './resources';

export function useToolbar(resources: GridResources, model: SessionsModel): CollectionToolbarConfig {
    const filters = useFilter(resources);
    const stopAction = useStopSession(resources, model);
    return useMemo(() => ({
        decorator: doubleToolbar(),
        items: [...filters, stopAction],
    }), []);
}
