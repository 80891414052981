// Copyright © Veeam Software Group GmbH

import moment from 'moment';

import type { RESTItemComposed, RESTOneDrive, RESTOneDriveFolder } from 'api/rxjs';
import type {
    ExplorePath,
    OneDriveDocument,
    OneDriveDocumentId,
    OneDriveFolderId,
    OneDriveFolderItem,
    OneDriveId,
    OneDriveNode,
    OneDriveFolder,
} from 'services/models';

import { OneDriveItemType, OneDriveNodeType, Product } from 'services/models';
import { convertBase } from '../../baseConverter';

const convertOneDriveFromRest = (oneDrive: RESTOneDrive): OneDriveNode => ({
    ...convertBase('Node', oneDrive),
    product: Product.OneDrive,
    nodeType: OneDriveNodeType.OneDrive,
    oneDriveId: oneDrive.id as OneDriveId,
});

const convertOneDriveFolderFromRest = (folder: RESTOneDriveFolder, oneDriveId: OneDriveId, parent: ExplorePath): OneDriveFolder => ({
    ...convertBase('Node', folder, parent),
    product: Product.OneDrive,
    nodeType: OneDriveNodeType.Folder,
    oneDriveId,
    folderId: folder.id as OneDriveFolderId,
});

const convertOneDriveDocumentFromRest = (
    document: RESTItemComposed,
    oneDriveId: OneDriveId,
    parent: ExplorePath,
    isVersion: boolean
): OneDriveDocument => ({
    ...convertBase(isVersion ? 'Version' : 'Item', document, parent),
    itemType: OneDriveItemType.Document,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _raw_rest: document,
    product: Product.OneDrive,
    id: document.id as OneDriveDocumentId,
    oneDriveId,
    version: document.version,
    sizeBytes: document.sizeBytes,
    modificationTime: moment(document.modificationTime),
    modifiedBy: document.modifiedBy,
    isVersion,
});

const convertOneDriveDocumentItemFromRest = (
    folder: RESTItemComposed,
    oneDriveId: OneDriveId,
    parent: ExplorePath,
    isVersion: boolean
): OneDriveFolderItem => ({
    ...convertBase(isVersion ? 'Version' : 'Item', folder, parent),
    itemType: OneDriveItemType.Folder,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _raw_rest: folder,
    product: Product.OneDrive,
    id: folder.id as OneDriveDocumentId,
    oneDriveId,
    version: folder.version,
    sizeBytes: folder.sizeBytes,
    modificationTime: moment(folder.modificationTime),
    modifiedBy: folder.modifiedBy,
    isVersion,
});

export const convert = {
    oneDrive: {
        fromRest: convertOneDriveFromRest,
    },
    folder: {
        fromRest: convertOneDriveFolderFromRest,
    },
    document: {
        fromRest: convertOneDriveDocumentFromRest,
    },
    folderItem: {
        fromRest: convertOneDriveDocumentItemFromRest,
    },
};
