// Copyright © Veeam Software Group GmbH

import type { Moment } from 'moment';
import type { RESTItemComposed } from 'api/rxjs';
import type { Product, ItemBase } from '..';


import type { OneDriveId } from './OneDriveId';

interface OneDriveItemBase extends ItemBase {
    product: Product.OneDrive;
    oneDriveId: OneDriveId;
}

export enum OneDriveItemType {
    Document = 'Document',
    Folder = 'Folder',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type OneDriveDocumentId = string & { __onedrive_document_id: never; };

export interface OneDriveDocument extends OneDriveItemBase {
    itemType: OneDriveItemType.Document;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _raw_rest: RESTItemComposed;
    id: OneDriveDocumentId;
    version?: string;
    sizeBytes: number;
    modificationTime: Moment;
    modifiedBy: string;
    isVersion: boolean;
}

export interface OneDriveFolderItem extends OneDriveItemBase {
    itemType: OneDriveItemType.Folder;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _raw_rest: RESTItemComposed;
    id: OneDriveDocumentId;
    version?: string;
    sizeBytes: number;
    modificationTime: Moment;
    modifiedBy: string;
    isVersion: boolean;
}

export type OneDriveItem = OneDriveDocument | OneDriveFolderItem;
