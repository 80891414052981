// Copyright © Veeam Software Group GmbH

import { GRID_COLUMN_JUSTIFY } from '@veeam/components';
import React from 'react';

import type { DataGridColumnConfig } from '@veeam/components';
import type { OneDriveDocument, SharePointDocument, VersionableItem } from 'services';
import type { Resources } from 'infrastructure/resources';

import { itemInRestoreList } from 'assets';
import { RestoreListCell } from 'pages/ExplorePage/components/ExploreGrid/components/RestoreListCell';
import { textCell, dateCell, sizeCell, iconHeaderCell } from 'components/dataGridCells';
import { Product, SharePointItemType } from 'services';
import { ExploreSortKeys } from '../../../../services/explore/enums/explore-sort-keys';

type GridResources = Resources['features']['Explore']['VersionsDialog'];
type Cols = DataGridColumnConfig<VersionableItem>[];

export const getVersionsGridColumns = (
    item: VersionableItem,
    resources: GridResources,
): DataGridColumnConfig<VersionableItem>[] => {
    if (item.product === Product.OneDrive || item.itemType === SharePointItemType.Document) {
        return getDocumentColumns(resources) as Cols;
    }
    return getCommonColumns(resources);
};

const getCommonColumns = (resources: GridResources): DataGridColumnConfig<VersionableItem>[] => [
    {
        id: 'inRestoreList',
        title: resources.cells.inRestoreList,
        cell: (rowData, payload) => <RestoreListCell rowData={rowData} payload={payload} />,
        headerCell: iconHeaderCell(() => itemInRestoreList),
        justify: GRID_COLUMN_JUSTIFY.center,
        width: 32,
        minWidth: 32,
    },
    {
        id: 'version',
        title: resources.cells.version,
        cell: textCell(row => row.version),
        width: 100,
        sortKey: ExploreSortKeys.Version,
    },
    {
        id: 'modifiedData',
        title: resources.cells.modifiedData,
        cell: dateCell(row => row.modificationTime),
        width: 250,
        sortKey: ExploreSortKeys.ModificationTime,
    },
    {
        id: 'modifiedBy',
        title: resources.cells.modifiedBy,
        cell: textCell(row => row.modifiedBy),
        sortKey: ExploreSortKeys.ModifiedBy,
    },
];

const getDocumentColumns = (resources: GridResources): DataGridColumnConfig<OneDriveDocument | SharePointDocument>[] => [
    ...getCommonColumns(resources),
    {
        id: 'size',
        title: resources.cells.size,
        cell: sizeCell(row => row.sizeBytes),
        width: 150,
        justify: GRID_COLUMN_JUSTIFY.right,
        sortKey: ExploreSortKeys.SizeBytes,
    },
];
