/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable, of } from 'rxjs';
import { ajax, AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { map, concatMap, catchError, tap } from 'rxjs/operators';
import resourcesController from 'infrastructure/resources';
import { authController, isAuthenticated, isContainLoginInfo } from 'infrastructure/auth';
import { errorManager, getErrorMessage } from 'infrastructure/error-management';
import { server } from './servers';
import {
    RESTSharePointFolder,
    RESTSharePointItem,
    RESTSharePointItemComposed,
    RESTSharePointDocument,
    RESTExchangeItemsComposed,
    RESTOneDriveDocument,
    RESTOneDriveFolder,
} from 'api/rxjs';

export type Json = any;
export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';
export type HttpHeaders = { [key: string]: string };
export type HttpQuery = Partial<{ [key: string]: string | number | null | boolean | Array<string | number | null | boolean> }>; // partial is needed for strict mode
export type HttpBody = Json | FormData;

export interface Request extends AjaxRequest {
    query?: HttpQuery; // additional prop
    // the following props have improved types over AjaxRequest
    method: HttpMethod;
    headers?: HttpHeaders;
    body?: HttpBody;
    responseType?: 'json' | 'blob' | 'arraybuffer' | 'text';
}

export const throwIfNullOrUndefined = (value: any, paramName: string, nickname: string) => {
    if (value == null) {
        throw new Error(`Parameter "${paramName}" was null or undefined when calling "${nickname}".`);
    }
};

export const encodeURI = (value: any) => encodeURIComponent(`${value}`);

export const queryString = (params: HttpQuery): string => Object.entries(params)
    .map(([key, value]) => value instanceof Array
        ? value.map((val) => `${encodeURI(key)}=${encodeURI(val)}`).join('&')
        : `${encodeURI(key)}=${encodeURI(value)}`
    )
    .join('&');

function createRequestArgs({ url: baseUrl, query, method, headers, body, responseType }: Request): AjaxRequest {
    // only add the queryString to the URL if there are query parameters.
    // this is done to avoid urls ending with a '?' character which buggy webservers
    // do not handle correctly sometimes.
    const url = `${server}${baseUrl}${query && Object.keys(query).length ? `?${queryString(query)}`: ''}`;

    return {
        url,
        method,
        headers,
        body, //: body instanceof FormData ? body : JSON.stringify(body),
        responseType: responseType ?? 'json',
    };
}

interface ResultMapper<T> {
    map<U = T>(defaultValue: U): T | U;
    getResultOrThrow(): T;
}

export interface ResultKeeper<T> extends ResultMapper<T> {
    isError: false;
    data: T;
}

export interface ErrorKeeper<T> extends ResultMapper<T> {
    isError: true;
    error: AjaxError;
}

export type Optional<T> = ResultKeeper<T> | ErrorKeeper<T>;

const optionalResult = <T>(data: T): Optional<T> => ({ isError: false, data, map: () => data, getResultOrThrow: () => data });
const optionalError = <T>(error: AjaxError): Optional<T> => ({ isError: true, error, map: (def) => def, getResultOrThrow: () => { throw error } });
const optional = <T>(response:AjaxResponse | AjaxError): Optional<T> => response instanceof AjaxResponse ? optionalResult<T>(response.response) : optionalError<T>(response);

export interface RequestOption {
    registerError?: boolean;
}

const handleError = (response: AjaxResponse | AjaxError, option?: RequestOption) =>
    (option?.registerError === undefined ? true : option.registerError)
    && response instanceof AjaxError
    && errorManager.register(response);

export const requestAnonymous = <T>(request: Request, option?: RequestOption): Observable<Optional<T>> => of(request).pipe(
    map(request => createRequestArgs(request)),
    concatMap(request => ajax(request).pipe(catchError(err => of(err)))),
    tap(err => handleError(err, option)),
    map(response => optional<T>(response))
);

function getAuthenticatedRequest(request: AjaxRequest): AjaxRequest {
    if(isContainLoginInfo(authController)) {
        return {
            ...request,
            headers: {
                ...(request.headers || {}),
                Authorization: `Bearer ${authController.info.accessToken}`
            },
        }
    }
    throw new Error(resourcesController.current.infrastructure.auth.notAuthorizedError);
}

export const requestAuthenticated = <T>(request: Request, option?: RequestOption) => of(request).pipe(
    map(rq => createRequestArgs(rq)),
    map(rq => getAuthenticatedRequest(rq)),
    concatMap(rq => ajax(rq).pipe(
        catchError((error: AjaxError) => {
            if(error.status === 401 && isAuthenticated(authController)) {
                return authController.refreshTokens().pipe(
                    catchError((subError: AjaxError) => {
                        authController.emergencyLogout(getErrorMessage(subError).message);
                        return of(subError);
                    }),
                    concatMap(() => ajax(getAuthenticatedRequest(rq)).pipe(
                        catchError((error: AjaxError) => {
                            if(error.status === 401) authController.emergencyLogout(getErrorMessage(error).message);
                            return of(error);
                        }),
                    )),
                );
            }
            return of(error);
        })
    )),
    tap(err => handleError(err, option)),
    map(response => optional<T>(response)),
);

export interface RESTPageRESTSharePointFolder {
    offset: number;
    limit: number;
    results: Array<RESTSharePointFolder>;
}

export interface RESTPageRESTSharePointItem {
    offset: number;
    limit: number;
    results: Array<RESTSharePointItem>;
}

export interface RESTPageRESTSharePointItemBase {
    offset: number;
    limit: number;
    results: Array<RESTSharePointItemComposed>;
}

export interface RESTPageRESTSharePointDocument {
    offset: number;
    limit: number;
    results: Array<RESTSharePointDocument>;
}

export interface RESTPageRESTExchangeItem {
    offset: number;
    limit: number;
    results: Array<RESTExchangeItemsComposed>;
}

export interface RESTPageRESTOneDriveDocument {
    offset: number;
    limit: number;
    results: Array<RESTOneDriveDocument>;
}

export interface RESTPageRESTOneDriveFolder {
    offset: number;
    limit: number;
    results: Array<RESTOneDriveFolder>;
}
