// Copyright © Veeam Software Group GmbH

import { useEffect, useMemo } from 'react';

import type React from 'react';
import type { Observable } from 'rxjs';
import type { SubheaderType } from 'pages/ExplorePage/components/ExploreGrid/enums/subheader-type';
import type { Node } from 'services';

import { ExploreGridModel } from 'pages/ExplorePage/models/explore-grid-model';
import { itemsLoader } from 'pages/ExplorePage/components/ExploreGrid/helpers/items-loader';


export function useExploreGridModel(
    onNodeChanged: Observable<Node | undefined>,
    setAsyncLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setSubheaderType: React.Dispatch<React.SetStateAction<SubheaderType>>
): ExploreGridModel {
    const model = useMemo(() => new ExploreGridModel(itemsLoader, onNodeChanged, setAsyncLoading, setSubheaderType), []);

    useEffect(() => () => model.destroy(), []);

    return model;
}
