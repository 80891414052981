// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { RESTExchangeItemsComposed } from 'api/rxjs';
import type { LoadPagesConfig } from 'infrastructure/rxjs';
import type { Override1 } from 'infrastructure/types';
import type { ExchangeFolder, ExchangeItem, ExchangeMailbox, ExchangeNode, VexSession } from 'services/models';
import type { GetItemsRequest } from 'services/explore/interfaces/get-items-request';

import { never } from 'infrastructure/never';
import { Batch, loadPages } from 'infrastructure/rxjs';
import { convert } from './converters';
import { exchangeApi } from './wrappedExchangeApi';
import { exploreSessionService } from 'services/exploreSessions';
import { ExchangeNodeType } from 'services/models';


export type GetExchangeItemsRequest = Override1<GetItemsRequest, 'parent', ExchangeNode>;

const loadItemsFromFolder = (session: VexSession, parent: ExchangeFolder, config: LoadPagesConfig | undefined): Observable<Batch<RESTExchangeItemsComposed>> =>
    loadPages(exchangeApi.getItems, config)({
        restoreSessionId: session,
        mailboxId: parent.mailboxId,
        parentId: parent.folderId,
    });

const loadItemsFromMailbox = (session: VexSession, parent: ExchangeMailbox, config: LoadPagesConfig | undefined): Observable<Batch<RESTExchangeItemsComposed>> =>
    loadPages(exchangeApi.getItems, config)({
        restoreSessionId: session,
        mailboxId: parent.mailboxId,
        parentId: 'root',
    });

const loadItems = (session: VexSession, parent: ExchangeNode, config: LoadPagesConfig | undefined): Observable<Batch<RESTExchangeItemsComposed>> => {
    switch (parent.nodeType) {
        case ExchangeNodeType.Mailbox: return loadItemsFromMailbox(session, parent, config);
        case ExchangeNodeType.Folder: return loadItemsFromFolder(session, parent, config);
        default: return never(parent);
    }
};

const searchItemsInMailbox = (session: VexSession, parent: ExchangeMailbox, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTExchangeItemsComposed>> =>
    loadPages(exchangeApi.searchIn.mailbox, config)({
        restoreSessionId: session,
        mailboxId: parent.mailboxId,
        body: {
            query: search,
        },
    });

const searchItemsInFolder = (session: VexSession, parent: ExchangeFolder, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTExchangeItemsComposed>> =>
    loadPages(exchangeApi.searchIn.folder, config)({
        restoreSessionId: session,
        mailboxId: parent.mailboxId,
        folderId: parent.folderId,
        body: {
            query: search,
        },
    });

const searchItems = (session: VexSession, parent: ExchangeNode, search: string, config: LoadPagesConfig | undefined): Observable<Batch<RESTExchangeItemsComposed>> => {
    switch (parent.nodeType) {
        case ExchangeNodeType.Mailbox: return searchItemsInMailbox(session, parent, search, config);
        case ExchangeNodeType.Folder: return searchItemsInFolder(session, parent, search, config);
        default: return never(parent);
    }
};

export const getExchangeItems = ({ parent, config, filter: { search } }: GetExchangeItemsRequest): Observable<Batch<ExchangeItem>> => {
    const { vexSession } = exploreSessionService.getSessions();
    if (!vexSession) {
        console.error('');
        return of(Batch.empty());
    }

    const fetch = search === ''
        ? loadItems(vexSession, parent, config)
        : searchItems(vexSession, parent, search, config);

    return fetch.pipe(
        map(items => items.mapData(item => convert.item.fromRest(item, parent))),
    );
};
