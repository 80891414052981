// Copyright © Veeam Software Group GmbH

import moment from 'moment';

import type { RESTExchangeFolder, RESTExchangeItemsComposed, RESTExchangeMailbox } from 'api/rxjs';
import type {
    ExplorePath,
    MailboxId,
    ExchangeMailbox,
    ExchangeFolder,
    ExchangeFolderId,
    ExchangeItem,
    ExchangeNode } from 'services/models';
import type {
    ExchangeAppointment,
    ExchangeContact,
    ExchangeMail,
    ExchangeStickyNote,
    ExchangeTask } from 'services/models/Exchange/ExchangeItem';

import {
    Product,
    ExchangeNodeType,
    ExchangeFolderType,
    ExchangeFolderCategory,
} from 'services/models';
import {
    ExchangeItemType,
    ExchangeMailImportance,
} from 'services/models/Exchange/ExchangeItem';
import { convertBase } from '../../baseConverter';

const convertMailboxFromRest = (mailbox: RESTExchangeMailbox): ExchangeMailbox => ({
    ...convertBase('Node', mailbox),
    product: Product.Exchange,
    nodeType: ExchangeNodeType.Mailbox,
    mailboxId: mailbox.id as MailboxId,
    isArchive: mailbox.isArchive,
});

const convertFolderFromRest = (
    folder: RESTExchangeFolder,
    mailboxId: MailboxId,
    parent: ExplorePath
): ExchangeFolder => ({
    ...convertBase('Node', folder, parent),
    product: Product.Exchange,
    nodeType: ExchangeNodeType.Folder,
    mailboxId,
    folderId: folder.id as ExchangeFolderId,
    folderType: folder.type || ExchangeFolderType.None,
    folderCategory: folder.category ?? ExchangeFolderCategory.None,
});

const convertAppointmentFromRest = (item: RESTExchangeItemsComposed, parent: ExchangeFolder): ExchangeAppointment => ({
    ...item,
    ...convertBase('Item', item, parent.explorePath),
    itemType: ExchangeItemType.Appointment,
    title: item.subject || '',
    product: Product.Exchange,
    mailboxId: parent.mailboxId,
    startTime: item.startTime ? moment(item.startTime) : undefined,
    endTime: item.endTime ? moment(item.endTime) : undefined,
});

const convertContactFromRest = (item: RESTExchangeItemsComposed, parent: ExchangeFolder): ExchangeContact => ({
    ...item,
    ...convertBase('Item', item, parent.explorePath),
    itemType: ExchangeItemType.Contact,
    title: item.displayAs || item.fileAs || item.fullName || item.name || item.subject || '',
    product: Product.Exchange,
    mailboxId: parent.mailboxId,
    fullName: item.fullName || item.name,
    displayAs: item.displayAs || item.name,
});

const convertStickyNoteFromRest = (item: RESTExchangeItemsComposed, parent: ExchangeFolder): ExchangeStickyNote => ({
    ...item,
    ...convertBase('Item', item, parent.explorePath),
    itemType: ExchangeItemType.StickyNote,
    title: item.subject || '',
    product: Product.Exchange,
    mailboxId: parent.mailboxId,
    date: item.date ? moment(item.date) : undefined,
});

const convertTaskFromRest = (item: RESTExchangeItemsComposed, parent: ExchangeFolder): ExchangeTask => ({
    ...item,
    ...convertBase('Item', item, parent.explorePath),
    itemType: ExchangeItemType.Task,
    title: item.subject || '',
    product: Product.Exchange,
    mailboxId: parent.mailboxId,
    startDate: item.startDate ? moment(item.startDate) : undefined,
    dueDate: item.dueDate ? moment(item.dueDate) : undefined,
});

const convertMailFromRest = (item: RESTExchangeItemsComposed, parent: ExchangeNode): ExchangeMail => ({
    ...item,
    ...convertBase('Item', item, parent.explorePath),
    title: item.subject || '',
    itemType: ExchangeItemType.Mail,
    product: Product.Exchange,
    mailboxId: parent.mailboxId,
    received: item.received ? moment(item.received) : undefined,
    sent: item.sent ? moment(item.sent) : undefined,
    importance: item.importance ?? ExchangeMailImportance.Normal,
    attachments: item.attachments ?? [],
});

const convertItemFromRest = (item: RESTExchangeItemsComposed, parent: ExchangeNode): ExchangeItem => {
    if (parent.nodeType === ExchangeNodeType.Mailbox) return convertMailFromRest(item, parent);
    switch (parent.folderType) {
        case ExchangeFolderType.Appointment:
            return convertAppointmentFromRest(item, parent);
        case ExchangeFolderType.Contact:
            return convertContactFromRest(item, parent);
        case ExchangeFolderType.StickyNote:
            return convertStickyNoteFromRest(item, parent);
        case ExchangeFolderType.Task:
            return convertTaskFromRest(item, parent);
        default:
            return convertMailFromRest(item, parent);
    }
};

export const convert = {
    mailbox: {
        fromRest: convertMailboxFromRest,
    },
    folder: {
        fromRest: convertFolderFromRest,
    },
    item: {
        fromRest: convertItemFromRest,
    },
};
