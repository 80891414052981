// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTDataRetrievalSessionStatus,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTDataRetrievalSession
 */
export interface RESTDataRetrievalSession {
    /**
     * Data retrieval session ID.
     * @type {string}
     * @memberof RESTDataRetrievalSession
     */
    id?: string;
    /**
     * Name of the data retrieval session.
     * @type {string}
     * @memberof RESTDataRetrievalSession
     */
    name?: string;
    /**
     * Retrieval job ID.
     * @type {string}
     * @memberof RESTDataRetrievalSession
     */
    retrievalId?: string;
    /**
     * Date and time when the data retrieval session was created.
     * @type {string}
     * @memberof RESTDataRetrievalSession
     */
    creationTime?: string;
    /**
     * Date and time when the latest data retrieval session ended.
     * @type {string}
     * @memberof RESTDataRetrievalSession
     */
    endTime?: string;
    /**
     * Number of processed objects during the data retrieval session.
     * @type {number}
     * @memberof RESTDataRetrievalSession
     */
    progress?: number;
    /**
     * Number of items processed by the retrieval job.
     * @type {number}
     * @memberof RESTDataRetrievalSession
     */
    processedObjects?: number;
    /**
     * Type of the data retrieval session.
     * @type {string}
     * @memberof RESTDataRetrievalSession
     */
    type?: RESTDataRetrievalSessionTypeEnum;
    /**
     * @type {RESTDataRetrievalSessionStatus}
     * @memberof RESTDataRetrievalSession
     */
    status?: RESTDataRetrievalSessionStatus;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTDataRetrievalSession
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTDataRetrievalSessionTypeEnum {
    Retrieve = 'Retrieve',
    RetrievalChangeAvailabilityPeriodDays = 'RetrievalChangeAvailabilityPeriodDays',
    RetrievalRemoving = 'RetrievalRemoving'
}

