// Copyright © Veeam Software Group GmbH

import { LinkButton } from '@veeam/components';
import * as React from 'react';

import type { DatetimeInputControlRendererProps } from '@veeam/components';

import restorePointIcon from '../../../../../assets/icons/restore-point.svg';
import { datetimeFormatter } from '../../../helpers/datetimeFormatter';

export function createInputControl(suffix = '') {
    return function defaultInputControlRenderer({
        control,
        placeholder,
        disabled,
        actions,
        value,
        is12Hours,
        texts,
    }: DatetimeInputControlRendererProps) {
        return (
            <LinkButton
                compact
                disabled={disabled}
                onClick={actions.open}
                iconBefore={restorePointIcon}
                ref={control.ref}
                style={{
                    textDecoration: 'none',
                }}
            >
                {value
                    ? datetimeFormatter({
                        value,
                        withTime: true,
                        withSeconds: true,
                        is12Hours,
                        texts,
                    }) + suffix
                    : placeholder}
            </LinkButton>
        );
    };
}
