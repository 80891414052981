// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
    RESTRbacItemComposed,
} from './';

/**
 * @export
 * @interface RESTRbacRoleCreateModel
 */
export interface RESTRbacRoleCreateModel {
    /**
     * Specifies the ID of the restore operator role.
     * @type {string}
     * @memberof RESTRbacRoleCreateModel
     */
    id?: string;
    /**
     * Specifies the backed-up organization ID.
     * @type {string}
     * @memberof RESTRbacRoleCreateModel
     */
    organizationId?: string;
    /**
     * Specifies a name of the restore operator role.
     * @type {string}
     * @memberof RESTRbacRoleCreateModel
     */
    name?: string;
    /**
     * Specifies a description of the restore operator role.
     * @type {string}
     * @memberof RESTRbacRoleCreateModel
     */
    description?: string;
    /**
     * Specifies the restore operator role type. The following types are available: <ul> <li>*EntireOrganization*. Restore operators are allowed to explore and restore backed-up data of all objects within the specified Microsoft 365 organization.</li> <li>*SpecificObjects*. Restore operators are allowed to explore and restore backed-up data of the specified objects.</li> </ul>  **Note**: For the restore operator role of the *SpecificObjects* type, you must specify a collection of objects to manage. Restore operators will be able to explore and restore backed-up data of these objects.  
     * @type {string}
     * @memberof RESTRbacRoleCreateModel
     */
    roleType?: RESTRbacRoleCreateModelRoleTypeEnum;
    /**
     * Specifies IDs of restore operators that you want to add to the restore operator role. For more information on how to get such IDs, see [Get Restore Operators](#tag/RbacRole/operation/RbacRole_GetOperators).
     * @type {Array<RESTRbacItemComposed>}
     * @memberof RESTRbacRoleCreateModel
     */
    operators?: Array<RESTRbacItemComposed>;
    /**
     * Specifies IDs of objects that you want to add to the restore operator role as objects to manage. Restore operators will be able to explore and restore data from backups created by Veeam Backup for Microsoft 365 for these objects. For more information on how to get such IDs, see [Get Objects to Manage](#tag/RbacRole/operation/RbacRole_GetSelectedItems).
     * @type {Array<RESTRbacItemComposed>}
     * @memberof RESTRbacRoleCreateModel
     */
    selectedItems?: Array<RESTRbacItemComposed>;
    /**
     * Specifies IDs of objects that you want to exclude from the scope of the restore operator role. Restore operators will not be able to explore and restore data from backups created by Veeam Backup for Microsoft 365 for these objects. For more information on how to get such IDs, see [Get Excluded Objects](#tag/RbacRole/operation/RbacRole_GetExcludedItems).
     * @type {Array<RESTRbacItemComposed>}
     * @memberof RESTRbacRoleCreateModel
     */
    excludedItems?: Array<RESTRbacItemComposed>;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTRbacRoleCreateModel
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTRbacRoleCreateModelRoleTypeEnum {
    EntireOrganization = 'EntireOrganization',
    SpecificObjects = 'SpecificObjects'
}

