/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTApplication,
    RESTApplicationFromClient,
    RESTExceptionInfo,
} from '../models';

export interface ApplicationApiApplicationCreateApplicationRequest {
    organizationId: string;
    body: RESTApplicationFromClient;
}

export interface ApplicationApiApplicationGetApplicationsRequest {
    organizationId: string;
    limit: number;
    offset: number;
    displayName?: string;
    tag?: string;
}

/**
 * no description
 */
export const applicationApi = ({

    /**
     * Creates Azure AD applications in Microsoft Azure Active Directory using the device code.
     * Create Applications in Azure
     */
    applicationCreateApplication: ({ organizationId, body }: ApplicationApiApplicationCreateApplicationRequest, option?: RequestOption): Observable<Optional<PageOfRESTApplication>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'applicationCreateApplication');
        throwIfNullOrUndefined(body, 'body', 'applicationCreateApplication');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<PageOfRESTApplication>({
            url: '/v7/Organizations/{organizationId}/Applications'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a collection of existing applications for the specified Microsoft 365 organization from Azure Active Directory.
     * Get Applications from Azure
     */
    applicationGetApplications: ({ organizationId, limit, offset, displayName, tag }: ApplicationApiApplicationGetApplicationsRequest, option?: RequestOption): Observable<Optional<PageOfRESTApplication>> => {
        throwIfNullOrUndefined(organizationId, 'organizationId', 'applicationGetApplications');
        throwIfNullOrUndefined(limit, 'limit', 'applicationGetApplications');
        throwIfNullOrUndefined(offset, 'offset', 'applicationGetApplications');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (displayName != null) { query['displayName'] = displayName; }
        if (tag != null) { query['tag'] = tag; }

        return requestAuthenticated<PageOfRESTApplication>({
            url: '/v7/Organizations/{organizationId}/Applications'.replace('{organizationId}', encodeURI(organizationId)),
            method: 'GET',
            query,
        }, option);
    },

})
