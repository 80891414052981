// Copyright © Veeam Software Group GmbH

import React, { useEffect, useState } from 'react';

import type { CellRendererProps } from '@veeam/components/lib/Grid/types';
import type { Item } from 'services';

import { itemInRestoreList } from 'assets';
import { iconCellRender } from 'components/dataGridCells/iconCell';
import { bucket } from 'services/restoreService/bucket';

export const RestoreListCell = ({ rowData, payload }: { rowData: Item; payload: CellRendererProps<Item>; }) => {
    const [inRestoreList, setInRestoreList] = useState(false);

    useEffect(() => {
        bucket.isItemInRestoreList(rowData.uniqId)
            .then(isItemInRestoreList => setInRestoreList(isItemInRestoreList));
    });

    const cell = iconCellRender({ iconSrc: inRestoreList ? itemInRestoreList : undefined, text: null }, payload);

    return <>{cell}</>;
};
