// Copyright © Veeam Software Group GmbH

import React, { useEffect, useState } from 'react';
import { required, useForm } from '@veeam/core';
import {
    SplashScreenLayout,
    SplashScreenFormBody,
    Spinner,
    Text,
    Button,
    StackView,
    FormLayout,
    ErrorMessage,
    TextField,
    STACK_DIRECTION,
    STACK_GAP,
    TEXT_SIZE,
    INDENT,
    CONTROL_SIZE,
    STACK_ALIGN,
    useStateValue,
} from '@veeam/components';

import type { FC } from 'react';
import type { Form } from '@veeam/core';
import type { Resources } from 'infrastructure/resources';
import type { Action0, Func1 } from 'infrastructure/types';

import { logoSrc, loginBackgroundSrc } from 'assets';
import { useResources } from 'infrastructure/resources';
import { LogoutProgress } from './LogoutProgress';
import { useLoginContext } from 'features/Login/Login';


type LoginResources = Resources['features']['Login'];

interface LoginFormState {
    userMail: string;
}

function useLogin(
    login: Func1<string, Promise<string | undefined>>,
    externalError: string | undefined
): {
    error?: string;
    form: Form<LoginFormState>;
    loginSubmit: Action0;
} {
    const { getValue: getError, setValue: setError } = useStateValue(externalError, [externalError]);
    const { username } = useLoginContext();
    const form = useForm<LoginFormState>(
        { userMail: username },
        {
            validationSchema: { userMail: [required()] },
        }
    );

    const loginSubmit = async(): Promise<void> => {
        const ok = form.validateAndDisplayErrors();
        if (ok) {
            setError('');
            const error = await login(form.getValue().userMail.trim());
            if (error) {
                setError(error);
                form.fields.userMail.setError(error);
            }
        }
    };

    useEffect(() => {
        form.fields.userMail.update(username);
    }, [username]);

    return { error: getError(), form, loginSubmit };
}

const LoginForm: FC<{
    resources: LoginResources;
    authenticating: boolean;
    login(userMail: string): Promise<string | undefined>;
    error?: string;
}> = ({ authenticating, login, error: externalError, resources }) => {
    const { error, form, loginSubmit } = useLogin(login, externalError);
    function onKeyDown(event: React.KeyboardEvent): void {
        if (event.key === 'Enter') {
            loginSubmit();
        }
    }
    return (
        <>
            <Text size={TEXT_SIZE.xl} trailer={error ? INDENT.none : INDENT.m}>
                {resources.LoginFormTitle}
            </Text>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <StackView onKeyDown={onKeyDown} direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                <FormLayout disabled={authenticating}>
                    <TextField
                        field={form.fields.userMail}
                        placeholder={resources.UsernamePlaceholder}
                        size={CONTROL_SIZE.full}
                    />
                </FormLayout>
                <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s} align={STACK_ALIGN.center}>
                    <Button onClick={loginSubmit} disabled={authenticating}>
                        {resources.LoginButton}
                    </Button>
                    {authenticating && <Spinner />}
                </StackView>
            </StackView>
        </>
    );
};

export const LoginPage: FC<{
    loading: boolean;
    login(userMail: string): Promise<string | undefined>;
    isLogout: boolean;
    error?: string;
}> = ({ isLogout, error, loading, login }) => {
    const resources = useResources().features.Login;
    if (isLogout) {
        return <LogoutProgress error={error} />;
    }

    return (
        <SplashScreenLayout
            productImageSrc={loginBackgroundSrc}
            minWidth={1024}
            content={
                <SplashScreenFormBody
                    title={resources.productName}
                    icon={logoSrc}
                    content={
                        <StackView size={{ width: '320px' }} direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                            <LoginForm resources={resources} error={error} authenticating={loading} login={login} />
                        </StackView>
                    }
                />
            }
        />
    );
};
