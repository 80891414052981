// Copyright © Veeam Software Group GmbH

import type { DataGridCellRenderer } from '@veeam/components';
import type { Func1 } from 'infrastructure/types';

import { textCellRender } from './textCell';

import type { CellRender, CellRendererProps } from './CellRender';

function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const size = parseInt((Math.floor(Math.log(bytes) / Math.log(1024))).toString(), 10);
    if (size === 0) return `${bytes} ${sizes[size]}`;
    return `${(bytes / (1024 ** size)).toFixed(1)} ${sizes[size]}`;
}

export const sizeCellRender = (value: number | undefined, props: CellRendererProps): CellRender => textCellRender(
    value ? bytesToSize(value) : undefined,
    props,
);

export const sizeCell = <TRowData>(
    value: Func1<TRowData, number | undefined>,
): DataGridCellRenderer<TRowData> => (row, payload) => sizeCellRender(value(row), payload);
