import { Subject } from "rxjs";
import "../models";

export const sharePointSessionEventsHub = {
    onUnavailableDataFound: new Subject<unknown>(),
    };

function onConnect(connection: SignalR.Hub.Connection): void {
    const proxy = connection.createHubProxy('SharePointSessionEventsHub');
    proxy.on('onUnavailableDataFound', (req) => sharePointSessionEventsHub.onUnavailableDataFound.next(req));
}

export const sharePointSessionEventsHubConnector = { onConnect };
