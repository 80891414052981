// Copyright © Veeam Software Group GmbH

import { useEffect, useMemo } from 'react';

import type React from 'react';
import type { TeamsPostItem } from 'services/models/Teams/TeamsItem';

import { findIdValueInUrl } from 'services/explore/products/teams/find-id-value-in-url';
import { RepliesGridModel } from './replies-grid-model';
import { EXPLORE_PAGE_LIMIT } from '../ExploreGrid/consts/explore-page-limit';

import type { SubheaderType } from '../ExploreGrid/enums/subheader-type';


export function useRepliesGridModel(
    parent: TeamsPostItem,
    setAsyncLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setSubheaderType: React.Dispatch<React.SetStateAction<SubheaderType>>
): RepliesGridModel {
    const model = useMemo(() => new RepliesGridModel({
        parent: parent,
        teamId: findIdValueInUrl(parent._links.team.href, 'teams'),
        channelId: findIdValueInUrl(parent._links.channel.href, 'channels'),
        limit: EXPLORE_PAGE_LIMIT,
    }, setAsyncLoading, setSubheaderType), []);

    useEffect(() => () => model.destroy(), []);

    return model;
}
