// Copyright © Veeam Software Group GmbH

import { ACTION_VIEW, DataGrid, Dialog, DIALOG_SIZE } from '@veeam/components';
import React, { useMemo } from 'react';

import type { Action0 } from 'infrastructure/types';
import type { VersionableItem } from 'services';

import { useResources } from 'infrastructure/resources';
import { getVersionsGridColumns } from './get-versions-grid-columns';
import { restoreToolbarAction } from '../../../../features/Restore';
import { useVersionGridModel } from '../../models/version-grid-model';
import { addToRestoreListAction } from '../../helpers/addToRestoreListAction';

export const VersionDialog: React.FC<{
    item: VersionableItem;
    deactivate: Action0;
}> = ({ item, deactivate }) => {
    const resources = useResources().features.Explore.VersionsDialog;
    const model = useVersionGridModel(item);
    const columns = useMemo(() => getVersionsGridColumns(item, resources), [item, resources]);

    const getItems = (): VersionableItem[] => {
        const selection = model.getValue().selection;

        if (selection.length !== 1) return [];

        const selected = model.getItemById(selection[0]);

        if (!selected) return [];

        return [selected];
    };

    return (
        <Dialog
            header={resources.title}
            hasTopPadding={false}
            size={{
                width: DIALOG_SIZE.xl,
                height: DIALOG_SIZE.xl,
            }}
            onRequestClose={deactivate}
            actions={[
                { text: resources.close, onClick: deactivate, view: ACTION_VIEW.primary },
            ]}
        >
            <DataGrid
                model={model}
                columns={columns}
                toolbar={{
                    items: [
                        restoreToolbarAction(getItems),
                        addToRestoreListAction(getItems, model.unselectAll),
                    ],
                }}
                config={{
                    canResizeColumns: true,
                }}
            />
        </Dialog>
    );
};
