// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTRbacItemComposed,
} from './';

/**
 * @export
 * @interface RESTOperatorExploreOptions
 */
export interface RESTOperatorExploreOptions {
    /**
     * Specifies the date and time.
     * @type {string}
     * @memberof RESTOperatorExploreOptions
     */
    dateTime?: string;
    /**
     * Specifies a type of the restore session to start.
     * @type {string}
     * @memberof RESTOperatorExploreOptions
     */
    type?: RESTOperatorExploreOptionsTypeEnum;
    /**
     * Defines whether the restore session will show items that have been removed by the user before the specified date.
     * @type {boolean}
     * @memberof RESTOperatorExploreOptions
     */
    showDeleted?: boolean;
    /**
     * Defines whether the restore session will show all versions of items that have been modified by the user before the specified date.
     * @type {boolean}
     * @memberof RESTOperatorExploreOptions
     */
    showAllVersions?: boolean;
    /**
     * @type {RESTRbacItemComposed}
     * @memberof RESTOperatorExploreOptions
     */
    scope?: RESTRbacItemComposed;
    /**
     * Specifies a reason for the restore operation.
     * @type {string}
     * @memberof RESTOperatorExploreOptions
     */
    reason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTOperatorExploreOptionsTypeEnum {
    Vex = 'Vex',
    Veod = 'Veod',
    Vesp = 'Vesp',
    Vet = 'Vet'
}

