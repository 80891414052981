// Copyright © Veeam Software Group GmbH

import { PublicClientApplication } from '@azure/msal-browser';

import resourcesController from 'infrastructure/resources';
import { msalStorage } from 'infrastructure/storage';

export interface MsalAuthResult {
    username: string;
    assertion: string;
    clientId: string;
}

let msalInstance: PublicClientApplication | undefined;

function getMsalInstance(appId?: string, msalAuthorityUri?: string): PublicClientApplication {
    if (!msalInstance) {
        const msalStorageInstance = msalStorage.get();
        const clientId = appId || msalStorageInstance?.appId;
        const authority = msalAuthorityUri || msalStorageInstance?.msalAuthorityUri;
        if (!clientId) throw new Error(resourcesController.current.infrastructure.auth.msalClientIdIsNotExist);
        msalInstance = new PublicClientApplication({
            auth: {
                clientId,
                authority,
                redirectUri: window.location.origin,
                navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
            },
        });
    }
    return msalInstance;
}

export async function beginMsalAuth(appId: string, loginHint: string, msalAuthorityUri?: string): Promise<void> {
    sessionStorage.clear();
    msalStorage.save({ appId, loginHint, msalAuthorityUri });
    const msal = getMsalInstance(appId, msalAuthorityUri);
    await msal.loginRedirect({
        scopes: [`api://${appId}/access_as_user`],
        redirectStartPage: `${window.location.origin}`,
        loginHint,
    });
}

export const isMsalLoginInitiated = (): boolean => !!msalStorage.get();

export const resetMsalInitiated = (): void => msalStorage.clear();

export async function endMsalAuth(): Promise<MsalAuthResult> {
    const msalAuthFailed = resourcesController.current.infrastructure.auth.msalAuthFailed;

    const authResult = await getMsalInstance().handleRedirectPromise();

    if (authResult === null) {
        resetMsalInitiated();
        sessionStorage.clear();
        throw new Error(msalAuthFailed);
    }

    const username = authResult?.account?.username;
    const assertion = authResult?.accessToken;
    const clientId = authResult?.account?.homeAccountId;

    if (!username || !assertion || !clientId) throw new Error(msalAuthFailed);

    return { username, assertion, clientId };
}

export async function msalLogout(emergency?: boolean): Promise<void> {
    const instance = getMsalInstance();
    resetMsalInitiated();
    if (!emergency) {
        await instance.logout();
    }
}
