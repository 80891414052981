// Copyright © Veeam Software Group GmbH

import React from 'react';
import {
    GRID_COLUMN_JUSTIFY,
    Icon,
    ICON_SIZES,
    StackView,
    STACK_DIRECTION,
} from '@veeam/components';

import type {
    DataGridHeaderCellRenderer,
    DataGridHeaderCellRendererProps } from '@veeam/components';
import type { Func1 } from 'infrastructure/types';

import { mapGridJustifyToStackAlign } from './iconCell';

export function iconHeaderCell(
    value: Func1<DataGridHeaderCellRendererProps, string>,
    size: ICON_SIZES = ICON_SIZES.m
): DataGridHeaderCellRenderer {
    return (props) => {
        const { justify = GRID_COLUMN_JUSTIFY.center } = props;
        return (
            <StackView direction={STACK_DIRECTION.column} align={mapGridJustifyToStackAlign(justify)}>
                <Icon src={value(props)} size={size} />
            </StackView>
        );
    };
}

export function iconHeaderCellContent(importanceSrc: string) {
    return () => {
        return <Icon style={{marginLeft: 14}} src={importanceSrc} size={ICON_SIZES.m} />;
    }
}
