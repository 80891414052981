// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTUser
 */
export interface RESTUser {
    /**
     * Organization user ID.
     * @type {string}
     * @memberof RESTUser
     */
    id?: string;
    /**
     * ID of the organization user in the on-premises organization.
     * @type {string}
     * @memberof RESTUser
     */
    onPremisesSid?: string;
    /**
     * Display name of the organization user.
     * @type {string}
     * @memberof RESTUser
     */
    displayName?: string;
    /**
     * Email address of the organization user.
     * @type {string}
     * @memberof RESTUser
     */
    name?: string;
    /**
     * User type
     * @type {string}
     * @memberof RESTUser
     */
    type?: RESTUserTypeEnum;
    /**
     * Microsoft 365 organization deployment type.
     * @type {string}
     * @memberof RESTUser
     */
    locationType?: RESTUserLocationTypeEnum;
    /**
     * Office location in the place of business of a user.
     * @type {string}
     * @memberof RESTUser
     */
    office?: string;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTUser
     */
    _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTUserTypeEnum {
    User = 'User',
    Shared = 'Shared',
    Public = 'Public'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTUserLocationTypeEnum {
    OnPremises = 'OnPremises',
    Cloud = 'Cloud',
    Hybrid = 'Hybrid'
}

