// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { DataGridColumnConfig } from '@veeam/components';
import type { LocalizableString } from 'infrastructure/resources';
import type {
    RestoreSession,
    RestoreSessionType,
    RestoreSessionStatus } from 'services/restoreService';

import { textCell, dateCell, iconCell } from 'components/dataGridCells';
import { getStatusToTitleMap, getTypeToTitleMap, restoreSessionStatusIconMap } from './mappers';
import {
    useRestoreSessionTitle,
    RestoreSessionsSortKeys,
} from 'services/restoreService';

import type { GridResources } from './resources';


const mapType = (type: RestoreSessionType, resources: GridResources): LocalizableString =>
    getTypeToTitleMap(resources.types)[type];
const mapStatus = (
    status: RestoreSessionStatus,
    resources: GridResources
): { text: LocalizableString; iconSrc: string; } => ({
    text: getStatusToTitleMap(resources.statuses)[status],
    iconSrc: restoreSessionStatusIconMap[status],
});

export const useColumns = (resources: GridResources): DataGridColumnConfig<RestoreSession>[] => {
    const titleMapper = useRestoreSessionTitle();
    return useMemo(
        () => [
            {
                id: 'name',
                title: resources.cells.session,
                sortKey: RestoreSessionsSortKeys.Title,
                cell: textCell(row => titleMapper(row)),
            },
            {
                id: 'type',
                title: resources.cells.type,
                sortKey: RestoreSessionsSortKeys.Type,
                cell: textCell(row => mapType(row.type, resources)),
                width: 120,
            },
            {
                id: 'status',
                title: resources.cells.status,
                sortKey: RestoreSessionsSortKeys.Status,
                cell: iconCell(row => mapStatus(row.status, resources)),
                width: 150,
            },
            {
                id: 'startTime',
                title: resources.cells.startTime,
                sortKey: RestoreSessionsSortKeys.StartTime,
                cell: dateCell(row => row.startTime),
                width: 230,
            },
            {
                id: 'endTime',
                title: resources.cells.endTime,
                sortKey: RestoreSessionsSortKeys.EndTime,
                cell: dateCell(row => row.endTime),
                width: 230,
            },
            {
                id: 'details',
                title: resources.cells.details,
                sortKey: RestoreSessionsSortKeys.Details,
                cell: textCell(row => row.details),
            },
            {
                id: 'reason',
                title: resources.cells.reason,
                sortKey: RestoreSessionsSortKeys.Reason,
                cell: textCell(row => row.reason),
            },
            {
                id: 'scopeName',
                title: resources.cells.scopeName,
                sortKey: RestoreSessionsSortKeys.ScopeName,
                cell: textCell(row => row.scopeName),
            },
        ],
        [resources]
    );
};
