// Copyright © Veeam Software Group GmbH

import { createRecoveryListTable } from './recoveryListTable';

import type { SelfServicePortalDatabase } from '../database';

export const createLocalStorageDatabase = (): SelfServicePortalDatabase => {
    const recoveryList = createRecoveryListTable();
    const clear = () => new Promise<void>((resolve) => {
        recoveryList.clear();
        resolve();
    });
    return { recoveryList, clear };
};
