// Copyright © Veeam Software Group GmbH

import cloneDeepWith from 'lodash/cloneDeepWith';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import moment, { isMoment } from 'moment';

import type { EnumMap } from 'infrastructure/types';

enum SerializableTypes {
    Moment = 'Moment',
}

interface Serialized {
    __serialized: true;
    type: SerializableTypes;
    value: string;
}

interface Serializer {
    trySerialize(value: unknown): false | Serialized;
    deserialize(value: Serialized): unknown;
}

const momentSerializer: Serializer = {
    trySerialize: value =>
        isMoment(value)
            ? {
                __serialized: true,
                type: SerializableTypes.Moment,
                value: value.toISOString(true),
            }
            : false,
    deserialize: serialized => moment(serialized.value),
};

const serializersMap: EnumMap<SerializableTypes, Serializer> = {
    Moment: momentSerializer,
};

const serializers = values(serializersMap);

const cloneObject = (value: object): unknown => {
    for (const serializer of serializers) {
        const result = serializer.trySerialize(value);
        if (isObject(result)) return result;
    }
    return mapValues(value, serializeComplexType);
};

export const serializeComplexType = (obj: unknown): unknown => {
    if (isArray(obj)) return obj.map(serializeComplexType);
    if (isObject(obj)) return cloneObject(obj);
    return obj;
};

export const deserializeComplexType = (obj: unknown): unknown =>
    cloneDeepWith(obj, (value) => {
        const escaped = value as Serialized;
        if (escaped?.__serialized === true) {
            const serializer = serializersMap[escaped.type];
            return serializer.deserialize(escaped);
        }
        // On return `undefined`, we run the default approach of `cloneDeep` function,
        // as if we weren't specifying `customizer` parameter at all.
        return undefined;
    });
