// Copyright © Veeam Software Group GmbH

export interface FilterRule<TRowValue, TFilter> {
    filter(value: TRowValue, filter: TFilter): boolean;
    skip(filter: TFilter | undefined): boolean;
}

export type FilterRules<TRowValue, TFilterObject> = {
    [prop in keyof TFilterObject]: FilterRule<TRowValue, TFilterObject[prop]>;
};

type Getter<TRowValue, TValue> = (rowValue: TRowValue) => TValue | undefined;

function baseFilter<TRowValue, TValue>(
    getter: Getter<TRowValue, TValue>,
    row: TRowValue,
    concrete: (value: TValue) => boolean,
): boolean {
    const value = getter(row);
    return value ? concrete(value) : false;
}

const skipRule: FilterRule<unknown, unknown> = ({
    skip: () => true,
    filter: () => true,
});

const searchRule = <TRowValue>(getter: Getter<TRowValue, string>): FilterRule<TRowValue, string> => ({
    skip: filter => !filter || filter === '',
    filter: (row, filter) => baseFilter(getter, row, value => value.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0),
});

const enumRule = <TRowValue, TEnum extends string>(getter: Getter<TRowValue, TEnum>): FilterRule<TRowValue, TEnum[]> => ({
    skip: filters => !filters || filters.length === 0,
    filter: (row, filters) => baseFilter(getter, row, value => filters.some(filter => filter === value)),
});

export const filters = {
    skip: skipRule,
    search: searchRule,
    enum: enumRule,
};

interface FilterConstraint {
    [key: string]: unknown;
}

const enumerateRules = <TRowValue, TFilterObject>(rules: FilterRules<TRowValue, TFilterObject>): [
    keyof TFilterObject,
    FilterRule<TRowValue, TFilterObject[keyof TFilterObject]>,
][] => Object.entries(rules).map(([ruleKey, rule]) => [
    ruleKey as keyof TFilterObject,
    rule as FilterRule<TRowValue, TFilterObject[keyof TFilterObject]>,
    ]);

export type FilterEngine<TRowValue, TFilter> = (filters: TFilter, values: TRowValue[]) => TRowValue[];

export const createFilterEngine = <
    TRowValue,
    TFilterObject extends FilterConstraint,
>(rules: FilterRules<TRowValue, TFilterObject>): FilterEngine<TRowValue, TFilterObject> => (filters, values) => {
        const notEmptyRules = enumerateRules(rules).filter(([ruleKey, rule]) => !rule.skip(filters[ruleKey]));
        if (notEmptyRules.length === 0) return values;
        const isPass = (value: TRowValue): boolean => notEmptyRules.reduce((result, [ruleKey, rule]) => result && rule.filter(value, filters[ruleKey]), true as boolean);
        return values.filter(isPass);
    };
