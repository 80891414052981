// Copyright © Veeam Software Group GmbH

import React from 'react';

import type { CollectionToolbarCustomItem } from '@veeam/components';
import type { Func0 } from 'infrastructure/types';
import type { Item } from 'services';

import { RestoreButton } from './RestoreButton';


export const restoreToolbarAction = (getItems: Func0<Item[]>): CollectionToolbarCustomItem => ({
    isAction: true,
    render: () => <RestoreButton items={getItems()} />,
    hasOffset: true,
});
