// Copyright © Veeam Software Group GmbH

import { authController } from './AuthController';
import { AuthState } from './types';

import type { AuthController, AuthenticatedController, AuthenticatingController, LogoutingController, NotAuthenticatedController } from './types';

export const isAuthenticated = (controller?: AuthController): controller is AuthenticatedController =>
    (controller || authController).status === AuthState.Authenticated;
export const isContainLoginInfo = (controller?: AuthController): controller is AuthenticatedController | LogoutingController =>
    (controller || authController).status === AuthState.Authenticated || (controller || authController).status === AuthState.Logouting;
export const isAuthenticating = (controller?: AuthController): controller is AuthenticatingController =>
    (controller || authController).status === AuthState.Authenticating;
export const isNotAuthenticated = (controller?: AuthController): controller is NotAuthenticatedController =>
    (controller || authController).status === AuthState.NotAuthenticated;
