import { Observable } from "rxjs";
import { subjects } from "../runtime";
import { RestoreSessionLogConfig } from "../models";

export interface RestoreSessionLogEventsHub {
    addRestoreSessionLog: Observable<RestoreSessionLogConfig>;
    finishRestoreSessionLog: Observable<RestoreSessionLogConfig>;
}

export const restoreSessionLogEventsHub: RestoreSessionLogEventsHub = subjects.restoreSessionLogEventsHub;
