// Copyright © Veeam Software Group GmbH

import { exchangeFolderApi, exchangeItemApi, exchangeMailboxApi } from 'api/rxjs';
import { exploreSessionService } from 'services/exploreSessions';
import { renewSession } from '../../renewSession';

import type { Fetch } from '../../renewSession';

const renewExchangeSession = <TRequest, TResponse>(fetch: Fetch<TRequest, TResponse>): Fetch<TRequest, TResponse> =>
    renewSession(fetch, exploreSessionService.renewSession.exchange);

export const exchangeApi = {
    getItems: renewExchangeSession(exchangeItemApi.exchangeItemGet),
    getMailboxes: renewExchangeSession(exchangeMailboxApi.exchangeMailboxGet),
    getFolders: renewExchangeSession(exchangeFolderApi.exchangeFolderGet),
    searchIn: {
        mailbox: renewExchangeSession(exchangeMailboxApi.exchangeMailboxSearchByIdByOptions),
        folder: renewExchangeSession(exchangeFolderApi.exchangeFolderSearchByOptions),
    },
};
