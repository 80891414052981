// Copyright © Veeam Software Group GmbH

import { useReducer } from 'react';

import { authController, isAuthenticating, isAuthenticated as isAuth } from 'infrastructure/auth';
import { never } from 'infrastructure/never';

export interface LoginReducerState {
    username: string;
    isAuthenticated: boolean;
    isLogout: boolean;
    loading: boolean;
    error?: string;
}

interface AuthenticatingAction {
 type: 'Authenticating'; username: string;
}
interface AuthenticatedAction {
 type: 'Authenticated'; username: string;
}
interface ErrorAction {
 type: 'Error'; error: string;
}
interface LogoutingAction {
 type: 'Logouting'; reason?: string;
}
interface LogoutedAction {
 type: 'Logouted'; reason?: string;
}
type Action = AuthenticatingAction | AuthenticatedAction | ErrorAction | LogoutingAction | LogoutedAction;

export interface Actions {
    authenticating(username: string): void;
    authenticated(username: string): void;
    error(error: string): void;
    logouting(reason?: string): void;
    logouted(reason?: string): void;
}

function loginReducer(state: LoginReducerState, action: Action): LoginReducerState {
    switch (action.type) {
        case 'Authenticating': return {
            isAuthenticated: false,
            isLogout: false,
            loading: true,
            username: action.username,
            error: state.error,
        };
        case 'Authenticated': return {
            isAuthenticated: true,
            isLogout: false,
            loading: false,
            username: action.username,
            error: undefined,
        };
        case 'Error': return {
            isAuthenticated: false,
            isLogout: false,
            loading: false,
            username: '',
            error: state.error || action.error,
        };
        case 'Logouting': return {
            isAuthenticated: false,
            isLogout: true,
            loading: true,
            username: '',
            error: state.error || action.reason,
        };
        case 'Logouted': return {
            isAuthenticated: false,
            isLogout: false,
            loading: false,
            username: '',
            error: state.error || action.reason,
        };
        default: return never(action);
    }
}

const getInitial = (): LoginReducerState => ({
    isLogout: false,
    isAuthenticated: isAuth(),
    loading: isAuthenticating(),
    username: isAuth(authController) ? authController.info.username : '',
});

export interface LoginReducer {
    state: LoginReducerState;
    actions: Actions;
}

export function useLoginReducer(): LoginReducer {
    const [state, dispatch] = useReducer(loginReducer, undefined, getInitial);
    const authenticating = (username: string): void => dispatch({ type: 'Authenticating', username });
    const authenticated = (username: string): void => dispatch({ type: 'Authenticated', username });
    const error = (err: string): void => dispatch({ type: 'Error', error: err });
    const logouting = (reason?: string): void => dispatch({ type: 'Logouting', reason });
    const logouted = (reason?: string): void => dispatch({ type: 'Logouted', reason });

    const actions: Actions = {
        authenticating, authenticated, error, logouting, logouted,
    };

    return { state, actions };
}
