// Copyright © Veeam Software Group GmbH

import { useEffect } from 'react';
import { validate as validateEmail } from 'email-validator';

import type { Resources } from 'infrastructure/resources';

import { getErrorMessage } from 'infrastructure/error-management';
import { useResources } from 'infrastructure/resources';
import { useLoginReducer } from './useLoginReducer';
import { authController, isAuthenticating, isNotAuthenticated, isAuthenticated as isAuth } from 'infrastructure/auth';
import { msalStorage } from 'infrastructure/storage';

import type { LoginReducerState, Actions } from './useLoginReducer';

type LoginResources = Resources['features']['Login'];

export interface LoginState extends LoginReducerState {
    login(userMail: string): Promise<string | undefined>;
    logout(): Promise<void>;
}

async function endLogin({ authenticating, authenticated, error }: Actions): Promise<void> {
    if (!isAuthenticating(authController)) return;
    try {
        authenticating(msalStorage.get()?.loginHint ?? '');
        const loginResult = await authController.endLogin();
        authenticated(loginResult.username);
    } catch (err) {
        error(getErrorMessage(err).message);
    }
}

async function beginLogin(
    userMail: string,
    resources: LoginResources,
    { authenticating, error }: Actions
): Promise<string | undefined> {
    if (!validateEmail(userMail)) return resources.userMailNotValid;
    if (!isNotAuthenticated(authController)) return resources.loginAuthControllerError;
    try {
        authenticating(userMail);
        await authController.beginLogin(userMail);
    } catch (err) {
        error(getErrorMessage(err).message);
    }
    return undefined;
}

export function useLogin(): LoginState {
    const resources = useResources().features.Login;
    const { state, actions } = useLoginReducer();

    useEffect(() => {
        authController.events.logout.before.subscribe(actions.logouting);
        authController.events.logout.after.subscribe(actions.logouted);
        authController.events.login.afterMsalLogin.subscribe(actions.authenticating);
        endLogin(actions);
    }, []);

    const login = (userMail: string): Promise<string | undefined> => beginLogin(userMail, resources, actions);

    async function logout(): Promise<void> {
        if (!isAuth(authController)) throw new Error(resources.logoutAuthControllerError);
        actions.logouting();
        await authController.logout();
        actions.logouted();
    }

    return {
        ...state,
        login,
        logout,
    };
}
