/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTRestoreSession,
    RESTDeviceCodeRequestOptions,
    RESTDeviceCodeResponse,
    RESTExceptionInfo,
    RESTExploreOptions,
    RESTOperatorExploreOptions,
    RESTRestoreSession,
} from '../models';

export interface RestoreSessionApiRestoreSessionDeviceCodeActionRequest {
    restoreSessionId: string;
    body: RESTDeviceCodeRequestOptions;
}

export interface RestoreSessionApiRestoreSessionExploreActionRequest {
    body: RESTExploreOptions;
}

export interface RestoreSessionApiRestoreSessionGetByRestoreSessionIdRequest {
    restoreSessionId: string;
}

export interface RestoreSessionApiRestoreSessionGetPageRequest {
    limit: number;
    offset: number;
    startTimeFrom?: string;
    startTimeTo?: string;
}

export interface RestoreSessionApiRestoreSessionOperatorExploreActionRequest {
    body: RESTOperatorExploreOptions;
}

export interface RestoreSessionApiRestoreSessionStopActionRequest {
    restoreSessionId: string;
}

/**
 * no description
 */
export const restoreSessionApi = ({

    /**
     * Allows you to obtain a device code from Microsoft Azure to restore data of Microsoft 365 organizations added using modern app-only authentication.
     * Get Device Code
     */
    restoreSessionDeviceCodeAction: ({ restoreSessionId, body }: RestoreSessionApiRestoreSessionDeviceCodeActionRequest, option?: RequestOption): Observable<Optional<RESTDeviceCodeResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionDeviceCodeAction');
        throwIfNullOrUndefined(body, 'body', 'restoreSessionDeviceCodeAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTDeviceCodeResponse>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/restoreDeviceCode'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Creates and starts a restore session to explore and restore data of a tenant Microsoft organization.  To use this resource, make sure to obtain a token using the same credentials that were used to add the organization instead of credentials of an administrative account. For organizations with modern app-only authentication, obtain a token using an assertion obtained from Microsoft Azure. For more information, see the Veeam Backup for Microsoft 365 REST API Reference, section [Authorization for Tenants](https://helpcenter.veeam.com/docs/vbo365/rest/authorization_tenants_section.html?ver=70). 
     * Create Restore Session for Tenant Organization
     */
    restoreSessionExploreAction: ({ body }: RestoreSessionApiRestoreSessionExploreActionRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(body, 'body', 'restoreSessionExploreAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v7/Organization/Explore',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a resorce representation of a restore session with the specified ID.
     * Get Restore Session
     */
    restoreSessionGetByRestoreSessionId: ({ restoreSessionId }: RestoreSessionApiRestoreSessionGetByRestoreSessionIdRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionGetByRestoreSessionId');

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v7/RestoreSessions/{restoreSessionId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a collection of restore sessions.
     * Get Restore Sessions
     */
    restoreSessionGetPage: ({ limit, offset, startTimeFrom, startTimeTo }: RestoreSessionApiRestoreSessionGetPageRequest, option?: RequestOption): Observable<Optional<PageOfRESTRestoreSession>> => {
        throwIfNullOrUndefined(limit, 'limit', 'restoreSessionGetPage');
        throwIfNullOrUndefined(offset, 'offset', 'restoreSessionGetPage');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'limit': limit,
            'offset': offset,
        };

        if (startTimeFrom != null) { query['startTimeFrom'] = startTimeFrom; }
        if (startTimeTo != null) { query['startTimeTo'] = startTimeTo; }

        return requestAuthenticated<PageOfRESTRestoreSession>({
            url: '/v7/RestoreSessions',
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Creates and starts a restore session to explore and restore data using Restore Portal.  To use this resource, make sure to obtain a token using an assertion obtained from Microsoft Azure and log in to Veeam Backup for Microsoft 365 REST API as a restore operator. For more information, see the Veeam Backup for Microsoft 365 REST API Reference, section [Authorization for Restore Operators](https://helpcenter.veeam.com/docs/vbo365/rest/authorization_restore_operator.html?ver=70).  
     * Create Restore Session for Restore Operator
     */
    restoreSessionOperatorExploreAction: ({ body }: RestoreSessionApiRestoreSessionOperatorExploreActionRequest, option?: RequestOption): Observable<Optional<RESTRestoreSession>> => {
        throwIfNullOrUndefined(body, 'body', 'restoreSessionOperatorExploreAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTRestoreSession>({
            url: '/v7/Organization/OperatorExplore',
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Stops a restore session with the specified ID.
     * Stop Restore Session
     */
    restoreSessionStopAction: ({ restoreSessionId }: RestoreSessionApiRestoreSessionStopActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'restoreSessionStopAction');

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Stop'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
        }, option);
    },

})
