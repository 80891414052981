// Copyright © Veeam Software Group GmbH

import { Subject } from 'rxjs';

import { authController } from 'infrastructure/auth';
import { getErrorMessage } from './getErrorMessage';

import type { UnknownError, ErrorDescription } from './getErrorMessage';

const sessionErrors = [
    'Restore session is stopped.',
    'The session was forcibly closed.',
    'The restore session has stopped due to inactivity.',
    'Authentication session has been expired or could not be found.',
];

const criticalErrors = [
    ...sessionErrors,
];

const minorErrors = [
    'This organization does not have any SharePoint restore points',
    'This organization does not have any OneDrive restore points',
    'This organization does not have any Exchange restore points',
    'The chosen restore point date precedes the date of the first available restore point',
    'Failed to get task result because the task was canceled',
    'Exchange state not found in backup',
];

class ErrorManager {
    errorEvent = new Subject<ErrorDescription>();

    register(error: UnknownError, options?: { silent?: boolean; }): void {
        const silent = options?.silent;

        console.error(error);

        if (silent) return;

        const errorDescription = getErrorMessage(error);

        if (this.isCriticalError(errorDescription)) {
            authController.emergencyLogout(errorDescription.message);
        } else if (!this.isMinorError(errorDescription)) {
            this.errorEvent.next(errorDescription);
        }
    }

    isCriticalError(error: ErrorDescription): boolean {
        return this.isErrorInArray(error, criticalErrors);
    }

    isMinorError(error: ErrorDescription): boolean {
        return this.isErrorInArray(error, minorErrors);
    }

    isSessionError(error: ErrorDescription): boolean {
        return this.isErrorInArray(error, sessionErrors);
    }

    private isErrorInArray(errorDescription: ErrorDescription, errors: string[]): boolean {
        return errors.some(error => errorDescription.message.includes(error));
    }
}

// @singleton
export const errorManager = new ErrorManager();
