// Copyright © Veeam Software Group GmbH

import { sharePointSiteApi, sharePointListApi, sharePointLibraryApi, sharePointFolderApi, sharePointItemApi, sharePointDocumentApi } from 'api/rxjs';
import { exploreSessionService } from 'services/exploreSessions';
import { renewSession } from '../../renewSession';

import type { Fetch } from '../../renewSession';

const renewSharePointSession = <TRequest, TResponse>(fetch: Fetch<TRequest, TResponse>): Fetch<TRequest, TResponse> =>
    renewSession(fetch, exploreSessionService.renewSession.sharePoint);

export const sharePointApi = {
    getSites: renewSharePointSession(sharePointSiteApi.sharePointSiteGet),
    getLists: renewSharePointSession(sharePointListApi.sharePointListGet),
    getLibraries: renewSharePointSession(sharePointLibraryApi.sharePointLibraryGet),
    getFolders: renewSharePointSession(sharePointFolderApi.sharePointFolderGet),
    getItems: renewSharePointSession(sharePointItemApi.sharePointItemGet),
    getItemVersions: renewSharePointSession(sharePointItemApi.sharePointItemGetVersions),
    getListFolderItemVersions: renewSharePointSession(sharePointFolderApi.sharePointFolderGetVersions),
    getDocuments: renewSharePointSession(sharePointDocumentApi.sharePointDocumentGet),
    getDocumentVersions: renewSharePointSession(sharePointDocumentApi.sharePointDocumentGetVersions),
    getFolderVersions: renewSharePointSession(sharePointFolderApi.sharePointFolderGetVersions),
    searchIn: {
        site: renewSharePointSession(sharePointSiteApi.sharePointSiteSearchSiteByOptions),
        list: renewSharePointSession(sharePointListApi.sharePointListSearchListByOptions),
        library: renewSharePointSession(sharePointLibraryApi.sharePointLibrarySearchLibraryByOptions),
        folder: renewSharePointSession(sharePointFolderApi.sharePointFolderSearchFolderByOptions),
    },
};
