// Copyright © Veeam Software Group GmbH

import entries from 'lodash/entries';
import set from 'lodash/set';

import type { FilterQuery } from '@veeam/core';
import type { baseObjectOutputType, ZodObject, ZodRawShape } from 'zod';

export interface Converter<TFilter> {
    (filter: FilterQuery): TFilter;
}

export const createConverter = <TShape extends ZodRawShape>(schema: ZodObject<TShape>): Converter<baseObjectOutputType<TShape>> =>
    filterQuery => entries(schema.shape)
        .reduce((acc, [key, fieldParser]) => {
            const rawValue = filterQuery.getFilterValue(key);
            const value = fieldParser.parse(rawValue);
            return set(acc, key, value);
        }, {} as baseObjectOutputType<TShape>);
