// Copyright © Veeam Software Group GmbH

import React, { createContext, useContext, useEffect } from 'react';
import { useModal, MessageBox, MESSAGE_BOX_ICON_VIEW } from '@veeam/components';

import type { FC } from 'react';

import { useResources } from 'infrastructure/resources';
import { errorManager } from './errorManager';

import type { UnknownError, ErrorDescription } from './getErrorMessage';

export interface ErrorManagement {
    register(err: UnknownError): void;
}

export interface MessageConfig extends ErrorDescription {
    iconView?: MESSAGE_BOX_ICON_VIEW;
    closeActionLabel?: string;
    onClose?: () => void;
}
export interface MessageBoxManagement {
    show(msgConfig: MessageConfig): void;
}

interface ErrorContextState extends ErrorManagement, MessageBoxManagement {}

const register = (err: UnknownError): void => errorManager.register(err);

const ErrorContext = createContext<ErrorContextState>({ register, show: () => {} });

export const useErrorManagement = (): ErrorManagement => useContext(ErrorContext);
export const useMessageBoxManagement = (): MessageBoxManagement => useContext(ErrorContext);

export const ErrorManager: FC = (props) => {
    const { ErrorTitle, unknownError, CloseActionLabel } = useResources().infrastructure.errorManagement;

    const [dialog, { activate, isActive }] = useModal<MessageConfig>({
        render: ({ data, deactivate }) => {
            if (!data) {
                return null;
            }
            const onCloseHandler = () => {
                deactivate();
                data.onClose?.();
            };
            return (
                <MessageBox
                    header={data.header || ErrorTitle}
                    iconView={data.iconView ?? MESSAGE_BOX_ICON_VIEW.error}
                    onRequestClose={onCloseHandler}
                    actions={[
                        {
                            text: data.closeActionLabel ?? CloseActionLabel,
                            onClick: onCloseHandler,
                        },
                    ]}
                >
                    {data.message || unknownError}
                </MessageBox>
            );
        },
    });

    const show = (msgConfig: MessageConfig) => {
        if (!isActive) activate(msgConfig);
    };

    useEffect(() => {
        const subscription = errorManager.errorEvent.subscribe((errorMessage) => {
            if (!isActive) activate(errorMessage);
        });
        return () => {
            if (!subscription.closed) subscription.unsubscribe();
        };
    });

    return (
        <ErrorContext.Provider value={{ register, show }}>
            <>
                {props.children}
                {dialog}
            </>
        </ErrorContext.Provider>
    );
};
