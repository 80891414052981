import { authController, isAuthenticated } from "infrastructure/auth";
import { restoreSessionEventsHub, restoreSessionLogEventsHub, exchangeSessionEventsHub, oneDriveSessionEventsHub, sharePointSessionEventsHub, restoreSessionEventsHubConnector, restoreSessionLogEventsHubConnector, exchangeSessionEventsHubConnector, oneDriveSessionEventsHubConnector, sharePointSessionEventsHubConnector } from "./subjects";

interface Hub {
    onConnect(connection: SignalR.Hub.Connection): void;
}

let server = window.origin + '/v7';
let state: { connection?: SignalR.Hub.Connection } = {};
export let subjects = { restoreSessionEventsHub, restoreSessionLogEventsHub, exchangeSessionEventsHub, oneDriveSessionEventsHub, sharePointSessionEventsHub };
export let connectors: Hub[] = [ restoreSessionEventsHubConnector, restoreSessionLogEventsHubConnector, exchangeSessionEventsHubConnector, oneDriveSessionEventsHubConnector, sharePointSessionEventsHubConnector ];

async function reconnect(newToken: string) {
    if (state.connection) state.connection.stop();
    const newConn = $.hubConnection(server);
    (newConn as any).accessToken = newToken;
    state.connection = newConn;
    connectors.forEach(x => x.onConnect(newConn));
    await newConn.start();
}
authController.events.renewToken.subscribe(reconnect);
if(isAuthenticated(authController)) reconnect(authController.info.accessToken);
