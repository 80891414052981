// Copyright © Veeam Software Group GmbH

/* eslint-disable */
// It is autogenerated file. Please, do not change it
//
import componentsDataGridCells from "../../../components/dataGridCells/en.res.json";
import featuresExploreExploreGrid from "../../../pages/ExplorePage/components/ExploreGrid/en.res.json";
import restorePointSelector from "../../../pages/ExplorePage/components/RestorePointSelector/en.res.json";
import featuresExploreVersionsDialogVersionsGrid from "../../../pages/ExplorePage/components/VersionDialog/en.res.json";
import featuresLogin from "../../../features/Login/en.res.json";
import featuresMain from "../../../features/Main/en.res.json";
import featuresRbac from "../../../features/Rbac/en.res.json";
import featuresRbacScopesGrid from "../../../features/Rbac/ScopesGrid/en.res.json";
import featuresRecoveryList from "../../../features/RecoveryList/en.res.json";
import featuresRestore from "../../../features/Restore/en.res.json";
import featuresRestoreWizardsExchangeRestoreWizard from "../../../features/Restore/Wizards/ExchangeRestoreWizard/en.res.json";
import featuresRestoreWizardsExchangeRestoreWizardRestoreModeStep from "../../../features/Restore/Wizards/ExchangeRestoreWizard/RestoreModeStep/en.res.json";
import featuresRestoreWizardsExchangeRestoreWizardRestoreOptionsPanel from "../../../features/Restore/Wizards/ExchangeRestoreWizard/RestoreOptionsPanel/en.res.json";
import featuresRestoreWizardsOneDriveRestoreWizard from "../../../features/Restore/Wizards/OneDriveRestoreWizard/en.res.json";
import featuresRestoreWizardsOneDriveRestoreWizardOneDriveRestoreModeStep from "../../../features/Restore/Wizards/OneDriveRestoreWizard/OneDriveRestoreModeStep/en.res.json";
import featuresRestoreWizardsSharedReasonStep from "../../../features/Restore/Wizards/Shared/ReasonStep/en.res.json";
import featuresRestoreWizardsSharedItemsStep from "../../../features/Restore/Wizards/Shared/ItemsStep/en.res.json";
import featuresRestoreWizardsSharePointRestoreWizard from "../../../features/Restore/Wizards/SharePointRestoreWizard/en.res.json";
import featuresRestoreWizardsSharePointRestoreWizardRestoreModeStep from "../../../features/Restore/Wizards/SharePointRestoreWizard/RestoreModeStep/en.res.json";
import featuresRestoreWizardsSharePointRestoreWizardRestoreOptionsPanel from "../../../features/Restore/Wizards/SharePointRestoreWizard/RestoreOptionsPanel/en.res.json";
import featuresRestoreSessionsEventsGrid from "../../../features/RestoreSessions/EventsGrid/en.res.json";
import featuresRestoreSessionsSessionsGrid from "../../../features/RestoreSessions/SessionsGrid/en.res.json";
import infrastructureAuth from "../../auth/en.res.json";
import infrastructureErrorManagement from "../../error-management/en.res.json";
import infrastructureNever from "../../never/en.res.json";
import servicesExploreSharePoint from "../../../services/explore/products/sharePoint/en.res.json";
import servicesRbac from "../../../services/rbac/en.res.json";
import servicesRestoreServiceBucket from "../../../services/restoreService/bucket/en.res.json";
import servicesRestoreServiceSessions from "../../../services/restoreService/sessions/en.res.json";
import featuresTeamsRestoreWizard from "features/Restore/Wizards/TeamsRestoreWizard/en.res.json";
import featuresRepliesDialog from "pages/ExplorePage/components/RepliesDialog/en.res.json";

export default {
    components: {
        dataGridCells: componentsDataGridCells
    },
    features: {
        Explore: {
            ExploreGrid: featuresExploreExploreGrid,
            VersionsDialog: featuresExploreVersionsDialogVersionsGrid,
            RepliesDialog: featuresRepliesDialog,
            RestorePointSelector: restorePointSelector,
        },
        Login: featuresLogin,
        Main: featuresMain,
        Rbac: {
            ...featuresRbac,
            ScopesGrid: featuresRbacScopesGrid
        },
        RecoveryList: featuresRecoveryList,
        Restore: {
            ...featuresRestore,
            Wizards: {
                ExchangeRestoreWizard: {
                    ...featuresRestoreWizardsExchangeRestoreWizard,
                    RestoreModeStep: featuresRestoreWizardsExchangeRestoreWizardRestoreModeStep,
                    RestoreOptionsPanel: featuresRestoreWizardsExchangeRestoreWizardRestoreOptionsPanel
                },
                OneDriveRestoreWizard: {
                    ...featuresRestoreWizardsOneDriveRestoreWizard,
                    OneDriveRestoreModeStep: featuresRestoreWizardsOneDriveRestoreWizardOneDriveRestoreModeStep
                },
                Shared: {
                    ReasonStep: featuresRestoreWizardsSharedReasonStep,
                    ItemsStep: featuresRestoreWizardsSharedItemsStep
                },
                SharePointRestoreWizard: {
                    ...featuresRestoreWizardsSharePointRestoreWizard,
                    RestoreModeStep: featuresRestoreWizardsSharePointRestoreWizardRestoreModeStep,
                    RestoreOptionsPanel: featuresRestoreWizardsSharePointRestoreWizardRestoreOptionsPanel
                },
                TeamsRestoreWizard: featuresTeamsRestoreWizard
            }
        },
        RestoreSessions: {
            EventsGrid: featuresRestoreSessionsEventsGrid,
            SessionsGrid: featuresRestoreSessionsSessionsGrid
        }
    },
    infrastructure: {
        auth: infrastructureAuth,
        errorManagement: infrastructureErrorManagement,
        never: infrastructureNever
    },
    services: {
        explore: {
            sharePoint: servicesExploreSharePoint
        },
        rbac: servicesRbac,
        restoreService: {
            bucket: servicesRestoreServiceBucket,
            sessions: servicesRestoreServiceSessions
        }
    }
};
