// Copyright © Veeam Software Group GmbH

import { useMemo } from 'react';

import type { DataGridColumnConfig } from '@veeam/components';
import type { RestoreSessionsRouting } from 'features/Routing';

import { errorSrc, neverStartedSrc, runningSrc, stoppingSrc, successSrc, warningSrc, pendingSrc } from 'assets';
import { never } from 'infrastructure/never';
import { textCell, iconCell, dateCell } from 'components/dataGridCells';
import { BucketItemSortKeys, BucketItemStatus } from 'services/restoreService';
import { explorePath as path } from 'services';
import { isExchangeMail } from 'services/restoreService/bucket';

import type { BucketItemPresentation } from './useModel';
import type { BucketResources } from './resources';

export const useColumns = (
    resources: BucketResources,
    routing: RestoreSessionsRouting
): DataGridColumnConfig<BucketItemPresentation>[] =>
    useMemo(
        () => [
            {
                id: 'title',
                title: resources.cells.item,
                sortKey: BucketItemSortKeys.Title,
                cell: textCell(row => row.bucketTitle),
            },
            {
                id: 'type',
                title: resources.cells.type,
                sortKey: BucketItemSortKeys.Type,
                cell: textCell(row => row.bucketType),
                width: 120,
            },
            {
                id: 'location',
                title: resources.cells.location,
                sortKey: BucketItemSortKeys.Location,
                cell: textCell(row => path.stringify(row.explorePath)),
            },
            {
                id: 'sent',
                title: resources.cells.sent,
                sortKey: BucketItemSortKeys.Sent,
                cell: dateCell(row => (isExchangeMail(row) ? row.sent : undefined)),
                width: 250,
            },
            {
                id: 'received',
                title: resources.cells.received,
                sortKey: BucketItemSortKeys.Received,
                cell: dateCell(row => (isExchangeMail(row) ? row.received : undefined)),
                width: 250,
            },
            {
                id: 'status',
                title: resources.cells.status,
                cell: iconCell((row) => {
                    if (row.restoreSessionStatusIsLoading) return { text: 'Loading...', iconSrc: pendingSrc };
                    const action = () => routing.goTo(row.sessionId);
                    switch (row.restoreSessionStatus) {
                        case BucketItemStatus.NeverStarted:
                            return { text: 'Never started', iconSrc: neverStartedSrc };
                        case BucketItemStatus.Running:
                            return { text: 'Running', iconSrc: runningSrc, action };
                        case BucketItemStatus.Success:
                            return { text: 'Success', iconSrc: successSrc, action };
                        case BucketItemStatus.Warning:
                            return { text: 'Warning', iconSrc: warningSrc, action };
                        case BucketItemStatus.Failed:
                            return { text: 'Failed', iconSrc: errorSrc, action };
                        case BucketItemStatus.Stopping:
                            return { text: 'Stopping', iconSrc: stoppingSrc, action };
                        default:
                            return never(row.restoreSessionStatus);
                    }
                }),
                width: 250,
            },
        ],
        [resources]
    );
