// Copyright © Veeam Software Group GmbH

import React from 'react';
import { DataGrid } from '@veeam/components';

import type { ReactElement } from 'react';

import { useRestoreBucketModel } from './useModel';
import { useColumns } from './useColumns';
import { useToolbar } from './useToolbar';
import { useRestoreSessionRouting } from 'features/Routing';

import type { RestoreBucketModel } from './useModel';
import type { BucketResources } from './resources';

export function useRecoveryListGrid(resources: BucketResources): { grid: ReactElement; model: RestoreBucketModel; } {
    const model = useRestoreBucketModel();
    const routing = useRestoreSessionRouting();
    const columns = useColumns(resources, routing);
    const toolbar = useToolbar(resources, model);
    const grid = (
        <DataGrid
            model={model}
            columns={columns}
            toolbar={toolbar}
            config={{
                canResizeColumns: true,
                columnsToggleable: true,
                selection: {
                    multi: true,
                    withCheckbox: true,
                },
            }}
        />
    );

    return { grid, model };
}
