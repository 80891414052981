// Copyright © Veeam Software Group GmbH

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { RESTSharePointItemComposed } from 'api/rxjs';
import type { LoadPagesConfig } from 'infrastructure/rxjs';
import type { Override1 } from 'infrastructure/types';
import type {
    SharePointDocument,
    SharePointItem,
    SharePointListItem,
    VersionableItem,
    VespSession,
    SharePointFolderItem,
    SharePointListFolderItem,
} from 'services/models';

import { never } from 'infrastructure/never';
import { Batch, loadPages } from 'infrastructure/rxjs';
import { convert } from './converters';
import { sharePointApi } from './wrappedSharePointApi';
import { SharePointItemType } from 'services/models';
import { exploreSessionService } from 'services/exploreSessions';

import type { GetItemVersionsRequest } from '../../interfaces/get-item-versions-request';

export type GetSharePointItemVersionsRequest = Override1<GetItemVersionsRequest, 'parent', SharePointItem>;

const loadListItemVersions = (session: VespSession, parent: SharePointListItem, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.getItemVersions, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        itemId: parent.id,
    });

const loadListFolderItemVersions = (session: VespSession, parent: SharePointListFolderItem, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.getListFolderItemVersions, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        folderId: parent.id,
    });

const loadDocumentVersions = (session: VespSession, parent: SharePointDocument, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.getDocumentVersions, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        documentId: parent.id,
    });

const loadFoldersVersions = (session: VespSession, parent: SharePointFolderItem, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> =>
    loadPages(sharePointApi.getFolderVersions, config)({
        restoreSessionId: session,
        siteId: parent.siteId,
        folderId: parent.id,
    });

const getLoader = (session: VespSession, parent: SharePointItem, config: LoadPagesConfig | undefined): Observable<Batch<RESTSharePointItemComposed>> => {
    switch (parent.itemType) {
        case SharePointItemType.ListItem: return loadListItemVersions(session, parent, config);
        case SharePointItemType.ListFolderItem: return loadListFolderItemVersions(session, parent, config);
        case SharePointItemType.Document: return loadDocumentVersions(session, parent, config);
        case SharePointItemType.Folder: return loadFoldersVersions(session, parent, config);
        default: return never(parent);
    }
};

export const getSharePointVersions = ({ parent, config }: GetSharePointItemVersionsRequest): Observable<Batch<VersionableItem>> => {
    const { vespSession } = exploreSessionService.getSessions();
    if (!vespSession) {
        console.error('');
        return of(Batch.empty());
    }

    return getLoader(vespSession, parent, config).pipe(
        map(items => items.mapData(item => convert.composedItem.fromRest(item, parent.siteId, parent.explorePath, true))),
    );
};
