// Copyright © Veeam Software Group GmbH

import React, { useState } from 'react';
import first from 'lodash/first';
import { useForm } from '@veeam/core';
import { WizardSummary } from '@veeam/components';
import { finalize } from 'rxjs/operators';

import type { FC } from 'react';
import type { Form } from '@veeam/core';
import type { WizardStepConfig } from 'components/wizard';
import type { Resources } from 'infrastructure/resources';
import type { Action0, Fieldset } from 'infrastructure/types';
import type { OneDriveRestoreOptions } from 'services/restoreService';
import type { OneDriveItem } from 'services';

import { useResources } from 'infrastructure/resources';
import { useRestoreSessionRouting } from 'features/Routing';
import { useReasonStep, getReasonSummary, useItemsStep } from '../Shared';
import { never } from 'infrastructure/never';
import {
    useOneDriveRestoreModeStep,
    getOneDriveRestoreModeSummary,
    OneDriveRestoreMode,
} from './OneDriveRestoreModeStep';
import { Wizard, WIZARD_TYPE } from 'components/wizard';
import { renderMultilineText } from 'components/renders';
import { OneDriveRestoreType, restoreService } from 'services/restoreService';
import { exploreSessionService } from '../../../../services/exploreSessions';

import type { FormWithReason } from '../Shared';
import type {
    FormWithOneDriveRestoreMode } from './OneDriveRestoreModeStep';


interface OneDriveRestoreForm extends FormWithOneDriveRestoreMode, FormWithReason {
    items: OneDriveItem[];
}

const getInitialFormValue = (items: OneDriveItem[]): OneDriveRestoreForm => ({
    items,
    restoreMode: OneDriveRestoreMode.Overwrite,
    reason: '',
});

type WizardResources = Resources['features']['Restore']['Wizards'];

const getItemsSummary = (
    value: OneDriveRestoreForm,
    resources: WizardResources['OneDriveRestoreWizard']
): Fieldset => ({
    title: resources.itemsTitle,
    fields: [
        {
            label: resources.itemsSummary.label,
            value: renderMultilineText(
                value.items.map((item) => {
                    let result = resources.itemsSummary.documentXname.format(item.title).toString();
                    if (item.version) {
                        result += ` (${item.version})`;
                    }

                    return result;
                }
            ).join('\n')),
        },
    ],
});

const Summary: FC<{
    form: Form<OneDriveRestoreForm>;
    resources: WizardResources;
}> = ({ form, resources }) => {
    const value = form.getValue();
    return (
        <WizardSummary
            fieldsets={[
                getItemsSummary(value, resources.OneDriveRestoreWizard),
                getOneDriveRestoreModeSummary(value, resources.OneDriveRestoreWizard.OneDriveRestoreModeStep),
                getReasonSummary(value, resources.Shared.ReasonStep),
            ]}
        />
    );
};

function getRestoreType({ restoreMode }: FormWithOneDriveRestoreMode): OneDriveRestoreType {
    switch (restoreMode) {
        case OneDriveRestoreMode.Overwrite:
            return OneDriveRestoreType.Overwrite;
        case OneDriveRestoreMode.Keep:
            return OneDriveRestoreType.Keep;
        default:
            return never(restoreMode);
    }
}

const getOneDriveRestoreOption = (form: OneDriveRestoreForm): OneDriveRestoreOptions => ({
    restoreType: getRestoreType(form),
    reason: form.reason,
});

export const OneDriveRestoreWizard: FC<{
    close: Action0;
    items: OneDriveItem[];
}> = ({ items, close }) => {
    const { veodSession } = exploreSessionService.getSessions();
    const [loading, setLoading] = useState<boolean>(false);
    const formApi = useForm(getInitialFormValue(items));
    const resources = useResources().features.Restore.Wizards;
    const { goTo } = useRestoreSessionRouting();
    const steps: WizardStepConfig[] = [
        useItemsStep(formApi, resources.Shared.ItemsStep),
        useOneDriveRestoreModeStep(formApi, resources.OneDriveRestoreWizard.OneDriveRestoreModeStep),
        useReasonStep(formApi, resources.Shared.ReasonStep),
        {
            tabTitle: resources.OneDriveRestoreWizard.summary.tabTitle,
            tag: 'summary',
            body: <Summary form={formApi} resources={resources} />,
            description: resources.OneDriveRestoreWizard.summary.description,
        },
    ];
    function submit(): void {
        if (!veodSession) throw new Error();
        setLoading(true);
        const formValue = formApi.getValue();
        const options = getOneDriveRestoreOption(formValue);

        restoreService.restore
            .oneDrive(veodSession, formValue.items, options)
            .pipe(finalize(() => setLoading(false)))
            .subscribe((sessionId) => {
                close();
                goTo(first(sessionId));
            });
    }
    return (
        <Wizard
            pageTitle={resources.OneDriveRestoreWizard.title}
            loading={loading}
            type={WIZARD_TYPE.page}
            onRequestClose={close}
            onExitPage={close}
            onDone={submit}
            steps={steps}
            buttons={{ done: { disabled: loading } }}
        />
    );
};
