// Copyright © Veeam Software Group GmbH

import { expand, map, reduce, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import type { Observable } from 'rxjs';
import type { Optional as Opt, RequestOption, RESTLinkHAL } from 'api/rxjs';
import { ResultKeeper } from 'api/rxjs';

const defaultPageSize = 100;

interface RESTPage<T> {
    offset: number;
    limit: number;
    setId?: string;
    results: T[];
    _links?: { [key: string]: RESTLinkHAL; };
}

export interface LoaderState {
    hasNextBatch: boolean;
    totalCount: number;
    setId?: string;
}

export interface BatchPlain<TData> {
    readonly data: TData[];
    readonly state: LoaderState;
}

interface BatchExtensions<TData> {
    mapData<TOther>(mapper: (item: TData) => TOther): Batch<TOther>;
}

export class Batch<TData> implements BatchPlain<TData>, BatchExtensions<TData> {
    constructor(public readonly data: TData[], public readonly state: LoaderState) { }
    mapData = <TOther>(mapper: (item: TData) => TOther): Batch<TOther> =>
        new Batch(this.data.map(mapper), this.state);
    filterData = <TOther extends TData>(predicate: (item: TData) => item is TOther): Batch<TOther> =>
        new Batch(this.data.filter(predicate), this.state);
    static from = <TData>(data: TData[], loaderState?: LoaderState): Batch<TData> =>
        new Batch(data, loaderState || { hasNextBatch: false, totalCount: data.length });
    static empty = <TData>(state?: LoaderState): Batch<TData> => Batch.from([] as TData[]);
}

interface LoaderPage<TData> {
    data: TData[];
    canContinue: boolean;
    hasNextPage: boolean;
    totalCount: number;
    setId?: string;
}

export enum LoadPagesMode {
    Sync = 'Sync',
    Async = 'Async',
}

export interface LoadPagesConfig extends RequestOption {
    // If specified, will be loaded only 'n = stopLimit' records
    stopLimit?: number;
    // Default: 100
    pageSize?: number;
    // Default: Sync
    mode?: LoadPagesMode;
    prevState?: LoaderState;
    reThrowError?: boolean;
    maxLimit?: number;
}

interface Page {
    offset: number;
    limit: number;
    setId?: string;
}

type LoaderResponse<TResponse> = Observable<Opt<RESTPage<TResponse>>>;
type WrappedLoaderRequest<TRequest> = Omit<TRequest, keyof Page>;
interface Loader<TRequest extends Page, TResponse> {
    (rq1: TRequest, rq2?: RequestOption): LoaderResponse<TResponse>;
}
interface WrappedMethod<TRequest extends Page, TResponse> {
    (request: WrappedLoaderRequest<TRequest>): Observable<Batch<TResponse>>;
}

const load = <TRequest extends Page, TResponse>(
    loader: Loader<TRequest, TResponse>,
    loaderArgs: WrappedLoaderRequest<TRequest>,
    page: Page,
    config?: LoadPagesConfig,
): Observable<LoaderPage<TResponse>> => loader({
    ...loaderArgs,
    ...page,
} as TRequest, config).pipe(
        tap((response) => {
            if (response.isError && config?.reThrowError) throw response.error;
        }),
        map(response => response.isError ? undefined : (response as ResultKeeper<RESTPage<TResponse>>).data),
        map((batch) => {
            if (!batch) return {
                data: [],
                canContinue: false,
                hasNextPage: false,
                totalCount: config?.prevState?.totalCount || 0,
            };
            const isFullPage = batch.results.length === batch.limit;
            const hasNextPage = isFullPage && !!batch._links?.next;
            const totalCount = batch.offset + batch.results.length;
            const maxLimitReached = totalCount === config?.maxLimit;
            const stopLimitOverflow = config?.stopLimit && config.stopLimit <= totalCount;

            const canContinue =
                isFullPage &&
                hasNextPage &&
                !stopLimitOverflow &&
                !maxLimitReached;

            return {
                data: batch.results,
                totalCount,
                canContinue,
                hasNextPage,
                setId: batch.setId,
            };
        })
    );

const calculateNextLimit = (offset: number, pageSize: number, stopLimit: number | undefined): number => {
    const nextTotalCount = offset + pageSize;
    return stopLimit && stopLimit < nextTotalCount ? nextTotalCount - stopLimit - 1 : pageSize;
};

const convertToBatch = <TData>(internalBatch: LoaderPage<TData>): Batch<TData> => new Batch(
    internalBatch.data,
    {
        totalCount: internalBatch.totalCount,
        hasNextBatch: internalBatch.hasNextPage,
        setId: internalBatch.setId,
    });

const loadPagesAsync = <TRequest extends Page, TResponse>(
    loader: Loader<TRequest, TResponse>,
    config?: LoadPagesConfig,
): WrappedMethod<TRequest, TResponse> => (loaderArgs) => {
        if (config?.prevState?.hasNextBatch === false) return of(Batch.empty(config.prevState));
        const stopLimit = config?.stopLimit;
        const pageSize = config?.pageSize || defaultPageSize;
        const firstOffset = config?.prevState?.totalCount || 0;
        const firstLimit = calculateNextLimit(firstOffset, pageSize, stopLimit);
        const firstPage: Page = {
            offset: firstOffset,
            limit: firstLimit,
            setId: config?.prevState?.setId,
        };
        return load(loader, loaderArgs, firstPage, config).pipe(
            expand((batch) => {
                if (!batch.canContinue) return EMPTY;
                const nextOffset = batch.totalCount;
                const nextLimit = calculateNextLimit(nextOffset, pageSize, stopLimit);
                const nextPage: Page = {
                    offset: nextOffset,
                    limit: nextLimit,
                    setId: batch.setId,
                };
                return load(loader, loaderArgs, nextPage, config);
            }),
            map(convertToBatch)
        );
    };

const loadPagesSync = <TRequest extends Page, TResponse>(
    loader: Loader<TRequest, TResponse>,
    config?: LoadPagesConfig,
): WrappedMethod<TRequest, TResponse> => loaderArgs => loadPagesAsync(loader, config)(loaderArgs).pipe(
        reduce((acc, cur) => Batch.from([...acc.data, ...cur.data], cur.state)),
    );

export const loadPages = <TRequest extends Page, TResponse>(
    loader: Loader<TRequest, TResponse>,
    config?: LoadPagesConfig,
): WrappedMethod<TRequest, TResponse> => config?.mode === LoadPagesMode.Async
        ? loadPagesAsync(loader, config)
        : loadPagesSync(loader, config);

// TODO: refactor merge
export const mergeBatches = <TData>(batches: Batch<TData>[]): Batch<TData> => {
    const data = batches.map(batch => batch.data).reduce((acc, cur) => ([...acc, ...cur]));
    const state = batches.map(batch => batch.state).reduce((acc, cur) => ({
        hasNextBatch: acc.hasNextBatch || cur.hasNextBatch,
        totalCount: acc.totalCount + cur.totalCount,
        setId: acc.setId || cur.setId,
    }));
    return Batch.from(data, state);
};

