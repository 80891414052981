// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTAction,
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTExchangeFolder
 */
export interface RESTExchangeFolder {
    /**
     * Name of the organization mailbox folder.
     * @type {string}
     * @memberof RESTExchangeFolder
     */
    name?: string;
    /**
     * Type of the organization mailbox folder.
     * @type {string}
     * @memberof RESTExchangeFolder
     */
    type?: RESTExchangeFolderTypeEnum;
    /**
     * Category of the organization mailbox folder.
     * @type {string}
     * @memberof RESTExchangeFolder
     */
    category?: RESTExchangeFolderCategoryEnum;
    /**
     * Description of the organization mailbox folder.
     * @type {string}
     * @memberof RESTExchangeFolder
     */
    description?: string;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTExchangeFolder
     */
    _links?: { [key: string]: RESTLinkHAL; };
    /**
     * @type {{ [key: string]: RESTAction; }}
     * @memberof RESTExchangeFolder
     */
    readonly _actions?: { [key: string]: RESTAction; };
    /**
     * ID of the organization mailbox folder.
     * @type {string}
     * @memberof RESTExchangeFolder
     */
    id?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RESTExchangeFolderTypeEnum {
    Journal = 'Journal',
    Task = 'Task',
    StickyNote = 'StickyNote',
    Contact = 'Contact',
    Appointment = 'Appointment',
    None = 'None'
}
/**
 * @export
 * @enum {string}
 */
export enum RESTExchangeFolderCategoryEnum {
    Inbox = 'Inbox',
    Outbox = 'Outbox',
    Sent = 'Sent',
    Deleted = 'Deleted',
    Drafts = 'Drafts',
    PermanentlyDeletedItems = 'PermanentlyDeletedItems',
    Purges = 'Purges',
    DiscoveryHolds = 'DiscoveryHolds',
    Conflicts = 'Conflicts',
    SyncIssues = 'SyncIssues',
    LocalFailures = 'LocalFailures',
    ServerFailures = 'ServerFailures',
    Junk = 'Junk',
    Versions = 'Versions',
    TeamsMessagesData = 'TeamsMessagesData',
    None = 'None'
}

