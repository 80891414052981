// Copyright © Veeam Software Group GmbH

import { createContext } from 'react';

import resourcesService from './ResourcesService';

export interface ResourcesContextState {
    resources: typeof resourcesService.current;
    toggleLang(langCode: string): void;
}

const ResourcesContext = createContext<ResourcesContextState>({
    resources: resourcesService.current,
    toggleLang: (langCode: string) => resourcesService.toggleLanguage(langCode),
});

export default ResourcesContext;
