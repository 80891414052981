// Copyright © Veeam Software Group GmbH

import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    Icon,
    ICON_SIZES,
    INDENT,
    MODAL_VIEW,
    SORT_DIRECTION,
    SPACE_FILL,
    STACK_ALIGN,
    STACK_DIRECTION,
    STACK_DISTRIBUTION,
    STACK_GAP,
    StackView,
    Text,
    TEXT_SIZE,
    useModal,
} from '@veeam/components';
import { observer } from 'mobx-react-lite';
import { map } from 'rxjs/operators';
import first from 'lodash/first';


import type { SelectionSubheaderRendererProps } from '@veeam/components';
import type { TeamsPostItem } from 'services/models/Teams/TeamsItem';
import type { Node, VersionableItem } from 'services';

import { getColumns } from './helpers/getColumns';
import { getToolbar } from './helpers/getToolbar';
import { Subheader } from 'pages/ExplorePage/components/ExploreGrid/components/Subheader';
import { SubheaderType } from 'pages/ExplorePage/components/ExploreGrid/enums/subheader-type';
import { useExploreGridModel } from 'pages/ExplorePage/components/ExploreGrid/hooks/use-explore-grid-model';
import selectRestorePoint from 'assets/icons/select-restore-point.svg';
import { useExplorePageStore } from '../../stores/explore-page-store';
import { useResources } from '../../../../infrastructure/resources';
import { useExploreTreeModel } from 'contexts/ExploreTreeProvider';
import { VersionDialog } from '../VersionDialog';
import { RepliesDialog } from '../RepliesDialog';
import { getExploreGridConfig } from './helpers/getExploreGridConfig';
import { useMessageBoxManagement } from '../../../../infrastructure/error-management';

export const ExploreGrid: React.FC = observer(() => {
    const explorePageStore = useExplorePageStore();
    const treeModel = useExploreTreeModel();
    const onNodeChanged = treeModel.asObservable().pipe(map((data) => {
        const id = first(data.selection);
        return id && treeModel.getItemById(id)?.model;
    }));
    const [modal, action] = useModal<VersionableItem>({
        view: MODAL_VIEW.dialog,
        render: ({ data, deactivate }) => (data ? <VersionDialog item={data} deactivate={deactivate} /> : null),
    });
    const [repliesModal, repliesAction] = useModal<TeamsPostItem>({
        view: MODAL_VIEW.dialog,
        render: ({ data, deactivate }) => (data ? <RepliesDialog item={data} deactivate={deactivate} /> : null),
    });
    const { show } = useMessageBoxManagement();
    const resources = useResources().features.Explore.ExploreGrid;
    const [subheaderType, setSubheaderType] = useState(SubheaderType.Default);
    const [asyncLoading, setAsyncLoading] = useState(false);
    const [node, setNode] = useState<Node>();
    const config = getExploreGridConfig(node);
    const model = useExploreGridModel(onNodeChanged, setAsyncLoading, setSubheaderType);
    const toolbar = getToolbar(
        resources,
        model,
        config.searchAvailable,
        config.exportAvailable,
        config.exportAllPostsAvailable,
        (message: string) => {
            show({ message });
        }
    );
    const columns = getColumns(
        resources,
        node,
        (item: VersionableItem) => action.activate(item),
        (item: TeamsPostItem) => repliesAction.activate(item)
    );
    const [staticData] = useState({
        isDisabledItem: config.isDisabledItem,
    });

    staticData.isDisabledItem = config.isDisabledItem;

    useEffect(() => {
        const subscription = onNodeChanged.subscribe(node => setNode(node));

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (config.sorting) {
            model.withSorting(config.sorting);
        } else {
            model.withSorting({
                key: null,
                direction: SORT_DIRECTION.desc,
            });
        }
    });

    return (
        <StackView
            spaceLeft={INDENT.s}
            spaceFill={SPACE_FILL.all}
            direction={STACK_DIRECTION.column}
            align={STACK_ALIGN.center}
            distribution={STACK_DISTRIBUTION.center}
        >
            {explorePageStore.restorePoint && explorePageStore.hasSessions &&
                <DataGrid
                    model={model}
                    columns={columns}
                    toolbar={toolbar}
                    isRowDisabled={ ({ rowData }) => staticData.isDisabledItem ? staticData.isDisabledItem(rowData) : false }
                    config={{
                        canResizeColumns: true,
                        columnsToggleable: true,
                        selection: {
                            withCheckbox: true,
                            multi: config.multiSelectAvailable,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore veeam/components has wrong typings
                            subheaderRenderer: ({ selected, total }: SelectionSubheaderRendererProps<{}>) =>
                                <Subheader
                                    subheaderType={subheaderType}
                                    selected={selected}
                                    total={total}
                                    loadAllItems={model.loadAllItems.bind(model)}
                                    resources={resources}
                                />,
                        },
                    }}
                    texts={{
                        empty: node ? undefined : resources.noSelectedNode,
                    }}
                    loading={asyncLoading}
                    disabled={asyncLoading}
                />
            }
            {!explorePageStore.restorePoint &&
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m} align={STACK_ALIGN.center}>
                    <Icon src={selectRestorePoint} size={ICON_SIZES.xxl} />
                    <Text size={TEXT_SIZE.xl}>Select the restore point to view the objects...</Text>
                </StackView>
            }
            {modal}
            {repliesModal}
        </StackView>
    );
});
