// Copyright © Veeam Software Group GmbH

import entries from 'lodash/entries';
import groupByLodash from 'lodash/groupBy';

import type { Func1 } from 'infrastructure/types';

export type Group<TKey, TValue> = [key: TKey, items: TValue[]];

export const groupBy = <TKey extends string, TValue>(collection: TValue[], keyGetter: Func1<TValue, TKey>): Group<TKey, TValue>[] => {
    const notTypedGroups = groupByLodash(collection, keyGetter);
    return entries(notTypedGroups).map(([key, items]) => ([key as TKey, items]));
};
