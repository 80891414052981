// Copyright © Veeam Software Group GmbH

import type { EnumMap } from 'infrastructure/types';
import type { RestoreSessionStatus } from 'services/restoreService';

import { errorSrc, runningSrc, stoppingSrc, successSrc, warningSrc } from 'assets';

export const restoreSessionStatusIconMap: EnumMap<RestoreSessionStatus, string> = {
    Running: runningSrc,
    Success: successSrc,
    Warning: warningSrc,
    Failed: errorSrc,
    Stopping: stoppingSrc,
};
