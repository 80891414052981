// Copyright © Veeam Software Group GmbH

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { malachite } from '@veeam/components/themes';

import { ResourcesContextProvider } from 'infrastructure/resources';
import { ErrorManager } from 'infrastructure/error-management/ErrorContextProvider';
import { Login } from 'features/Login';
import { Main } from 'features/Main';
import { Router } from 'features/Routing';

export const App: React.FC = () => (
    <ThemeProvider theme={malachite}>
        <ResourcesContextProvider>
            <Login>
                <ErrorManager>
                    <Router>
                        <Main />
                    </Router>
                </ErrorManager>
            </Login>
        </ResourcesContextProvider>
    </ThemeProvider>
);
