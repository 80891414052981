// Copyright © Veeam Software Group GmbH

import { useEffect } from 'react';
import first from 'lodash/first';

import { restoreService } from 'services/restoreService';
import { convertFilter } from './useModel';

import type { SessionsModel } from './useModel';

export function useEvents(model: SessionsModel): void {
    useEffect(() => {
        const subscription = restoreService.sessions.onSessionChanged.subscribe((eventSession) => {
            model.update((mutable) => {
                const sessions = [...mutable.items];
                const session = sessions.find(item => item.id === eventSession.id);
                if (session) {
                    session.status = eventSession.status;
                    session.endTime = eventSession.endTime;
                    session.details = eventSession.details;
                } else sessions.push(eventSession);
                const filteredSessions = restoreService.sessions.filterSessions(convertFilter(mutable.filters), sessions);
                const sortedSessions = restoreService.sessions.sortSessions(first(mutable.sorting), filteredSessions);
                mutable.items = sortedSessions; // can be optimized
            });
        });
        return () => subscription.unsubscribe();
    }, []);
}
