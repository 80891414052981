// Copyright © Veeam Software Group GmbH

import { SORT_DIRECTION } from '@veeam/core';
import get from 'lodash/get';

import type { Moment } from 'moment';

interface SortKeysConstraint {
    [key: string]: string;
}

export interface Comparator<TRowValue> {
    (one: TRowValue, two: TRowValue): number;
}

export type Comparators<TSortKeys extends SortKeysConstraint, TValue> = {
    [TKey in keyof TSortKeys]: Comparator<TValue>;
};

export interface Sorting<TSortKeys extends SortKeysConstraint> {
    direction: SORT_DIRECTION;
    key: keyof TSortKeys;
}

function baseComparator<TValue>(
    one: TValue | undefined,
    two: TValue | undefined,
    concrete: Comparator<TValue>
): number {
    if (one === undefined && two === undefined) return 0;
    if (one === undefined) return -1;
    if (two === undefined) return 1;
    return concrete(one, two);
}

const stringComparator: Comparator<string> = (one, two) => one.localeCompare(two);
const dateComparator: Comparator<Moment> = (one, two) => one.diff(two);
const numberComparator: Comparator<number> = (one, two) => one - two;

export const comparators = {
    string: (one: string | undefined, two: string | undefined): number => baseComparator(one, two, stringComparator),
    date: (one: Moment | undefined, two: Moment | undefined): number => baseComparator(one, two, dateComparator),
    number: (one: number | undefined, two: number | undefined): number => baseComparator(one, two, numberComparator),
};

export type SortEngine<TRowValue, TSortKeys extends SortKeysConstraint> = (
    sorting: Sorting<TSortKeys> | undefined,
    values: TRowValue[]
) => TRowValue[];

export const createSortEngine = <TRowValue, TSortKeys extends SortKeysConstraint>(
    comparatorsMap: Comparators<TSortKeys, TRowValue>
): SortEngine<TRowValue, TSortKeys> => (sorting, values) => {
        if (!sorting) return values;
        const comparator = get(comparatorsMap, sorting.key);
        return values.sort((one, two) => comparator(one, two) * (sorting.direction === SORT_DIRECTION.asc ? 1 : -1));
    };
