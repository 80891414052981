// tslint:disable
﻿/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RESTLinkHAL,
} from './';

/**
 * @export
 * @interface RESTTeamsNode
 */
export interface RESTTeamsNode {
    /**
     * Display name of the teams item.
     * @type {string}
     * @memberof RESTTeamsNode
     */
    displayName?: string;
    /**
     * Type of the teams item.
     * @type {string}
     * @memberof RESTTeamsNode
     */
    type?: RESTTeamsNodeTypeEnum;
    /**
     * @type {{ [key: string]: RESTLinkHAL; }}
     * @memberof RESTTeamsNode
     */
    readonly _links?: { [key: string]: RESTLinkHAL; };
}

/**
 * @export
 * @enum {string}
 */
export enum RESTTeamsNodeTypeEnum {
    Tabs = 'Tabs',
    Files = 'Files',
    Posts = 'Posts'
}

