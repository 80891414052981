// Copyright © Veeam Software Group GmbH

import moment from 'moment';

import type { Duration, Moment } from 'moment';
import type {
    RESTRestoreSession,
    RESTRestoreSessionEvent } from 'api/rxjs';
import type {
    RestoreSessionConfig as RestoreSessionSignalR,
    RestoreSessionLogConfig as EventSignalR } from 'api/signalr';

import {
    RESTRestoreSessionResultEnum,
    RESTRestoreSessionStateEnum,
} from 'api/rxjs';
import {
    RestoreSessionStatus as RestoreSessionStatusSignalR,
    RestoreSessionResult as RestoreSessionResultSignalR,
    RestoreSessionType as RestoreSessionTypeSignalR,
    RestoreItemStatus as StatusSignalR,
} from 'api/signalr';
import {
    RestoreSessionStatus,
    RestoreSessionType,
    RestoreSessionEventsStatus,
} from '../models';
import { never } from 'infrastructure/never';

import type {
    RestoreSessionId,
    RestoreSession,
    RestoreSessionEvent,
    RestoreSessionEventId } from '../models';


function convertSessionStatusFromRest(
    state: RESTRestoreSessionStateEnum,
    result: RESTRestoreSessionResultEnum
): RestoreSessionStatus {
    switch (state) {
        case RESTRestoreSessionStateEnum.Working:
            return RestoreSessionStatus.Running;
        case RESTRestoreSessionStateEnum.Stopped: {
            switch (result) {
                case RESTRestoreSessionResultEnum.Success:
                    return RestoreSessionStatus.Success;
                case RESTRestoreSessionResultEnum.Warning:
                    return RestoreSessionStatus.Warning;
                case RESTRestoreSessionResultEnum.Failed:
                    return RestoreSessionStatus.Failed;
                default:
                    return never(result);
            }
        }
        default:
            return never(state);
    }
}

export const convertSessionFromRest = ({
    id,
    creationTime,
    endTime,
    type,
    state,
    result,
    details,
    reason,
    scopeName,
}: RESTRestoreSession): RestoreSession => ({
    id: id as RestoreSessionId,
    startTime: moment(creationTime),
    endTime: endTime && !endTime.startsWith('9999') ? moment(endTime) : undefined,
    status: convertSessionStatusFromRest(state, result),
    type,
    details: details || '',
    reason: reason || '',
    scopeName: scopeName ?? '',
});

function convertSessionStatusFromSignalR(
    state: RestoreSessionStatusSignalR,
    result: RestoreSessionResultSignalR
): RestoreSessionStatus {
    switch (state) {
        case RestoreSessionStatusSignalR.Running:
            return RestoreSessionStatus.Running;
        case RestoreSessionStatusSignalR.Stopped: {
            switch (result) {
                case RestoreSessionResultSignalR.Success:
                    return RestoreSessionStatus.Success;
                case RestoreSessionResultSignalR.Warning:
                    return RestoreSessionStatus.Warning;
                case RestoreSessionResultSignalR.Failed:
                    return RestoreSessionStatus.Failed;
                default:
                    return never(result);
            }
        }
        default:
            return never(state);
    }
}

function convertSessionTypeFromSignalR(type: RestoreSessionTypeSignalR): RestoreSessionType {
    switch (type) {
        case RestoreSessionTypeSignalR.Exchange:
            return RestoreSessionType.Vex;
        case RestoreSessionTypeSignalR.SharePoint:
            return RestoreSessionType.Vesp;
        case RestoreSessionTypeSignalR.OneDrive:
            return RestoreSessionType.Veod;
        case RestoreSessionTypeSignalR.Teams:
            return RestoreSessionType.Vet;
        default:
            return never(type);
    }
}

export const convertSessionFromSignalR = ({
    Id,
    Status,
    Result,
    SessionType,
    StartTime,
    EndTime,
    Details,
}: RestoreSessionSignalR): RestoreSession => ({
    id: Id as RestoreSessionId,
    status: convertSessionStatusFromSignalR(Status, Result),
    type: convertSessionTypeFromSignalR(SessionType),
    startTime: moment(StartTime),
    endTime: EndTime && !EndTime.startsWith('9999') ? moment(EndTime) : undefined,
    details: Details,
});

export const convertEventFromRest = (
    sessionId: RestoreSessionId,
    { id, message, startTime, endTime, duration, status, order }: RESTRestoreSessionEvent
): RestoreSessionEvent => ({
    id: id as RestoreSessionEventId,
    sessionId,
    status,
    message,
    order,
    startTime: moment(startTime),
    endTime: endTime ? moment(endTime) : undefined,
    duration: moment.duration(duration),
});

function convertEventStatusFromSignalR(status: StatusSignalR): RestoreSessionEventsStatus {
    switch (status) {
        case StatusSignalR.Running:
            return RestoreSessionEventsStatus.Running;
        case StatusSignalR.Skipped:
        case StatusSignalR.Success:
            return RestoreSessionEventsStatus.Success;
        case StatusSignalR.Warning:
            return RestoreSessionEventsStatus.Warning;
        case StatusSignalR.Failed:
            return RestoreSessionEventsStatus.Failed;
        case StatusSignalR.Cancelled:
            return RestoreSessionEventsStatus.Cancelled;
        default:
            return never(status);
    }
}

function convertTime(event: EventSignalR): { startTime: Moment; endTime?: Moment; duration: Duration; } {
    const startTime = moment(event.StartTime);
    const endTime = event.EndTime && !event.EndTime.startsWith('9999') ? moment(event.EndTime) : undefined;
    const duration = moment.duration((endTime || moment()).diff(startTime));
    return { startTime, endTime, duration };
}

export const convertEventFromSignalR = (event: EventSignalR): RestoreSessionEvent => ({
    id: event.Id as RestoreSessionEventId,
    sessionId: event.RestoreSessionId as RestoreSessionId,
    message: event.DisplayText!, // fix in backend
    status: convertEventStatusFromSignalR(event.Status),
    order: event.Order,
    ...convertTime(event),
});
