// Copyright © Veeam Software Group GmbH

import service from './ResourcesService';

import type { LocalizableString as _LocalizableString } from './LocalizableString';
import type { LanguageConstraint as _LanguageConstraint } from './Types';

export { ResourcesContextProvider } from './ResourcesContextProvider';
export { useResources } from './useResources';

export type LocalizableString = _LocalizableString;
export type ResourcesNode = _LanguageConstraint;

export type Resources = typeof service.current;

export default service;
