// Copyright © Veeam Software Group GmbH

import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/style.css';
import './global-styles.css';

import { App } from './App';

ReactDOM.render(<App/>, document.getElementById('app'));
